import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoundButton from '../../components/RoundButton'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import DeleteIntegrationModal from '../../components/modals/DeleteIntegrationModal'
import { Redirect } from '../../components/Link'
import apiFetch from '../../../../_shared/api/apiFetch'
export class IntegrationManageDropdownOptions extends React.PureComponent {
  state = {
    dropdownOpen: false,
    isLoading: false,
    redirect: null,
  }

  static propTypes = {
    integration: PropTypes.object.isRequired,
  }

  duplicate = () => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const integration = await apiFetch('POST', '/integrations/copy', {
            integrationId: this.props.integration._id,
          })
          this.setState({
            isLoading: false,
            redirect: '/integrations/' + integration.integrationId,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }

    return (
      <div>
        <DropdownItem onClick={this.duplicate}>
          <FontAwesomeIcon fixedWidth icon={faCopy} /> Duplicate
        </DropdownItem>
        <DropdownItem divider />
        <DeleteIntegrationModal
          redirect="/integrations"
          buttonTag={DropdownItem}
          buttonProps={{ toggle: false }}
          integration={this.props.integration}
        />
      </div>
    )
  }
}

export default class IntegrationManageDropdown extends React.PureComponent {
  dropdownRef = React.createRef()
  state = {
    dropdownOpen: false,
  }

  static propTypes = {
    integration: PropTypes.object.isRequired,
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest text-small" right persist>
          <IntegrationManageDropdownOptions integration={this.props.integration} />
        </DropdownMenu>
      </Dropdown>
    )
  }
}
