import React from 'react'
import PropTypes from 'prop-types'
import Select from '../../../_shared/components/Form/Select'
import apiFetch from '../../../_shared/api/apiFetch'

class PathwaySelect extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    filter: PropTypes.object,
    mock: PropTypes.array,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func,
  }

  search = async (value, callback) => {
    const { mock, filter } = this.props
    if (mock) {
      callback(mock)
      return
    }

    try {
      const result = await apiFetch(
        'GET',
        '/pathways/search',
        {},
        {
          name: value,
          filter,
        }
      )

      return result.map((d) => {
        return { label: d.name, value: d._id }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { name, isMulti, onChange } = this.props
    const selectProps = {
      isMulti,
      placeholder: 'Search pathways',
      noOptionsMessage: () => 'No pathways found',
      loadOptions: this.search,
      defaultOptions: true,
    }

    if (onChange) {
      selectProps.onChange = onChange
    }

    return <Select name={name} selectProps={selectProps} />
  }
}

export default PathwaySelect
