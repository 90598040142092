import React from 'react'
import Select from '../../../_shared/components/Form/Select'
import PropTypes from 'prop-types'
import apiFetch from '../../../_shared/api/apiFetch'
import qs from 'qs'

class CampusSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    companyId: PropTypes.string.isRequired,
    mock: PropTypes.array,
  }

  search = async (value, callback) => {
    const { mock, companyId } = this.props
    if (mock) {
      callback(mock)
      return
    }

    try {
      const search = await apiFetch('GET', '/campuses/search', {}, { companyId, name: value })

      return search.map((d) => {
        return { label: d.name, value: d._id }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { name } = this.props

    return (
      <Select
        name={name}
        selectProps={{
          isMulti: true,
          placeholder: 'Search campuses',
          noOptionsMessage: () => 'No campuses found',
          loadOptions: this.search,
          defaultOptions: true,
        }}
      />
    )
  }
}

export default CampusSelect
