import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import CompanyForm from './CompanyForm'
import PageActions, { PageAction } from '../../../components/PageActions'
import { DropdownItem } from 'reactstrap'
import DisableCompanyModal from '../../../components/modals/DisableCompanyModal'
import ArchiveCompanyModal from '../../../components/modals/ArchiveCompanyModal'
import DeleteCompanyModal from '../../../components/modals/DeleteCompanyModal'
import MoveCompanyModal from '../../../components/modals/MoveCompanyModal'
import { withRouter } from 'react-router-dom'
import View from '../../../components/View'
import NotFound from '../../NotFound'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'

class CompanyUpdate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      error: false,
    }
  }

  static propTypes = {
    company: PropTypes.object.isRequired,
    mock: PropTypes.object,
    history: PropTypes.object.isRequired,
  }

  getCrumbs() {
    return [{ label: 'Clients', path: '/admin/clients' }]
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/companies/update', {
          companyId: this.props.company._id,
          doc: data.formData,
        })
        this.props.history.push(`/admin/clients`)
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { company, mock } = this.props
    const title = `Edit ${company.name}`

    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={this.getCrumbs()}>
          <PageActions>
            {/* <PageAction button>
              <DisableCompanyModal company={company} />
            </PageAction> */}
            {/* <PageAction button>
              <ArchiveCompanyModal company={company} />
            </PageAction> */}
            {/* <PageAction tag="div">
              <DropdownItem divider />
            </PageAction> */}
            {/* <PageAction button>
              <MoveCompanyModal company={company} />
            </PageAction> */}
            <PageAction button>
              <DeleteCompanyModal company={company} />
            </PageAction>
          </PageActions>
        </PageHeader>
        <div className="content">
          <CompanyForm
            isLoading={this.state.isLoading}
            onSubmit={this.onSubmit}
            formData={company}
            buttonLabel="Save Changes"
          />
        </div>
      </View>
    )
  }
}

const CompanyUpdateWithRouter = withRouter(CompanyUpdate)

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="company"
      query={{ _id: props.match.params.companyId }}
      fields={[
        '_id',
        'name',
        'website',
        'phone',
        'reporterApiKey',
        'notes',
        'bundles',
        'features',
        'type',
        'reps',
      ]}
      showLoader={true}
    >
      {({ data }) => {
        if (!data?.companies || data.companies.length === 0) {
          return <NotFound />
        }

        return <CompanyUpdateWithRouter company={data.companies[0]} />
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
