import React from 'react'
import Link from './Link'
import { UncontrolledToggleButton } from './ToggleButton'
import { Badge } from 'reactstrap'
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import NotificationType from '../../../_shared/models/notification/notification.client'
import { PanelListItem } from './PanelList'
import styled from 'styled-components'
interface NotiticationStatusProps {
  pathway: Pathway
  toggleNotificationStatus: (notification: NotificationType) => void
  doesThisPathwayHaveThisIntegration: (
    pathwayId: string
  ) => DoesThisPathwayHaveThisIntegrationReturnType
}
type DoesThisPathwayHaveThisIntegrationReturnType = {
  isInPathway: boolean
  isEnabled: boolean
  hasTriggers: boolean
  matchingNotifications: NotificationType[]
}


const StyledPanelListItem = styled(PanelListItem)`
  border-bottom: 1px solid var(--border-color);
  padding: 0;
  margin: 0;
  background-color: #fff;
`
const PathwayName = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.75rem 0.5rem;

  & > span {
    margin-right: 0.5rem;
    width: 80px;
    border: 2px solid #f0f2f6;
  }
`
const NotificationStyleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 2px solid #f0f2f6;
  border-radius: 5px;
  margin-top: 8px;
  padding: 0.5rem;
  font-size: 0.8rem;
`
const NotificationStatus: React.FC<NotiticationStatusProps> = ({
  pathway,
  toggleNotificationStatus,
  doesThisPathwayHaveThisIntegration,
}) => {
  const { isInPathway, isEnabled, hasTriggers, matchingNotifications } =
    doesThisPathwayHaveThisIntegration(pathway._id)
  const renderBadge = ({ isInPathway, hasTriggers, isEnabled }) => {
    if (!isInPathway) return <Badge color="black">Not Setup</Badge>
    if (!hasTriggers) return <Badge color="danger">Triggers</Badge>
    return isEnabled ? (
      <Badge color="success">Active</Badge>
    ) : (
      <Badge color="warning">Inactive</Badge>
    )
  }

  return (
    <StyledPanelListItem key={pathway._id}>
      <PathwayName>
        {renderBadge({ isInPathway, hasTriggers, isEnabled })}
        <Link to={`/pathways/${pathway._id}/notifications`}>
          <span style={{color: isInPathway ? "primary" : "grey"}}>{pathway.name}</span>
        </Link>
      </PathwayName>
      {matchingNotifications.map((notification) => (
        <div key={notification._id}>
          <NotificationStyleWrapper>
            <Link to={`/pathways/${pathway._id}/notifications/${notification._id}`}>
              {notification.name}
            </Link>
            <UncontrolledToggleButton
              className="toggler"
              activeLabel="Enabled"
              inactiveLabel="Disabled"
              icon={faPowerOff}
              iconProps={{ color: notification.isEnabled ? 'white' : 'primary' }}
              value={notification.isEnabled}
              toggle={() => toggleNotificationStatus(notification)}
            />
          </NotificationStyleWrapper>
        </div>
      ))}
    </StyledPanelListItem>
  )
}

export default NotificationStatus
