import differenceInDays from 'date-fns/differenceInDays'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'

const generateChartData = function (aggregateResult, start, end, valueKey = 'registrations') {
  const labels = []
  const values = []

  // Generate a map from the aggregate result array
  const dataMap = aggregateResult.reduce((map, obj) => {
    map[obj._id] = obj
    return map
  }, {})

  // Calculate the difference in days between start and end
  // Use it to decide the label format
  const diff = differenceInDays(end, start)
  let labelFormat = 'MMM d yyyy'
  if (diff < 365) {
    labelFormat = 'MMM d'
  }

  let day = start

  while (isBefore(day, end) || isSameDay(day, end)) {
    labels.push(format(day, labelFormat))
    const dayFormatted = format(day, 'yyyy-MM-dd')
    const dayData = dataMap[dayFormatted]
    if (dayData && dayData[valueKey]) {
      values.push(dayData[valueKey])
    } else {
      values.push(0)
    }
    day = addDays(day, 1)
  }

  return {
    labels,
    values,
  }
}

export default generateChartData
