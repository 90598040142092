import BaseIntegrationAdapter from '../BaseIntegrationAdapter'

export interface IntegrationEmailConfig {
  to: string
  bcc?: string
  replyTo?: string
  subject: string
  body?: string
  pdf?: string
  mappingScript?: string
  pdfTimezone?: string
}

export const EmailAdapterSchema = {
  type: 'object',
  properties: {
    to: {
      title: 'To',
      type: 'string',
    },
    subject: {
      title: 'Subject',
      type: 'string',
    },
    replyTo: {
      title: 'Reply To',
      type: 'string',
    },
    pdf: {
      title: 'Attach a PDF',
      type: 'string',
    },
    pdfTimezone: {
      title: 'PDF Timezone',
      type: 'string',
    },
    body: {
      title: 'Body',
      type: 'string',
    },
    mappingScript: {
      title: 'Pre-processing Script',
      type: 'string',
    },
  },
  required: ['to', 'subject'],
  additionalProperties: false,
}

class EmailAdapter extends BaseIntegrationAdapter {
  name = 'Email'
  key = 'email'
  description =
    'Send an email to one or more recipients. Great for sending notifications and reports.'
  image = 'email'
  schema = EmailAdapterSchema
}

export default EmailAdapter
