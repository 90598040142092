import React from 'react'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter'
import SlidingPanel from '../../../components/SlidingPanel'
import CampusSelect from '../../../components/CampusSelect'
import ProgramSelect from '../../../components/ProgramSelect'
import PathwayStatisticsFilterSchema from './PathwayStatisticsFilterSchema'
import AnswersFilter from '../../results/AnswersFilter'
import Form, { FormContext, FormState } from '../../../../../_shared/components/Form'
import ButtonGroup, { ButtonGroupButton } from '../../../../../_shared/components/Form/ButtonGroup'
import Slider from '../../../../../_shared/components/Form/Slider'
import Input from '../../../../../_shared/components/Form/Input'
import Pathway from '../../../../../_shared/models/pathway/pathway.backend.client'
import { Company } from '../../../../../_shared/models/company/company.client'
import { useSelector } from 'react-redux'
import { AppState } from '../../../types'

interface PathwayStatisticsFilterPanelProps {
  isOpen?: boolean
  filter?: Record<string, any>
  onChange?: (formState: FormState) => void
  toggle?: () => void
  pathway: Pathway
}

export const PathwayStatisticsFilterPanel: React.FC<PathwayStatisticsFilterPanelProps> = (
  props
) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => (state.subscriptions.companyContext.data.companies[0] as Company) as Company
  )
  return (
    <SlidingPanel title="Filter" isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        formData={props.filter || {}}
        schema={PathwayStatisticsFilterSchema}
        onValidSubmit={props.onChange}
        validateOnChange={true}
      >
        <AnswersFilter pathway={props.pathway} />

        <CampusSelect name="campuses" defaultOptions={true} companyId={selectedCompany._id} />
        <ProgramSelect name="programs" defaultOptions={true} companyId={selectedCompany._id} />
        <ButtonGroup name="progress" defaultValue="any" size="sm">
          <ButtonGroupButton label="Any" value="any" />
          <ButtonGroupButton label="In Progress" value="inprogress" />
          <ButtonGroupButton label="Completed" value="completed" />
        </ButtonGroup>

        <Slider name="score" min={0} max={100} range={true} />

        <Input type="text" name="lead_src" size="sm" />
        <Input type="text" name="referrer" size="sm" />

        <hr />
        <FormContext.Consumer>
          {(formContext) => {
            return (
              <Button
                color="primary"
                type="submit"
                block
                onClick={props.toggle}
                disabled={Object.values(formContext.errors).length > 0}
              >
                Apply Filters
              </Button>
            )
          }}
        </FormContext.Consumer>
      </Form>
    </SlidingPanel>
  )
}

interface Props {
  onChange?: (formState: FormState) => void
  filter?: Record<string, any>
  pathway: Pathway
}

interface State {
  isOpen: boolean
}

export default class PathwayStatisticsFilter extends React.Component<Props, State> {
  state = {
    isOpen: false,
  }

  toggle = (): void => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <Button color="alt" onClick={this.toggle}>
          <FontAwesomeIcon icon={faFilter} size="sm" /> Filter
        </Button>
        <PathwayStatisticsFilterPanel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          filter={this.props.filter}
          pathway={this.props.pathway}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    )
  }
}
