import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { redirect } from '../Link'
import { withRouter } from 'react-router-dom'
import apiFetch from '../../../../_shared/api/apiFetch'

class DeleteUserModal extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }

  callMethod = async () => {
    try {
      await apiFetch('DELETE', '/users/delete', { userId: this.props.user._id })
      const { location, history } = this.props
      if (this.props.redirect) {
        redirect({
          location,
          history,
          pathname: this.props.redirect,
        })
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete User"
        message={`Are you sure you want to delete ${this.props.user.profile?.name}?`}
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faTimes} fixedWidth /> Delete
      </BasicModal>
    )
  }
}

export default withRouter(DeleteUserModal)
