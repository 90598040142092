export const PersonaColors = {
  primary: 'hsl(199,92%,85%)',
  secondary: 'hsl(199,92%,56%)',
  tertiary: 'hsl(202,98%,48%)',
  quaternary: 'hsl(213,64%,38%)',
  quinary: 'hsl(199,92%,56%)',

  analytical: 'hsl(52,100%,54%)',
  driver: 'hsl(198,75%,55%)',
  amiable: 'hsl(126,45%,50%)',
  expressive: 'hsl(40,97%,50%)',

  barriers: '#c42636',
  cautions: '#e8861b',
  highlights: '#1bab2c',

  ageChildPoint: 'hsl(144,59%,57%)',
  ageYoungPoint: 'hsl(175,100%,26%)',
  ageAdultPoint: 'hsl(189,100%,24%)',

  unknown: 'hsl(0,0%,65%)',

  vennOne: '#029af2',
  vennTwo: '#26ce41',
  vennThree: '#885fdf',
}
