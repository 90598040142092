import React from 'react'
import Panel, { PanelHero } from './Panel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'

const FirstCompany = (
  <Panel>
    <PanelHero>
      <img src="/images/building.png" width="128" />
      <h3>Create your first client</h3>
      <h6>Easily organize pathways &amp; users</h6>
      <button className="btn btn-lg btn-primary">
        <FontAwesomeIcon icon={faPlus} /> New Client
      </button>
    </PanelHero>
  </Panel>
)

export default FirstCompany
