import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import { Route, Switch } from 'react-router-dom'
import View from '../../components/View'
import CompanySettings from './CompanySettings'
import CampusIndex from './campuses/CampusIndex'
import ProgramIndex from './programs/ProgramIndex'
import DomainsIndex from './domains/DomainsIndex'
import ProgramGroupIndex from './programGroups/ProgramGroupIndex'
import CareerFieldIndex from './careerFields/CareerFieldIndex'
import CRMImport from './crmImport/CRMImport'

const CompanyRoute = ({ component: Component, company, ...rest }) => (
  <Route {...rest} render={(p) => <Component {...p} company={company} />} />
)

const CompanyView = (props) => {
  const { company } = props
  return (
    <View title={`Settings for ${company.name}`}>
      <PageHeader
        title={props.company.name}
        navItems={[
          { label: 'Account', route: `/organization` },
          { label: 'Domains', route: `/organization/domains` },
          {
            label: 'Program Groups',
            route: `/organization/programGroups`,
          },
          {
            label: 'Career Fields',
            route: `/organization/careerFields`,
          },
          {
            label: 'CRM Import',
            route: `/organization/crmImport`,
          },
        ]}
      />

      <div className="content">
        <Switch>
          <CompanyRoute path="/organization" exact component={CompanySettings} company={company} />
          <CompanyRoute
            path="/organization/domains"
            exact
            component={DomainsIndex}
            company={company}
          />
          <CompanyRoute
            path="/organization/campuses"
            exact
            component={CampusIndex}
            company={company}
          />
          <CompanyRoute
            path="/organization/programs"
            exact
            component={ProgramIndex}
            company={company}
          />
          <CompanyRoute
            path="/organization/programGroups"
            exact
            component={ProgramGroupIndex}
            company={company}
          />
          <CompanyRoute
            path="/organization/careerFields"
            exact
            component={CareerFieldIndex}
            company={company}
          />
          <CompanyRoute
            path="/organization/crmImport"
            exact
            component={CRMImport}
            company={company}
          />
        </Switch>
      </div>
    </View>
  )
}

CompanyView.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default CompanyView
