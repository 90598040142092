import React from 'react'
import PropTypes from 'prop-types'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import RoundButton from '../../components/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import DeleteNotificationModal from '../../components/modals/DeleteNotificationModal'
import { Redirect } from '../../components/Link'
import apiFetch from '../../../../_shared/api/apiFetch'

export class NotificationManageDropdownOptions extends React.PureComponent {
  state = {
    isLoading: false,
    redirect: null,
  }
  static propTypes = {
    notification: PropTypes.object.isRequired,
    deleteRedirect: PropTypes.string,
  }

  duplicate = () => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const notification = await apiFetch('POST', '/notifications/copy', {
            notificationId: this.props.notification._id,
          })
          this.setState({
            isLoading: false,
            redirect: `/pathways/${this.props.notification.pathwayId}/notifications/${notification.notificationId}`,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { notification, deleteRedirect } = this.props

    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }

    return (
      <div>
        <DropdownItem onClick={this.duplicate} toggle={false}>
          <FontAwesomeIcon fixedWidth icon={faCopy} /> Duplicate
        </DropdownItem>
        <DropdownItem divider />
        <DeleteNotificationModal
          notification={notification}
          redirect={deleteRedirect}
          buttonTag={DropdownItem}
          buttonProps={{ toggle: false }}
        />
      </div>
    )
  }
}

export default class NotificationManageDropdown extends React.Component {
  static propTypes = {
    notification: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }

    this.dropdownRef = React.createRef()
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest text-small" right persist>
          <NotificationManageDropdownOptions notification={this.props.notification} />
        </DropdownMenu>
      </Dropdown>
    )
  }
}
