import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import View from '../../../components/View'
import CompanyForm from './CompanyForm'
import { withRouter } from 'react-router-dom'
import apiFetch from '../../../../../_shared/api/apiFetch'

function breadcrumbs(props) {
  return [{ label: 'Clients', path: '/admin/clients' }]
}

class CompanyCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      error: false,
    }
  }

  static propTypes = {
    mock: PropTypes.object,
    history: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const company = await apiFetch('POST', '/companies/create', { doc: data.formData })
        this.props.history.push(`/?a=${company.companyId}`)
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { mock } = this.props
    const title = 'Create Client'
    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={breadcrumbs(this.props)} />
        <div className="content">
          <CompanyForm
            bundles={mock && mock.bundles}
            onSubmit={this.onSubmit}
            isLoading={this.state.isLoading}
            buttonLabel="Create Client"
          />
        </div>
      </View>
    )
  }
}

export default withRouter(CompanyCreate)
