import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../components/Panel'
import Link from '../../components/Link'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faArrows } from '@fortawesome/pro-regular-svg-icons/faArrows'
import RoundButton from '../../components/RoundButton'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import NewButton from '../../components/NewButton'
import View from '../../components/View'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { PanelList, PanelListItem } from '../../components/PanelList'
import { withAlert } from 'react-alert'
import DeleteStepModal from '../../components/modals/DeleteStepModal'
import apiFetch from '../../../../_shared/api/apiFetch'

if (process.env.IS_CLIENT) {
  // todo: this probably needs to be fixed or re-stubbed
  // Meteor.methods({
  //   ReorderSteps(pathwayId, stepOrder) {
  //     const query = {}
  //     stepOrder.forEach((stepId, index) => {
  //       query[`steps.${stepId}.order`] = index
  //     })
  //     Pathways.update(
  //       { _id: pathwayId },
  //       {
  //         $set: query,
  //       }
  //     )
  //     Tracker.flush()
  //   },
  // })
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})

// what is the db update query going to look like?
// send array of stepids in correct order to meteor
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

class StepDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      error: null,
      isLoading: false,
      dropdownOpen: false,
    }

    this.dropdownRef = React.createRef()
  }

  static propTypes = {
    pathway: PropTypes.object,
    step: PropTypes.object,
  }

  duplicate = () => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/steps/copy', {
            pathwayId: this.props.pathway._id,
            stepId: this.props.step._id,
          })
        } catch (e) {
          throw new Error(e)
        }

        this.setState({
          isLoading: false,
        })
      }
    )
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    const { step, pathway } = this.props
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest text-small" right>
          <DropdownItem onClick={this.duplicate}>
            <FontAwesomeIcon fixedWidth icon={faCopy} /> Duplicate
          </DropdownItem>
          <DropdownItem divider />
          <DeleteStepModal
            step={step}
            pathway={pathway}
            buttonTag={DropdownItem}
            buttonProps={{ toggle: false }}
          />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const FirstStep = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/map.png" width="128" />
      <h3>Create your first step</h3>
      <h6>Gather prospects, view statistics, insights and more</h6>
      <Link
        tag="button"
        to={`/pathways/${props.pathway._id}/newStep`}
        className="btn btn-lg btn-primary"
      >
        <FontAwesomeIcon icon={faPlus} /> New Step
      </Link>
    </PanelHero>
  </Panel>
)

const Step = (props) => (
  <div className="row align-items-center flex-nowrap">
    <div className="col">
      <div className="panel-list-item-title">
        <Link to={`/pathways/${props.pathway._id}/steps/${props.step._id}`}>{props.step.name}</Link>
      </div>
    </div>
    <div className="d-flex flex-nowrap col-auto buttons">
      <RoundButton
        size="sm"
        tag={Link}
        to={`/pathways/${props.pathway._id}/steps/${props.step._id}`}
        className="d-none d-md-inline-block"
      >
        <FontAwesomeIcon icon={faPencil} />
      </RoundButton>
      <RoundButton size="sm" {...props.dragHandleProps} tag="div">
        <FontAwesomeIcon icon={faArrows} />
      </RoundButton>
      <StepDropdown pathway={props.pathway} step={props.step} />
    </div>
  </div>
)

Step.propTypes = {
  step: PropTypes.object.isRequired,
  pathway: PropTypes.object.isRequired,
  dragHandleProps: PropTypes.object,
}

class StepIndex extends React.Component {
  onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const stepOrder = reorder(
      this.props.pathway.getSteps(),
      result.source.index,
      result.destination.index
    ).map((step) => step._id)

    try {
      await apiFetch('POST', '/steps/reorder', { pathwayId: this.props.pathway._id, stepOrder })
      this.props.alert.show('Pathway saved')
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { pathway, className } = this.props

    if (!pathway.steps || pathway.steps.length === 0) {
      return <FirstStep pathway={pathway} />
    }

    return (
      <div className={className}>
        <View title={pathway.name}>
          <DragDropContext onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <PanelList provided={provided}>
                  {pathway.getSteps().map((p, index) => (
                    <Draggable key={p._id} draggableId={p._id} index={index}>
                      {(provided, snapshot) => (
                        <PanelListItem
                          provided={provided}
                          {...provided.draggableProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <Step
                            key={p._id}
                            step={p}
                            pathway={pathway}
                            dragHandleProps={provided.dragHandleProps}
                          />
                        </PanelListItem>
                      )}
                    </Draggable>
                  ))}
                </PanelList>
              )}
            </Droppable>
          </DragDropContext>
          <NewButton label="New Step" tag={Link} to={`/pathways/${pathway._id}/newStep`} center />
        </View>
      </div>
    )
  }
}

StepIndex.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default withAlert()(styled(StepIndex)`
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`)
