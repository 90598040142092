import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelHero } from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import PageActions, { PageAction } from '../../../components/PageActions'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import RoundButton from '../../../components/RoundButton'
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import View from '../../../components/View'
import Link from '../../../components/Link'
import { PanelList, PanelListItem } from '../../../components/PanelList'
import DeleteUserModal from '../../../components/modals/DeleteUserModal'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'

class UserDropdown extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const { user } = this.props
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag={RoundButton} size="sm">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest" right persist>
          <DeleteUserModal user={user} buttonTag={DropdownItem} buttonProps={{ toggle: false }} />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const User = (props) => {
  const { user } = props
  return (
    <PanelListItem>
      <div className="row align-items-center flex-nowrap">
        <div className="col">
          <Link to={`/admin/users/${user._id}`}>{user.profile?.name}</Link>
          <div className="email text-smaller text-muted-blue">{user.username}</div>
        </div>
        <div className="d-none d-md-flex col-auto buttons">
          <RoundButton size="sm" tag={Link} to={`/admin/users/${user._id}`}>
            <FontAwesomeIcon icon={faPencil} />
          </RoundButton>
          <UserDropdown user={user} />
        </div>
      </div>
    </PanelListItem>
  )
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  deleteUser: PropTypes.func,
}

class AdminUserIndex extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  render() {
    const title = 'Users'
    return (
      <View title={title}>
        <PageHeader title={title}>
          <PageActions>
            <PageAction
              toolbar
              button
              color="primary"
              tag={Link}
              tagProps={{ to: '/admin/users/new' }}
            >
              <FontAwesomeIcon icon={faPlus} /> New User
            </PageAction>
          </PageActions>
        </PageHeader>
        <div className="content">
          <SubscriptionLoader
            resource="users"
            query={{ roles: 'admin' }}
            sort={{ 'profile.name': 1 }}
            showLoader={true}
            fields={['_id', 'profile', 'username']}
            transform={(store) => ({
              ...store,
              users: sort(store.users).asc((r) => r.profile?.name),
            })}
          >
            {({ data }) => {
              const { users } = data
              return (
                <PanelList>{users && users.map((u) => <User key={u._id} user={u} />)}</PanelList>
              )
            }}
          </SubscriptionLoader>
        </div>
      </View>
    )
  }
}

export default AdminUserIndex
