import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'
import Link, { Redirect } from '../../components/Link'
import styled from 'styled-components'
import NotFound from '../NotFound'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faRedo } from '@fortawesome/pro-solid-svg-icons/faRedo'
import { faUndo } from '@fortawesome/pro-solid-svg-icons/faUndo'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop'
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile'
import { faTablet } from '@fortawesome/pro-regular-svg-icons/faTablet'
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil'
import '../../../../pagebuilder/blocks/index.editor'
import PageBuilderWrapper from '../../../../pagebuilder/PageBuilderWrapper'
import Sidebar from '../../../../pagebuilder/components/Sidebar'
import ReportSchema from '../../../../_shared/models/report/ReportSchema'
import PathwayContext from '../../../../pagebuilder/PathwayContext'
import View from '../../components/View'
import { withAlert } from 'react-alert'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { connect } from 'react-redux'
import { ReactFrontendContext } from '../../../../frontend/client/containers/StepViewContainer'
import ClientProgramGroup from '../../../../_shared/models/programGroup/programGroup.client'
import { editorUpdatePathway, editorUpdateProgramGroup } from '../../editorFrame/editorRedux'
import { RESTDataLoader } from '../../containers/RESTDataLoader'
import apiFetch from '../../../../_shared/api/apiFetch'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'

const { name } = ReportSchema.properties

const ReportFormSchema = {
  title: 'Pathway Form',
  type: 'object',
  properties: { name },
  required: ['name'],
}

class ReportUpdateSidebar extends React.PureComponent {
  static propTypes = {
    setFormData: PropTypes.func,
    report: PropTypes.object.isRequired,
  }

  render() {
    const { setFormData, report } = this.props
    const { name, isDefault } = report

    return (
      <Sidebar title={'Summary Settings'}>
        <Form
          formData={{ name, isDefault }}
          formSchema={ReportFormSchema}
          validateOnChange
          onValidChange={setFormData}
          warnOnUnsavedLeave={false}
        >
          <Input name="name" />
        </Form>
      </Sidebar>
    )
  }
}
class ReportUpdate extends React.Component {
  state = {
    isLoading: true,
    isSaving: false,
    redirect: null,
    previewMode: 'desktop',
    editMode: 'edit',
    formData: null,
  }

  static propTypes = {
    report: PropTypes.object.isRequired,
    pathway: PropTypes.object.isRequired,
    className: PropTypes.string,
    programGroup: PropTypes.object,
  }

  setFormData = ({ formData }) => {
    this.setState({
      formData,
    })
  }

  save = async (content) => {
    const { report, alert } = this.props
    const { formData } = this.state

    try {
      await apiFetch('POST', '/reports/update', {
        reportId: report._id,
        doc: { ...report, ...formData, content },
      })
      alert.show('Report saved')
      this.setState({
        isSaving: false,
      })
    } catch (e) {
      this.setState({ isSaving: false, error: e })
      throw new Error(e)
    }
  }

  onLoad = () => {
    this.setState({
      isLoading: false,
    })
  }

  changeEditMode(editMode) {
    this.setState({
      editMode,
    })
  }

  changePreviewMode(previewMode) {
    this.setState({
      previewMode,
    })
  }

  requestSave = () => {
    this.setState(
      {
        isSaving: true,
      },
      () => {
        document.querySelector('.editor-iframe').contentWindow.postMessage('pb_requestContent', '*')
      }
    )
  }

  render() {
    const { pathway, report, programGroup, className } = this.props

    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }

    const Sidebar = <ReportUpdateSidebar report={report} setFormData={this.setFormData} />

    return (
      <View title={`Edit ${report.name} - ${pathway.name}`}>
        <div className={className}>
          <header className="header">
            <div className="row align-items-center">
              <div className="col-auto">
                <Button color="alt" tag={Link} to={`/pathways/${pathway._id}/summaries`}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                  &nbsp;&nbsp;Exit
                </Button>
              </div>
              <div className="col-auto">
                <h2>{report.name}</h2>
              </div>
              <div className="col" />
              <div className="col-auto">
                <ButtonGroup>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changeEditMode('edit')
                    }}
                    active={this.state.editMode === 'edit'}
                  >
                    <FontAwesomeIcon icon={faPencil} fixedWidth />
                  </Button>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changeEditMode('preview')
                    }}
                    active={this.state.editMode === 'preview'}
                  >
                    <FontAwesomeIcon icon={faEye} fixedWidth />
                  </Button>
                </ButtonGroup>
              </div>
              <div className="col-auto">
                <ButtonGroup>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changePreviewMode('desktop')
                    }}
                    active={this.state.previewMode === 'desktop'}
                  >
                    <FontAwesomeIcon icon={faDesktop} fixedWidth />
                  </Button>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changePreviewMode('tablet')
                    }}
                    active={this.state.previewMode === 'tablet'}
                  >
                    <FontAwesomeIcon icon={faTablet} fixedWidth />
                  </Button>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changePreviewMode('mobile')
                    }}
                    active={this.state.previewMode === 'mobile'}
                  >
                    <FontAwesomeIcon icon={faMobile} fixedWidth />
                  </Button>
                </ButtonGroup>
              </div>
              {/* <div className="col-auto">
                <ButtonGroup>
                  <Button color="alt" id="undo" disabled={this.state.isLoading}>
                    <FontAwesomeIcon icon={faUndo} fixedWidth />
                  </Button>
                  <Button color="alt" id="redo" disabled={this.state.isLoading}>
                    <FontAwesomeIcon icon={faRedo} fixedWidth />
                  </Button>
                </ButtonGroup>
              </div> */}
              <div className="col-auto">
                <Button
                  color="primary"
                  onClick={this.requestSave}
                  disabled={this.state.isLoading || this.state.isSaving}
                >
                  Save
                </Button>
              </div>
            </div>
          </header>
          <ReactFrontendContext.Provider
            value={{
              pathway,
              programGroup,
            }}
          >
            <PageBuilderWrapper
              save={this.save}
              pathway={pathway}
              url={`/pathways/${pathway._id}/reportEditor/${report._id}?a=${report.companyId}`}
              onLoad={this.onLoad}
              isLoading={this.state.isLoading}
              previewMode={this.state.previewMode}
              editMode={this.state.editMode}
              Sidebar={Sidebar}
            />
          </ReactFrontendContext.Provider>
        </div>
      </View>
    )
  }
}

const ReportUpdateWithAlert = withAlert()(ReportUpdate)

const StyledReportUpdate = styled(ReportUpdateWithAlert)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fff;

  .header {
    padding: 0.5rem;
    border-bottom: 1px solid var(--border-color);

    &,
    .btn {
      font-size: 0.875rem;
    }
  }
  h2 {
    margin: 0;
  }
  .btn-group {
    button {
      &.active {
        background: var(--primary);
        color: #fff !important;
      }
    }
  }
`

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <RESTDataLoader
      resource="reports"
      action={props.match.params.reportId}
      params={{
        companyId: props.selectedCompany._id,
      }}
      showLoader={true}
      // reduxAction={editorUpdateReport}
      // transform={(p) => new Pathway(p)}
    >
      {(report) => {
        if (!report) {
          return <NotFound />
        }

        return (
          <RESTDataLoader
            resource="pathways"
            action={props.match.params.pathwayId}
            // params={{
            //   pathwayId: props.match.params.pathwayId,
            // }}
            showLoader={true}
            // reduxAction={editorUpdatePathway}
            transform={(p) => new Pathway(p)}
          >
            {(pathway) => {
              if (!pathway) {
                return <NotFound />
              }

              if (pathway.programGroupId) {
                return (
                  <RESTDataLoader
                    resource="programGroups"
                    action={pathway.programGroupId}
                    params={{
                      companyId: pathway.companyId,
                    }}
                    showLoader={true}
                    // reduxAction={editorUpdateProgramGroup}
                    transform={(p) => new ClientProgramGroup(p)}
                  >
                    {(programGroup) => {
                      if (pathway.programGroupId && !programGroup) {
                        return <NotFound />
                      }

                      return (
                        <div key={report._id}>
                          <StyledReportUpdate
                            report={report}
                            pathway={pathway}
                            programGroup={programGroup}
                          />
                        </div>
                      )
                    }}
                  </RESTDataLoader>
                )
              } else {
                return (
                  <div key={report._id}>
                    <StyledReportUpdate report={report} pathway={pathway} />
                  </div>
                )
              }
            }}
          </RESTDataLoader>
        )
      }}
    </RESTDataLoader>
  )
}

const mapStateToProps = (state) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0],
})

export default connect(mapStateToProps)(DataLoader)
