import { PathwayDocument } from '../../pathway/pathway.model'
import { PersonDocument } from '../../person/person.model'
import { ResponseDocument } from '../../response/response.model'
import Pathway from '../../pathway/pathway.backend.client'

/* eslint-disable */
export default class BaseIntegrationModule {
  name: string
  key: string
  renderForm(pathway?: Pathway) {
    return null
  }
  process(
    integrationConfig: Record<string, any>,
    response: ResponseDocument,
    profile: PersonDocument,
    pathway: PathwayDocument
  ) {
    throw new Error('No process function defined')
  }
  onStepEnter() {
    return null
  }
  onPathwayResume() {
    return null
  }
  onPathwayStartOver() {
    return null
  }
  onPathwayAbandon() {
    return null
  }
}
