import React from 'react'
import Panel from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import UserForm from './UserForm'
import View from '../../components/View'
import PropTypes from 'prop-types'
import { redirect } from '../../components/Link'
import apiFetch from '../../../../_shared/api/apiFetch'

class UserCreate extends React.Component {
  static propTypes = {
    company: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  state = {
    isLoading: false,
    error: null,
  }

  createUser = ({ formData }) => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/users/createBackend', {
            companyId: this.props.company._id,
            doc: formData,
          })
          const { location, history } = this.props
          redirect({
            location,
            history,
            pathname: `/users`,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e.message,
          })
        }
      }
    )
  }

  render() {
    const title = 'Create User'
    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={[{ label: 'Users', path: '/users' }]} />
        <div className="content">
          <Panel>
            <UserForm
              buttonLabel="Create User"
              onSubmit={this.createUser}
              error={this.state.error}
              isLoading={this.state.isLoading}
            />
          </Panel>
        </div>
      </View>
    )
  }
}

export default UserCreate
