import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Divider = styled.h2`
  display: grid;
  width: 100%;
  align-items: center;
  text-align: center;
  grid-template-columns: minmax(20px, 1fr) auto minmax(20px, 1fr);
  grid-gap: 20px;
  &:before,
  &:after {
    content: '';
    border-top: 1px solid var(--border-color);
  }
`

const DividerTitle = (props) => {
  return <Divider>{props.children}</Divider>
}

DividerTitle.propTypes = {
  children: PropTypes.string,
}

export default DividerTitle
