import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faAt } from '@fortawesome/pro-solid-svg-icons/faAt'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import ScoreGauge from '../../components/ScoreGauge'
import TimeAgo from 'react-timeago'
import formatDistance from 'date-fns/formatDistance'
import format from 'date-fns/format'
import { UncontrolledTooltip, Badge } from 'reactstrap'
import styled from 'styled-components'
import classnames from 'classnames'
import Link from '../../components/Link'
import DeleteResponseModal from '../../components/modals/DeleteResponseModal'
import FlagInvalidModal from '../../components/modals/FlagInvalidModal'
import FlagValidModal from '../../components/modals/FlagValidModal'
import { withRouter } from 'react-router-dom'
import Timestamp from '../../components/Timestamp'
import { useSelector } from 'react-redux'
import { ResponseMetadata } from '../../../../_shared/models/response/response.types'

const StyledTimestamp = styled.span`
  border-bottom: 1px dashed var(--border-color);
`

const PathwayProgress = styled((props) => {
  const { response, className } = props
  return (
    <div className={className}>
      <div className="progress">
        <div
          className={classnames('progress-bar', {
            'bg-success progress-bar-striped progress-bar-animated': response.isOnline,
          })}
          role="progressbar"
          aria-valuenow={response.progress}
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: `${response.progress}%` }}
        >
          {response.progress}%
        </div>
      </div>
    </div>
  )
})`
  padding: 1rem 1rem 0;

  .progress {
    height: 1.25rem;
  }
`

const ResponseMeta = ({ response }) => {
  let currentlyViewing
  let completed

  if (response.completedAt) {
    completed = (
      <li>
        <FontAwesomeIcon icon={faCheckCircle} fixedWidth transform="down-1" />
        <span className="icon-label">
          Completed in{' '}
          <StyledTimestamp id="completedLastModifiedTimestamp">
            {formatDistance(response.createdAt, response.completedAt)}
          </StyledTimestamp>
          <UncontrolledTooltip
            placement="top"
            boundariesElement="body"
            target="completedLastModifiedTimestamp"
          >
            {format(response.completedAt, "MMM do yyyy 'at' hh:mma")}
          </UncontrolledTooltip>
        </span>
      </li>
    )
  } else {
    if (response.isOnline) {
      currentlyViewing = (
        <li className="currently-viewing">
          <FontAwesomeIcon icon={faUserCircle} fixedWidth />
          Viewing <strong>{response.nextStep?.name}</strong>
        </li>
      )
    } else {
      completed = (
        <li>
          <FontAwesomeIcon icon={faTimesCircle} fixedWidth />
          Abandoned{' '}
          {response.nextStep && (
            <span>
              on <strong>{response.nextStep.name}</strong>
            </span>
          )}{' '}
          after{' '}
          <StyledTimestamp id="lastModifiedTimestamp">
            {formatDistance(response.lastModified, response.createdAt)}
          </StyledTimestamp>
          <UncontrolledTooltip
            placement="top"
            boundariesElement="body"
            target="lastModifiedTimestamp"
          >
            {format(response.lastModified, "MMM do yyyy 'at' hh:mma")}
          </UncontrolledTooltip>
        </li>
      )
    }
  }

  return (
    <div className="result-meta">
      <ul>
        {currentlyViewing}
        <li>
          <FontAwesomeIcon icon={faClock} fixedWidth transform="up-1" />
          Started <Timestamp date={response.createdAt} />
        </li>
        {completed}
      </ul>
    </div>
  )
}

const ResponseViewSidebar = (props) => {
  const { response, profile, pathway, className } = props
  const user = useSelector((state) => state.user)

  return (
    <div className="sidebar">
      <div className="profile">
        <div className="row no-gutters align-items-center flex-nowrap" style={{ width: '100%' }}>
          <div className="col meta-col">
            <ul className="meta">
              <li className="name">
                <Link to={`/people/${profile._id}`}>
                  <FontAwesomeIcon icon={faUser} fixedWidth transform="up-1" />
                  {profile.getFullName()}
                </Link>
              </li>
              {profile.email && (
                <li className="email">
                  <FontAwesomeIcon icon={faAt} fixedWidth />
                  {profile.email}
                </li>
              )}
              {profile.phone && (
                <li className="phone">
                  <FontAwesomeIcon icon={faPhone} fixedWidth />
                  {profile.phone}
                </li>
              )}

              {profile.isInterviewed && (
                <li className="interviewed">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                  Interviewed
                </li>
              )}

              {profile.isEnrolled && (
                <li className="enrolled">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                  Enrolled
                </li>
              )}
            </ul>
          </div>
          {profile.pic && (
            <div className="col-auto">
              <img className="pic" src={profile.pic} />
            </div>
          )}
        </div>
      </div>
      <PathwayProgress response={response} pathway={pathway} isOnline={response.isOnline} />
      <ResponseMeta response={response} pathway={pathway} isOnline={response.isOnline} />
      {pathway?.maxScore !== 0 &&
        (response.scoringComplete || response.progress >= 100) &&
        response.score !== undefined && (
          <div className="score">
            <ScoreGauge value={response.score} size="lg" showPercent />
            {/* <div className="text-center">
            <a href="#">
              View score breakdown <FontAwesomeIcon icon={faCaretDown} />
            </a>
          </div> */}
          </div>
        )}

      <div className="actions">
        <div className="title">Actions</div>
        <ul>
          <li className="d-none d-print-block">
            <Link to={`/people/${profile._id}`}>
              <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
              View in VirtualAdviser
            </Link>
          </li>
          <li>
            <Link to={`/people/${profile._id}`}>
              <FontAwesomeIcon icon={faUser} fixedWidth />
              View profile
            </Link>
          </li>
          <li className="d-print-none">
            {!response.isInvalid && (
              <FlagInvalidModal response={response} tag="a" tagProps={{ href: '#' }} />
            )}
            {response.isInvalid && (
              <FlagValidModal response={response} buttonTag="a" buttonProps={{ href: '#' }} />
            )}
          </li>
          {user.isAdmin() && (
            <li className="d-print-none">
              <DeleteResponseModal response={response} buttonTag="a" buttonProps={{ href: '#' }} />
            </li>
          )}
        </ul>
      </div>

      {profile.social?.length > 0 && (
        <div className="actions">
          <div className="title">Social Media</div>
          <ul>
            {profile.social
              .sort((a, b) => {
                if (a.name < b.name) return -1
                if (a.name > b.name) return 1
              })
              .map((s) => (
                <li key={s.name}>
                  <a href={s.url} rel="noopener noreferrer" target="_blank">
                    {s.name}
                  </a>
                </li>
              ))}
          </ul>
        </div>
      )}

      <div className="metadata">
        <ul>
          {response.device && (
            <li>
              <span className="metadata-title">Device</span>
              <span className="metadata-value">
                {`${response.device.type} - ${response.device.name} (${response.device.browser} ${response.device.version})`}
              </span>
            </li>
          )}
          <li>
            <span className="metadata-title">IP Address</span>
            <span className="metadata-value">{response.ipAddress || '(unknown)'}</span>
          </li>

          {response.lead_src && (
            <li>
              <span className="metadata-title">Lead Source</span>
              <span className="metadata-value">{response.lead_src}</span>
            </li>
          )}

          {response.referrer && (
            <li>
              <span className="metadata-title">Referrer</span>
              <span className="metadata-value" title={response.referrer}>
                {response.referrer}
              </span>
            </li>
          )}

          {response.embedPage && (
            <li>
              <span className="metadata-title">Embedded On</span>
              <span className="metadata-value" title={response.embedPage}>
                {response.embedPage}
              </span>
            </li>
          )}

          {ResponseMetadata.map((prop) => {
            if (
              prop === 'embedPage' ||
              prop === 'lead_src' ||
              prop === 'referrer' ||
              prop === 'isTest'
            ) {
              return null
            }
            if (response[prop]) {
              return (
                <li key={prop}>
                  <span className="metadata-title">{prop}</span>
                  <span className="metadata-value">{response[prop]}</span>
                </li>
              )
            }
          })}

          {typeof response.statisticsConsent !== 'undefined' && (
            <li>
              <span className="metadata-title">Data Permissions</span>
              <span className="metadata-value">
                {response.statisticsConsent
                  ? 'Consent given for analytics'
                  : 'Consent denied for analytics'}
              </span>
            </li>
          )}

          {response.recaptchaScore && (
            <li>
              <span className="metadata-title">ReCaptcha Score</span>
              <span className="metadata-value" title={response.recaptchaScore}>
                {response.recaptchaScore}
              </span>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
ResponseViewSidebar.propTypes = {
  className: PropTypes.string,
}

export default ResponseViewSidebar
