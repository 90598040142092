import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { TabContent, TabPane } from 'reactstrap'
import capitalize from 'voca/capitalize'
import CommonBlockSchema from '../blocks/commonSchemas'
import RowAppearanceSettings from '../settings/RowAppearanceSettings'
import SectionAppearanceSettings from '../settings/SectionAppearanceSettings'
import ContentAppearanceSettings from '../settings/ContentAppearanceSettings'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'
import Sidebar from '../components/Sidebar'
import SettingsSection from '../settings/SettingsSection'
import Field from '../blocks/Field'
import Form from '../../_shared/components/Form'
import { Tabs } from '../../backend/client/components/TabNav'
import Input from '../../_shared/components/Form/Input'
import Code from '../../_shared/components/Form/Code'
import Dropdown from '../../_shared/components/Form/Dropdown'
import Cardbox, { CardboxItem, StyledCardboxItem } from '../../_shared/components/Form/Cardbox'

import ace from 'ace-builds'

ace.config.setModuleUrl(
  'ace/mode/scss_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-scss.js'
)

const forceWebpackToLoad = [AceEditor]

class BlockSettings extends React.PureComponent {
  static propTypes = {
    block: PropTypes.object.isRequired,
    pathway: PropTypes.object.isRequired,
    className: PropTypes.string,
    close: PropTypes.func,
    update: PropTypes.func,
  }

  state = {
    activeTab: 'block',
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  onChange = (formState) => {
    const { block } = this.props
    const { formData } = formState
    let data = formData
    this.props.update(data)
  }

  renderAppearanceForm(block) {
    switch (block.type) {
      case 'Section':
        return <SectionAppearanceSettings block={block} />
      case 'Row':
        return <RowAppearanceSettings block={block} />
      default:
        return <ContentAppearanceSettings block={block} />
    }
  }

  onInvalid(formState) {
    console.warn('invalid change', formState)
  }

  render() {
    const { className, close, block, pathway } = this.props

    const { label } = block.constructor.editor

    const settingsForms = block.getSettingsForms()

    const { activeTab } = this.state

    const title = (
      <React.Fragment>
        <div className="close" onClick={close}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {`${capitalize(label)} Settings`}
      </React.Fragment>
    )

    return (
      <Sidebar className={className} title={title}>
        <Form
          key={block.id}
          formData={block.data}
          schema={{
            type: 'object',
            properties: {
              config: block.getCompleteSettingsSchema() || {},
              ...CommonBlockSchema,
            },
            additionalProperties: true,
          }}
          validateOnChange
          onInvalidChange={this.onInvalid}
          onValidChange={(formState) => {
            this.onChange(formState)
          }}
        >
          <Tabs
            items={[
              {
                label: 'Block',
                onClick: () => this.toggle('block'),
                isActive: activeTab === 'block',
              },
              {
                label: 'Appearance',
                onClick: () => this.toggle('appearance'),
                isActive: activeTab === 'appearance',
              },
              {
                label: 'Advanced',
                onClick: () => this.toggle('advanced'),
                isActive: activeTab === 'advanced',
              },
            ]}
          />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="block">
              {settingsForms?.map((SettingsForm, index) => (
                <SettingsForm key={index} block={block} pathway={pathway} />
              ))}
            </TabPane>
            <TabPane tabId="appearance">{this.renderAppearanceForm(block)}</TabPane>
            <TabPane tabId="advanced">
              <Input
                name="advanced.name"
                disabled={block.constructor.editor.allowFieldNameEdit === false}
                help={
                  <div>
                    Naming this field allows it to be{' '}
                    <a href="#" target="_blank">
                      referenced for advanced functionality
                    </a>
                    . Must not contain spaces, capitals, or punctuation.
                  </div>
                }
              />
              <SettingsSection title="CSS">
                <Input name="advanced.cssClass" />
                <Code
                  tabSize={4}
                  editorProps={{
                    $blockScrolling: Infinity,
                  }}
                  setOptions={{
                    fontSize: '12px',
                  }}
                  mode="scss"
                  wrapEnabled={true}
                  name="advanced.customCss"
                />
              </SettingsSection>
              {block instanceof Field && (
                <SettingsSection title="Profile">
                  <Dropdown
                    name="advanced.profile.group"
                    label="Section"
                    help="If enabled, this field will add it's value to the
                        user's profile."
                    placeholder="(Disabled)"
                  >
                    <option value="contact">Contact</option>
                    <option value="prospects">Prospects</option>
                    <option value="situation">Situation</option>
                    <option value="history">History</option>
                    <option value="logistics">Logistics</option>
                    <option value="motivation">Motivation</option>
                    <option value="tourRequest">Tour Request</option>
                  </Dropdown>
                  <Input name="advanced.profile.label" label="Label" maxLength={30} />
                  <Input
                    name="advanced.profile.key"
                    label="Key"
                    help="Must not contain spaces, capitals, or punctuation."
                  />
                </SettingsSection>
              )}
              <SettingsSection title="Linked Blocks">
                <Input
                  name="advanced.linkedKey"
                  label="Key"
                  help="Changes to this block will be applied to all other blocks with the same key."
                />
                <Cardbox name="advanced.linkedType" showLabel={false} thin vertical>
                  <CardboxItem
                    value="config"
                    title="Enable content override"
                    description="This block has unique content and should not be shared with other linked blocks"
                  />
                </Cardbox>
              </SettingsSection>
              <SettingsSection title="Events">
                <Cardbox name="advanced.trimAnswers" showLabel={false} thin vertical>
                  <CardboxItem
                    value={true}
                    title="Trim answers when changed"
                    description="Resets all of the form fields that appear after this field when changed"
                  />
                </Cardbox>
              </SettingsSection>
            </TabPane>
          </TabContent>
        </Form>
      </Sidebar>
    )
  }
}

export default styled(BlockSettings)`
  color: #4d6377;

  > .content {
    padding-bottom: 20rem;
  }
  .TabNav {
    a,
    > div {
      font-size: 1rem;
    }
    margin: -1rem -1rem 1rem;
    margin-bottom: calc(1rem + 1px);
  }

  .section-content {
    margin: 1.5rem 0 0 0;
  }

  .close {
    color: #fff;
    opacity: 1;
    transition: opacity 0.1s ease-out;
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
      color: #fff;
      opacity: 0.75;
    }
  }

  .tab-pane > .settings-section:first-child {
    margin-top: calc(-1rem + -1px);
  }

  .tab-pane {
    padding-top: 1rem;
  }

  ${StyledCardboxItem} {
    border: 1px solid #dee2e6;
    color: #88929c;
  }
`
