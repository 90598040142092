import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { Persona } from '../../../../../_shared/models/persona/persona.types'
import Loader from '../../../components/Loader'

interface Props {
  companyId: string
  className?: string
  id: string
  children: React.ReactNode
}
export const PersonaContext = React.createContext<Persona>(null)

const PersonaLoader: React.FC<Props> = ({ className, companyId, children, id }) => {
  const [persona, setPersona] = useState<Persona>(null)

  const loadPersona = useRef(async () => {
    setPersona(null)
    const result = await apiFetch('GET', `/statistics/persona/${id}`, null, { companyId })
    setPersona(result)
  })

  useEffect(() => {
    loadPersona.current()
  }, [companyId, id])

  if (!persona) {
    return <Loader />
  }

  return (
    <div className={className}>
      <PersonaContext.Provider value={persona}>{children}</PersonaContext.Provider>
    </div>
  )
}

export default styled(PersonaLoader)``
