import React from 'react'
import PropTypes from 'prop-types'
import SettingsSection from '../SettingsSection'
import styled from 'styled-components'
import MobileOptionsWrapper from '../MobileOptionsWrapper'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import Input from '../../../_shared/components/Form/Input'

const UnitDropdown = (props) => (
  <Dropdown name={`${props.path}.margin.${props.device}.${props.direction}Unit`} showLabel={false}>
    <option value="px">px</option>
    <option value="em">em</option>
    <option value="rem">rem</option>
    <option value="%">%</option>
  </Dropdown>
)
UnitDropdown.propTypes = {
  direction: PropTypes.string.isRequired,
}

const Controls = ({ path, device }) => (
  <React.Fragment>
    <div className="top">
      <Input name={`${path}.margin.${device}.top`} label="Top" direction="top" showLabel={false} />
      <UnitDropdown path={path} device={device} direction="top" />
    </div>
    <div className="row align-items-center">
      <div className="col">
        <Input name={`${path}.margin.${device}.left`} label="Left" showLabel={false} />
        <UnitDropdown path={path} device={device} direction="left" />
      </div>
      <div className="col-auto">
        <div className="box" />
      </div>
      <div className="col">
        <Input name={`${path}.margin.${device}.right`} label="Right" showLabel={false} />
        <UnitDropdown path={path} device={device} direction="right" />
      </div>
    </div>
    <div className="bottom">
      <Input name={`${path}.margin.${device}.bottom`} label="Bottom" showLabel={false} />
      <UnitDropdown path={path} device={device} direction="bottom" />
    </div>
  </React.Fragment>
)

class MarginOptions extends React.Component {
  state = {
    activeTab: 'desktop',
  }

  toggle = (activeTab) => {
    this.setState({
      activeTab,
    })
  }

  render() {
    const { className, path } = this.props

    return (
      <div className={className}>
        <SettingsSection title="Margin">
          <MobileOptionsWrapper controls={Controls} path={path} />
        </SettingsSection>
      </div>
    )
  }
}
MarginOptions.propTypes = {
  className: PropTypes.string,
  path: PropTypes.string,
}

export default styled(MarginOptions)`
  .top,
  .bottom {
    text-align: center;
  }

  .top {
    margin: 0 0 1rem 0;
  }

  .bottom {
    margin: 1rem 0 0 0;
  }

  .box {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background: #ccc;
  }

  input,
  select {
    font-size: 12px;
    padding: 0.4rem;
    height: 2rem;
  }

  input {
    width: 70px;
    margin: 0;
    vertical-align: middle;
  }

  .form-group {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
  }

  select {
    display: inline-block;
    vertical-align: middle;
    margin-left: -2px;
  }
`
