import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../components/Panel'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../components/RoundButton'
import { Button } from 'reactstrap'
import Link from '../../components/Link'
import PageHeader from '../../components/PageHeader'
import PageActions, { PageAction } from '../../components/PageActions'
import IntegrationManageDropdown from './IntegrationManageDropdown'
import View from '../../components/View'
import { PanelList, PanelListItem } from '../../components/PanelList'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import { shortCache } from '../../api/subscriptionCache'

const FirstIntegration = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/map.png" width="128" />
      <h3>Create your first integration</h3>
      <h6>Gather prospects, view statistics, insights and more</h6>
      <Button tag={Link} color="primary" size="lg" to={`/integrations/new`}>
        <FontAwesomeIcon icon={faPlus} />
        &nbsp;&nbsp;New Integration
      </Button>
    </PanelHero>
  </Panel>
)

const StyledPanel = styled(Panel)`
  h5 {
    margin: 0;
    a {
      text-decoration: none;
    }
  }
  .buttons .btn {
    margin-right: 0.75rem;
  }

  .buttons .dropdown .btn {
    margin-right: 0;
  }
`

const Integration = (props) => (
  <PanelListItem>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <div className="panel-list-item-title">
          <Link to={`/integrations/${props.integration._id}`}>{props.integration.name}</Link>
        </div>
      </div>
      <div className="d-flex flex-nowrap col-auto buttons">
        <RoundButton
          tag={Link}
          size="sm"
          to={`/integrations/${props.integration._id}`}
          className="d-none d-md-inline-block"
        >
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <IntegrationManageDropdown
          company={props.company}
          integration={props.integration}
          copyRedirectBase="/integrations/"
          deleteRedirect="/integrations"
        />
      </div>
    </div>
  </PanelListItem>
)

Integration.propTypes = {
  integration: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

const IntegrationIndex = (props) => {
  const { company } = props
  const title = 'Integrations'
  return (
    <View layout="sidebar" title={title}>
      <PageHeader title={title}>
        <PageActions>
          <PageAction
            tag={Link}
            tagProps={{ to: '/integrations/new' }}
            button
            toolbar
            color="primary"
          >
            <FontAwesomeIcon icon={faPlus} /> New Integration
          </PageAction>
        </PageActions>
      </PageHeader>
      <div className="content">
        <SubscriptionLoader
          cache={shortCache}
          resource="integrations"
          query={{ companyId: company._id }}
          sort={{ name: 1 }}
          showLoader={true}
          fields={['_id', 'name']}
          transform={(store) => ({
            ...store,
            integrations: sort(store.integrations).asc((r) => r.name),
          })}
        >
          {({ data }) => {
            if (!data?.integrations || data.integrations.length === 0) {
              return <FirstIntegration company={company} />
            }
            return (
              <PanelList>
                {data.integrations.map((i) => (
                  <Integration key={i._id} integration={i} company={company} />
                ))}
              </PanelList>
            )
          }}
        </SubscriptionLoader>
      </div>
    </View>
  )
}

IntegrationIndex.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default IntegrationIndex
