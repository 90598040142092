import { truncate } from 'fs'
import isEqual from 'lodash/isEqual'
import { SortOrder } from 'mongoose'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { ClientSubscription } from '../../../pubsub/src/server/PubSub/client'
import SubscriptionCache from '../../../pubsub/src/server/PubSub/SubscriptionCache'
import { SubscriptionParams, SubscriptionQuery } from '../../../pubsub/src/server/PubSub/types'
import Log from '../../../_shared/log'
import { updateSubscription } from '../actions'
import { pubSub } from '../api/socketio'
import { shortCache } from '../api/subscriptionCache'
import Loader from '../components/Loader'

interface SubViewState {
  subscription: ClientSubscription
  isLoading: boolean
  d?: number
}

type SubscriptionLoaderProps = {
  children: any
  resource: string
  query: SubscriptionQuery
  sort?: Record<string, SortOrder>
  limit?: number
  cache?: SubscriptionCache
  fields?: string[]
  showLoader?: boolean
  subKey?: string
  dispatch: Dispatch
  transform?: (props) => any
  setCount?: (store: Record<string, any[]>) => void
  showLoaderInline?: boolean
}

class SubscriptionLoader extends React.Component<SubscriptionLoaderProps, SubViewState> {
  storeKey = null

  state = {
    subscription: null,
    isLoading: true,
  }

  getQueryParams(): SubscriptionParams['queryParams'] {
    const { query, sort, limit, fields } = this.props
    return { query, sort, limit, fields }
  }

  onSubscriptionChange = (sub: ClientSubscription) => {
    const { subKey, dispatch, setCount } = this.props
    if (sub.ready) {
      if (subKey) {
        dispatch(updateSubscription(subKey, sub))
      }
      this.setState({
        isLoading: !sub.ready,
      })
      if (setCount) {
        setCount(sub.store.data)
      }
    }
  }

  /**
   * Create a subscription to a specific topic.
   * @param topic
   * @param query
   */
  subscribe(): void {
    const { resource, subKey, dispatch, setCount, cache } = this.props
    const subscription = pubSub.subscribe(resource, this.getQueryParams(), {
      cache,
      onChange: this.onSubscriptionChange,
    })

    this.setState({
      isLoading: !subscription.ready,
      subscription,
    })

    if (subKey) {
      dispatch(updateSubscription(subKey, subscription))
    }
  }

  unsubscribe(callback?: () => void): void {
    const { subscription } = this.state
    this.setState(
      {
        isLoading: false,
        subscription: null,
      },
      () => {
        subscription.unsubscribe()
        if (callback) {
          callback()
        }
      }
    )
  }

  unsubscribeImmediate(): void {
    this.state.subscription?.unsubscribe()
  }

  restartSubscription(): void {
    this.unsubscribe(() => {
      this.subscribe()
    })
  }

  componentDidMount(): any {
    this.subscribe()
  }

  componentWillUnmount(): any {
    this.state.subscription?.removeChangeHandler(this.onSubscriptionChange)
    this.unsubscribeImmediate()
  }

  componentDidUpdate(prevProps: SubscriptionLoaderProps, prevState: SubViewState) {
    // When the query changes, we must notify the PubSubManger
    if (prevProps) {
      if (
        !isEqual(prevProps.query, this.props.query) ||
        !isEqual(prevProps.sort, this.props.sort)
      ) {
        this.restartSubscription()
      }
    }

    if (prevProps.limit !== this.props.limit) {
      const { subscription } = this.state
      if (subscription) {
        subscription.queryParams = this.getQueryParams()
        subscription.subscribe(true)
        this.setState({
          isLoading: !subscription.ready,
        })
      }
    }
  }

  render(): JSX.Element {
    const { showLoader, children, transform, showLoaderInline } = this.props
    const { isLoading, subscription } = this.state

    if (showLoader && isLoading && !showLoaderInline) {
      return <Loader />
    }

    if (!subscription) {
      return null
    }

    // data must be sorted
    let data = subscription.getStore()

    if (transform) {
      data = transform(data)
    }

    return (
      <>
        {typeof children === 'function'
          ? children({
              subscription,
              data,
            })
          : children}
        {showLoaderInline && subscription.ready === false && <Loader />}
      </>
    )
  }
}

export default connect()(SubscriptionLoader)
