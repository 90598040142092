const ReportSchema = {
  title: 'Report',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },

    pathwayId: {
      type: 'string',
    },
    companyId: {
      type: 'string',
    },

    name: {
      type: 'string',
    },

    isDefault: {
      type: 'boolean',
    },

    content: {
      type: 'array',
    },
  },
  required: ['pathwayId', 'name', 'companyId'],
  additionalProperties: false,
}

export default ReportSchema
