import React, { ChangeEvent, ReactElement, ReactNode } from 'react'
import { Button, FormGroup, ButtonGroup as RsButtonGroup } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import withForm from '../withForm'
import { FormContextProps } from '../Form'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import styled from 'styled-components'

export interface ButtonGroupButtonProps {
  value: string | number | Record<string, any>
  icon?: IconProp
  color?: string
  label?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
  active?: boolean
  disabled?: boolean
}

export const ButtonGroupButton = ({
  active,
  onClick,
  disabled,
  color,
  icon,
  label,
}: ButtonGroupButtonProps): React.ReactElement => {
  return (
    <Button active={active} onClick={onClick} disabled={disabled} color={color || 'alt'}>
      {icon && <FontAwesomeIcon fixedWidth icon={icon} />}
      {icon && label && ' '}
      {label && label}
    </Button>
  )
}

export interface ButtonGroupProps extends Partial<FormContextProps> {
  label?: string
  className?: string
  showLabel?: boolean
  id?: string
  help?: string | ReactNode
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (event: ChangeEvent<HTMLButtonElement>) => void
  name: string
  value?: string | string[]
  children?: ReactNode
  vertical?: boolean
  multiple?: boolean
  defaultValue?: string | string[]
  size?: string
}

class ButtonGroup extends React.PureComponent<ButtonGroupProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
    options: [],
    value: [],
  }

  componentDidMount() {
    const { defaultValue, multiple, value, setValue } = this.props
    if (defaultValue && !value) {
      if (multiple) {
        if (!(defaultValue instanceof Array)) {
          throw new Error('defaultValue must be an array when multiple is set')
        }
      } else {
        if (typeof defaultValue !== 'string') {
          throw new Error('defaultValue must be a string')
        }
      }
      setValue(this.props, defaultValue)
    }
  }

  componentWillUnmount() {
    const { setValue } = this.props
    setValue(this.props, undefined)
  }

  clickItem = (newValue) => {
    const { value, setValue } = this.props

    if (this.props.multiple) {
      const currentValue: string[] = (value as string[]) || []

      if (currentValue.find((v) => v === newValue)) {
        newValue = currentValue.filter((v) => v !== newValue)
      } else {
        newValue = [...currentValue, newValue]
      }

      if (newValue.length === 0) {
        newValue = undefined
      }

      setValue(this.props, newValue)
    } else {
      const currentValue: string = value as string
      if (newValue === currentValue) {
        // Deselect the value
        setValue(this.props, undefined)
      } else {
        setValue(this.props, newValue)
      }
    }
  }

  render() {
    const {
      name,
      id,
      showLabel,
      label,
      formSchema,
      errors,
      value,
      showValid,
      multiple,
      vertical,
      validFeedback,
      help,
      className,
      size,
    } = this.props

    //todo: type this
    const children = React.Children.map(this.props.children, (item: ReactElement) => {
      let active
      if (multiple) {
        active = value && !!(value as string[]).find((v) => v === item.props.value)
      } else {
        active = value === item.props.value
      }

      return React.cloneElement(item, {
        onClick: () => {
          if (!item.props.disabled) {
            this.clickItem(item.props.value)
          }
        },
        active,
      })
    })

    return (
      <FormGroup tag="fieldset" className={getFormGroupClasses(errors, showValid) + className}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <div>
          <RsButtonGroup className="btn-block" size={size} vertical={vertical}>
            {children}
          </RsButtonGroup>
        </div>
        <HelpText help={help} />
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
      </FormGroup>
    )
  }
}

const buttonGroupWithForm = withForm<ButtonGroupProps>(ButtonGroup)
export default styled(buttonGroupWithForm)`
.btn-alt:not(:disabled):not(.disabled):active, .btn-alt:not(:disabled):not(.disabled).active{
      background: var(--primary);
      color: #fff;
      border-color: var(--primary);
    }
  }

  .svg-inline--fa{
    margin-right:0.2rem;
  }
`
