import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Stat from '../../../components/Stat'
import Loader from '../../../components/Loader'
import apiFetch from '../../../../../_shared/api/apiFetch'
import Log from '../../../../../_shared/log'

const StatOverview = ({ className, statData = [] }) => (
  <div className={className}>
    <div className="row quick-stats">
      {statData &&
        statData.map((data, index) => (
          <div key={index} className="col">
            <Stat value={data.value} label={data.label} align="center" labelPosition="bottom" />
          </div>
        ))}
    </div>
  </div>
)

const StyledStatOverview = styled(StatOverview)``

StatOverview.propTypes = {
  className: PropTypes.string,
  statData: PropTypes.array,
}

export class StatOverviewContainer extends React.PureComponent {
  state = {
    isLoading: true,
    campaignData: null,
  }

  static propTypes = {
    campaign: PropTypes.object,
    companyId: PropTypes.string,
    dateRange: PropTypes.array.isRequired,
  }

  componentDidMount() {
    this.loadCampaignData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange) {
      this.loadCampaignData()
    }
  }

  loadCampaignData = () => {
    const { campaign, companyId, pathway, dateRange } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/statistics/statOverview',
            {},
            {
              campaignId: campaign?._id,
              companyId,
              pathwayId: pathway?._id,
              start: dateRange[0],
              end: dateRange[1],
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            true
          )
          this.setState({
            isLoading: false,
            campaignData: result.stats,
          })
        } catch (e) {
          Log.error(e)
          this.setState({
            isLoading: false,
            error: e.message,
          })
        }
      }
    )
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }

    return <StyledStatOverview {...this.props} statData={this.state.campaignData} />
  }
}

export default StyledStatOverview
