import React from 'react'
import { connect } from 'react-redux'
import Checkbox from '../../../../_shared/components/Form/Checkbox'
import withForm from '../../../../_shared/components/withForm'
import { shortCache } from '../../api/subscriptionCache'
import SubscriptionLoader from '../../containers/SubscriptionLoader'

class RestrictCampus extends React.Component {
  shouldComponentUpdate(prevProps) {
    return prevProps.formData.campusView !== this.props.formData.campusView
  }
  render() {
    const { formData, selectedCompany } = this.props
    const { campusView } = formData
    if (campusView === 'all') {
      return null
    }

    return (
      <SubscriptionLoader
        resource="programGroups"
        cache={shortCache}
        query={{ companyId: selectedCompany._id }}
        sort={{ name: 1 }}
        showLoader={true}
      >
        {({ data }) => {
          const programGroups = data.program_groups

          const options = []
          programGroups?.forEach((group) => {
            group.campuses?.forEach((campus) => {
              options.push({
                label: `${campus.name} (${group.name})`,
                value: campus._id,
              })
            })
          })

          return (
            <Checkbox
              name="campuses"
              label="Which campuses can this user see results for?"
              options={options}
            />
          )
        }}
      </SubscriptionLoader>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0],
})

export default connect(mapStateToProps)(withForm(RestrictCampus, true))
