import React from 'react'
import { Meteor } from 'meteor/meteor'
import PropTypes from 'prop-types'
import { PanelListItem } from '../../../../components/PanelList'
import SparklineChart from '../../../../components/SparklineChart'
import Loader from '../../../../components/Loader'
import generateChartData from '../../../../components/generateChartData'
import styled from 'styled-components'
import apiFetch from '../../../../../../_shared/api/apiFetch'

class CompanyHealthPanel extends React.Component {
  state = {
    chartData: null,
  }

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    parentCompanyId: PropTypes.string,
    parentCompanyName: PropTypes.string,
    responsesByDay: PropTypes.array,
    key: PropTypes.string,
    history: PropTypes.any,
    dateRange: PropTypes.array,
  }

  componentDidMount() {
    this.prepareChartData(this.props.responsesByDay)
  }

  prepareChartData(chartData) {
    const { dateRange } = this.props
    const [start, end] = dateRange
    this.setState({
      chartData: generateChartData(chartData, start, end, 'responses'),
    })
  }

  // getTrendColor(trend) {
  //   if (trend > 0) {
  //     return {
  //       border: '#5cb85b',
  //       background: 'rgba(92,184,91,0.1)',
  //     }
  //   }

  //   if (trend < 0 && trend !== null) {
  //     return {
  //       border: '#f0ae4e',
  //       background: 'rgba(240,174,78,0.1)',
  //     }
  //   }

  //   return {
  //     border: '#15457d',
  //     background: 'rgba(21,69,125,0.1)',
  //   }
  // }

  render() {
    let {
      className,
      id,
      name,
      campaigns,
      parentCompanyId,
      parentCompanyName,
      error,
      selectCompany,
    } = this.props
    let { chartData } = this.state
    return (
      <div className={className}>
        {error && <div className="alert alert-danger">{error}</div>}
        <PanelListItem>
          <div className="row flex-nowrap title-row">
            <div className="col">
              <a
                onClick={(event) => {
                  event.preventDefault()
                  selectCompany({ _id: id })
                }}
                href={`/?a=${id}`}
              >
                {name}
              </a>
              {parentCompanyId && (
                <div className="company-parent">
                  Under{' '}
                  <a
                    onClick={(event) => {
                      event.preventDefault()
                      selectCompany({ _id: parentCompanyId })
                    }}
                    href={`/?a=${parentCompanyId}`}
                  >
                    {parentCompanyName}
                  </a>
                </div>
              )}
            </div>
            {campaigns &&
              campaigns.map((campaign, i) => (
                <div className="col col-sm-2" key={campaign.name}>
                  <StyledCampaignStat campaign={campaign} />
                </div>
              ))}
          </div>
          <div className="row align-items-center flex-nowrap chart-row">
            <div className="col col-md-12">
              {chartData && (
                <div className="chart-container">
                  <SparklineChart
                    height={50}
                    labels={chartData.labels}
                    datasets={[
                      {
                        data: chartData.values,
                        borderColor: '#15457d',
                        fill: true,
                        backgroundColor: 'rgba(21,69,125,0.1)',
                        pointHoverBackgroundColor: '#0b6dc7',
                        pointRadius: 0,
                        pointBackgroundColor: '#0b6dc7',
                        tension: 0.4,
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </PanelListItem>
      </div>
    )
  }
}

CompanyHealthPanel.propTypes = {
  className: PropTypes.string,
  campaigns: PropTypes.array,
  healthData: PropTypes.array,
  selectCompany: PropTypes.func,
  error: PropTypes.string,
}

export const StyledCompanyHealthPanel = styled(CompanyHealthPanel)`
  border-bottom: 1px solid #dadada;

  .title-row {
    margin-bottom: 15px;
  }

  .company-parent {
    font-size: 0.7rem;
    padding-left: 5px;
  }

  .company-parent:before {
    position: relative;
    top: -0.3em;
    height: 5px;
    width: 4px;
    color: white;
    border-left: 1px solid rgb(100, 100, 100);
    border-bottom: 1px solid rgb(100, 100, 100);
    content: '';
    display: inline-block;
    left: -2px;
  }
`

class CampaignStat extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    campaign: PropTypes.object,
  }

  getCompareColor(comparison) {
    if (comparison < 0) {
      return 'negative'
    }

    return 'positive'
  }

  render() {
    let { campaign, className } = this.props

    let displayComparison = campaign.change
    if (campaign.change && campaign.change > 0) {
      displayComparison = `+${campaign.change}`
    }

    if (!isNaN(campaign.changePercent) && campaign.changePercent !== Infinity) {
      displayComparison = `${displayComparison} (${campaign.changePercent}%)`
    }

    return (
      <div className={className}>
        <div className="stat-title">{campaign.name}</div>
        <div className="stat-count">{campaign.count}</div>
        {campaign.change !== 0 && (
          <div className={`comparison ${this.getCompareColor(campaign.change)}`}>
            {displayComparison}
          </div>
        )}
      </div>
    )
  }
}

const StyledCampaignStat = styled(CampaignStat)`
  .stat-title {
    color: #777;
    font-size: 0.875rem;
  }

  .stat-title::first-letter {
    text-transform: uppercase;
  }

  .stat-count {
    font-weight: 700;
    font-size: 1.2rem;
    color: #337ab7;
    white-space: nowrap;
    display: inline-block;
  }

  .comparison {
    font-size: 0.7rem;
    padding-left: 7px;
    display: inline-block;
  }

  .comparison.negative {
    color: #b90000;
  }

  .comparison.positive {
    color: #00812e;
  }
`

export default class CompanyHealthLoader extends React.Component {
  state = {
    isLoadingHealthData: true,
    healthData: null,
    error: null,
  }

  static propTypes = {
    dateRange: PropTypes.array.isRequired,
    children: PropTypes.func,
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange) {
      this.loadData()
    }
  }

  loadHealthOverview = () => {
    const { dateRange } = this.props
    this.setState(
      {
        isLoadingHealthData: true,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/statistics/companyHealth',
            {},
            {
              start: dateRange[0],
              end: dateRange[1],
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            true
          )

          this.setState({
            isLoadingHealthData: false,
            healthData: result,
          })
        } catch (e) {
          this.setState({
            isLoadingHealthData: false,
            error: e,
          })
          throw new Error(e)
        }
      }
    )
  }

  loadData = () => {
    this.loadHealthOverview()
  }

  render() {
    const { dateRange, children } = this.props
    const { isLoadingHealthData, healthData, error } = this.state

    if (isLoadingHealthData) {
      return <Loader />
    }

    return children({ healthData, error, dateRange })
  }
}

// const TrendStatus = ({ trend, className }) => (
//   <div className={className}>
//     {trend === null && <div className="status-na">NA</div>}
//     {trend > 0 && <div className="status-up">Up</div>}
//     {trend < 0 && trend !== null && <div className="status-down">Down</div>}
//   </div>
// )

// TrendStatus.propTypes = {
//   className: PropTypes.string,
//   trend: PropTypes.number,
// }

// const StyledTrendStatus = styled(TrendStatus)`
//   font-weight: 600;

//   .status-na {
//     color: #40566f;
//   }

//   .status-up {
//     color: #5cb85b;
//   }

//   .status-down {
//     color: #f0ae4e;
//   }
// `
