import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import './blocks/index.editor'
import BlockSettings from './components/BlockSettings'
import throttle from 'lodash/throttle'
import { instantiateBlock } from './components/utilities'
import Loader from '../backend/client/components/Loader'

const StyledIframe = styled.iframe`
  margin: 0 auto;
  transition: width 0.15s ease-out;
  ${(props) => {
    const style = {}
    switch (props.previewMode) {
      case 'mobile':
        style.width = '400px'
        break
      case 'tablet':
        style.width = '768px'
        break
      case 'desktop':
      default:
        style.width = '100%'
    }
    return style
  }}
`

class PageBuilderWrapper extends React.PureComponent {
  editor = React.createRef()

  state = {
    settings: null,
  }

  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
    className: PropTypes.string,
    save: PropTypes.func,
    onLoad: PropTypes.func,
    editMode: PropTypes.string.isRequired,
    previewMode: PropTypes.string.isRequired,
    Sidebar: PropTypes.any,
    pathway: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.editor.current.contentWindow.addEventListener('message', this.receiveMessage, false)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.editMode !== this.props.editMode) {
      this.editor.current.contentWindow.postMessage(`pb_mode_${this.props.editMode}`, '*')
    }
  }

  receiveMessage = (event) => {
    const { save, onLoad } = this.props
    if (event.data === 'pb_init') {
      return onLoad()
    }
    if (typeof event.data === 'object') {
      switch (event.data.key) {
        case 'pb_content':
          return save(event.data.content)
        case 'pb_settings':
          const { settings } = event.data
          if (settings?.block) {
            settings.block = instantiateBlock(settings.block)
          }
          this.setState({
            settings,
          })
      }
    }
  }

  closeBlockSettings = () => {
    this.setState({
      settings: null,
    })
  }

  updateBlock = (location, data, blockContent) => {
    this.editor.current.contentWindow.postMessage(
      {
        key: 'pb_updateBlock',
        update: {
          location,
          data,
          blockContent,
        },
      },
      '*'
    )
  }

  getSidebar() {
    const { Sidebar, pathway } = this.props
    const { settings } = this.state
    if (settings) {
      return (
        <BlockSettings
          pathway={pathway}
          block={settings.block}
          update={throttle((data) => {
            this.updateBlock(settings.locationQuery, data)
          }, 75)}
          close={this.closeBlockSettings}
        />
      )
    } else {
      return Sidebar
    }
  }

  render() {
    const { url, className, isLoading, previewMode, editMode } = this.props

    return (
      <div className={classnames('editor', className)}>
        <div
          className={classnames('loader', 'animated', {
            fadeOutNone: !isLoading,
          })}
        >
          <Loader delay={0} />
        </div>
        <div className="row align-items-stretch editor-inner no-gutters">
          <div className="col canvas">
            <StyledIframe
              ref={this.editor}
              previewMode={previewMode}
              className="editor-iframe"
              src={url}
            />
          </div>
          {editMode !== 'preview' && this.getSidebar()}
        </div>
      </div>
    )
  }
}

const StyledPageBuilderWrapper = styled(PageBuilderWrapper)`
  flex: 1 1 auto;
  position: relative;
  display: flex;
  align-items: stretch;
  overflow: hidden;
  flex-direction: column;
  .loader {
    z-index: 214748360;
    background: var(--bg-color);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    ${Loader} {
      font-size: 6rem;
    }
  }
  .editor-inner {
    flex: 1 1 auto;
  }
  .loader,
  .editor-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    border: 0;
  }
  .editor-iframe {
    box-shadow: 0 0 330px 0px rgba(39, 43, 45, 0.4);
  }
  .canvas {
    overflow: hidden;
    background: var(--bg-color);
  }
`

export default StyledPageBuilderWrapper
