import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../components/Panel'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../components/RoundButton'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import NewButton from '../../components/NewButton'
import View from '../../components/View'
import DeleteReportModal from '../../components/modals/DeleteReportModal'
import { PanelList, PanelListItem } from '../../components/PanelList'
import Link from '../../components/Link'
import { UncontrolledToggleButton } from '../../components/ToggleButton'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'
class ReportDropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }

    this.dropdownRef = React.createRef()
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    const { pathway, report } = this.props
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest text-small" right>
          <DropdownItem>
            <FontAwesomeIcon fixedWidth icon={faCopy} /> Duplicate
          </DropdownItem>
          <DropdownItem divider />
          <DeleteReportModal
            pathway={pathway}
            report={report}
            buttonTag={DropdownItem}
            buttonProps={{ toggle: false }}
          />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const FirstReport = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/map.png" width="128" />
      <h3>Create your first summary</h3>
      <h6>Gather prospects, view statistics, insights and more</h6>
      <Link
        className="btn btn-lg btn-primary"
        tag="button"
        to={`/pathways/${props.pathway._id}/newReport`}
      >
        <FontAwesomeIcon icon={faPlus} /> New Summary
      </Link>
    </PanelHero>
  </Panel>
)

const Report = ({ pathway, report, toggleDefaultReport }) => (
  <PanelListItem>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <div className="panel-list-item-title">
          <Link to={`/pathways/${pathway._id}/reports/${report._id}`}>{report.name}</Link>
        </div>
      </div>
      <div className="d-flex flex-nowrap col-auto buttons">
        <UncontrolledToggleButton
          activeLabel="Primary"
          inactiveLabel="Primary"
          icon={faStar}
          value={report.isDefault}
          toggle={(value) => {
            toggleDefaultReport(report, value)
          }}
        />

        <RoundButton size="sm" className="d-none d-md-inline-block">
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <ReportDropdown pathway={pathway} report={report} />
      </div>
    </div>
  </PanelListItem>
)

Report.propTypes = {
  pathway: PropTypes.object.isRequired,
  report: PropTypes.object.isRequired,
}

class ReportIndex extends React.Component {
  state = {
    isLoading: false,
  }

  toggleDefaultReport = (report, value) => {
    const { pathway } = this.props
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/reports/toggleDefault', {
            pathwayId: pathway._id,
            reportId: report._id,
            value,
          })
        } catch (e) {
          throw new Error(e)
        }
        this.setState({
          isLoading: false,
        })
      }
    )
  }

  render() {
    const { pathway } = this.props

    return (
      <View title={`Reports - ${pathway.name}`}>
        <SubscriptionLoader
          resource="reports"
          cache={shortCache}
          query={{ pathwayId: pathway._id, companyId: pathway.companyId }}
          sort={{ name: 1 }}
          showLoader={true}
          transform={(store) => ({
            ...store,
            reports: sort(store.reports).asc((r) => r.name),
          })}
          fields={['name', 'pathwayId', 'companyId', 'isDefault']}
        >
          {({ data, subscription }) => {
            if (!data?.reports || data.reports.length === 0) {
              return <FirstReport pathway={pathway} />
            }
            return (
              <React.Fragment>
                <PanelList>
                  {data.reports.map((r) => (
                    <Report
                      key={r._id}
                      report={r}
                      pathway={pathway}
                      toggleDefaultReport={this.toggleDefaultReport}
                    />
                  ))}
                </PanelList>
                <NewButton
                  label="New Summary"
                  center
                  tag={Link}
                  to={`/pathways/${pathway._id}/newReport`}
                />
              </React.Fragment>
            )
          }}
        </SubscriptionLoader>
      </View>
    )
  }
}

ReportIndex.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default ReportIndex
