import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'
import { Provider } from 'react-redux'
import store from './store'
import Log, { LogLevel } from '../../_shared/log'
import { initSocket } from './api/socketio'
import apiFetch, { CREDENTIALS } from '../../_shared/api/apiFetch'
import Cookies from 'js-cookie'

Sentry.init({
  enabled: !process.env.CI && process.env.NODE_ENV == 'production',
  dsn: 'https://98b2a0c1b14e4c0981a4c7a26acf0589@o315296.ingest.sentry.io/5876322',
  environment: process.env.SERVER_ENV,
  integrations: [new Integrations.BrowserTracing()],
  release: process.env.COMMIT,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

// // const logLevel: LogLevel =
// //   LogLevel[new URLSearchParams(window.location.search).get('_logLevel')?.toUpperCase()] ||
// //   (window.location.href.includes('localhost:') ? LogLevel.INFO : LogLevel.WARN)
// // Log.setLogLevel(logLevel)
Log.setLogLevel(0)
Log.useSentry(Sentry)

const isEditor = window.location.href.includes('Editor') ? true : false

async function main() {
  if (CREDENTIALS.token) {
    await initSocket(store.dispatch)
  }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
  )
}

if (!isEditor) {
  main()
}

if (module.hot) {
  module.hot.accept()
}
