import React from 'react'
import styled from 'styled-components'

const StyledLayout = styled.div`
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #174070;
  min-height: 100%;
  color: #fff;

  label {
    color: #fff;
  }
  input {
    &:hover,
    &:active,
    &:focus,
    & {
      background: #0a2547;
      border: 0;
      color: #fff;
      height: auto;
    }
  }

  .logo {
    margin: 0 auto 3rem;
    display: block;
  }
  .links {
    margin: -1rem 0 1rem 0;
    a {
      color: #b6cbe0;
      text-decoration: none;
      &:hover {
        color: #d6e6f7;
      }
    }
    font-size: 0.8125rem;
  }

  .form-group {
    margin-bottom: 1.5rem;
  }

  > * {
    width: 400px;
    position: relative;
    top: -10vh;
  }

  input {
    padding: 0.5rem 1rem;
  }
  .btn {
    margin-top: 2rem;
    padding: 0.6rem 1rem;
  }

  .btn-primary {
    background-color: #0085d8;
    border: 0;
    &:hover,
    &:active,
    &:focus {
      background-color: #16a5ff;
    }
  }

  .logo {
    margin: 0 auto 3rem;
    display: block;
  }

  .form-group.is-invalid {
    > label,
    > .form-check > label,
    > .form-text {
      color: #fff !important;
    }
  }
  .invalid-feedback {
    background-color: #dc3545;
    color: #fff;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
  }
  .alert-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
  }
`

const SimpleLayout: React.FC = (props) => <StyledLayout>{props.children}</StyledLayout>

export default SimpleLayout
