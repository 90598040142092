import {
  ActionTypes,
  AppState,
  BEGIN_LOGIN,
  BEGIN_RECONNECTING,
  FINISH_LOGIN,
  FINISH_RECONNECTING,
  UPDATE_COMPANY,
  UPDATE_SUBSCRIPTION,
  UPDATE_USER,
} from './types'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { pubSub } from './api/socketio'
import { ClientSubscription } from '../../pubsub/src/server/PubSub/client'
import { redirect } from './components/Link'

let companySubscription
let userSubscription

export function subscribeCompany(): ThunkAction<void, AppState, unknown, Action<string>> {
  return async (dispatch, getState): Promise<void> => {
    if (!companySubscription) {
      const user = getState().user
      companySubscription = pubSub.subscribe(
        'company',
        { query: { _id: user.companyId } },
        {
          useStore: false,
          onChange: (sub, payload) => {
            if (sub.ready) {
              dispatch(finishLogin())
            }
            if (payload) {
              dispatch(updateCompany(payload))
            }
          },
        }
      )
    }
  }
}

export function subscribeUser(): ThunkAction<void, AppState, unknown, Action<string>> {
  return async (dispatch, getState): Promise<void> => {
    dispatch(beginLogin())
    if (!userSubscription) {
      userSubscription = pubSub.subscribe('login', null, {
        useStore: false,
        onChange: (sub, payload) => {
          if (payload) {
            dispatch(updateUser(payload))
          } else if (sub.ready) {
            if (getState().user) {
              dispatch(subscribeCompany())
            }
          }
        },
      })
    }
  }
}

export function beginLogin(): ActionTypes {
  return {
    type: BEGIN_LOGIN,
  }
}

export function finishLogin(): ActionTypes {
  return {
    type: FINISH_LOGIN,
  }
}

export function updateUser(payload: Record<string, any>): ActionTypes {
  return {
    type: UPDATE_USER,
    payload,
  }
}
export function updateSubscription<T>(key: string, subscription: ClientSubscription): ActionTypes {
  return {
    type: UPDATE_SUBSCRIPTION,
    key,
    subscription,
  }
}

export function updateCompany(payload?: Record<string, any>): ActionTypes {
  return {
    type: UPDATE_COMPANY,
    payload,
  }
}

export function changeCompanyContext(companyId: string): void {
  redirect({
    pathname: '/',
    query: { a: companyId },
  })
}

export function BeginReconnecting(): ActionTypes {
  return {
    type: BEGIN_RECONNECTING,
  }
}

export function FinishReconnecting(): ActionTypes {
  return {
    type: FINISH_RECONNECTING,
  }
}
