import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import { redirect } from '../../../components/Link'
import ProgramForm from './ProgramForm'
import { withAlert } from 'react-alert'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import NotFound from '../../NotFound'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'

class ProgramCreate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    programGroup: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
  }

  onSubmit = ({ formData }) => {
    const { programGroup } = this.props
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/programs/create', {
          programGroupId: programGroup._id,
          doc: formData,
        })
        const { location, history, alert } = this.props
        alert.show('Program created')
        redirect({
          location,
          history,
          pathname: `/organization/programGroups/${programGroup._id}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { programGroup, company } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[
            { label: company.name, path: '/organization' },
            { label: 'Program Groups', path: `/organization/programGroups` },
            {
              label: programGroup.name,
              path: `/organization/programGroups/${programGroup._id}`,
            },
          ]}
          title="New Program"
        />
        <div className="content">
          <Panel>
            <ProgramForm
              program={{}}
              onSubmit={this.onSubmit}
              isLoading={this.state.isLoading}
              programGroup={programGroup}
              company={company}
              buttonLabel="Create Program"
              error={this.state.error}
            />
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

const ProgramCreateWithAlert = withAlert()(ProgramCreate)

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="programGroup"
      cache={shortCache}
      query={{
        _id: props.match.params.programGroupId,
      }}
      showLoader={true}
    >
      {({ data }) => {
        const { program_groups: programGroups } = data
        if (!programGroups || programGroups.length === 0) {
          return <NotFound />
        }
        return <ProgramCreateWithAlert {...props} programGroup={programGroups[0]} />
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
