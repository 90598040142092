import { composeWithDevTools } from 'redux-devtools-extension'
import { createStore, applyMiddleware, Store, AnyAction } from 'redux'
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk'
import rootReducer from './reducers'
import { ActionTypes, AppState } from './types'

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware<AppState, ActionTypes>))
)

export { store as default }
