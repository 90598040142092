export default {
  title: 'Notification',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },

    name: {
      type: 'string',
    },

    isEnabled: {
      type: 'boolean',
    },

    duplicates: {
      type: 'string',
    },

    pathwayId: {
      type: 'string',
    },

    companyId: {
      type: 'string',
    },

    type: {
      type: 'string',
    },

    config: {
      type: 'object',
      // todo: make schemas for each notification config
    },

    triggers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            enum: ['submitStep', 'resumePathway', 'abandonPathway'],
          },
          target: {
            type: 'string',
          },
        },
        required: ['type', 'target'],
        additionalProperties: false,
      },
    },
  },

  required: ['name', 'pathwayId', 'type', 'duplicates'],
  additionalProperties: false,
}
