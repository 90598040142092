import React from 'react'
import Panel from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import UserForm from './AdminUserForm'
import View from '../../../components/View'
import PropTypes from 'prop-types'
import { redirect } from '../../../components/Link'
import apiFetch from '../../../../../_shared/api/apiFetch'

class AdminUserCreate extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    history: PropTypes.object,
  }

  state = {
    isLoading: false,
    error: null,
  }

  createUser = ({ formData }) => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/users/createAdmin', { doc: formData })
          const { location, history } = this.props
          redirect({
            location,
            history,
            pathname: `/admin/users`,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e,
          })
        }
      }
    )
  }

  render() {
    const title = 'Create User'
    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={[{ label: 'Users', path: '/admin/users' }]} />
        <div className="content">
          <Panel>
            <UserForm
              buttonLabel="Create User"
              onSubmit={this.createUser}
              error={this.state.error}
              isLoading={this.state.isLoading}
            />
          </Panel>
        </div>
      </View>
    )
  }
}

export default AdminUserCreate
