import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CampaignChart from './CampaignChart'

const Campaigns = (props) => {
  const { data } = props
  let otherCampaigns = []
  const otherCampaignNames = Object.keys(data.results.leads.totals.uniqueWeb.other)
  otherCampaignNames.forEach((campaign, i) => {
    otherCampaigns.push({
      name: campaign,
      total: data.results.leads.totals.uniqueWeb.other[campaign],
      data: data.results.charts.leads.other[i],
    })
  })

  return (
    <div>
      {!data.ppcOnly && (
        <CampaignChart
          label="Web"
          value={data.results.leads.totals.uniqueWeb.web}
          data={data.results.charts.leads.web}
        />
      )}
      {!data.type === 'webedu' && (
        <CampaignChart
          label="PPC"
          value={data.results.leads.totals.uniquePPC.ppc}
          data={data.results.charts.leads.ppc}
        />
      )}
      <CampaignChart
        label="VA"
        value={data.results.leads.totals.uniqueWeb.vaa}
        data={data.results.charts.leads.vaa}
      />
      {otherCampaigns.map((campaign, i) => (
        <CampaignChart label={campaign.name} value={campaign.total} data={campaign.data} key={i} />
      ))}
    </div>
  )
}

export default Campaigns
