export enum StatisticalSignificance {
  NO_SIGNIFICANCE = 'none',
  SIGNIFICANCE = 'significance',
  SIGNIFICANCE_LOW_DATA = 'significance_low_data',
}

export interface PersonaMetric {
  value: number
  significance?: StatisticalSignificance
  overallValue?: number
}

export type PersonaMetrics =
  | 'analytical'
  | 'expressive'
  | 'amiable'
  | 'driver'
  | 'numTours'
  | 'numInterviewed'
  | 'numEnrolled'
  | 'child'
  | 'youngAdult'
  | 'fullAdult'
  | 'unknownAge'
  | 'haveHighSchool'
  | 'stillInHighSchool'
  | 'noHighSchool'
  | 'noHighSchoolData'
  | 'noPostSecondaryData'
  | 'attendedPostSecondary'
  | 'currentlyWorking'
  | 'noWorkingData'
  | 'haveDependents'
  | 'noReliableTransportation'
  | 'inquiredOtherSchools'
  | 'interestedForSomeTime'
  | 'startingResearch'
  | 'noInquiryData'
  | 'noInterestedData'
  | 'noResearchData'
  | 'frustratedInYear'
  | 'sameInYear'
  | 'contentInYear'
  | 'noFrustratedData'
  | 'unhappyWork'
  | 'happyWork'
  | 'okWork'
  | 'noHappyData'
  | 'troubleImaginingDetails'
  | 'notWorking'
  | 'currentlyWorkinghaveDependents'
  | 'currentlyWorkingnoReliableTransportation'
  | 'haveDependentsnoReliableTransportation'
  | 'currentlyWorkinghaveDependentsnoReliableTransportation'
  | 'inquiredOtherSchoolsinterestedForSomeTime'
  | 'inquiredOtherSchoolsstartingResearch'
  | 'interestedForSomeTimestartingResearch'
  | 'inquiredOtherSchoolsinterestedForSomeTimestartingResearch'

export interface PersonaSocialStyles {
  expressive: number
  analytical: number
  driver: number
  amiable: number
  total?: number
}
export interface PersonaBackground {
  medianAge: number
  child: number
  youngAdult: number
  fullAdult: number
  unknownAge: number
}
export interface PersonaEducation {
  attendedPostSecondary: number
  haveHighSchool: number
  stillInHighSchool: number
  noHighSchool: number
  noHighSchoolData: number
  noPostSecondaryData: number
}
export interface PersonaCareerInterest {
  interestedForSomeTime: number
  startingResearch: number
  inquiredOtherSchools: number
  noInquiryData: number
  noInterestedData: number
  noResearchData: number
  inquiredOtherSchoolsinterestedForSomeTime: number
  inquiredOtherSchoolsstartingResearch: number
  interestedForSomeTimestartingResearch: number
  inquiredOtherSchoolsinterestedForSomeTimestartingResearch: number
}
export interface PersonaChallenges {
  currentlyWorking: number
  noWorkingData: number
  notWorking: number
  haveDependents: number
  noReliableTransportation: number
  currentlyWorkinghaveDependents: number
  currentlyWorkingnoReliableTransportation: number
  haveDependentsnoReliableTransportation: number
  currentlyWorkinghaveDependentsnoReliableTransportation: number
}
export interface PersonaCareerGoals {
  troubleImaginingDetails: number
  intrinsicMotivators: number
  extrinsicMotivators: number
  goals: Record<string, number>
}
export interface PersonaCareerMotivations {
  desiredChange: Record<string, number>
  unhappyWork: number
  happyWork: number
  noHappyData: number
  okWork: number
  frustratedInYear: number
  sameInYear: number
  contentInYear: number
  noFrustratedData: number
}

export interface PersonaBarriersAndHighlights {
  cautions: string[]
  highlights: string[]
}
export interface PersonaEnrollmentRoadBlock {
  theme: string
  impact: string
  count: number
  example: string
}

export interface PersonaHoldingBackItem {
  answer: string
  count: number
}
export interface Persona {
  _id?: string
  insights: string
  barriersAndHighlights: PersonaBarriersAndHighlights
  marketingInsights: string[]
  holdingBack: PersonaEnrollmentRoadBlock[]
  companyId: string
  category: string
  name: string
  programs: string[]
  sampleSize: number // numpeople
  overallSampleSize: number
  medianAge: number
  overallMedianAge: number
  desiredChange: Record<string, number>
  goals: Record<string, number>
  ageDistribution: Record<number, number>
  metrics: Record<PersonaMetrics, PersonaMetric>
  targetAudience: Record<string, string>
  socialStyles: PersonaSocialStyles

  // _id?: string
  // companyId: string
  // category: string
  // programs: string[]
  // numPeople: number
  // numTours: number
  // numEnrolled: number
  // socialStyles: PersonaSocialStyles
  // background: PersonaBackground
  // education: PersonaEducation
  // challenges: PersonaChallenges
  // careerInterest: PersonaCareerInterest
  // careerMotivation: PersonaCareerMotivations
  // careerGoals: PersonaCareerGoals
  // significance: {
  //   numTours: StatisticalSignificance
  //   numEnrolled: StatisticalSignificance
  //   numAnalytical: StatisticalSignificance
  //   numAmiable: StatisticalSignificance
  //   numDriver: StatisticalSignificance
  //   numExpressive: StatisticalSignificance
  //   numChild: StatisticalSignificance
  //   medianAge: StatisticalSignificance
  //   numYoungAdult: StatisticalSignificance
  //   numFullAdult: StatisticalSignificance
  //   haveHighSchool: StatisticalSignificance
  //   stillInHighSchool: StatisticalSignificance
  //   noHighSchool: StatisticalSignificance
  //   attendedPostSecondary: StatisticalSignificance
  //   currentlyWorking: StatisticalSignificance
  //   haveDependents: StatisticalSignificance
  //   noReliableTransportation: StatisticalSignificance
  //   inquiredOtherSchools: StatisticalSignificance
  //   interestedForSomeTime: StatisticalSignificance
  //   startingResearch: StatisticalSignificance
  //   frustratedInYear: StatisticalSignificance
  //   unhappyWork: StatisticalSignificance
  //   troubleImaginingDetails: StatisticalSignificance
  // }
}

export const PersonaCategories = {
  overall: 'Overall',
  age: {
    child: 'Under 21',
    youngAdult: '21-29',
    fullAdult: '30+',
  },
  careerFields: {
    agriculture: 'Career Field: Agriculture',
    arts: 'Career Field: Arts, Culture and Entertainment',
    beauty: 'Career Field: Beauty',
    business: 'Career Field: Business, Management and Administration',
    community: 'Career Field: Community and Social Services',
    education: 'Career Field: Education',
    health: 'Career Field: Health and Medicine',
    hospitality: 'Career Field: Hospitality',
    law: 'Career Field: Law and Public Policy',
    medical: 'Career Field: Medical Administration',
    science: 'Career Field: Science and Technology',
    trade: 'Career Field: Trades',
    other: 'Career Field: Other',
  },
}

export const PersonaDescriptions = {
  child: 'Prospects under 21 years old',
  youngAdult: 'Prospects between 21 and 29 years old',
  fullAdult: 'Prospects 30 years old and older',
  agriculture: 'Prospects interested in agriculture',
  arts: 'Prospects interested in arts, culture and entertainment',
  beauty: 'Prospects interested in beauty programs',
  business: 'Prospects interested in business, management and administration programs',
  community: 'Prospects interested in community and social services programs',
  education: 'Prospects interested in education programs',
  health: 'Prospects interested in health and medicine programs',
  hospitality: 'Prospects interested in hospitality programs',
  law: 'Prospects interested in law and public policy programs',
  medical: 'Prospects interested in medical administration programs',
  science: 'Prospects interested in science and technology programs',
  trade: 'Prospects interested in trades programs',
  other: 'Prospects interested in other fields',
}
