import React from 'react'
import { FormGroup, Button } from 'reactstrap'
import classnames from 'classnames'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faStar } from '@fortawesome/pro-solid-svg-icons/faStar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoundButton from '../../../components/RoundButton'
import styled from 'styled-components'
import {
  FormErrors,
  FormLabel,
  getFormGroupClasses,
  HelpText,
  ValidFeedback,
} from '../../../../../_shared/components/Form/helpers'
import InputGroup from '../../../../../_shared/components/Form/InputGroup'
import Input from '../../../../../_shared/components/Form/Input'
import Dropdown from '../../../../../_shared/components/Form/Dropdown'
import withForm from '../../../../../_shared/components/withForm'

class AdditionalDomains extends React.Component {
  addUrl = () => {
    const newValue = Array.from(this.props.value)
    newValue.push({ value: '', type: 'custom' })
    this.props.setFieldValue(this.props.name, newValue)
  }

  removeUrl = (index) => {
    const newValue = Array.from(this.props.value)
    newValue.splice(index, 1)
    this.props.setFieldValue(this.props.name, newValue)
  }

  setDefault = (index) => {
    const newValue = Array.from(this.props.value)
    newValue.forEach((value) => {
      value.isDefault = false
    })
    newValue[index].isDefault = true
    this.props.setFieldValue(this.props.name, newValue)
  }

  render() {
    const {
      showLabel,
      className,
      name,
      label,
      formSchema,
      id,
      errors,
      showValid,
      help,
      validFeedback,
      value,
    } = this.props

    const domainSuffix = process.env.DOMAIN_SUFFIX.replace(/^\./, '')

    return (
      <FormGroup className={classnames(getFormGroupClasses(errors, showValid), className)}>
        <FormLabel
          showLabel={showLabel}
          id={id || name}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        <table className="table table-striped">
          <thead>
            <tr>
              <th colSpan="3">URLs</th>
            </tr>
          </thead>
          <tbody>
            {value &&
              value.map((url, index) => {
                return (
                  <tr key={index}>
                    <td width="20">
                      <RoundButton
                        size="sm"
                        type="button"
                        className={url.isDefault ? 'active' : ''}
                        onClick={() => {
                          this.setDefault(index)
                        }}
                      >
                        <FontAwesomeIcon icon={faStar} />
                      </RoundButton>
                    </td>
                    <td>
                      <InputGroup
                        size="sm"
                        showLabel={false}
                        name={`urls[${index}].prefix`}
                        prefix="https://"
                      />
                    </td>
                    <td>
                      {index === 0 && (
                        <Input
                          disabled
                          size="sm"
                          showLabel={false}
                          name={`urls[${index}].suffix`}
                        />
                      )}
                      {index > 0 && (
                        <Dropdown size="sm" showLabel={false} name={`urls[${index}].suffix`}>
                          <option value={domainSuffix}>.{domainSuffix}</option>
                          {this.props.company.domains?.map((domain, index) => (
                            <option key={index} value={domain}>
                              .{domain}
                            </option>
                          ))}
                        </Dropdown>
                      )}
                    </td>
                    <td width="20">
                      {index > 0 && (
                        <RoundButton
                          size="sm"
                          type="button"
                          onClick={() => {
                            this.removeUrl(index)
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </RoundButton>
                      )}
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>

        {value && value.length < 5 && (
          <Button color="primary" type="button" onClick={this.addUrl} size="sm">
            <FontAwesomeIcon icon={faPlus} /> Add URL
          </Button>
        )}

        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default styled(withForm(AdditionalDomains))`
  margin-bottom: 2rem;
  .table {
    &,
    .input-group-text {
      font-size: 0.875rem;
    }
    .form-group {
      margin: 0;
    }
    th {
      font-size: 1rem;
      font-weight: 500;
      border-top: 0;
      color: #212529;
    }
  }
  .btn.btn-alt {
    font-size: 0.75rem;
    line-height: 2.1em;
  }
  .btn.btn-alt.active {
    color: #fff;
    background: var(--primary);
  }
`
