import React from 'react'
import styled from 'styled-components'
import TopNav from '../TopNav'
import Footer from '../BackendFooter'
import { StyledSidebar } from '../Sidebar'
import UserSidebar from '../UserSidebar'
import Error from '../Error'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import Log from '../../../../_shared/log'
import DisconnectedNotice from '../DisconnectedNotice'

const StyledMain = styled.section`
  flex: 1 0 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: hidden;
  height: 100vh;
`

const StyledTopNav = styled(TopNav)`
  flex: 0 0 auto;
`

const StyledContent = styled.section`
  flex: 1 1 auto;
  /* padding: 0 1rem 0 var(--page-gutter); */
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;

  .content {
    overflow: auto;
    padding: 1rem 1rem 1rem 1.3125rem;
    @media print {
      padding-left: 1rem;
    }
  }
`

const StyledFooter = styled(Footer)`
  flex: 0 0 auto;
`

const StyledLayout = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  min-height: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;

  ${StyledSidebar} {
    flex: 0 0 auto;
  }

  @media print {
    ${StyledMain} {
      height: auto;
      overflow: visible;
      margin: 0 !important;
    }
    ${StyledContent} {
      flex: 0 1 auto;
      overflow: visible;
    }
  }
`

interface Props extends RouteComponentProps {
  sidebar?: any
}

interface State {
  sidebarOpen?: boolean
  hasError?: boolean
  error?: string
}

class SidebarLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    let sidebarOpen
    if (window.localStorage) {
      sidebarOpen = window.localStorage.getItem('sidebar')

      if (sidebarOpen === null) {
        sidebarOpen = true
      } else {
        sidebarOpen = sidebarOpen == 'true'
      }
    }

    this.state = {
      sidebarOpen,
    }
  }

  static defaultProps = {
    sidebar: UserSidebar,
  }

  toggleSidebar = () => {
    this.setState(
      (prevState) => ({
        sidebarOpen: !prevState.sidebarOpen,
      }),
      () => {
        window.localStorage?.setItem('sidebar', String(this.state.sidebarOpen))
      }
    )
  }

  static getDerivedStateFromError(error) {
    Log.error(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        hasError: false,
        error: null,
      })
    }
  }

  closeSidebar = (event) => {
    if (!event.path) return
    let trigger = true
    event.path.forEach((elem) => {
      if (elem.id === 'sidebar' || elem.id === 'toggleSidebar') {
        trigger = false
      }
    })

    if (trigger) {
      this.setState({
        sidebarOpen: false,
      })
    }
  }

  handleSidebarClose = () => {
    // if (this.state.sidebarOpen) {
    //   document.addEventListener('click', this.closeSidebar)
    // } else {
    //   document.removeEventListener('click', this.closeSidebar)
    // }
  }

  render() {
    const { sidebar: Sidebar, children } = this.props
    return (
      <StyledLayout>
        <Sidebar id="sidebar" isOpen={this.state.sidebarOpen} toggleSidebar={this.toggleSidebar} />
        <StyledMain id="main">
          <DisconnectedNotice />
          <StyledTopNav id="header" toggleSidebar={this.toggleSidebar} />
          <StyledContent>{this.state.hasError ? <Error /> : children}</StyledContent>
          <StyledFooter />
        </StyledMain>
      </StyledLayout>
    )
  }
}

export default withRouter(SidebarLayout)
