import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import View from '../../components/View'
import NotFound from '../NotFound'
import styled from 'styled-components'
import Profile from './Profile'
import PeopleResults from './PeopleResults'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faAt } from '@fortawesome/pro-solid-svg-icons/faAt'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import DeletePersonModal from '../../components/modals/DeletePersonModal'
import { useSelector } from 'react-redux'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { shortCache } from '../../api/subscriptionCache'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'

const PeopleView = (props) => {
  const user = useSelector((state) => state.user)
  const { profile, className } = props

  return (
    <View title={profile.getFullName(true)}>
      <div className={`row align-items-stretch no-gutters ${className}`}>
        <div className="col d-flex flex-column" style={{ height: '100%' }}>
          <PageHeader
            breadcrumbs={[{ label: 'People', path: '/people' }]}
            title={profile.getFullName(true)}
          />
          <div className="content">
            <Profile profile={profile} />
            <PeopleResults profile={profile} />
          </div>
        </div>
        <div className="col-auto people-sidebar">
          <div className="profile">
            <div className="pic">
              <img src={profile.pic || '/images/default.png'} />
            </div>
            <ul className="meta">
              <li className="name">
                <FontAwesomeIcon icon={faUser} fixedWidth transform="up-1" />
                {profile.getFullName()}
              </li>
              {profile.email && (
                <li className="email">
                  <FontAwesomeIcon icon={faAt} fixedWidth />
                  {profile.email}
                </li>
              )}
              {profile.phone && (
                <li className="phone">
                  <FontAwesomeIcon icon={faPhone} fixedWidth />
                  {profile.phone}
                </li>
              )}
              {profile.isInterviewed && (
                <li className="interviewed">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                  Interviewed
                </li>
              )}

              {profile.isEnrolled && (
                <li className="enrolled">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                  Enrolled
                </li>
              )}
            </ul>
          </div>

          {user.isAdmin() && (
            <div className="actions">
              <div className="title">Actions</div>
              <ul>
                {user.isAdmin() && (
                  <li>
                    <DeletePersonModal
                      profile={profile}
                      buttonTag="a"
                      buttonProps={{ href: '#' }}
                    />
                  </li>
                )}
              </ul>
            </div>
          )}

          {profile.social && (
            <div className="actions">
              <div className="title">Social Media</div>
              <ul>
                {profile.social
                  .sort((a, b) => {
                    if (a.name < b.name) return -1
                    if (a.name > b.name) return 1
                  })
                  .map((s) => (
                    <li key={s.name}>
                      <a href={s.url} rel="noopener noreferrer" target="_blank">
                        {s.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </View>
  )
}

PeopleView.propTypes = {
  profile: PropTypes.object.isRequired,
  mock: PropTypes.object,
  user: PropTypes.object,
  className: PropTypes.string,
}

const StyledPersonView = styled(PeopleView)`
  height: 100%;
  .people-sidebar {
    font-size: 0.875rem;
    flex: 0 0 18em;
    border-left: 1px solid var(--border-color);
    background: #fff;
    overflow-x: hidden;
    overflow-y: auto;
    color: #6f7982;

    .enrolled,
    .interviewed {
      color: var(--success);
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .svg-inline--fa {
      margin-right: 0.3em;
      font-size: 1em;
      display: inline-block;
    }

    .profile {
      border-bottom: 1px solid var(--border-color);
      padding: 1em;
      font-size: 0.95em;

      .pic {
        img {
          border-radius: 100%;
          margin: 0 auto;
          width: 100px;
          display: block;
        }
        margin: 0 0 1rem 0;
      }
      .meta {
        li {
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0.1em 0;
        }
        .name {
          color: var(--primary);
          font-weight: 600;
          font-size: 1em;
        }
      }
    }

    .actions {
      border-bottom: 1px solid var(--border-color);
      padding: 1em;
      .title {
        font-weight: 600;
        margin-bottom: 0.4em;
        color: var(--heading-color);
      }
      .svg-inline--fa {
        color: var(--text-muted-blue);
      }
      a {
        &:hover {
          text-decoration: underline !important;
        }
      }
      ul {
        li {
          padding: 0.15em 0;
        }
      }
    }
  }
  .content {
    flex: 1 1 auto;
  }
`

const PeopleViewLoader = (props) => {
  const selectedCompany = useSelector(
    (state) => state.subscriptions.companyContext.data.companies[0]
  )
  return (
    <SubscriptionLoader
      resource="person"
      cache={shortCache}
      query={{
        _id: props.match.params.personId,
        companyId: selectedCompany._id,
      }}
      showLoader={true}
    >
      {({ subscription, data }) => {
        if (!data?.people || data.people.length === 0) {
          return <NotFound />
        }

        return <StyledPersonView profile={data.people[0]} {...props} />
      }}
    </SubscriptionLoader>
  )
}

export default PeopleViewLoader
