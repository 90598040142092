import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from '../Link'
import apiFetch from '../../../../_shared/api/apiFetch'

class DeleteNotificationModal extends React.PureComponent {
  state = {
    isLoading: false,
    error: null,
    redirect: false,
  }

  static propTypes = {
    notification: PropTypes.object.isRequired,
    buttonTag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    buttonProps: PropTypes.object,
    redirect: PropTypes.string,
  }

  callMethod = () => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('DELETE', '/notifications/delete', {
            notificationId: this.props.notification._id,
          })
          if (this.props.redirect) {
            this.setState({
              redirect: true,
            })
          }
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    if (this.state.redirect) {
      return <Redirect pathname={this.props.redirect} />
    }

    return (
      <BasicModal
        title="Delete Notification"
        message={<div>Are you sure you want to delete {this.props.notification.name}?</div>}
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon fixedWidth icon={faTimes} /> Delete
      </BasicModal>
    )
  }
}

export default DeleteNotificationModal
