import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import PageActions, { PageAction } from '../../../components/PageActions'
import FirstCompany from '../../../components/FirstCompany'
import { Link, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import RoundButton from '../../../components/RoundButton'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import View from '../../../components/View'
import { PanelList, PanelListItem } from '../../../components/PanelList'
import DeleteCompanyModal from '../../../components/modals/DeleteCompanyModal'
import CompanyTagDropdown from '../../../components/CompanyTagDropdown'
import { faTags } from '@fortawesome/pro-solid-svg-icons/faTags'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import InfiniteScroll from 'react-infinite-scroller'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import { changeCompanyContext } from '../../../actions'
import { sort } from 'fast-sort'
import { longCache, shortCache } from '../../../api/subscriptionCache'
import classNames from 'classnames'
import { CompanyStatus } from '../../../../../_shared/models/company/company.client'
import NotFound from '../../NotFound'

class CompanyDropdown extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }

    this.dropdownRef = React.createRef()
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path?.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest" right>
          {/* <DropdownItem>
            <FontAwesomeIcon fixedWidth icon={faArrows} /> Move
          </DropdownItem>
          <DropdownItem>
            <FontAwesomeIcon fixedWidth icon={faPowerOff} /> Disable
          </DropdownItem>
          <DropdownItem divider /> */}
          <DeleteCompanyModal
            company={this.props.company}
            buttonTag={DropdownItem}
            buttonProps={{ toggle: false }}
          />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const Company = (props) => (
  <PanelListItem>
    <div className={'row align-items-center flex-nowrap ' + props.className}>
      <div className="col-auto">
        <div
          className={classNames({
            'company-status': 1,
            active: props.company.isActive(),
            inactive: !props.company.isActive(),
          })}
        ></div>
      </div>
      <div className="col">
        <a
          href={`/?a=${props.company._id}`}
          onClick={(event) => {
            event.preventDefault()
            changeCompanyContext(props.company._id)
          }}
        >
          {props.company.name}
        </a>
        <CompanyTagDropdown company={props.company} tags={props.tags} />
      </div>
      <div className="d-none d-md-flex col-auto buttons">
        <RoundButton size="sm" tag={Link} to={`/admin/clients/${props.company._id}`}>
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <CompanyDropdown company={props.company} />
      </div>
    </div>
  </PanelListItem>
)
Company.propTypes = {
  company: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
const CompanyStyled = styled(Company)`
  .company-status {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    &.active {
      background-color: var(--success);
    }
    &.inactive {
      background-color: var(--danger);
    }
  }
  .col-auto:first-child {
    padding-right: 0;
  }
`

class CompanyTagsFilterDropdown extends React.Component {
  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    const { className, tags, active, add } = this.props

    // only show tags that arent already active
    const activeTags = active || []
    const tagsToShow = tags
      .filter((t) => !activeTags.includes(t._id))
      .sort((a, b) => {
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1
        }
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        }
        return 0
      })

    return (
      <div className={this.props.className}>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggle}>
          <DropdownToggle tag="button" caret>
            <FontAwesomeIcon icon={faTags} />
          </DropdownToggle>
          <DropdownMenu>
            {tagsToShow.map((tag) => (
              <DropdownItem
                key={tag._id}
                onClick={() => {
                  add(tag._id)
                }}
              >
                <div className={`tag-color-preview tag-color-${tag.color}`} /> {tag.name}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}
const CompanyTagsFilterDropdownStyled = styled(CompanyTagsFilterDropdown)`
  .dropdown-toggle {
    border: 0;
    padding: 0;
    background: none;
    outline: none;
    color: var(--text-muted);
    .fa-tags {
      font-size: 1rem;
    }
    cursor: pointer;
  }
  .dropdown-menu {
    padding: 0;
    font-size: 0.875rem;
    max-height: 200px;
    overflow: auto;
  }
`

const CompanyTagsFilter = ({ className, active, tags, add, remove }) => (
  <div className={className}>
    <div className="row align-items-center no-gutters">
      <div className="col-auto" style={{ marginRight: '0.5rem' }}>
        <ul className="active-tags" style={{ margin: 0, padding: 0, fontSize: '0.875rem' }}>
          {active &&
            active.map((activeTag) => {
              const tag = tags.find((t) => t._id === activeTag)
              if (!tag) {
                return
              }

              return (
                <li key={tag._id} className={`company-tag tag-color-${tag.color}`}>
                  {tag.name}{' '}
                  <button
                    type="button"
                    className="remove"
                    onClick={(event) => {
                      event.stopPropagation()
                      remove(tag._id)
                    }}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </li>
              )
            })}
        </ul>
      </div>
      <div className="col-auto">
        <CompanyTagsFilterDropdownStyled active={active} add={add} tags={tags} />
      </div>
    </div>
  </div>
)

class CompanyListing extends React.PureComponent {
  static defaultProps = {
    limit: 20,
    perPage: 20,
    status: CompanyStatus.ACTIVE,
  }

  state = {
    hasMore: false,
    limit: 20,
    firstLoad: true,
    currentPage: 0,
  }

  loadMore = () => {
    const newLimit = this.state.limit + this.props.perPage
    this.setState({
      limit: newLimit,
      currentPage: this.state.currentPage + 1,
      hasMore: false,
    })
  }

  setCount = (store) => {
    const hasMore = store?.companies?.length >= this.state.limit
    this.setState({
      hasMore,
    })
  }

  render() {
    const query = {
      status: this.props.status,
    }

    if (this.props.filter?.length > 0) {
      query.tags = { $in: this.props.filter }
    }

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={this.loadMore}
        hasMore={this.state.hasMore}
        useWindow={false}
      >
        <SubscriptionLoader
          resource="companies"
          query={query}
          sort={{ status: -1, name: 1 }}
          limit={this.state.limit}
          setCount={this.setCount}
          showLoader={true}
          cache={shortCache}
          showLoaderInline={true}
          transform={(store) => ({
            ...store,
            companies: sort(store.companies).asc([(r) => r.status, (r) => r.name]),
          })}
        >
          {({ data, subscription }) => {
            if (subscription.ready && (!data || !data.companies || data.companies.length === 0)) {
              return FirstCompany
            }

            if (!data || !data.companies) {
              return null
            }

            return (
              <PanelList>
                {data.companies.map((c) => (
                  <CompanyStyled
                    key={c._id}
                    tags={this.props.tags}
                    company={c}
                    history={this.props.history}
                    selectCompany={this.props.selectCompany}
                  />
                ))}
              </PanelList>
            )
          }}
        </SubscriptionLoader>
      </InfiniteScroll>
    )
  }
}

class CompanyIndex extends React.Component {
  state = {
    filter: null,
  }

  addTagToFilter = (tagId) => {
    let filter = Array.from(this.state.filter || [])
    filter.push(tagId)
    this.setState({
      filter,
    })
  }

  removeTagFromFilter = (tagId) => {
    let filter = Array.from(this.state.filter || [])
    filter = filter.filter((t) => t !== tagId)
    this.setState({
      filter,
    })
  }

  render() {
    return (
      <View title="Clients">
        <SubscriptionLoader resource="companyTags" showLoader={true} cache={shortCache}>
          {({ data }) => {
            const tags = data.company_tags || []
            return (
              <React.Fragment>
                <PageHeader
                  title="Clients"
                  navItems={[
                    { label: 'Active', route: `/admin/clients` },
                    { label: 'Inactive', route: `/admin/clients/inactive` },
                  ]}
                >
                  <PageActions>
                    <PageAction toolbar>
                      <CompanyTagsFilter
                        add={this.addTagToFilter}
                        remove={this.removeTagFromFilter}
                        active={this.state.filter}
                        tags={tags}
                      />
                    </PageAction>
                    <PageAction
                      toolbar
                      button
                      color="primary"
                      tag={Link}
                      tagProps={{
                        to: '/admin/clients/new',
                      }}
                    >
                      <FontAwesomeIcon icon={faPlus} /> New Client
                    </PageAction>
                  </PageActions>
                </PageHeader>
                <div className="content">
                  <Switch>
                    <Route
                      path="/admin/clients"
                      exact
                      render={() => (
                        <CompanyListing
                          status={CompanyStatus.ACTIVE}
                          tags={tags}
                          filter={this.state.filter}
                          {...this.props}
                        />
                      )}
                    />
                    <Route
                      path="/admin/clients/inactive"
                      exact
                      render={() => (
                        <CompanyListing
                          status={CompanyStatus.INACTIVE}
                          tags={tags}
                          filter={this.state.filter}
                          {...this.props}
                        />
                      )}
                    />
                    <NotFound />
                  </Switch>
                </div>
              </React.Fragment>
            )
          }}
        </SubscriptionLoader>
      </View>
    )
  }
}

CompanyIndex.propTypes = {
  mock: PropTypes.object,
  history: PropTypes.object,
  selectCompany: PropTypes.func,
}

export default CompanyIndex
