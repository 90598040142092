import { v4 as uuidv4 } from 'uuid'
import { Payload, SubscriptionParams, SubscriptionQuery } from './types'
import PubSubStore from './store'
import { Socket } from 'socket.io-client'
import SubscriptionCache from './SubscriptionCache'

export interface SubscriptionEventHandler {
  (subscription: ClientSubscription, payload?: Payload): void
}

export interface SubscriptionDataHandler {
  (subscription: ClientSubscription, payload: Payload): void
}

export interface SubscriptionReadyHandler {
  (subscription: ClientSubscription, payload: Payload): void
}
export interface SubscribeOptions {
  cache?: SubscriptionCache
  useStore?: boolean
  onInsert?: SubscriptionEventHandler
  onUpdate?: SubscriptionEventHandler
  onRemove?: SubscriptionEventHandler
  onReady?: SubscriptionEventHandler
  onChange?: SubscriptionEventHandler
  transform?: (record: any) => any
}

export class ClientSubscription {
  id: string
  topic: string
  _queryParams: SubscriptionParams['queryParams']
  ready: boolean
  pubSubManager: PubSub
  store?: PubSubStore
  cache?: SubscriptionCache
  isNew: boolean

  useStore = true

  onInsert: SubscriptionEventHandler
  onUpdate: SubscriptionEventHandler
  onRemove: SubscriptionEventHandler
  onReady: SubscriptionEventHandler
  onChange: Set<SubscriptionEventHandler>
  onError: (message: string, subscriptionId?: string) => void

  constructor(
    id: string,
    topic: string,
    queryParams: SubscriptionParams['queryParams'],
    pubSubManager: PubSub,
    options?: SubscribeOptions
  ) {
    Object.assign(this, options || {})
    this.id = id
    this.topic = topic
    this._queryParams = queryParams
    this.pubSubManager = pubSubManager
    this.ready = false
    this.onChange = new Set()
    this.isNew = true

    if (options.onChange) {
      this.onChange.add(options.onChange)
    }

    if (this.useStore) {
      this.store = new PubSubStore(this)
    }
  }

  set queryParams(queryParams: SubscriptionParams['queryParams']) {
    this._queryParams = queryParams
  }

  get queryParams(): SubscriptionParams['queryParams'] {
    return this._queryParams
  }

  subscribe(skipCache = false): void {
    this.ready = false

    this.pubSubManager.socket.emit('subscribe', {
      id: this.id,
      topic: this.topic,
      queryParams: this.queryParams,
    })

    if (!skipCache && this.cache && this.useStore && !this.isNew) {
      this.ready = true
    }

    this.onChange?.forEach((handler) => {
      handler(this)
    })

    this.isNew = false
  }

  unsubscribe(forceIfCached = false): void {
    if (forceIfCached || !this.cache) {
      this.pubSubManager.unsubscribe(this)
      this._unsubscribed()
    }
  }

  _unsubscribed(): void {
    this.ready = false
    this.clearStore()
  }

  getStore(): Record<string, any> {
    return this.store.getData()
  }

  clearStore(): void {
    if (this.useStore) {
      this.store.empty()
    }

    this.onChange?.forEach((handler) => {
      handler(this)
    })
  }

  dataHandler(payload: Payload): void {
    if (this.id !== payload.subscriptionId) {
      return
    }

    switch (payload.operation) {
      case 'insert':
        if (this.onInsert) {
          this.onInsert(this, payload)
        }
        if (this.store) {
          this.store.upsert(payload)
        }
        break
      case 'update':
        if (this.onUpdate) {
          this.onUpdate(this, payload)
        }
        if (this.store) {
          this.store.upsert(payload)
        }
        break
      case 'remove':
        if (this.onRemove) {
          this.onRemove(this, payload)
        }
        if (this.store) {
          this.store.remove(payload)
        }
        break
    }

    this.onChange?.forEach((handler) => {
      handler(this, payload)
    })
  }

  registerChangeHandler(handler: any) {
    this.onChange.add(handler)
  }

  removeChangeHandler(handler: any) {
    this.onChange.delete(handler)
  }

  errorHandler(message: string, subscriptionId?: string): void {
    console.error(`Error in subscription "${subscriptionId}": ${message}`)
    if (this.onError) {
      this.onError(message, subscriptionId)
    }
  }

  readyHandler(payload: Payload): void {
    if (this.id !== payload.subscriptionId) {
      return
    }
    this.ready = true
    if (this.onReady) {
      this.onReady(this)
    }
    this.onChange?.forEach((handler) => {
      handler(this)
    })
  }
}

export interface CollectionTransform {
  (document: any): any
}

export interface PubSubClientOptions {
  collectionTransforms?: Record<string, CollectionTransform>
  loginToken?: string
  onLogin?: () => void
  onError?: (error: string) => void
  onReconnecting?: () => void
  onReconnected?: () => void
}

class PubSub {
  socket: Socket
  subscriptions: Map<string, ClientSubscription>
  collectionTransforms: Record<string, CollectionTransform>
  loginToken?: string
  loggingIn = false
  loginResolve?: () => void
  loginReject?: (reason?: string) => void
  onLogin?: () => void
  onError?: (error: string) => void
  onReconnecting?: () => void
  onReconnected?: () => void

  constructor(socket: Socket, options?: PubSubClientOptions) {
    this.socket = socket
    this.subscriptions = new Map<string, ClientSubscription>()

    this.collectionTransforms = options?.collectionTransforms || {}
    this.loginToken = options?.loginToken
    this.onLogin = options?.onLogin
    this.onError = options?.onError
    this.onReconnecting = options?.onReconnecting
    this.onReconnected = options?.onReconnected

    // Listen to the relevant socket event
    this.socket.on('data', this.dataHandler)
    this.socket.on('error', this.errorHandler)
    this.socket.on('ready', this.readyHandler)
    this.socket.on('login', this.loginHandler)
    this.socket.on('connect', this.connectedHandler)
    this.socket.io.on('reconnect', this.reconnectedHandler)
    this.socket.on('disconnect', this.disconnectHandler)
  }

  login = () => {
    if (this.loginToken) {
      if (this.loggingIn) {
        return
      }
      const promise = new Promise<void>((resolve, reject) => {
        this.loggingIn = true
        this.loginResolve = resolve
        this.loginReject = reject
        this.socket.emit('login', this.loginToken)
      }).catch((error) => {
        this.onError(error)
      })
      return promise
    }
  }

  loginHandler = (result) => {
    this.loggingIn = false
    if (result.success) {
      if (this.loginResolve) {
        this.loginResolve()
      }
    } else {
      if (this.loginReject) {
        this.loginReject('Login failed: ' + result.error)
      }
    }
  }

  disconnectHandler = () => {
    if (this.onReconnecting) {
      this.onReconnecting()
    }
  }

  connectedHandler = async (): Promise<void> => {
    if (this.loginToken) {
      await this.login()
      if (this.onLogin) {
        this.onLogin()
      }
    }
  }

  reconnectedHandler = async (): Promise<void> => {
    if (this.onReconnected) {
      this.onReconnected()
    }
    if (this.loginToken) {
      await this.login()
      if (this.onLogin) {
        this.onLogin()
      }
    }
    this.subscriptions.forEach((sub) => {
      sub._unsubscribed()
      sub.subscribe()
    })
  }

  errorHandler = ({ message, subscriptionId }): void => {
    const sub = this.subscriptions.get(subscriptionId)
    if (sub) {
      sub.errorHandler(message, subscriptionId)
    }
  }

  dataHandler = (payload: Payload): void => {
    const sub = this.subscriptions.get(payload.subscriptionId)
    if (sub) {
      sub.dataHandler(payload)
    }
  }

  readyHandler = (payload: Payload): void => {
    const sub = this.subscriptions.get(payload.subscriptionId)
    if (sub) {
      sub.readyHandler(payload)
    }
  }

  subscribe(
    topic: string,
    queryParams: SubscriptionParams['queryParams'],
    options: SubscribeOptions
  ): ClientSubscription {
    let subscription: ClientSubscription
    if (options.cache) {
      subscription = options.cache.getSubscription(topic, queryParams)
    }

    if (!subscription) {
      subscription = new ClientSubscription(uuidv4(), topic, queryParams, this, options)
      if (options.cache) {
        options.cache.addSubscription(subscription)
      }
    }

    if (options.onChange) {
      subscription.registerChangeHandler(options.onChange)
    }

    this.subscriptions.set(subscription.id, subscription)

    subscription.subscribe()

    return subscription
  }

  unsubscribe(subscription: ClientSubscription): void {
    this.socket.emit('unsubscribe', {
      id: subscription.id,
    })
    this.subscriptions.delete(subscription.id)
  }

  destroy(): void {
    this.socket.off('data', this.dataHandler)
    this.socket.off('ready', this.readyHandler)
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe()
    })
  }
}

export default PubSub
