import React from 'react'
import styled from 'styled-components'

const Sidebar = props => (
  <div className={props.className}>
    <div className="sidebar-title">{props.title}</div>
    <div className="content">{props.children}</div>
  </div>
)

export default styled(Sidebar)`
  display: flex;
  flex-direction: column;
  width: 380px;
  border-left: 1px solid #ccc;
  font-size: 0.875rem;

  > * {
    flex: 0 0 auto;
  }

  .TabNav {
    margin: -1rem -1rem 0 -1rem;
    box-shadow: inset 0 -1px 0px 0px #eef1f3;

    > div {
      padding: 0.9rem 1.3rem;
    }
  }

  .content {
    flex: 1 1 1rem;
    overflow: auto;
    padding: 1rem;
    background: #fbfcfc;

    .TabNav {
      background: #fff;
      div {
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 600;
      }
    }
  }

  .sidebar-title {
    background: var(--primary);
    color: #fff;
    padding: 0.9rem 1rem;
    font-size: 1rem;
    font-weight: 500;
  }
`
