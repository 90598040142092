import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { FormContextProps } from '../../../../../_shared/components/Form'
import Dropdown from '../../../../../_shared/components/Form/Dropdown'
import withForm from '../../../../../_shared/components/withForm'

interface Props extends Partial<FormContextProps> {
  className?: string
}

const CompanyMaxioConfig: React.FC<Props> = ({ className, formData }) => {
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(false)
  const [isLoadingSubscriptions, setIsLoadingSubscriptions] = useState(false)
  const [isLoadingComponents, setIsLoadingComponents] = useState(false)
  const [isLoadingPricePoints, setisLoadingPricePoints] = useState(false)
  const [maxioCustomers, setMaxioCustomers] = useState([])
  const [maxioSubscriptions, setMaxioSubscriptions] = useState([])
  const [maxioComponents, setMaxioComponents] = useState([])
  const [maxioPricePoints, setMaxioPricePoints] = useState([])

  const loadCustomers = useRef(async () => {
    setIsLoadingCustomers(true)
    const result = await apiFetch('GET', '/companies/maxio/customers')
    setMaxioCustomers(result)
    setMaxioSubscriptions([])
    setMaxioComponents([])
    setMaxioPricePoints([])
    setIsLoadingCustomers(false)
  })

  const loadSubscriptions = useRef(async (customerId: string) => {
    setIsLoadingSubscriptions(true)
    const result = await apiFetch('GET', '/companies/maxio/subscriptions', null, { customerId })
    setMaxioSubscriptions(result)
    setMaxioComponents([])
    setMaxioPricePoints([])
    setIsLoadingSubscriptions(false)
  })

  const loadComponents = useRef(async (subscriptionId: string) => {
    setIsLoadingComponents(true)
    const result = await apiFetch('GET', '/companies/maxio/components', null, { subscriptionId })
    setMaxioComponents(result)
    setMaxioPricePoints([])
    setIsLoadingComponents(false)
  })

  const loadComponentPricePoints = useRef(async (componentId: string) => {
    setisLoadingPricePoints(true)
    const result = await apiFetch('GET', '/companies/maxio/componentPricePoints', null, {
      componentId,
    })
    setMaxioPricePoints(result)
    setisLoadingPricePoints(false)
  })

  const initialLoad = useRef(async () => {
    await loadCustomers.current()
    if (formData.maxio?.customerId) {
      await loadSubscriptions.current(formData.maxio.customerId)
    }
    if (formData.maxio?.subscriptionId) {
      await loadComponents.current(formData.maxio.subscriptionId)
    }
    if (formData.maxio?.componentId) {
      await loadComponentPricePoints.current(formData.maxio.componentId)
    }
  })

  // on first run load data as needed
  useEffect(() => {
    // always load customers
    initialLoad.current()
    // wait to load all customers
    // wait to load all subscriptions
    // wait to load all components
  }, [])

  // when customerId changes, load subscriptions and clear data
  useEffect(() => {
    if (formData.maxio?.customerId) {
      loadSubscriptions.current(formData.maxio.customerId)
    }
  }, [formData.maxio?.customerId])

  useEffect(() => {
    if (formData.maxio?.subscriptionId) {
      loadComponents.current(formData.maxio.subscriptionId)
    }
  }, [formData.maxio?.subscriptionId])

  useEffect(() => {
    if (formData.maxio?.componentId) {
      loadComponentPricePoints.current(formData.maxio.componentId)
    }
  }, [formData.maxio?.componentId])

  return (
    <div className={className}>
      <h3>Billing</h3>
      <Dropdown
        placeholder="(Select One)"
        name="maxio.customerId"
        disabled={isLoadingCustomers}
        help="Customer as they appear in the Maxio (Chargify) UI"
      >
        {!isLoadingCustomers &&
          maxioCustomers?.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.organization}
            </option>
          ))}
      </Dropdown>

      <Dropdown
        placeholder="(Select One)"
        name="maxio.subscriptionId"
        disabled={isLoadingSubscriptions || !formData.maxio?.customerId}
        help="Product the customer is subscribed to"
      >
        {!isLoadingSubscriptions &&
          maxioSubscriptions?.map((subscription) => (
            <option key={subscription.id} value={subscription.id}>
              {subscription.productName}
            </option>
          ))}
      </Dropdown>

      <Dropdown
        placeholder="(Select One)"
        name="maxio.componentId"
        disabled={isLoadingComponents || !formData.maxio?.subscriptionId}
        help="Component to attach metered pricing to"
      >
        {!isLoadingComponents &&
          maxioComponents?.map((component) => (
            <option key={component.id} value={component.id}>
              {component.name}
            </option>
          ))}
      </Dropdown>

      <Dropdown
        placeholder="(Select One)"
        name="maxio.componentPricePointId"
        disabled={isLoadingPricePoints || !formData.maxio?.componentId}
        help="Price point to use (optional)"
      >
        {!isLoadingPricePoints &&
          maxioPricePoints?.map((component) => (
            <option key={component.id} value={component.id}>
              {component.name}
            </option>
          ))}
      </Dropdown>
    </div>
  )
}

export default styled(withForm(CompanyMaxioConfig, true))``
