import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import Panel from '../../components/Panel'
import { redirect } from '../../components/Link'
import View from '../../components/View'
import TrackingCodeForm from './TrackingCodeForm'
import NotFound from '../NotFound'
import { withAlert } from 'react-alert'
import apiFetch from '../../../../_shared/api/apiFetch'
class TrackingCodeUpdate extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }
    const { pathway, match } = this.props

    const doc = {
      _id: match.params.codeId,
      ...data.formData,
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/trackingCodes/update', { pathwayId: pathway._id, code: doc })
        const { location, history } = this.props
        this.props.alert.show('Tracking Code saved')
        redirect({
          location,
          history,
          pathname: `/pathways/${this.props.pathway._id}/settings/codes`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { pathway, match } = this.props
    const code = pathway.trackingCodes.find((c) => c._id === match.params.codeId)
    if (!code) {
      return <NotFound />
    }
    const title = `Edit ${code.name}`
    return (
      <View layout="sidebar" title={title}>
        <PageHeader
          breadcrumbs={[
            {
              label: pathway.name,
              path: `/pathways/${pathway._id}/settings/codes`,
            },
          ]}
          title={title}
        />
        <div className="content">
          <Panel>
            <TrackingCodeForm
              code={code}
              pathway={pathway}
              buttonLabel="Save Changes"
              onSubmit={this.onSubmit}
              isLoading={this.state.isLoading}
              error={this.state.error}
            />
          </Panel>
        </div>
      </View>
    )
  }
}

export default withAlert()(TrackingCodeUpdate)
