import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CampaignSchema from '../../../../_shared/models/campaign/CampaignSchema'
import { Button } from 'reactstrap'
import Panel, { PanelForm } from '../../components/Panel'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'

const { name } = CampaignSchema.properties

const CampaignFormSchema = {
  type: 'object',
  properties: {
    name,
  },
  required: ['name'],
}

const CampaignForm = (props) => {
  const { formData, onSubmit, buttonLabel, isLoading } = props

  return (
    <Form formData={formData} schema={CampaignFormSchema} onValidSubmit={onSubmit}>
      <Panel>
        <PanelForm center={false}>
          <Input type="text" name="name" />
          <hr />
          <Button color="primary" type="submit" disabled={isLoading}>
            {buttonLabel || 'Submit'}
          </Button>
        </PanelForm>
      </Panel>
    </Form>
  )
}
CampaignForm.propTypes = {
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
}

export default styled(CampaignForm)``
