import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import Panel, { PanelForm } from '../../components/Panel'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import CompanySchema from '../../../../_shared/models/company/CompanySchema'
import PathwayBundles from '../../../../_shared/api/PathwayBundles/PathwayBundles'

const { name, type, website, phone, notes, status } = CompanySchema.properties

const CompanyFormSchema = {
  type: 'object',
  properties: {
    name,
    type,
    bundles: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    website,
    phone,
    notes,
    status,
  },
  required: ['name', 'bundles'],
}

const CompanyFormC = (props) => {
  const { formData, onSubmit, buttonLabel, isLoading } = props

  return (
    <Form formData={formData} schema={CompanyFormSchema} onValidSubmit={onSubmit}>
      <Panel>
        <PanelForm center={false}>
          <Input type="text" name="name" />
        </PanelForm>

        <Cardbox
          name="status"
          label="Account Status"
          help="Determines whether or not pathways function and users can login"
          thin
          defaultValue="active"
        >
          <CardboxItem
            value="active"
            image="/images/tick.png"
            title="Active"
            // description="Enables all functionality"
          />
          <CardboxItem
            value="inactive"
            image="/images/cross.png"
            title="Inactive"
            // description="Disables pathway functionality and access to VirtualAdviser"
          />
        </Cardbox>
        <Cardbox
          name="type"
          label="Account Type"
          help="Determines this client's level of access"
          vertical
          thin
          defaultValue="standard"
        >
          <CardboxItem
            value="standard"
            image="/images/building2.png"
            title="Standard"
            description="Grants access to pathways, users, statistics, and integrations"
          />
          <CardboxItem
            value="agency"
            image="/images/building.png"
            title="Agency"
            description="Grants access to organizations"
          />
        </Cardbox>
      </Panel>

      <Panel>
        <Cardbox
          multiple
          name="bundles"
          help="Select the bundles of pathway templates that this
                    organization will have access to."
          label="Pathway Bundles"
          thin
          vertical
        >
          {Object.entries(PathwayBundles).map(([key, bundle]: [string, any]) => (
            <CardboxItem key={key} value={key} title={bundle.name} />
          ))}
        </Cardbox>

        <hr />
        <Button color="primary" type="submit" disabled={isLoading}>
          {buttonLabel || 'Submit'}
        </Button>
      </Panel>
    </Form>
  )
}
CompanyFormC.propTypes = {
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
}

export default styled(CompanyFormC)``
