import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loader from './Loader'
import Link from './Link'
import palette from 'google-palette'
import Pie from './Pie'
import apiFetch from '../../../_shared/api/apiFetch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { Tooltip } from 'reactstrap';

const ConsentTooltip = ({id}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)

  return (
    <Tooltip
      autohide={true}
      isOpen={tooltipOpen}
      target={id}
      toggle={() => setTooltipOpen(!tooltipOpen)}
    >Cookie consent is enabled for this pathway. Visitors who do not consent will not be counted. Actual visitor count may be higher.</Tooltip>
  )
}

const PathwayStatisticsTableWithTraffic = ({ id, className, pathways }) => {
  const colors = palette('tol-rainbow', pathways?.length).map(function (hex) {
    return '#' + hex
  })

  return (
    <div className={className}>
      <table id={id} className="table table-striped pathway-stat-table">
        <thead>
          <tr>
            <th>Pathway</th>
            <th width="150">Unique Visitors</th>
            <th width="150">Registrations</th>
            <th width="150">Completions</th>
          </tr>
        </thead>
        <tbody>
          {pathways &&
            pathways.map((pathway, index) => (
              <tr key={pathway._id}>
                <td>
                  <Link to={`/statistics/pathway/${pathway._id}`}>
                    <div className="chart-legend" style={{ backgroundColor: colors[index] }} />
                    {pathway.name}
                  </Link>
                </td>
                <td>{pathway.visitors} {pathway.cookieConsentBanner && (
                  <>
                    <FontAwesomeIcon id={`cookieConsentTooltip-${pathway._id}`} icon={faInfoCircle} />
                    <ConsentTooltip id={`cookieConsentTooltip-${pathway._id}`}/>
                  </>
                )}</td>
                <td>
                  {pathway.registrations} ({pathway.registrationRate})
                </td>
                <td>
                  {pathway.completions} ({pathway.completionRate})
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}
PathwayStatisticsTableWithTraffic.propTypes = {
  className: PropTypes.string,
  pathways: PropTypes.array.isRequired,
}

const StyledPathwayStatisticsTableWithTraffic = styled(PathwayStatisticsTableWithTraffic)`
  .table {
    margin-top: 2rem;
    font-size: 0.875rem;
  }
  .table th {
    border-top: 0;
    font-weight: 600;
  }
  .table td {
    vertical-align: center;
  }
  .csspie-wrapper {
    margin-right: 0.7rem;
    font-size: 0.875rem;
  }
  .csspie-wrapper,
  .completion-rate {
    display: inline-block;
    vertical-align: middle;
  }
  .chart-legend {
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }
  .fa-info-circle {
    color: #808080;
    margin-left: 0.2rem;
  }
`

export class PathwayStatisticsTableWithTrafficContainer extends React.Component {
  state = {
    isLoading: true,
    data: null,
  }

  static propTypes = {
    id: PropTypes.string,
    campaignId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.start !== this.props.start || prevProps.end !== this.props.end) {
      this.loadData()
    }
  }

  loadData = () => {
    const { campaignId, companyId, start, end } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/statistics/pathwayStatisticsWithTraffic',
            {},
            {
              companyId,
              campaignId,
              start,
              end,
            },
            true
          )
          this.setState({
            isLoading: false,
            data: result,
          })
        } catch (e) {
          this.setState({
            error: e,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { isLoading, data } = this.state

    if (isLoading) {
      return <Loader />
    }

    return <StyledPathwayStatisticsTableWithTraffic id={this.props.id} pathways={data} />
  }
}

export default StyledPathwayStatisticsTableWithTraffic
