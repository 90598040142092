import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import apiFetch from '../../../../_shared/api/apiFetch'

class DeleteReportModal extends React.PureComponent {
  static propTypes = {
    report: PropTypes.object.isRequired,
  }

  state = {
    isLoading: false,
  }

  callMethod = async () => {
    const { report } = this.props
    if (this.state.isLoading) {
      return
    }
    try {
      await apiFetch('DELETE', '/reports/delete', { reportId: report._id })
      this.setState({
        isLoading: false,
      })
    } catch (e) {
      this.setState({
        isLoading: false,
      })
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Report"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.report.name}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faTimesCircle} fixedWidth /> Delete
      </BasicModal>
    )
  }
}

export default DeleteReportModal
