import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'

const data = {
  datasets: [
    {
      label: 'Abandonments',
      lineTension: 0.25,
      backgroundColor: '#4d92d2',
      hoverBackgroundColor: '#1476bf',
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: 'origin',
    },
  ],
}

function abandonmentToChart(abandonment) {
  const steps = []
  const abandonments = []

  abandonment.forEach((a) => {
    steps.push(a.name)
    abandonments.push(a.abandons)
  })

  return {
    steps,
    datasets: [Object.assign(data.datasets[0], { data: abandonments })],
  }
}

const StepAbandonment = (props) => {
  const { className, abandonment } = props

  const chartData = abandonmentToChart(abandonment)

  return (
    <div className={className}>
      <div className="chart-container" style={{ position: 'relative', height: '350px' }}>
        <Bar
          data={{
            labels: chartData.steps,
            datasets: chartData.datasets,
          }}
          options={{
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                ticks: {
                  maxTicksLimit: 6,
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
            },
          }}
        />
      </div>
    </div>
  )
}
StepAbandonment.propTypes = {
  className: PropTypes.string,
  abandonment: PropTypes.array.isRequired,
}

export default StepAbandonment
