import React from 'react'
import PropTypes from 'prop-types'
import StepAbandonment from '../../../components/StepAbandonment'
import Loader from '../../../components/Loader'
import Panel from '../../../components/Panel'
import apiFetch from '../../../../../_shared/api/apiFetch'

export default class PathwayStepAbandonment extends React.PureComponent {
  state = {
    isLoading: true,
    abandonment: null,
  }

  static propTypes = {
    dateRange: PropTypes.array.isRequired,
    pathway: PropTypes.object.isRequired,
    filter: PropTypes.object,
  }

  loadStepAbandonment = async () => {
    const { pathway, dateRange, filter } = this.props
    const start = dateRange[0]
    const end = dateRange[1]

    try {
      const result = await apiFetch(
        'GET',
        '/statistics/stepAbandonment',
        {},
        {
          pathwayId: pathway._id,
          start,
          end,
          filter,
        },
        true
      )
      this.setState({
        isLoading: false,
        abandonment: result,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter || prevProps.dateRange !== this.props.dateRange) {
      this.loadStepAbandonment()
    }
  }

  componentDidMount() {
    this.loadStepAbandonment()
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }
    return (
      <Panel>
        <h4 className="text-center">Step Abandonment</h4>
        <p className="text-center text-small">Where are prospects abandoning your pathway?</p>
        <StepAbandonment abandonment={this.state.abandonment} />
      </Panel>
    )
  }
}
