import React from 'react';
import Input from '../../../../../_shared/components/Form/Input';
import { SpecialHelpToolTip } from './AiCopyModal';
import ProgramSelect from '../../ProgramSelect'
import IconColorSelect from './IconColorSelect';
import ToneSelector from './ToneSelector'
import { Company } from '../../../../../_shared/models/company/company.client';
import styled from 'styled-components';
import { amiableTone, analyticalTone, driverTone, expressiveTone } from '../../../../server/methods/generateAiCopy/utils/selectedToneDescription';
interface AILPFormProps {
  company: Company;
  onColorChange: (color: string) => void;
  colors: { value: string, label: string }[];
  iconColor: string;
  setSelectedTone: (tone: string) => void;
  selectedTone: string;
}

const AILPForm: React.FC<AILPFormProps> = ({ company, onColorChange, colors, iconColor, setSelectedTone, selectedTone }) => {
  return (
    <>
      <InputWithToolTip>
        <ProgramSelect
          name="program"
          defaultOptions={false}
          isMulti={false}
          companyId={company._id}
        />
        <SpecialHelpToolTip title={null} description="The programs listed here can be configured in Account - Program Group. It is used for the program name, available campuses, and career data" />
      </InputWithToolTip>

      <InputWithToolTip>
        <Input type="text" name="companyName" />
        <SpecialHelpToolTip title={null} description="The Company Name is used throughout the copy such as: Why study Cosmetology at Sample College" />
      </InputWithToolTip>

      <InputWithToolTip>
        <Input
          type="text"
          name="programPageUrl"
          placeholder="https://www.yourwebsite.edu/program"
        />
        <SpecialHelpToolTip title={null} description="This should be the most up-to-date version of your website program page. The program page is used to accumulate accurate information regarding your program." />
      </InputWithToolTip>

      <InputWithToolTip>
        <Input
          type="text"
          name="keywords"
          multipleLines={true}
          placeholder="prestigious, comprehensive, self-paced"
        />
        <SpecialHelpToolTip title={null} description="Keywords will be inserted into relevant sections of the copy. Keyword: glamorous -&gt; Discover the glamorous world of cosmetology with us!" />
      </InputWithToolTip>

      <InputWithToolTip>
        <ToneSelector
          name="selectedTone"
          label="Select Tone"
          selectedTone={selectedTone}
          setSelectedTone={setSelectedTone}
        />
        <SpecialHelpToolTip title={null} description={toneSelectorHelpToolTip} />
      </InputWithToolTip>

      <InputWithToolTip>
        <IconColorSelect
          name="iconColor"
          label="Color Palette for Icons"
          onColorChange={onColorChange}
          colors={colors.map((color) => ({ ...color }))}
        />
        <SpecialHelpToolTip title={null} description="These are the colors used on your landing pages" />
      </InputWithToolTip>
    </>
  );
}

export default AILPForm;



const toneSelectorHelpToolTip = (
  <div>
    <p>The tone of the copy will be adjusted based on the selected tone:</p>
    <ul>
      <li>
        <strong>Expressive: </strong>
        {expressiveTone}
      </li>
      <li>
        <strong>Analytical: </strong>
        {analyticalTone}
      </li>
      <li>
        <strong>Amiable: </strong> {amiableTone}
      </li>
      <li>
        <strong>Driver: </strong> {driverTone}
      </li>
    </ul>
  </div>
)

const InputWithToolTip = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 1.25rem;

  > :first-child {
    width: 100%;
    flex-grow: 1;
  }

  > :nth-child(2) {
    position: absolute;
    right: 0;
    top: 0;
  }
  .form-group {
    margin-bottom: 0;
  }
`