import React from 'react'
import styled from 'styled-components'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import PersonaPanel from '../PersonaPanel'
import PersonaStackedBarChart from './PersonaStackedBarChart'

interface Props {
  className?: string
  persona: Persona
}

const PersonaSocialStyles: React.FC<Props> = ({ className, persona }) => {
  return (
    <div className={className}>
      <PersonaPanel
        title="Social Style"
        helpTitle="Social Styles"
        helpDescription={
          <>
            <p>
              Social styles are a way of categorizing people based on their personality traits.
              Expressives are outgoing and empathetic, creatively leading discussions with their
              strong convictions while also being considerate of others' wellbeing. Analytical
              individuals, on the other hand, are detail-oriented and data-driven, preferring to
              thoroughly understand all facets before making decisions. Amiable individuals put a
              high value on personal relationships and prefer a collaborative approach to
              decision-making, while drivers are goal-oriented and decisive, often taking control in
              conversations and focusing on results.
            </p>
            <a
              target="_blank"
              href="https://kb.enrollmentresources.com/books/admissions-resources/page/social-styles-techniques-for-selling-to-different-personalities"
            >
              Learn more about social styles
            </a>
          </>
        }
      >
        <PersonaStackedBarChart
          analytical={Math.floor((persona.metrics.analytical.value / persona.sampleSize) * 100)}
          amiable={Math.floor((persona.metrics.amiable.value / persona.sampleSize) * 100)}
          driver={Math.floor((persona.metrics.driver.value / persona.sampleSize) * 100)}
          expressive={Math.floor((persona.metrics.expressive.value / persona.sampleSize) * 100)}
        />
      </PersonaPanel>
    </div>
  )
}

export default styled(PersonaSocialStyles)`
  .persona-meta-link {
    font-size: 0.8rem;
  }
`
