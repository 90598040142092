import React from 'react'
import PropTypes from 'prop-types'
import { PanelForm } from '../../../components/Panel'
import { Button } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus'
import ProgramSchema from '../../../../../_shared/models/company/ProgramSchema'
import Checkbox from '../../../../../_shared/components/Form/Checkbox'
import withForm from '../../../../../_shared/components/withForm'
import Dropdown from '../../../../../_shared/components/Form/Dropdown'
import Input from '../../../../../_shared/components/Form/Input'
import Form from '../../../../../_shared/components/Form'
import Cardbox, { CardboxItem } from '../../../../../_shared/components/Form/Cardbox'
import FormError from '../../../../../_shared/components/Form/FormError'
import RichText from '../../../../../_shared/components/Form/RichText'
import CareerFieldMap from '../../../../../_shared/models/programGroup/careerFields.ts'

const { name, campuses, careers, careerField } = ProgramSchema.properties

const ProgramFormSchema = {
  type: 'object',
  properties: {
    name,
    campuses,
    careers,
    availability: {
      type: 'string',
      enum: ['all', 'specific'],
    },
    careerField,
  },
  required: ['name', 'careerField'],
  if: {
    properties: {
      availability: { enum: ['specific'] },
    },
  },
  then: { required: ['campuses'] },
}

const CampusSelector = (props) => {
  const { formData, programGroups } = props
  if (formData.availability !== 'specific') {
    return null
  }

  const options =
    programGroups?.campuses?.map((campus) => ({
      label: campus.name,
      value: campus._id,
    })) || []

  return <Checkbox name="campuses" options={options} />
}
const CampusSelectorWithForm = withForm(CampusSelector, true)

class WageDataForm extends React.Component {
  static propTypes = {
    careerIndex: PropTypes.number,
    className: PropTypes.string,
    wageData: PropTypes.array,
    campuses: PropTypes.array,
  }
  constructor(props) {
    super(props)
    this.state = {
      wageData: props.wageData || [{}],
    }
  }
  remove = (index) => {
    const wageData = Array.from(this.state.wageData)
    wageData.splice(index, 1)
    this.setState({
      wageData,
    })
  }

  add = () => {
    this.setState({
      wageData: [...this.state.wageData, {}],
    })
  }

  render() {
    const { careerIndex, className, campuses } = this.props
    const { wageData } = this.state
    return (
      <div className={className}>
        <ul className="wage-data-list">
          {wageData.map((wage, index) => (
            <li className="wage-data-item" key={index}>
              <div className="remove">
                <Button
                  color="primary"
                  type="button"
                  className="remove-btn"
                  onClick={() => {
                    this.remove(index)
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              </div>
              <div className="wage-item">
                <Dropdown
                  name={`careers[${careerIndex}].wageData[${index}].campus`}
                  placeholder="(Select One)"
                >
                  <option value="all">All Campuses</option>
                  {campuses.map((campus) => (
                    <option key={campus._id} value={campus._id}>
                      {campus.name}
                    </option>
                  ))}
                </Dropdown>
                <Input type="number" name={`careers[${careerIndex}].wageData[${index}].low`} />
                <Input type="number" name={`careers[${careerIndex}].wageData[${index}].high`} />
                <Input
                  type="text"
                  label="Disclosure Link"
                  name={`careers[${careerIndex}].wageData[${index}].disclosureUrl`}
                />
              </div>
            </li>
          ))}
        </ul>
        <Button
          color="primary"
          type="button"
          onClick={() => {
            this.add()
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Wage Data
        </Button>
      </div>
    )
  }
}
const StyledWageDataForm = styled(WageDataForm)`
  .wage-data-list {
    list-style: none;
    padding-left: 0;

    .wage-data-item {
      display: table;
      width: 100%;
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 15px;
      }

      .remove {
        display: table-cell;

        .remove-btn {
          margin-right: 15px;
        }
      }

      .wage-item {
        display: table-cell;
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 4px;
        padding: 10px 15px;
      }
    }
  }
`

class CareerForm extends React.Component {
  static propTypes = {
    careers: PropTypes.array,
    className: PropTypes.string,
    campuses: PropTypes.array,
  }
  constructor(props) {
    super(props)
    this.state = {
      careers: props.careers || [{}],
    }
  }

  remove = (index) => {
    const careers = Array.from(this.state.careers)
    careers.splice(index, 1)
    this.setState({
      careers,
    })
  }

  add = () => {
    this.setState({
      careers: [...this.state.careers, {}],
    })
  }

  render() {
    const { className, campuses } = this.props
    const { careers } = this.state
    return (
      <div className={className}>
        <h3>Careers</h3>
        <ul className="career-list">
          {careers.map((career, index) => (
            <li className="career" key={index}>
              <div className="remove">
                {' '}
                <Button
                  color="primary"
                  type="button"
                  className="remove-btn"
                  onClick={() => {
                    this.remove(index)
                  }}
                >
                  <FontAwesomeIcon icon={faMinus} />
                </Button>
              </div>
              <div className="career-item">
                <Input type="text" name={`careers[${index}].name`} />
                <Input
                  type="text"
                  name={`careers[${index}].disclosureUrl`}
                  label="Disclosure Link"
                />
                <StyledWageDataForm
                  wageData={career.wageData}
                  careerIndex={index}
                  campuses={campuses}
                />
              </div>
            </li>
          ))}
        </ul>
        <Button
          color="primary"
          type="button"
          onClick={() => {
            this.add()
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> Add Career
        </Button>
      </div>
    )
  }
}
const StyledCareerForm = styled(CareerForm)`
  .career-list {
    list-style: none;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    padding: 10px 15px;

    .career {
      display: table;
      width: 100%;
      margin-bottom: 15px;

      .remove {
        display: table-cell;

        .remove-btn {
          margin-right: 15px;
        }
      }

      .career-item {
        display: table-cell;
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 4px;
        padding: 10px 15px;
      }
    }
  }
`

const careerFieldOptions = (company) => {
  const options = CareerFieldMap
  if (company.careerFields) {
    company.careerFields.forEach((careerField) => {
      options[careerField._id] = careerField.name
    })
  }
  return options
}

const ProgramForm = (props) => {
  return (
    <Form formData={props.program} schema={ProgramFormSchema} onValidSubmit={props.onSubmit}>
      <PanelForm center={false}>
        <Input type="text" name="name" />
      </PanelForm>

      <RichText name="description" />
      <Input name="programDisclosureUrl" label="Program Disclosure Link" />

      <Cardbox thin name="availability" defaultValue="all" selectionRequired={true}>
        <CardboxItem
          title="All Campuses"
          value="all"
          description="This program is available at all campuses"
        />
        <CardboxItem
          title="Specific Campuses"
          value="specific"
          description="This program is only available at specific campuses"
        />
      </Cardbox>

      <Dropdown name="careerField" label="Career Field" placeholder="(Select One)">
        {Object.entries(careerFieldOptions(props.company)).map(([key, value]) => {
          console.log(CareerFieldMap)
          return (
            <option key={key} value={key}>
              {value}
            </option>
          )
        })}
      </Dropdown>

      <CampusSelectorWithForm programGroups={props.programGroup} />

      <hr />

      <StyledCareerForm
        careers={props.program?.careers}
        campuses={props.programGroup.campuses || []}
      />

      <PanelForm center={false}>
        <hr />
        {props.error && <FormError>{props.error}</FormError>}

        <Button color="primary" type="submit" disabled={props.isLoading}>
          {props.buttonLabel}
        </Button>
      </PanelForm>
    </Form>
  )
}
ProgramForm.propTypes = {
  program: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  programGroup: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string.isRequired,
  error: PropTypes.any,
}

export default ProgramForm
