import React from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup } from 'reactstrap'
import { Meteor } from 'meteor/meteor'
import Link, { Redirect } from '../../components/Link'
import styled from 'styled-components'
import NotFound from '../NotFound'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faDesktop } from '@fortawesome/pro-solid-svg-icons/faDesktop'
import { faMobile } from '@fortawesome/pro-regular-svg-icons/faMobile'
import { faTablet } from '@fortawesome/pro-regular-svg-icons/faTablet'
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil'
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly'
import PageBuilderWrapper from '../../../../pagebuilder/PageBuilderWrapper'
import Sidebar from '../../../../pagebuilder/components/Sidebar'
// import { Tabs } from '../../components/TabNav'
import StepSchema from '../../../../_shared/models/step/StepSchema'
import PathwayContext from '../../../../pagebuilder/PathwayContext'
import View from '../../components/View'
import StepSelector from '../../components/StepSelector'
import { withAlert } from 'react-alert'
import LinkedPathwaysModal from '../../components/modals/LinkedPathwaysModal'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { connect } from 'react-redux'
import { RESTDataLoader } from '../../containers/RESTDataLoader'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'
import ClientProgramGroup from '../../../../_shared/models/programGroup/programGroup.client'
import { ReactFrontendContext } from '../../../../frontend/client/containers/StepViewContainer'
import apiFetch from '../../../../_shared/api/apiFetch'

const { name, pageTitle } = StepSchema.properties

const StepFormSchema = {
  title: 'Step Form',
  type: 'object',
  properties: { name, pageTitle },
  required: ['name'],
}

class StepUpdateSidebar extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    settings: PropTypes.object,
    updateBlock: PropTypes.func,
    closeBlockSettings: PropTypes.func,
    step: PropTypes.object.isRequired,
    setFormData: PropTypes.func.isRequired,
  }

  state = {
    activeTab: 'add',
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    const { setFormData, step } = this.props
    const { name, pageTitle } = step
    // const { activeTab } = this.state

    return (
      <Sidebar title={'Settings'}>
        {/* <Tabs
          items={[
            {
              label: 'Step Settings',
              onClick: () => this.toggle('settings'),
              isActive: activeTab === 'settings',
            },
          ]}
        />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="settings">settings</TabPane>
        </TabContent> */}
        <Form
          formData={{ name, pageTitle }}
          formSchema={StepFormSchema}
          validateOnChange
          onValidChange={setFormData}
          warnOnUnsavedLeave={false}
        >
          <Input name="name" />
          <Input
            name="pageTitle"
            label="Page Title"
            help="The title text to display in the browser tab"
          />
        </Form>
      </Sidebar>
    )
  }
}

class StepUpdate extends React.Component {
  state = {
    error: false,
    isLoading: true,
    isSaving: false,
    redirect: null,
    previewMode: 'desktop',
    editMode: 'edit',
    formData: null,
    linkModalOpen: false,
    linkedBlocks: null,
    linkedPathways: null,
    pendingContent: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
    className: PropTypes.string,
  }

  setFormData = ({ formData }) => {
    this.setState({
      formData,
    })
  }

  _doSave = async (content, linkedPathways) => {
    const { formData, pendingContent } = this.state
    const { pathway, step } = this.props

    content = content || pendingContent

    try {
      await apiFetch('POST', '/steps/update', {
        pathwayId: pathway._id,
        stepId: step._id,
        doc: { ...step, ...formData, content },
        linkedPathways,
      })
      this.props.alert.show('Step saved')
    } catch (e) {
      this.setState({ isSaving: false, error: e })
      throw new Error(e)
    }

    this.setState({
      pendingContent: null,
      linkModalOpen: false,
      linkedBlocks: null,
      linkedPathways: null,
      isSaving: false,
    })
  }

  save = async (content) => {
    const { pathway } = this.props

    // If the content has a linked key, we'll ask the server what other pathways may be affected
    // and give the user a choice to update all linked pathways or just this pathway
    // this helps prevent unintentionally modifying other pathways
    if (content.indexOf('linkedKey') !== -1) {
      const linkedKeys = []
      const regex = /"linkedKey":"([^"]*)"/g
      // use regex to parse the linkedKeys out of the JSON
      let match = regex.exec(content)
      while (match !== null) {
        const key = match[1]
        if (linkedKeys.indexOf(key) === -1) {
          linkedKeys.push(key)
        }
        match = regex.exec(content)
      }

      // 1. make a method call to get affected pathways
      try {
        const result = await apiFetch(
          'GET',
          '/pathways/linked',
          {},
          { pathwayId: pathway._id, linkedBlocks: linkedKeys }
        )
        if (result?.length > 0) {
          this.setState({
            linkModalOpen: true,
            linkedBlocks: linkedKeys,
            linkedPathways: result,
            pendingContent: content,
          })
        } else {
          this._doSave(content)
        }
      } catch (e) {
        this.setState({
          isSaving: false,
        })
        throw new Error(e)
      }
    } else {
      this._doSave(content)
    }
  }

  onLoad = () => {
    this.setState({
      isLoading: false,
    })
  }

  changeEditMode(editMode) {
    this.setState({
      editMode,
    })
  }

  changePreviewMode(previewMode) {
    this.setState({
      previewMode,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.step?._id !== this.props.step?._id) {
      console.log('[StepUpdate] Step ID changed, showing loader...')
      this.setState({
        isLoading: true,
      })
    }
  }

  requestSave = () => {
    this.setState(
      {
        isSaving: true,
      },
      () => {
        document.querySelector('.editor-iframe').contentWindow.postMessage('pb_requestContent', '*')
      }
    )
  }

  toggleLinkModal = () => {
    this.setState({
      linkModalOpen: !this.state.linkModalOpen,
      isSaving: !this.state.linkModalOpen,
    })
  }

  render() {
    const { pathway, step, className, programGroup } = this.props
    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }

    return (
      <View title={`Edit ${step.name}`}>
        <div className={className}>
          <header className="header">
            <div className="row align-items-center">
              <div className="col-auto">
                <Button color="alt" tag={Link} to={`/pathways/${pathway._id}`}>
                  <FontAwesomeIcon icon={faChevronLeft} />
                  &nbsp;&nbsp;Exit
                </Button>
              </div>
              <div className="col-auto">
                <StepSelector step={step} pathway={pathway} />
              </div>
              <div className="col" />
              <div className="col-auto">
                <ButtonGroup>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changeEditMode('edit')
                    }}
                    active={this.state.editMode === 'edit'}
                  >
                    <FontAwesomeIcon icon={faPencil} fixedWidth />
                  </Button>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changeEditMode('preview')
                    }}
                    active={this.state.editMode === 'preview'}
                  >
                    <FontAwesomeIcon icon={faEye} fixedWidth />
                  </Button>
                </ButtonGroup>
              </div>
              <div className="col-auto">
                <ButtonGroup>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changePreviewMode('desktop')
                    }}
                    active={this.state.previewMode === 'desktop'}
                  >
                    <FontAwesomeIcon icon={faDesktop} fixedWidth />
                  </Button>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changePreviewMode('tablet')
                    }}
                    active={this.state.previewMode === 'tablet'}
                  >
                    <FontAwesomeIcon icon={faTablet} fixedWidth />
                  </Button>
                  <Button
                    color="alt"
                    disabled={this.state.isLoading}
                    onClick={() => {
                      this.changePreviewMode('mobile')
                    }}
                    active={this.state.previewMode === 'mobile'}
                  >
                    <FontAwesomeIcon icon={faMobile} fixedWidth />
                  </Button>
                </ButtonGroup>
              </div>
              {/* <div className="col-auto">
                <ButtonGroup>
                  <Button color="alt" id="undo" disabled={this.state.isLoading}>
                    <FontAwesomeIcon icon={faUndo} fixedWidth />
                  </Button>
                  <Button color="alt" id="redo" disabled={this.state.isLoading}>
                    <FontAwesomeIcon icon={faRedo} fixedWidth />
                  </Button>
                </ButtonGroup>
              </div> */}
              <div className="col-auto">
                <Button
                  color="primary"
                  onClick={this.requestSave}
                  disabled={this.state.isLoading || this.state.isSaving}
                >
                  Save
                </Button>
              </div>
            </div>
          </header>
          <ReactFrontendContext.Provider
            value={{
              pathway,
              programGroup,
            }}
          >
            <PageBuilderWrapper
              save={this.save}
              pathway={pathway}
              url={`/pathways/${pathway._id}/stepEditor/${step._id}?a=${pathway.companyId}`}
              onLoad={this.onLoad}
              isLoading={this.state.isLoading}
              previewMode={this.state.previewMode}
              editMode={this.state.editMode}
              Sidebar={<StepUpdateSidebar step={step} setFormData={this.setFormData} />}
            />
          </ReactFrontendContext.Provider>
        </div>
        <LinkedPathwaysModal
          isOpen={this.state.linkModalOpen}
          toggle={this.toggleLinkModal}
          linkedBlocks={this.state.linkedBlocks}
          linkedPathways={this.state.linkedPathways}
          save={this._doSave}
        />
      </View>
    )
  }
}

const StyledStepUpdate = withAlert()(styled(StepUpdate)`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #fff;

  .header {
    padding: 0.5rem;
    border-bottom: 1px solid var(--border-color);

    &,
    .btn {
      font-size: 0.875rem;
    }
  }
  h2 {
    margin: 0;
  }
  .btn-group {
    button {
      &.active {
        background: var(--primary);
        color: #fff !important;
      }
    }
  }
`)

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <RESTDataLoader
      resource="pathways"
      action={props.match.params.pathwayId}
      params={{}}
      showLoader={true}
      transform={(p) => new Pathway(p)}
    >
      {(pathway) => {
        if (!pathway) {
          return <NotFound />
        }

        if (pathway.programGroupId) {
          return (
            <RESTDataLoader
              resource="programGroups"
              action={pathway.programGroupId}
              params={{}}
              showLoader={true}
              transform={(p) => new ClientProgramGroup(p)}
            >
              {(programGroup) => {
                if (!programGroup) {
                  return <NotFound />
                }

                const step = pathway.getStep(props.match.params.stepId)
                if (!step) {
                  return <NotFound />
                }

                return (
                  <div key={step._id}>
                    <StyledStepUpdate pathway={pathway} step={step} programGroup={programGroup} />
                  </div>
                )
              }}
            </RESTDataLoader>
          )
        } else {
          const step = pathway.getStep(props.match.params.stepId)
          if (!step) {
            return <NotFound />
          }

          return (
            <div key={step._id}>
              <StyledStepUpdate pathway={pathway} step={step} />
            </div>
          )
        }
      }}
    </RESTDataLoader>
  )
}

const mapStateToProps = (state) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0],
})

export default connect(mapStateToProps)(DataLoader)
