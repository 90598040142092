import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import Panel from '../../components/Panel'
import { redirect } from '../../components/Link'
import View from '../../components/View'
import TrackingCodeForm from './TrackingCodeForm'
import { withAlert } from 'react-alert'
import apiFetch from '../../../../_shared/api/apiFetch'

class TrackingCodeCreate extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    mock: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }
    const { pathway } = this.props

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/trackingCodes/create', {
          pathwayId: pathway._id,
          doc: data.formData,
        })
        const { location, history } = this.props
        this.props.alert.show('Tracking Code saved')

        redirect({
          location,
          history,
          pathname: `/pathways/${this.props.pathway._id}/settings/codes`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { pathway } = this.props
    const title = `New Tracking Code`
    return (
      <View layout="sidebar" title={title}>
        <PageHeader
          breadcrumbs={[
            {
              label: pathway.name,
              path: `/pathways/${pathway._id}/settings/codes`,
            },
          ]}
          title={title}
        />
        <div className="content">
          <Panel>
            <TrackingCodeForm
              buttonLabel="Create Tracking Code"
              onSubmit={this.onSubmit}
              isLoading={this.state.isLoading}
              error={this.state.error}
              pathway={pathway}
            />
          </Panel>
        </div>
      </View>
    )
  }
}
export default withAlert()(TrackingCodeCreate)
