import React from 'react'
import PropTypes from 'prop-types'
import toPath from 'lodash/toPath'
import get from 'lodash/get'
import SettingsSection from '../SettingsSection'
import ConditionalOptions from '../Conditional/ConditionalOptions'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import withForm from '../../../_shared/components/withForm'

export const VisibilityOptionsSchema = {
  width: {
    type: 'object',
    additionalProperties: false,
    properties: {
      type: {
        type: 'string',
      },
      custom: {
        type: 'object',
        additionalProperties: false,
        properties: {
          value: {
            title: 'Custom width',
            type: 'string',
          },
          unit: {
            type: 'string',
            enum: ['px', '%', 'rem', 'em', 'vw', 'vh'],
          },
        },
        required: ['value', 'unit'],
      },
    },
  },
}

const VisibilityOptions = ({ formData, path }) => (
  <SettingsSection title="Visibility">
    <Dropdown name={`${path}.show`} defaultValue="show" showLabel={false} block>
      <option value="show">Always show</option>
      <option value="hide">Always hide</option>
      <option value="conditional-show">Conditionally show</option>
      <option value="conditional-hide">Conditionally hide</option>
    </Dropdown>

    {String(get(formData, toPath(`${path}.show`))).startsWith('conditional') && (
      <ConditionalOptions name={`${path}.conditional`} />
    )}
  </SettingsSection>
)

VisibilityOptions.propTypes = {
  formData: PropTypes.object,
  options: PropTypes.array,
  path: PropTypes.string,
}

export default withForm(VisibilityOptions, true)
