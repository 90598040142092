import React from 'react'
import { withRouter, Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import View from '../components/View'
import SimpleLayout from '../components/layouts/SimpleLayout'
import FormError from '../../../_shared/components/Form/FormError'
import apiFetch from '../../../_shared/api/apiFetch'

class Unsubscribe extends React.Component {
  state = {
    error: null,
    isLoading: false,
  }

  static propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
    user: PropTypes.object,
  }

  unsubscribe = () => {
    const { match } = this.props
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/users/unsubscribe', {
            userId: match.params.userId,
            email: match.params.email,
          })
          this.setState({
            isLoading: false,
          })
        } catch (e) {
          this.setState({
            error: e,
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  componentDidMount() {
    this.unsubscribe()
  }

  render() {
    const { error, isLoading } = this.state

    const msg = isLoading ? 'Please wait...' : 'Unsubscribed from future monthly reports'

    return (
      <View layout="simple" title="Unsubscribe">
        <SimpleLayout>
          {error && <FormError>{error}</FormError>}

          <div>{!error && msg}</div>
        </SimpleLayout>
      </View>
    )
  }
}

export default withRouter(Unsubscribe)
