import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import GoalForm from './GoalForm'
import { redirect } from '../../components/Link'
import FormError from '../../../../_shared/components/Form/FormError'
import apiFetch from '../../../../_shared/api/apiFetch'

class GoalCreate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { _id: pathwayId } = this.props.pathway
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/goals/create', { pathwayId, goal: data.formData })
        const { location, history } = this.props

        redirect({
          location,
          history,
          pathname: `/pathways/${pathwayId}/goals`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[{ label: pathway.name, path: `/pathways/${pathway._id}/goals` }]}
          title="New Goal"
        />
        <div className="content">
          <Panel>
            <GoalForm
              pathway={pathway}
              onSubmit={this.onSubmit}
              buttonLabel="Create Goal"
              error={this.state.error}
              isLoading={this.state.isLoading}
            />
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

GoalCreate.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default GoalCreate
