import React from 'react'
import styled from 'styled-components'

function escapeHtml(unsafe) {
  return {
    __html: unsafe
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;'),
  }
}

class PathwaySettingsEmbed extends React.Component {
  render() {
    let embedCode = `<!-- Begin "${this.props.pathway.name}" Form Embed -->
<div class="va-embed" data-src="https://${this.props.pathway.getPrimaryURL()}"></div>
<script src="https://assets.virtualadviser.com/embed.js" async></script>
<!-- /End "${this.props.pathway.name}" Form Embed -->`

    if (this.props.pathway.type === 'chatbot') {
      embedCode = `<!-- Begin "${this.props.pathway.name}" Chatbot Embed -->
<script>
  window.VA_CHAT = {
    src: 'https://${this.props.pathway.getPrimaryURL()}',
    // Customize the button message below:
    message: 'Get the details!',
    // Customize the widget frame heading:
    heading: 'Virtual Assistant',
    // Customize the color of the button and widget header
    // buttonColor: 'red',
  }
</script>
<script src="https://assets.virtualadviser.com/embed.js" async></script>
<!-- /End "${this.props.pathway.name}" Chatbot Embed -->
`
    }

    return (
      <div className={this.props.className}>
        <h4>Embed</h4>
        <br />
        <p>Embed this pathway by copying the following code to your website:</p>
        <div className="embed">
          <pre>
            <code dangerouslySetInnerHTML={escapeHtml(embedCode)}></code>
          </pre>
        </div>
      </div>
    )
  }
}

export default styled(PathwaySettingsEmbed)`
  .embed {
    overflow: auto;
  }
`
