import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../../components/Panel'
import { Button } from 'reactstrap'
import PageHeader from '../../../components/PageHeader'
import { redirect } from '../../../components/Link'
import { withAlert } from 'react-alert'
import NotFound from '../../NotFound'
import CampusSchema from '../../../../../_shared/models/company/CampusSchema'
import FormError from '../../../../../_shared/components/Form/FormError'
import Form from '../../../../../_shared/components/Form'
import Input from '../../../../../_shared/components/Form/Input'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'

const { name } = CampusSchema.properties

const CampusFormSchema = {
  type: 'object',
  properties: {
    name,
  },
  required: ['name'],
}

class CampusUpdate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    programGroup: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    campus: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { programGroup, campus } = this.props
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/campuses/update', {
          programGroupId: programGroup._id,
          campusId: campus._id,
          doc: data.formData,
        })
        const { location, history, alert } = this.props
        alert.show('Campus saved')
        redirect({
          location,
          history,
          pathname: `/organization/programGroups/${programGroup._id}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { company, campus, programGroup } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[
            { label: company.name, path: '/organization' },
            { label: 'Program Groups', path: `/organization/programGroups` },
            {
              label: programGroup.name,
              path: `/organization/programGroups/${programGroup._id}`,
            },
          ]}
          title={`Edit ${campus.name}`}
        />

        <div className="content">
          <Panel>
            <Form formData={campus} schema={CampusFormSchema} onValidSubmit={this.onSubmit}>
              <PanelForm center={false}>
                <Input type="text" name="name" />

                <hr />
                {this.renderError()}

                <Button color="primary" type="submit" disabled={this.state.isLoading}>
                  Save Changes
                </Button>
              </PanelForm>
            </Form>
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}
const CampusUpdateWithAlert = withAlert()(CampusUpdate)

const CampusUpdateLoader = (props) => {
  const { programGroup, match } = props
  const campus = programGroup.campuses?.find((c) => c._id === match.params.campusId)
  if (!campus) {
    return <NotFound />
  }

  return <CampusUpdateWithAlert {...props} campus={campus} />
}

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="programGroup"
      cache={shortCache}
      query={{
        _id: props.match.params.programGroupId,
      }}
      showLoader={true}
    >
      {({ data }) => {
        const { program_groups } = data
        if (!program_groups || program_groups.length === 0) {
          return <NotFound />
        }
        return <CampusUpdateLoader {...props} programGroup={program_groups[0]} />
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
