import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faTags } from '@fortawesome/pro-solid-svg-icons/faTags'
import apiFetch from '../../../_shared/api/apiFetch'

class TagEditor extends React.Component {
  setTagColor = async (tag, color) => {
    try {
      await apiFetch('POST', '/tags/color', { tag, color })
    } catch (e) {
      throw new Error(e)
    }
  }

  renameTag = async (tagId, newName) => {
    try {
      await apiFetch('POST', '/tags/update', { tagId, name: newName })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <div
        className="tag-editor"
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        <div className="tag-editor-title">Edit Tag</div>
        <input
          autoFocus
          type="text"
          name="tag-editor-value"
          defaultValue={this.props.tag.name}
          onKeyPress={(event) => {
            if (event.key === 'Enter') {
              this.renameTag(this.props.tag._id, event.currentTarget.value)
              this.props.toggle()
            }
          }}
        />
        <ul className="tag-editor-colors">
          <li
            onClick={() => {
              this.setTagColor(this.props.tag.name, 'blue')
            }}
            style={{ backgroundColor: 'var(--blue)' }}
          />
          <li
            onClick={() => {
              this.setTagColor(this.props.tag.name, 'cyan')
            }}
            style={{ backgroundColor: 'var(--cyan)' }}
          />
          <li
            onClick={() => {
              this.setTagColor(this.props.tag.name, 'green')
            }}
            style={{ backgroundColor: 'var(--green)' }}
          />
          <li
            onClick={() => {
              this.setTagColor(this.props.tag.name, 'yellow')
            }}
            style={{ backgroundColor: 'var(--yellow)' }}
          />
          <li
            onClick={() => {
              this.setTagColor(this.props.tag.name, 'orange')
            }}
            style={{ backgroundColor: 'var(--orange)' }}
          />
          <li
            onClick={() => {
              this.setTagColor(this.props.tag.name, 'red')
            }}
            style={{ backgroundColor: 'var(--red)' }}
          />
        </ul>
      </div>
    )
  }
}

class Tag extends React.Component {
  handleBodyClick = () => {
    if (this.state.editorOpen) {
      this.setState({
        editorOpen: false,
      })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleBodyClick)
  }

  componentDidUpdate(prevProps) {
    if (this.state.editorOpen) {
      document.addEventListener('click', this.handleBodyClick)
    } else {
      document.removeEventListener('click', this.handleBodyClick)
    }
  }

  state = {
    editorOpen: false,
  }
  toggleEditor = () => {
    this.setState({
      editorOpen: !this.state.editorOpen,
    })
  }
  render() {
    const { tag, remove } = this.props
    return (
      <li className={`company-tag tag-color-${tag.color}`} onClick={this.toggleEditor}>
        {tag.name}{' '}
        <button
          type="button"
          className="remove"
          onClick={(event) => {
            event.stopPropagation()
            remove(tag._id)
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {this.state.editorOpen && <TagEditor tag={tag} toggle={this.toggleEditor} />}
      </li>
    )
  }
}

class CompanyTagDropdown extends React.Component {
  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  remove = async (tagId) => {
    const { company } = this.props
    try {
      await apiFetch('DELETE', '/tags/delete', { companyId: company._id, tagId })
    } catch (e) {
      throw new Error(e)
    }
  }

  addTag = async (name) => {
    const { company } = this.props
    try {
      await apiFetch('POST', '/tags/create', { companyId: company._id, tag: name })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { className, company, tags } = this.props
    const { isOpen } = this.state

    return (
      <div className={className}>
        <ul className="tags">
          {company.tags &&
            company.tags.map((tagId, index) => {
              const tag = this.props.tags?.find((t) => t._id === tagId)
              if (!tag) {
                return
              }
              return <Tag key={index} tag={tag} remove={this.remove} />
            })}
        </ul>

        <Dropdown isOpen={isOpen} toggle={this.toggle}>
          <DropdownToggle tag="button" caret>
            <FontAwesomeIcon icon={faTags} />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem tag="div" className="searchbox" toggle={false}>
              <input
                autoFocus
                type="text"
                name="tag"
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.addTag(event.currentTarget.value)
                    event.currentTarget.value = ''
                    this.toggle()
                  }
                }}
              />
            </DropdownItem>
            <div className="taglist">
              {tags &&
                tags
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1
                    }
                    if (a.name > b.name) {
                      return 1
                    }
                    return 0
                  })
                  .map((tag) => (
                    <DropdownItem
                      key={tag._id}
                      onClick={() => {
                        this.addTag(tag.name)
                        this.toggle()
                      }}
                    >
                      <div className={`tag-color-preview tag-color-${tag.color}`} /> {tag.name}
                    </DropdownItem>
                  ))}
            </div>
          </DropdownMenu>
        </Dropdown>
      </div>
    )
  }
}

export default styled(CompanyTagDropdown)`
  display: inline-block;
  .tags {
    margin: 0 0 0 0.5rem;
    vertical-align: middle;
    padding: 0;
    list-style: none;
    display: inline-block;
    font-size: 11px;
    color: #fff;
  }
  .company-tag {
    cursor: pointer;
  }
  .dropdown {
    display: inline-block;
  }
  .dropdown-toggle {
    background: none;
    border: 0;
    padding: 0;
    outline: none;
    color: var(--text-muted);
    font-size: 11px;
    margin: 0 0 0 0.5rem;
    cursor: pointer;
  }
  .dropdown-menu {
    padding: 0;
    font-size: 0.875rem;
  }
  .dropdown-item {
    padding: 0.4rem 0.6rem;
  }
  .searchbox {
    padding: 0;
    input {
      border: 0;
      outline: none;
      border-radius: 3px;
      padding: 0.4rem 0.6rem;
    }
    border-bottom: 1px solid var(--border-color);
  }

  .taglist {
    max-height: 200px;
    overflow: auto;
  }

  .tag-editor {
    cursor: auto;
    z-index: 200;
    color: #444;
    font-size: 0.875rem;
    position: absolute;
    background: #fff;
    border: 1px solid var(--border-color);
    left: 0;
    transform: translateX(-20%);
    border-radius: 3px;
    width: 15rem;

    .tag-editor-title {
      padding: 0.1rem;
      text-align: center;
    }
    input {
      display: block;
      width: 100%;
      padding: 0.4rem 0.6rem;
      border: 0;
      border-bottom: 1px solid var(--border-color);
      border-radius: 0;
      outline: none;
      margin: 0 0 0.5rem 0;
    }

    .tag-editor-colors {
      list-style: none;
      margin: 0;
      padding: 0 0 0.5rem 0;
      text-align: center;

      li {
        cursor: pointer;
        border-radius: 100%;
        width: 1rem;
        height: 1rem;
        margin-right: 1rem;
        display: inline-block;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
`
