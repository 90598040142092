import React from 'react'
import PropTypes from 'prop-types'
import { PanelForm } from '../../../components/Panel'
import { Button } from 'reactstrap'
import Form from '../../../../../_shared/components/Form'
import Input from '../../../../../_shared/components/Form/Input'
import FormError from '../../../../../_shared/components/Form/FormError'
import ProgramGroupSchema from '../../../../../_shared/models/programGroup/ProgramGroupSchema.js'

const { name } = ProgramGroupSchema.properties

const ProgramGroupFormSchema = {
  type: 'object',
  properties: {
    name,
  },
  required: ['name'],
}

const ProgramGroupForm = (props) => (
  <Form formData={props.program} schema={ProgramGroupFormSchema} onValidSubmit={props.onSubmit}>
    <PanelForm center={false}>
      <Input type="text" name="name" />
      {props.error && <FormError>{props.error}</FormError>}

      <Button color="primary" type="submit" disabled={props.isLoading}>
        {props.buttonLabel}
      </Button>
    </PanelForm>
  </Form>
)

ProgramGroupForm.propTypes = {
  program: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
  company: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string.isRequired,
  error: PropTypes.any,
}

export default ProgramGroupForm
