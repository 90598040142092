import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../../components/Loader'
import ResponseShortMeta from '../response/ResponseShortMeta'
import Link from '../../components/Link'
import format from 'date-fns/format'
import { PanelList, PanelListItem } from '../../components/PanelList'
import classnames from 'classnames'
import ScoreGauge from '../../components/ScoreGauge'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'

const Result = (props) => {
  const { className, response, isOnline, profile, pathway } = props

  if (!pathway || !response) {
    return null
  }

  return (
    <tr className={className}>
      <td className="pathway">
        {pathway ? <Link to={`/response/${response._id}`}>{pathway.name}</Link> : 'deleted'}{' '}
        {response.isOnline && <span className="badge badge-success">In Progress</span>}
      </td>
      <td>{format(response.createdAt, "MMM do yyyy 'at' hh:mma")}</td>
      <td>
        <div className="progress">
          <div
            className={classnames('progress-bar', {
              'bg-success progress-bar-striped progress-bar-animated': isOnline,
            })}
            role="progressbar"
            aria-valuenow={response.progress}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: `${response.progress}%` }}
          >
            {response.progress}%
          </div>
        </div>
      </td>
      <td>{response.score || 0}%</td>
    </tr>
  )
}

const StyledResult = styled(Result)`
  background: #fff;
  font-size: 0.875rem;
  td {
    vertical-align: middle;
  }

  .pathway {
    font-weight: 600;
    a {
      color: ${(props) => (props.response.isOnline ? 'var(--success)' : 'var(--primary)')};
    }
    .badge {
      margin-left: 0.3rem;
    }
  }

  .col-progress {
    min-width: 10rem;
  }

  .result-label {
    font-size: 0.8rem;
    color: var(--text-muted);
  }

  color: #555;

  ${ResponseShortMeta} {
    margin: 0;
    .lead-status {
      display: none;
    }
  }
`

class PeopleResults extends React.PureComponent {
  render() {
    const { className, profile } = this.props

    return (
      <div className={className}>
        <SubscriptionLoader
          resource="responses"
          query={{ companyId: profile.companyId, personId: profile._id }}
          fields={[
            '_id',
            'pathwayId',
            'createdAt',
            'progress',
            'score',
            'lastModified',
            'completedAt',
            'isOnline',
            'invalid',
          ]}
          showLoader={true}
          sort={{
            createdAt: -1,
          }}
          transform={(store) => ({
            ...store,
            responses: sort(store.responses).desc((r) => r.createdAt),
          })}
        >
          {({ data }) => {
            const { responses, pathways, people } = data

            return (
              <React.Fragment>
                <div className="tab-title">Pathways</div>
                <table className="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th width="220">Started</th>
                      <th width="150">Progress</th>
                      <th width="100">Score</th>
                    </tr>
                  </thead>
                  <tbody>
                    {responses &&
                      responses.map((response) => {
                        const pathway = pathways?.find((p) => p._id === response.pathwayId)
                        const profile = people?.find((p) => p._id === response.personId)
                        return (
                          <StyledResult
                            response={response}
                            pathway={pathway}
                            profile={profile}
                            key={response._id}
                          />
                        )
                      })}
                  </tbody>
                </table>
              </React.Fragment>
            )
          }}
        </SubscriptionLoader>
      </div>
    )
  }
}

export default styled(PeopleResults)`
  table {
    background: #fff;
    font-size: 0.875rem;
    border-radius: 3px;
    border-top-left-radius: 0;
    margin-top: -1px;
    overflow: hidden;
    th {
      font-weight: 600;
      color: #212529;
    }
  }
  .tab-title {
    background: #fff;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-weight: 600;
    margin: 0;
    color: #2d5982;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    display: inline-block;
  }
`
