import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import View from '../../components/View'
import VariableDocs from '../../../../_shared/models/IntegrationAdapters/VariableDocs'
import Panel from '../../components/Panel'

const PathwayVars = ({ pathway }) => {
  const title = `Variables for ${pathway.name}`
  return (
    <View layout="sidebar" title={title}>
      <PageHeader
        breadcrumbs={[
          { label: 'Pathways', path: '/pathways' },
          { label: pathway.name, path: `/pathways/${pathway._id}` },
        ]}
        title={title}
      />

      <div className="content">
        <Panel>
          <VariableDocs pathway={pathway} />
        </Panel>
      </div>
    </View>
  )
}

export default PathwayVars
