import React, { ComponentType } from 'react'
import { Route, RouteProps, Router, Switch } from 'react-router-dom'
import Login from './views/Login'
import ForgotPassword from './views/ForgotPassword'
import ResetPassword from './views/ResetPassword'
import ProtectedRoute from './routers/ProtectedRoute'
import CompanyContext from './containers/CompanyContext'
import NotFound from './views/NotFound'
import { UserRoles } from '../../_shared/models/user/user.client'
import DashboardIndex from './views/dashboard/DashboardIndex'
import SidebarLayout from './components/layouts/SidebarLayout'
import StatisticsIndex from './views/statistics/StatisticsIndex'
import PathwayStatistics from './views/statistics/PathwayStatistics'
import CampaignStatistics from './views/statistics/campaign/CampaignStatisticsIndex'
import CompanyIndex from './views/company/CompanyIndex'
import CompanyCreate from './views/company/CompanyCreate'
import CompanyUpdate from './views/company/CompanyUpdate'
import DomainCreate from './views/company/domains/DomainsCreate'
import CareerFieldCreate from './views/company/careerFields/CareerFieldCreate'
import CareerFieldUpdate from './views/company/careerFields/CareerFieldUpdate'
import history from './history'
import { useSelector } from 'react-redux'
import { AppState } from './types'
import { Company } from '../../_shared/models/company/company.client'
import ProgramGroupCreate from './views/company/programGroups/ProgramGroupCreate'
import ProgramGroupUpdate from './views/company/programGroups/ProgramGroupUpdate'
import CampusCreate from './views/company/programGroups/CampusCreate'
import CampusUpdate from './views/company/programGroups/CampusUpdate'
import ProgramCreate from './views/company/programGroups/ProgramCreate'
import ProgramUpdate from './views/company/programGroups/ProgramUpdate'
import CompanyView from './views/company/CompanyView'
import ResultsViewContainer from './views/results/ResultsViewContainer'
import PeopleViewLoader from './views/people/PeopleView'
import PeopleResponseView from './views/people/PeopleResponseView'
import PeopleIndex from './views/people/PeopleIndex'
import IntegrationIndex from './views/integration/IntegrationIndex'
import IntegrationCreate from './views/integration/IntegrationCreate'
import IntegrationUpdate from './views/integration/IntegrationUpdate'
import AccountView from './views/Account'
import UserIndex from './views/user/UserIndex'
import UserUpdate from './views/user/UserUpdate'
import UserCreate from './views/user/UserCreate'
import PathwayRoutes from './BackendPathwayRoutes'
import CreatePathwayForm from './views/pathway/PathwayCreate_Form'
import CampaignCreate from './views/campaign/CampaignCreate'
import CampaignUpdate from './views/campaign/CampaignUpdate'
import PathwayIndex from './views/pathway/PathwayIndex'
import CreatePathwayChooseTemplate from './views/pathway/PathwayCreate_ChooseTemplate'
import StepUpdate from './views/step/StepUpdate'
import ReportUpdate from './views/report/ReportUpdate'
import AdminRoutes from './BackendAdminRoutes'
import Unsubscribe from './views/Unsubscribe'
import MonthlyReport from './views/pdf/MonthlyReport'
import ReportViewContainer from './views/report/ReportViewContainer'
import { positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from './components/AlertTemplate'
import './../../_shared/models/IntegrationAdapters/adapters/index.client'
import apiFetch from '../../_shared/api/apiFetch'
import Cookies from 'js-cookie'
import Loader from './components/Loader'
import ReactGA from 'react-ga'
import PersonaStatistics from './views/statistics/personas/PersonaStatistics'

ReactGA.initialize(process.env.GOOGLE_ANALYTICS_UA)

interface CompanyContextRouteProps extends RouteProps {
  component: ComponentType<any>
}

// todo: eventually replace this by making each view load its dependencies
const CompanyContextRoute: React.FC<CompanyContextRouteProps> = ({
  component: Component, // eslint-disable-line
  ...rest
}) => {
  const company = useSelector<AppState, Company>(
    (state) => state.subscriptions.companyContext.data.companies[0] as Company
  )
  return <Route {...rest} render={(props) => <Component company={company} {...props} />} />
}

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 4000,
  offset: '10px',
}

history.listen((location) => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

const App = () => {
  return (
    <AlertProvider template={AlertTemplate} {...alertOptions}>
      <Router history={history}>
        <Switch>
          {/* Public Routes */}
          <Route
            path="/logout"
            exact
            render={() => {
              Cookies.remove('authToken', {
                secure: process.env.NODE_ENV === 'production',
                sameSite: 'strict',
                domain: window.location.hostname,
              })
              window.location.href = '/'
              return <></>
            }}
          />
          <Route path="/forgotPassword" exact component={ForgotPassword} />
          <Route path="/resetPassword/:token" exact component={ResetPassword} />
          <Route path="/login" exact component={Login} />
          <Route path="/unsubscribe/:userId/:email" exact component={Unsubscribe} />

          {/* Admin Routes */}
          <ProtectedRoute role={UserRoles.ADMIN} path="/admin">
            <AdminRoutes />
          </ProtectedRoute>

          {/* User Routes */}
          <ProtectedRoute>
            <Switch>
              <Route
                path="/pdf/summary/:reportId/:companyId/:responseId"
                exact
                component={ReportViewContainer}
              />
              <Route
                path="/googleOAuth"
                exact
                render={async (props) => {
                  const urlParams = new URLSearchParams(props.location.search)
                  const code = urlParams.get('code')
                  const companyId = urlParams.get('state')
                  if (code) {
                    apiFetch(
                      'POST',
                      '/google/authAccount',
                      {
                        code,
                        companyId,
                      },
                      {},
                      true
                    ).then(() => {
                      window.close()
                    })
                  }
                  return <Loader />
                }}
              />
              <Route
                path="/calendlyOAuth"
                exact
                render={async (props) => {
                  const urlParams = new URLSearchParams(props.location.search)
                  const code = urlParams.get('code')
                  const companyId = urlParams.get('companyId')
                  if (code) {
                    apiFetch(
                      'POST',
                      '/calendly/authAccount',
                      {
                        code,
                        companyId,
                      },
                      {},
                      true
                    ).then(() => {
                      window.close()
                    })
                  }
                  return <Loader />
                }}
              />
              <CompanyContext>
                <Switch>
                  <CompanyContextRoute
                    path="/pathways/:pathwayId/steps/:stepId"
                    exact
                    component={StepUpdate}
                  />
                  <CompanyContextRoute
                    path="/pathways/:pathwayId/reports/:reportId"
                    exact
                    component={ReportUpdate}
                  />
                  <CompanyContextRoute
                    path="/pdf/monthlyReport/:date"
                    exact
                    component={MonthlyReport}
                  />
                  <Route>
                    <SidebarLayout>
                      <Switch>
                        <Route path="/" exact component={DashboardIndex} />
                        <Route
                          path="/statistics/campaign/:campaignId"
                          component={CampaignStatistics}
                        />
                        <Route
                          path="/statistics/pathway/:pathwayId"
                          component={PathwayStatistics}
                        />
                        <Route path="/statistics/lens/:personaKey" component={PersonaStatistics} />
                        <Route path="/statistics" component={StatisticsIndex} />

                        <CompanyContextRoute path="/organizations" exact component={CompanyIndex} />
                        <CompanyContextRoute
                          path="/organizations/new"
                          exact
                          component={CompanyCreate}
                        />
                        <CompanyContextRoute
                          path="/organizations/:companyId"
                          exact
                          component={CompanyUpdate}
                        />

                        <CompanyContextRoute
                          path="/organization/domains/new"
                          exact
                          component={DomainCreate}
                        />
                        <CompanyContextRoute
                          path="/organization/programGroups/new"
                          exact
                          component={ProgramGroupCreate}
                        />
                        <CompanyContextRoute
                          path="/organization/careerFields/new"
                          exact
                          component={CareerFieldCreate}
                        />
                        <CompanyContextRoute
                          path="/organization/careerFields/:careerFieldId"
                          exact
                          component={CareerFieldUpdate}
                        />
                        <CompanyContextRoute
                          path="/organization/programGroups/:programGroupId/campus/:campusId"
                          exact
                          component={CampusUpdate}
                        />
                        <CompanyContextRoute
                          path="/organization/programGroups/:programGroupId/program/:programId"
                          exact
                          component={ProgramUpdate}
                        />
                        <CompanyContextRoute
                          path="/organization/programGroups/:programGroupId/newCampus"
                          exact
                          component={CampusCreate}
                        />
                        <CompanyContextRoute
                          path="/organization/programGroups/:programGroupId/newProgram"
                          exact
                          component={ProgramCreate}
                        />
                        <CompanyContextRoute
                          path="/organization/programGroups/:programGroupId"
                          exact
                          component={ProgramGroupUpdate}
                        />
                        <CompanyContextRoute path="/organization" component={CompanyView} />
                        <CompanyContextRoute
                          path="/results/:responseId?/:tab?"
                          exact
                          component={ResultsViewContainer}
                        />
                        <CompanyContextRoute
                          path="/response/:responseId/:tab?"
                          exact
                          component={PeopleResponseView}
                        />
                        <CompanyContextRoute
                          path="/people/:personId"
                          component={PeopleViewLoader}
                        />
                        <CompanyContextRoute path="/people" exact component={PeopleIndex} />
                        <CompanyContextRoute
                          path="/integrations/new"
                          exact
                          component={IntegrationCreate}
                        />
                        <CompanyContextRoute
                          path="/integrations/:integrationId"
                          exact
                          component={IntegrationUpdate}
                        />
                        <CompanyContextRoute
                          path="/integrations"
                          exact
                          component={IntegrationIndex}
                        />
                        <CompanyContextRoute path="/pathways" exact component={PathwayIndex} />
                        <CompanyContextRoute
                          path="/pathways/new"
                          exact
                          component={CreatePathwayChooseTemplate}
                        />
                        <CompanyContextRoute
                          path="/campaigns/new"
                          exact
                          component={CampaignCreate}
                        />
                        <CompanyContextRoute
                          path="/campaigns/:campaignId"
                          exact
                          component={CampaignUpdate}
                        />
                        <CompanyContextRoute
                          path="/pathways/new/:templateId"
                          exact
                          component={CreatePathwayForm}
                        />
                        <CompanyContextRoute
                          path="/pathways/:pathwayId"
                          component={PathwayRoutes}
                        />
                        <CompanyContextRoute path="/users/new" exact component={UserCreate} />
                        <CompanyContextRoute path="/users/:userId" exact component={UserUpdate} />
                        <CompanyContextRoute path="/users" exact component={UserIndex} />
                        <Route path="/account" exact component={AccountView} />
                        <Route component={NotFound} />
                      </Switch>
                    </SidebarLayout>
                  </Route>
                </Switch>
              </CompanyContext>
            </Switch>
          </ProtectedRoute>
        </Switch>
      </Router>
    </AlertProvider>
  )
}

export default App
