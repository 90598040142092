import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelHero } from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import PageActions, { PageAction } from '../../components/PageActions'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import RoundButton from '../../components/RoundButton'
import { Meteor } from 'meteor/meteor'
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import View from '../../components/View'
import Link from '../../components/Link'
import { PanelList, PanelListItem } from '../../components/PanelList'
import DeleteUserModal from '../../components/modals/DeleteUserModal'
import ResetPasswordModal from '../../components/modals/ResetPasswordModal'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import { shortCache } from '../../api/subscriptionCache'

class UserDropdown extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    const { user } = this.props
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <DropdownToggle tag={RoundButton} size="sm">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest text-small" right persist>
          <ResetPasswordModal
            user={user}
            buttonTag={DropdownItem}
            buttonProps={{ toggle: false }}
          />
          <DeleteUserModal user={user} buttonTag={DropdownItem} buttonProps={{ toggle: false }} />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const FirstUser = (
  <Panel>
    <PanelHero>
      <img src="/images/users.png" width="128" />
      <h3>Create your first user</h3>
      <h6>Give access to your team members</h6>
      <Button tag={Link} to={'/users/new'} color="primary" size="lg">
        <FontAwesomeIcon icon={faPlus} /> New User
      </Button>
    </PanelHero>
  </Panel>
)

const StyledPanel = styled(Panel)`
  h4 {
    margin: 0 0 0.1rem 0;
    a {
      text-decoration: none;
    }
  }
  .buttons .btn {
    margin-right: 0.75rem;
  }

  .buttons .dropdown .btn {
    margin-right: 0;
  }
  .email {
    font-size: 0.875rem;
    color: var(--text-muted-blue);
  }
`

const User = (props) => {
  const { user, deleteUser } = props
  return (
    <PanelListItem>
      <div className="row align-items-center flex-nowrap">
        <div className="col">
          <Link to={`/users/${user._id}`}>{user.profile?.name}</Link>
          <div className="email text-smaller text-muted-blue">{user.username}</div>
        </div>
        <div className="d-none d-md-flex col-auto buttons">
          <RoundButton size="sm" tag={Link} to={`/users/${user._id}`}>
            <FontAwesomeIcon icon={faPencil} />
          </RoundButton>
          <UserDropdown user={user} deleteUser={deleteUser} />
        </div>
      </div>
    </PanelListItem>
  )
}

User.propTypes = {
  user: PropTypes.object.isRequired,
  deleteUser: PropTypes.func,
}

class UserIndex extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  render() {
    const { company } = this.props
    const title = 'Users'
    return (
      <View title={title}>
        <PageHeader title={title}>
          <PageActions>
            <PageAction toolbar button color="primary" tag={Link} tagProps={{ to: '/users/new' }}>
              <FontAwesomeIcon icon={faPlus} /> New User
            </PageAction>
          </PageActions>
        </PageHeader>
        <div className="content">
          <SubscriptionLoader
            resource="users"
            cache={shortCache}
            query={{ companyId: company._id }}
            showLoader={true}
            fields={['_id', 'profile', 'username']}
            transform={(store) => ({
              ...store,
              users: sort(store.users).asc((r) => r.profile?.name),
            })}
          >
            {({ data }) => {
              const { users } = data
              if (!users || users.length === 0) {
                return FirstUser
              }

              return (
                <PanelList>
                  {data.users.map((u) => (
                    <User key={u._id} user={u} deleteUser={this.deleteUser} />
                  ))}
                </PanelList>
              )
            }}
          </SubscriptionLoader>
        </div>
      </View>
    )
  }
}

UserIndex.propTypes = {
  company: PropTypes.object.isRequired,
}

export default UserIndex
