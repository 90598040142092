import CampusSchema from '../company/CampusSchema'
import ProgramSchema from '../company/ProgramSchema'

export default {
  title: 'Program Group',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },

    name: {
      type: 'string',
    },

    companyId: {
      type: 'string',
    },

    campuses: {
      type: 'array',
      items: CampusSchema,
    },
    programs: {
      type: 'array',
      items: ProgramSchema,
    },
  },

  required: ['name', 'companyId'],
  additionalProperties: false,
}
