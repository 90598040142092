import React, { SyntheticEvent } from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import media from '../css/_media'
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons/faPlusCircle'
import Loader from './Loader'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import { Company } from '../../../_shared/models/company/company.client'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../types'
import { changeCompanyContext } from '../actions'
import apiFetch from '../../../_shared/api/apiFetch'

const StyledCompanySelectorDropdown = styled.div`
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: 0.4rem 1rem 0;
  position: absolute;
  font-size: 0.875rem;
  box-shadow: 0 3px 12px -3px rgba(0, 0, 0, 0.3);

  @media (min-width: ${media.md}) {
    margin: 4px 0 0 0;
    right: 0;
    left: auto;
    padding: 0;
    width: 280px;
  }
`

const SearchIcon = styled(FontAwesomeIcon)`
  color: #666;
`

const Search = styled.div`
  border-bottom: 1px solid #d8d8d8;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  flex-wrap: nowrap;

  input {
    display: block;
    width: 100%;
    border: 0;
    height: 38px;
    padding: 0.6rem 0.8rem 0.6rem 0.5rem;
    font-size: 0.875rem;
    outline: none;
  }
`

interface CompanyListProps {
  history?: any
}

const CompanyList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: auto;
  max-height: 9rem;

  ${(props: CompanyListProps) => {
    if (props.history) {
      return `margin-top:1rem;border-top:1px solid var(--border-color);`
    }
  }}

  @media (min-width: ${media.md}) {
    max-height: 14rem;
  }

  li {
    margin: 0;
    border-bottom: 1px solid var(--border-color);

    &:last-child {
      border-bottom: 0;
    }

    a {
      padding: 0.6rem 0.8rem;
      display: block;
    }
  }
`

const CreateCompany = styled.div`
  border-top: 1px solid var(--border-color);
  margin-top: -1px;

  a {
    display: block;
    padding: 0.6rem 0.8rem;

    font-weight: 500;
    color: var(--primary);
    text-decoration: none;
    &:hover {
      color: var(--link-hover-color);
    }
  }
`

const PlusIcon = styled(FontAwesomeIcon)`
  margin-right: 0.4rem;
`

const NoResults = styled.div`
  padding: 0.6rem 0.8rem;
  color: #666;
`

interface CompanySelectorDropdownProps {
  isLoading?: boolean
  companies?: any[]
  companyHistory?: any[]
  selectCompany: (company: Company) => void
  search: (event: SyntheticEvent) => void
}

export const CompanySelectorDropdown: React.FC<CompanySelectorDropdownProps> = (props) => (
  <StyledCompanySelectorDropdown>
    <Search>
      <SearchIcon icon={faSearch} />
      <input type="text" onChange={props.search} placeholder="Search organizations" autoFocus />
    </Search>
    {!props.isLoading && props.companies && props.companies.length === 0 ? (
      <NoResults>No results found</NoResults>
    ) : (
      ''
    )}
    {props.isLoading ? (
      <Loader />
    ) : (
      <CompanyList>
        {props.companies &&
          props.companies.map((company) => (
            <li key={company._id}>
              <a
                onClick={(event) => {
                  event.preventDefault()
                  props.selectCompany(company)
                }}
                href={`/${company.id}`}
              >
                {company.name}
              </a>
            </li>
          ))}
      </CompanyList>
    )}
    <CreateCompany>
      <Link to="/admin/clients/new">
        <PlusIcon icon={faPlusCircle} />
        Create a client
      </Link>
    </CreateCompany>
  </StyledCompanySelectorDropdown>
)

const StyledCompanySelector = styled(DropdownToggle)`
  color: #15457d;
  font-size: 0.75rem;
  border: 1px solid #cdd1d6;
  border-radius: 4px;
  padding: 0.3rem 0.6rem;
  cursor: pointer;
  background: none;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .selector-label {
    white-space: nowrap;
    max-width: 140px;
    min-width: 100px;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    margin-right: 0.5rem;
    text-align: left;

    &.placeholder {
      color: var(--text-muted);
      font-style: italic;
    }

    @media (min-width: ${media.md}) {
      width: 200px;
      max-width: none;
      min-width: none;
    }
  }
`
const StyledDropdown = styled(Dropdown)`
  display: inline-block;
  position: static;
  @media (min-width: ${media.md}) {
    position: relative;
  }
`

const StyledDropdownMenu = styled(DropdownMenu)`
  border: 0;
  padding: 0;
  position: static;
`

interface CompanySelectorProps extends RouteComponentProps {
  isOpen?: boolean
  isLoading?: boolean
  companies?: any[]
  selectedCompany?: Company
}

interface CompanySelectorState {
  dropdownOpen: boolean
  isLoading: boolean
  companies: any[]
  companyHistory: any[]
}

class CompanySelector extends React.PureComponent<CompanySelectorProps, CompanySelectorState> {
  constructor(props) {
    super(props)
    let companyHistory = []

    if (window.localStorage) {
      const savedHistory = window.localStorage.getItem('company-history')
      if (savedHistory) {
        companyHistory = JSON.parse(savedHistory)
      }
    }

    this.state = {
      dropdownOpen: !!props.isOpen,
      isLoading: !!props.isLoading,
      companies: props.companies || null,
      companyHistory,
    }
  }

  static propTypes = {}

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
      companies: null,
    }))
  }

  selectCompany = (company: Company) => {
    this.toggle()
    changeCompanyContext(company._id)
  }

  search = (event) => {
    const query = event.target.value
    this.setState(
      {
        isLoading: true,
        companies: null,
      },
      async () => {
        try {
          const result = await apiFetch('GET', '/companies/search', {}, { name: query })
          this.setState({
            isLoading: false,
            companies: result,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  updateCompanyHistory() {
    const company = this.props.selectedCompany
    if (!company) {
      return
    }

    const { companyHistory } = this.state
    const index = companyHistory.findIndex((c) => c._id === company._id)
    // if it already exists in the array, move to the top
    if (index !== -1) {
      if (index === 0) {
        return
      }

      companyHistory.splice(index, 1)
      companyHistory.splice(0, 0, company)
    } else {
      // Add the company id to the top of the array and remove last item if over maximum
      companyHistory.splice(0, 0, company)
      if (companyHistory.length > 5) {
        companyHistory.splice(5, companyHistory.length - 5)
      }
    }
    if (window.localStorage) {
      window.localStorage.setItem('company-history', JSON.stringify(companyHistory))
    }
    this.setState({
      companyHistory,
    })
  }

  componentDidMount() {
    if (this.props.selectedCompany) {
      this.updateCompanyHistory()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCompany?._id !== this.props.selectedCompany?._id) {
      this.updateCompanyHistory()
    }
  }

  render() {
    const { selectedCompany } = this.props

    return (
      <StyledDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <StyledCompanySelector color="alt">
          <span className={selectedCompany ? 'selector-label' : 'selector-label placeholder'}>
            {selectedCompany ? selectedCompany.name : 'Select an organization'}
          </span>
          <FontAwesomeIcon icon={faChevronDown} size="xs" />
        </StyledCompanySelector>
        <StyledDropdownMenu className="fadeIn animated fastest" right>
          <CompanySelectorDropdown
            isLoading={this.state.isLoading}
            companies={this.state.companies || this.state.companyHistory}
            selectCompany={this.selectCompany}
            search={this.search}
          />
        </StyledDropdownMenu>
      </StyledDropdown>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  selectedCompany: state.subscriptions.companyContext?.data?.companies[0] as Company,
})

export default compose(withRouter, connect(mapStateToProps))(CompanySelector)
