import React from 'react'
import View from '../../../components/View'
import { Company } from '../../../../../_shared/models/company/company.client'
import Panel from '../../../components/Panel'
import Form from '../../../../../_shared/components/Form'
import CRMImportSchema, { CRMImportResult } from './CRMImport.schema'
import { Button } from 'reactstrap'
import Input from '../../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../../_shared/components/Form/Cardbox'
import apiFetch from '../../../../../_shared/api/apiFetch'
import FormError from '../../../../../_shared/components/Form/FormError'
import Loader from '../../../components/Loader'

interface Props {
  company: Company
}

interface State {
  isLoading: boolean
  error: string
  result: CRMImportResult
}

export default class CRMImport extends React.Component<Props, State> {
  state = {
    isLoading: false,
    error: null,
    result: null,
  }

  onSubmit = ({ formData }) => {
    const { company } = this.props
    this.setState(
      {
        isLoading: true,
        result: null,
        error: null,
      },
      async () => {
        try {
          const result = await apiFetch('POST', `/companies/crmImport`, formData)

          this.setState({
            isLoading: false,
            result,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e.message,
          })
        }
      }
    )
  }

  render() {
    const { company } = this.props
    const { isLoading, error, result } = this.state

    return (
      <View title={`CRM Import - ${company.name}`}>
        <Panel>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h4>Importing from a CRM (beta)</h4>

              <p>
                If you use a CRM to manage prospect lifecycle (interviewed, enrolled, etc.), you can
                synchronize that information with VirtualAdviser for improved statistics and
                exports.
              </p>
            </div>
          </div>
          <div className="alert alert-warning text-small">
            <strong>Note:</strong> This is a beta feature, and may change in the future. If you
            would like to provide feedback, please email us at{' '}
            <a href="mailto:support@virtualadviser.com">support@virtualadviser.com</a>
          </div>

          <Form
            formData={{ companyId: company._id }}
            schema={CRMImportSchema}
            onValidSubmit={this.onSubmit}
          >
            <Input
              type="textarea"
              name="content"
              label="List of email addresses"
              help="Must be comma-separated or each email on a new line"
            />

            <Cardbox
              name="importType"
              label="Set their status to..."
              thin
              multiple={false}
              selectionRequired={true}
              defaultValue="interview"
            >
              <CardboxItem
                value="interview"
                image="/images/resume.png"
                title="Interviewed"
                description="These prospects have been interviewed by an admissions representative"
              />
              <CardboxItem
                value="enrollment"
                image="/images/goal.png"
                title="Enrolled"
                description="These prospects have been enrolled in one or more programs"
              />
            </Cardbox>

            <Button color="primary" type="submit" disabled={this.state.isLoading}>
              Synchronize
            </Button>
          </Form>

          {isLoading && (
            <>
              <hr />
              <Loader />
            </>
          )}

          {error && (
            <>
              <hr />
              <FormError>{error}</FormError>
            </>
          )}

          {result && (
            <div className="result">
              <hr />
              <h4>Results</h4>
              <br />
              <p>
                <div style={{ color: 'var(--success)' }}>Updated {result.updated} profiles</div>
                {result.notfound && (
                  <div style={{ color: 'var(--danger)' }}>
                    No profile was found for {result.notfound} email addresses:
                  </div>
                )}
              </p>
            </div>
          )}
          {/* <Form formData={formData} schema={CompanyFormSchema} onValidSubmit={onSubmit}>
      <Panel>
        <PanelForm center={false}>
          <Input type="text" name="name" />
        </PanelForm>
      </Panel>

      <Panel>
        <Cardbox
          multiple
          name="bundles"
          help="Select the bundles of pathway templates that this
                    organization will have access to."
          label="Pathway Bundles"
          thin
          vertical
        >
          {Object.entries(PathwayBundles).map(([key, bundle]: [string, any]) => (
            <CardboxItem key={key} value={key} title={bundle.name} />
          ))}
        </Cardbox>

        <hr />
        
        </Form> */}
        </Panel>
      </View>
    )
  }
}
