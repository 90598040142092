import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import apiFetch from '../../../../_shared/api/apiFetch'
import { redirect } from '../Link'
import { withRouter } from 'react-router-dom'

class DeleteResponseModal extends React.PureComponent {
  static propTypes = {
    response: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  callMethod = async () => {
    try {
      await apiFetch('DELETE', '/responses/delete', { responseId: this.props.response._id })
      redirect({
        location,
        history,
        pathname: '/results',
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Result"
        message={<div>Are you sure you want to delete this result?</div>}
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faTimesCircle} fixedWidth transform="down-1" />
        Delete
      </BasicModal>
    )
  }
}

export default withRouter(DeleteResponseModal)
