import React from 'react'
import { Link as RouterLink, NavLink } from 'react-router-dom'
import { Redirect as RouterRedirect, useLocation } from 'react-router'
import history from '../history'

interface RedirectFuncProps {
  pathname: string
  query?: Record<string, any>
}

export const redirect = function ({ pathname, query }: RedirectFuncProps): void {
  const search = new URLSearchParams(window.location.search)

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      search.set(key, value)
    })
  }

  const newTo = {
    pathname,
    search: search.toString(),
  }

  history.push(newTo)
}

interface RedirectProps {
  query?: Record<string, any>
  pathname: string
}

export const Redirect: React.FC<RedirectProps> = ({ query, pathname }) => {
  const location = useLocation()
  const search = new URLSearchParams(location.search)

  if (query) {
    Object.entries(query).forEach(([key, value]) => {
      search.set(key, value)
    })
  }

  const newTo = {
    pathname,
    search: search.toString(),
  }

  return <RouterRedirect to={newTo} />
}

interface Props {
  to: string | Record<string, any>
  tag?: string | JSX.Element
  navLink?: boolean
  target?: string
}

// We want to preserve the "a" query param
// and honor props.to as a string or an object
const Link: React.FC<Props> = (props) => {
  const location = useLocation()

  const { to, tag, navLink, target, children, ...rest } = props
  const search = new URLSearchParams(location.search)

  if (to.search) {
    Object.assign(search, to.search)
  }

  const newTo = {
    pathname: typeof to === 'string' ? to : to.pathname,
    search: search.toString(),
  }

  const Tag = navLink ? NavLink : RouterLink

  return (
    <Tag {...(rest as any)} to={newTo}>
      {props.children}
    </Tag>
  )
}

export default Link
