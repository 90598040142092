import React from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { parse, format } from 'date-fns'

class SpendContainer extends React.Component {
  chartData() {
    let days = []
    let spend = []
    let budget = []
    const charts = this.props.data.results.charts
    const cumulativeSpendByDay = charts.cumulativeSpend

    Object.keys(cumulativeSpendByDay).forEach((day, i) => {
      days.push(format(parse(day, 'yyyy-mm-dd', new Date()), 'Do'))
      if (charts.budgetLine) budget.push(charts.budgetLine[i].toFixed(2))
      if (cumulativeSpendByDay[day] !== null) {
        spend.push(cumulativeSpendByDay[day].toFixed(2))
      } else {
        spend.push(cumulativeSpendByDay[day])
      }
    })

    return { days, spend, budget }
  }

  render() {
    const cumulativeSpendByDay = this.chartData()
    const chartData = {
      labels: cumulativeSpendByDay.days,
      datasets: [
        {
          label: 'Budget',
          lineTension: 0.2,
          borderColor: 'rgba(255,0,0,0.4)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(255,0,0,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: 'rgba(255,0,0,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 6,
          data: cumulativeSpendByDay.budget,
          fill: false,
        },
        {
          label: 'Cumulative Spend',
          lineTension: 0.2,
          backgroundColor: 'rgba(64,86,111,0.4)',
          borderColor: 'rgba(64,86,111,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(64,86,111,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: 'rgba(64,86,111,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 6,
          data: cumulativeSpendByDay.spend,
          fill: 'origin',
        },
      ],
    }
    const options = {
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          mode: 'index',
          intersect: false,
        },
      },
    }

    return (
      <div>
        <h3>Spend By Day</h3>
        <Line data={chartData} options={options} height={50} />
      </div>
    )
  }

  static propTypes = {
    data: PropTypes.object,
  }
}

export default SpendContainer
