import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import PersonaPanel from '../PersonaPanel'
import { VennDiagramChart } from 'chartjs-chart-venn'
import { ChartConfiguration } from 'chart.js'
import { select, selectAll } from 'd3'
import { VennDiagram } from '@upsetjs/venn.js'
import PersonaHorizontalBarChart from './PersonaHorizontalBarChart'
import classNames from 'classnames'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import { PersonaColors } from '../PersonaColors'

interface Props {
  className?: string
  persona: Persona
}

interface TooltipData {
  label: string
  color: string
  value: string
}

const PersonaCareerInterest: React.FC<Props> = ({ className, persona }) => {
  const canvasRef = useRef(null)
  const tooltipRef = useRef(null)
  const [tooltipData, setTooltipData] = useState<TooltipData>(null)

  useEffect(() => {
    // define sets and set set intersections

    const interestedForSomeTimeSize = Math.round(
      (persona.metrics.interestedForSomeTime.value / persona.sampleSize) * 100
    )
    const startingResearchSize = Math.round(
      (persona.metrics.startingResearch.value / persona.sampleSize) * 100
    )
    const inquiredOtherSchoolsSize = Math.round(
      (persona.metrics.inquiredOtherSchools.value / persona.sampleSize) * 100
    )
    const inquiredOtherSchoolsinterestedForSomeTimeSize = Math.round(
      (persona.metrics.inquiredOtherSchoolsinterestedForSomeTime.value / persona.sampleSize) * 100
    )
    const inquiredOtherSchoolsstartingResearchSize = Math.round(
      (persona.metrics.inquiredOtherSchoolsstartingResearch.value / persona.sampleSize) * 100
    )
    const interestedForSomeTimestartingResearchSize = Math.round(
      (persona.metrics.interestedForSomeTimestartingResearch.value / persona.sampleSize) * 100
    )
    const inquiredOtherSchoolsinterestedForSomeTimestartingResearchSize = Math.round(
      (persona.metrics.inquiredOtherSchoolsinterestedForSomeTimestartingResearch.value /
        persona.sampleSize) *
        100
    )

    const sets = [
      {
        sets: ['A'],
        color: PersonaColors.vennOne,
        label: interestedForSomeTimeSize + '%',
        size: interestedForSomeTimeSize,
        tooltipValue: `${interestedForSomeTimeSize}% (${persona.metrics.interestedForSomeTime.value})`,
        tooltipLabel: 'Have been interested in this field for some time',
      },
      {
        sets: ['B'],
        color: PersonaColors.vennTwo,
        label: startingResearchSize + '%',
        size: startingResearchSize,
        tooltipValue: `${startingResearchSize}% (${persona.metrics.startingResearch.value})`,
        tooltipLabel: 'Are just starting their research',
      },
      {
        sets: ['C'],
        color: PersonaColors.vennThree,
        label: inquiredOtherSchoolsSize + '%',
        size: inquiredOtherSchoolsSize,
        tooltipValue: `${inquiredOtherSchoolsSize}% (${persona.metrics.inquiredOtherSchools.value})`,
        tooltipLabel: 'Have inquired with other schools',
      },
      {
        sets: ['A', 'B'],
        // color: 'hsl(198,77%,64%)',
        label: interestedForSomeTimestartingResearchSize + '%',
        size: interestedForSomeTimestartingResearchSize,
        tooltipValue: `${interestedForSomeTimestartingResearchSize}% (${persona.metrics.interestedForSomeTimestartingResearch.value})`,
        tooltipLabel:
          'Have been interested in this field for some time + Are just starting their research',
      },
      {
        sets: ['B', 'C'],
        // color: 'hsl(190,41%,45%)',
        label: inquiredOtherSchoolsstartingResearchSize + '%',
        size: inquiredOtherSchoolsstartingResearchSize,
        tooltipValue: `${inquiredOtherSchoolsstartingResearchSize}% (${persona.metrics.inquiredOtherSchoolsstartingResearch.value})`,
        tooltipLabel: 'Are just starting their research + Have inquired with other schools',
      },
      {
        sets: ['A', 'C'],
        // color: 'hsl(113,55%,77%)',
        label: inquiredOtherSchoolsinterestedForSomeTimeSize + '%',
        size: inquiredOtherSchoolsinterestedForSomeTimeSize,
        tooltipValue: `${inquiredOtherSchoolsinterestedForSomeTimeSize}% (${persona.metrics.inquiredOtherSchoolsinterestedForSomeTime.value})`,
        tooltipLabel:
          'Have been interested in this field for some time + Have inquired with other schools',
      },
      {
        sets: ['A', 'B', 'C'],
        color: 'hsl(126,50%,75%)',
        label: inquiredOtherSchoolsinterestedForSomeTimestartingResearchSize + '%',
        size: inquiredOtherSchoolsinterestedForSomeTimestartingResearchSize,
        tooltipValue: `${inquiredOtherSchoolsinterestedForSomeTimestartingResearchSize}% (${persona.metrics.inquiredOtherSchoolsinterestedForSomeTimestartingResearch.value})`,
        tooltipLabel: 'All',
      },
    ]

    const chart = VennDiagram({
      symmetricalTextCentre: false,
    })
      .fontSize('0px')
      .height(160)
      .width(200)

    const chartSelection = select(canvasRef.current)

    chartSelection.datum(sets).call(chart as any)

    canvasRef.current.querySelectorAll('.venn-circle path').forEach((elem) => {
      const color = elem.style.fill
      elem.style.stroke = color
      elem.style.strokeWidth = 1
      elem.style.strokeOpacity = 0
      elem.style.fillOpacity = 0.6
    })

    chartSelection.selectAll('g').on('mouseenter', function (event, d: any) {
      const elem = this as HTMLElement
      const color = elem.querySelector('path').style.fill

      setTooltipData({
        color,
        label: d.tooltipLabel,
        value: d.tooltipValue,
      })

      // Check if it's not an intersection
      if (!elem.classList.contains('venn-intersection')) {
        // Detach and reattach this element just before the first intersection
        const firstIntersection = chartSelection.select('.venn-intersection').node() as SVGGElement
        if (firstIntersection) {
          firstIntersection.parentNode.insertBefore(elem, firstIntersection)
        } else {
          // If there are no intersections, just append this to the end
          elem.parentNode.appendChild(elem)
        }
        select(elem).select('path').classed('venn-hovered', true)
      }

      if (elem.classList.contains('venn-intersection')) {
        select(elem).select('path').classed('venn-intersect-hovered', true)
      }
    })

    chartSelection.on('mousemove', function (event) {
      // right anchor based on mouse position
      const { width, height } = tooltipRef.current.getBoundingClientRect()

      if (event.pageX - width < 0) {
        tooltipRef.current.style.left = event.pageX + 10 + 'px'
      } else {
        tooltipRef.current.style.left = event.pageX - width - 10 + 'px'
      }
      tooltipRef.current.style.top = event.pageY - height - 10 + 'px'
    })

    chartSelection.selectAll('g').on('mouseleave', function (event, d) {
      const elem = this as HTMLElement
      setTooltipData(null)

      if (elem.classList.contains('venn-intersection')) {
        select(this).select('path').classed('venn-intersect-hovered', false)
      } else {
        select(this).select('path').classed('venn-hovered', false)
      }
    })
  })

  return (
    <div className={className}>
      <PersonaPanel title="Career Interest">
        <div className="challenges-inner">
          <div className="challenges-legend">
            <div className="chart-legend-wrap">
              <PersonaHorizontalBarChart
                items={[
                  {
                    label: 'Have been interested in this field for some time',
                    value: persona.metrics.interestedForSomeTime.value,
                    valuePercentage: Math.floor(
                      (persona.metrics.interestedForSomeTime.value / persona.sampleSize) * 100
                    ),
                    color: PersonaColors.vennOne,
                  },
                  {
                    label: 'Are just starting their research',
                    value: persona.metrics.startingResearch.value,
                    valuePercentage: Math.floor(
                      (persona.metrics.startingResearch.value / persona.sampleSize) * 100
                    ),
                    color: PersonaColors.vennTwo,
                  },
                  {
                    label: 'Have inquired with other schools',
                    value: persona.metrics.inquiredOtherSchools.value,
                    valuePercentage: Math.floor(
                      (persona.metrics.inquiredOtherSchools.value / persona.sampleSize) * 100
                    ),
                    color: PersonaColors.vennThree,
                  },
                ]}
              />
            </div>
          </div>
          <div className="chart">
            <div id="testchart2" ref={canvasRef}></div>
          </div>
        </div>

        <div
          className={classNames('chart-tooltip', { hide: tooltipData === null })}
          ref={tooltipRef}
        >
          <div
            className="chart-tooltip-color"
            style={{ backgroundColor: tooltipData?.color }}
          ></div>
          <div className="chart-tooltip-label">{tooltipData?.label}:</div>
          <div className="chart-tooltip-value">{tooltipData?.value}</div>
        </div>

        {/*  */}
        {/* </div> */}
      </PersonaPanel>
    </div>
  )
}

export default styled(PersonaCareerInterest)`
  .challenges-inner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }
  .chart {
    font-size: 20px;
    flex: 0 0 auto;
  }
  .challenges-legend {
    flex: 1 1 auto;
    padding-right: 1rem;
  }

  .chart-tooltip {
    &.hide {
      display: none;
    }
    background: rgba(0, 0, 0, 0.85);
    color: #fff;
    padding: 1em;
    font-size: 0.875rem;
    border-radius: 3px;
    position: fixed;
    top: 100px;
    left: 100px;
    z-index: 9999999;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    max-width: 300px;
  }
  .chart-tooltip-color {
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 3px;
    margin-right: 0.6em;
    flex: 0 0 auto;
  }
  .chart-tooltip-label {
    line-height: 1.2;
    margin-right: 0.4em;
    flex: 1 1 auto;
  }
  .chart-tooltip-value {
    font-weight: 600;
    line-height: 1.2;
    flex: 1 1 auto;
  }
  .venn-hovered {
    fill-opacity: 0.8 !important;
    stroke-opacity: 0.5 !important;
    stroke-width: 5 !important;
  }
  .venn-intersect-hovered {
    fill-opacity: 0.15 !important;
  }
`
