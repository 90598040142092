import React from 'react'
import PropTypes from 'prop-types'
import { PanelForm } from '../../components/Panel'
import { Button } from 'reactstrap'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-javascript'
import TrackingCodeSchema from '../../../../_shared/models/pathway/schema/TrackingCodeSchema'
import Form, { FormContext } from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Code from '../../../../_shared/components/Form/Code'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import FormError from '../../../../_shared/components/Form/FormError'

import ace from 'ace-builds'
const forceWebpackToLoad = [AceEditor]

ace.config.setModuleUrl(
  'ace/mode/html_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-html.js'
)

ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-javascript.js'
)

const TrackingCodeForm = ({ buttonLabel, isLoading, error, code, onSubmit, pathway }) => (
  <Form formData={code || {}} schema={TrackingCodeSchema} onValidSubmit={onSubmit}>
    <PanelForm center={false}>
      <Input type="text" name="name" />
    </PanelForm>

    <Cardbox name="type" thin selectionRequired={true} defaultValue="html">
      <CardboxItem
        value="html"
        title="HTML"
        description="Add tags to the HEAD or BODY of the page"
      />
      <CardboxItem
        value="script"
        title="JavaScript"
        description="Execute JavaScript on one or more steps"
      />
    </Cardbox>

    <FormContext.Consumer>
      {({ formData }) => {
        if (formData.type === 'html') {
          return (
            <React.Fragment>
              <Cardbox
                name="location"
                label="Location"
                thin
                selectionRequired={true}
                defaultValue="head"
              >
                <CardboxItem
                  value="head"
                  title="HEAD"
                  description="This code will be placed in the HEAD tag of the page"
                />
                <CardboxItem
                  value="body"
                  title="BODY"
                  description="This code will be placed in the BODY tag of the page"
                />
              </Cardbox>
              <Code
                tabSize={2}
                wrapEnabled={true}
                editorProps={{
                  $blockScrolling: Infinity,
                }}
                setOptions={{
                  wrap: true,
                  displayIndentGuides: false,
                  showPrintMargin: false,
                  showFoldWidgets: false,
                }}
                mode="html"
                label="HTML Content"
                name="content"
              />
              <div className="alert alert-info">
                <strong>Note:</strong> Code inserted via HTML only executes once when the pathway is
                first loaded. Google Tag Manager scripts should be configured to use the History
                Change listener.
              </div>
            </React.Fragment>
          )
        } else if (formData.type === 'script') {
          return (
            <React.Fragment>
              <Code
                tabSize={2}
                wrapEnabled={true}
                editorProps={{
                  $blockScrolling: Infinity,
                }}
                setOptions={{
                  wrap: true,
                  displayIndentGuides: false,
                  showPrintMargin: false,
                  showFoldWidgets: false,
                }}
                mode="javascript"
                label="JavaScript"
                name="content"
              />
              <Cardbox
                name="stepTarget"
                thin
                label="Which steps should this script execute on?"
                selectionRequired={true}
                defaultValue="all"
              >
                <CardboxItem
                  value="all"
                  title="All Steps"
                  description="Execute this script on every step"
                />
                <CardboxItem
                  value="specific"
                  title="Specific Step"
                  description="Execute on specific steps only"
                />
              </Cardbox>
              {formData.stepTarget === 'specific' && (
                <Cardbox
                  name="steps"
                  vertical
                  thin
                  label="Choose steps"
                  selectionRequired={true}
                  multiple={true}
                >
                  {pathway.getSteps().map((step) => (
                    <CardboxItem key={step._id} value={step._id} title={step.name} />
                  ))}
                </Cardbox>
              )}
            </React.Fragment>
          )
        }
      }}
    </FormContext.Consumer>

    {error && <FormError>{error}</FormError>}

    <hr />
    <Button color="primary" type="submit" disabled={isLoading}>
      {buttonLabel || 'Save Changes'}
    </Button>
  </Form>
)
TrackingCodeForm.propTypes = {
  error: PropTypes.object,
  pathway: PropTypes.object.isRequired,
  code: PropTypes.object,
  buttonLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default TrackingCodeForm
