import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import capitalize from 'voca/capitalize'

const StyledTable = styled(Table)`
  font-size: 0.875rem;

  tbody > tr:first-child > td {
    border-top: 0;
  }

  .label-cell {
    font-weight: 600;
    white-space: nowrap;
    width: 10px;
    text-align: right;
    padding-right: 0;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
  }
  &.table-striped tbody tr:nth-of-type(even) {
    background-color: #f9fafd;
  }

  ul {
    margin: 0;
  }

  th,
  td {
    border-top: 0;
    padding: 0.75em 0.85em;
    font-size: 0.8125rem;
  }
`

const OutputValue = ({ value }) => {
  if (value instanceof Array) {
    return (
      <ul>
        {value.map((v, index) => (
          <li key={index}>{v}</li>
        ))}
      </ul>
    )
  } else {
    return value
  }
}

const ProfileSection = (props) => {
  const { profile, section } = props
  const data = profile.data && profile.data[section]

  if (!data) {
    return null
  }

  return (
    <div className="col-lg-6">
      <div className="group-title">{capitalize(section)}</div>
      <div className="profile-group">
        <StyledTable striped>
          <tbody>
            {Object.entries(data).map(([key, field]) => (
              <tr key={key}>
                <td className="label-cell">{field.label}:</td>
                <td>
                  <OutputValue value={field.value} />
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </div>
    </div>
  )
}

const Profile = (props) => {
  const { className, profile } = props

  return (
    <div className={className}>
      <div className="row">
        <ProfileSection profile={profile} section="contact" />
        <ProfileSection profile={profile} section="prospects" />
        <ProfileSection profile={profile} section="tourRequest" />
        <ProfileSection profile={profile} section="situation" />
        <ProfileSection profile={profile} section="history" />
        <ProfileSection profile={profile} section="logistics" />
        <ProfileSection profile={profile} section="motivation" />
      </div>
    </div>
  )
}

export default styled(Profile)`
  padding-right: 1.5rem;
  font-size: 0.875rem;

  ul {
    margin: 0;
    padding-left: 1em;
  }

  .group-title {
    font-weight: 600;
    margin: 0;
    color: #2d5982;
    background: #fff;
    border-radius: 3px;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-size: 0.875rem;
  }
  .profile-group {
    padding: 0.5rem;
    background: #fff;
    border-radius: 3px;
    margin: 0 0 2rem 0;

    &:nth-child(odd) {
      margin-right: 0;
    }

    table {
      margin: 0;
    }
  }
`
