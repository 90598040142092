import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../../components/PageHeader'
import Datepicker, { DefaultOptions } from '../../../components/Datepicker'
import View from '../../../components/View'
import PageActions, { PageAction } from '../../../components/PageActions'
import { subDays, startOfDay, endOfDay, startOfMonth, endOfMonth, subMonths } from 'date-fns'
import withDateRange from '../../../containers/DateRangeSelectorContainer'
import { PanelList } from '../../../components/PanelList'
import FirstCompany from '../../../components/FirstCompany'
import CompanyHealthLoader, { StyledCompanyHealthPanel } from './components/CompanyHealth'

const LAST_90_DAYS = {
  label: 'Last 90 Days',
  key: '90days',
  dates() {
    const start = subDays(new Date(), 90)
    return [startOfDay(start), endOfDay(new Date())]
  },
}

export const DateOptions = [
  DefaultOptions.LAST_7_DAYS,
  DefaultOptions.LAST_30_DAYS,
  DefaultOptions.LAST_365_DAYS,
  LAST_90_DAYS,
  {
    label: 'This Month',
    key: 'thismonth',
    dates() {
      return [startOfMonth(new Date()), endOfMonth(new Date())]
    },
  },
  {
    label: 'Last Month',
    key: 'lastmonth',
    dates() {
      const lastMonth = subMonths(new Date(), 1)
      return [startOfMonth(lastMonth), endOfMonth(lastMonth)]
    },
  },
]

class AdminDashboardIndex extends React.PureComponent {
  render() {
    return (
      <View title="Admin Dashboard">
        <PageHeader title="Admin Dashboard">
          <PageActions>
            <PageAction toolbar>
              <Datepicker
                onChange={this.props.changeDateRange}
                value={this.props.dateRange}
                options={DateOptions}
              />
            </PageAction>
          </PageActions>
        </PageHeader>
        <div className="content">
          <CompanyHealthLoader dateRange={this.props.dateRange}>
            {({ healthData, dateRange }) => {
              if (!healthData || healthData?.length === 0) {
                return <FirstCompany />
              }
              return (
                <PanelList>
                  {healthData &&
                    healthData.map((h) => (
                      <StyledCompanyHealthPanel
                        key={h._id}
                        id={h._id}
                        name={h.name}
                        campaigns={h.campaigns}
                        responsesByDay={h.responsesByDay}
                        parentCompanyId={h.parentCompanyId}
                        parentCompanyName={h.parentCompanyName}
                        dateRange={dateRange}
                        history={this.props.history}
                        selectCompany={this.props.selectCompany}
                      />
                    ))}
                </PanelList>
              )
            }}
          </CompanyHealthLoader>
        </div>
      </View>
    )
  }
}

AdminDashboardIndex.propTypes = {
  className: PropTypes.string,
  dateRange: PropTypes.array.isRequired,
  changeDateRange: PropTypes.func.isRequired,
  history: PropTypes.object,
  selectCompany: PropTypes.func,
}

export default withDateRange(AdminDashboardIndex, LAST_90_DAYS.dates())
