import React from 'react'
import styled from 'styled-components'
import Breadcrumbs, { Breadcrumb } from '../components/Breadcrumbs'
import TabNav, { TabNavItem } from '../components/TabNav'

interface Props {
  className?: string
  title: string
  navItems?: TabNavItem[]
  breadcrumbs?: Breadcrumb[]
}

const PageHeader: React.FC<Props> = (props) => {
  return (
    <header className={props.className + ' PageHeader'}>
      <div className="row align-items-center flex-nowrap">
        <div className="col">
          {props.breadcrumbs ? <Breadcrumbs breadcrumbs={props.breadcrumbs} /> : ''}
          <h1>{props.title}</h1>
        </div>
        <div className="col-auto actions">{props.children}</div>
      </div>
      {props.navItems ? <TabNav items={props.navItems} /> : ''}
    </header>
  )
}

export default styled(PageHeader)`
  h1 {
    color: #40566f;
    margin: 0;
    font-size: 1.75rem;
  }
  padding: 1rem 0.875rem 1rem 1.3125rem;
  background: #fff;
  border-bottom: 1px solid var(--border-color);
  box-shadow: 0 2px 2px 0px rgba(0, 0, 0, 0.05);

  .TabNav {
    margin: 0.5rem 0 -1rem;
  }

  .breadcrumbs {
    margin: 0;
  }
`
