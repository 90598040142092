import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import Link, { redirect } from '../../../components/Link'
import ProgramGroupForm from './ProgramGroupForm'
import { withAlert } from 'react-alert'
import { PanelList, PanelListItem } from '../../../components/PanelList'
import RoundButton from '../../../components/RoundButton'
import NewButton from '../../../components/NewButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import BasicModal from '../../../components/BasicModal'
import styled from 'styled-components'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import NotFound from '../../NotFound'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'
class DeleteCampus extends React.PureComponent {
  static propTypes = {
    campus: PropTypes.object.isRequired,
    programGroup: PropTypes.object.isRequired,
  }

  callMethod = () => {
    const { programGroup, campus } = this.props
    try {
      apiFetch('DELETE', '/campuses/delete', {
        programGroupId: programGroup._id,
        campusId: campus._id,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Campus?"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.campus.name}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
      >
        <RoundButton size="sm" onClick={this.delete}>
          <FontAwesomeIcon icon={faTimes} />
        </RoundButton>
      </BasicModal>
    )
  }
}

class DeleteProgram extends React.PureComponent {
  static propTypes = {
    program: PropTypes.object.isRequired,
    programGroup: PropTypes.object.isRequired,
  }

  callMethod = () => {
    const { programGroup, program } = this.props
    try {
      apiFetch('DELETE', '/programs/delete', {
        programGroupId: programGroup._id,
        programId: program._id,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Program?"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.program.name}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
      >
        <RoundButton size="sm" onClick={this.delete}>
          <FontAwesomeIcon icon={faTimes} />
        </RoundButton>
      </BasicModal>
    )
  }
}

const Heading = styled('h3')`
  margin: 2rem 0 1rem;
`

class ProgramCamppusMappingUpdate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    programGroup: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
  }

  onSubmit = ({ formData }) => {
    const { programGroup } = this.props
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/programGroups/update', {
          programGroupId: programGroup._id,
          doc: formData,
        })
        const { location, history, alert } = this.props
        alert.show('Program Group saved')
        redirect({
          location,
          history,
          pathname: `/organization/programGroups`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e.message })
        throw new Error('UpdateProgram', e.message)
      }
    })
  }

  render() {
    const { company, programGroup } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[
            { label: company.name, path: '/organization' },
            {
              label: 'Program Groups',
              path: `/organization/programGroups`,
            },
          ]}
          title={`Edit ${programGroup.name}`}
        />
        <div className="content">
          <Panel>
            <ProgramGroupForm
              program={programGroup}
              onSubmit={this.onSubmit}
              isLoading={this.state.isLoading}
              company={company}
              buttonLabel="Save Changes"
              error={this.state.error}
            />
          </Panel>

          <Heading>Campuses</Heading>
          <PanelList>
            {(!programGroup.campuses || programGroup.campuses.length === 0) && (
              <PanelListItem className="text-muted">No campuses defined</PanelListItem>
            )}
            {programGroup.campuses
              ?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              })
              .map((campus) => (
                <PanelListItem key={campus._id}>
                  <div className="row align-items-center flex-nowrap">
                    <div className="col">
                      <Link
                        className="panel-list-item-title"
                        to={`/organization/programGroups/${programGroup._id}/campus/${campus._id}`}
                      >
                        {campus.name}
                      </Link>
                    </div>
                    <div className="d-none d-md-flex col-auto buttons">
                      <RoundButton
                        size="sm"
                        tag={Link}
                        to={`/organization/programGroups/${programGroup._id}/campus/${campus._id}`}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </RoundButton>
                      <DeleteCampus campus={campus} programGroup={programGroup} />
                    </div>
                  </div>
                </PanelListItem>
              ))}
          </PanelList>
          <NewButton
            label="New Campus"
            tag={Link}
            center
            to={`/organization/programGroups/${programGroup._id}/newCampus`}
          />

          <Heading>Programs</Heading>
          <PanelList>
            {(!programGroup.programs || programGroup.programs.length === 0) && (
              <PanelListItem className="text-muted">No programs defined</PanelListItem>
            )}
            {programGroup.programs
              ?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1
                }
                if (a.name > b.name) {
                  return 1
                }
                return 0
              })
              .map((program) => (
                <PanelListItem key={program._id}>
                  <div className="row align-items-center flex-nowrap">
                    <div className="col">
                      <Link
                        className="panel-list-item-title"
                        to={`/organization/programGroups/${programGroup._id}/program/${program._id}`}
                      >
                        {program.name}
                      </Link>
                    </div>
                    <div className="d-none d-md-flex col-auto buttons">
                      <RoundButton
                        size="sm"
                        tag={Link}
                        to={`/organization/programGroups/${programGroup._id}/program/${program._id}`}
                      >
                        <FontAwesomeIcon icon={faPencil} />
                      </RoundButton>
                      <DeleteProgram program={program} programGroup={programGroup} />
                    </div>
                  </div>
                </PanelListItem>
              ))}
          </PanelList>
          <NewButton
            label="New Program"
            tag={Link}
            center
            to={`/organization/programGroups/${programGroup._id}/newProgram`}
          />
        </div>
      </React.Fragment>
    )
  }
}

ProgramCamppusMappingUpdate.propTypes = {
  company: PropTypes.object.isRequired,
  programGroup: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

const ProgramCamppusMappingUpdateWithAlert = withAlert()(ProgramCamppusMappingUpdate)

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="programGroup"
      cache={shortCache}
      query={{
        _id: props.match.params.programGroupId,
      }}
      showLoader={true}
    >
      {({ data }) => {
        const { program_groups: programGroups } = data
        if (!programGroups || programGroups.length === 0) {
          return <NotFound />
        }
        return <ProgramCamppusMappingUpdateWithAlert programGroup={programGroups[0]} {...props} />
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
