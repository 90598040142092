import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import apiFetch from '../../../../_shared/api/apiFetch'

class FlagValidModal extends React.PureComponent {
  static propTypes = {
    response: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  callMethod = async () => {
    try {
      await apiFetch('POST', '/responses/valid', { responseId: this.props.response._id })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Flag as valid"
        message={<div>Are you sure you want to flag this result as valid?</div>}
        confirmLabel="Flag as valid"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faCheckCircle} /> Flag as valid
      </BasicModal>
    )
  }
}

export default FlagValidModal
