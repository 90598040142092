import React from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import { FormGroup } from 'reactstrap'
import { FormLabel } from '../../../../../_shared/components/Form/helpers'
const ToneSelectorButtonGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); 

  @media (width < 1200px) {
    grid-template-columns: repeat(1, 1fr); 
  }
  @media (width > 1600px) {
    grid-template-columns: repeat(4, 1fr); 
  }

  .btn-alt {
    width: 100%;
    padding: 0.75rem 1.25rem;
  }

  .btn-alt:not(:disabled):not(.disabled):active,
  .btn-alt:not(:disabled):not(.disabled).active {
    background: var(--primary);
    color: #fff;
    border-color: var(--primary);
  }
`;

interface ToneSelectorProps {
  selectedTone: string
  setSelectedTone: (tone: string) => void
  label: string
  name: string
}

const tones = ['expressive', 'amiable', 'analytical', 'driver']

const ToneSelector: React.FC<ToneSelectorProps> = ({
  selectedTone,
  setSelectedTone,
  label,
  name,
}) => {
  const handleButtonClick = (tone: string) => {
    setSelectedTone(tone)
  }

  return (
    <FormGroup>
      <FormLabel showLabel={true} id="selectedTone" name={name} label={label} />
      <br/>
      <ToneSelectorButtonGroup className="btn-group" role="group" aria-label="Select Tone">
        {tones.map((tone) => (
          <Button
            key={tone}
            className="btn-alt" // This class name aligns with your styled-components CSS
            onClick={() => handleButtonClick(tone)}
            active={selectedTone === tone}
          >
            {tone.charAt(0).toUpperCase() + tone.slice(1)}
          </Button>
        ))}
      </ToneSelectorButtonGroup>
    </FormGroup>
  )
}

export default ToneSelector
