import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-regular-svg-icons/faEye'
import PageActions, { PageAction } from '../../components/PageActions'
import StepIndex from '../../views/step/StepIndex'
import ReportIndex from '../../views/report/ReportIndex'
import PathwayNotificationIndex from '../../views/notification/NotificationIndex'
import PathwaySettings from '../../views/pathway/PathwaySettings'
import { PathwayManageDropdownOptions } from '../../views/pathway/PathwayManageDropdown'
import { Route, Switch } from 'react-router-dom'
import View from '../../components/View'
import GoalIndex from '../goal/GoalIndex'
import NotFound from '../NotFound'

const PathwayRoute = ({ component: Component, exact, pathway, ...rest }) => (
  <Route exact={exact} render={(p) => <Component {...p} pathway={pathway} {...rest} />} />
)

const PathwayView = (props) => {
  const { pathway, company } = props
  const routePrefix = props.routePrefix || ''

  let breadcrumbs
  if (props.routePrefix) {
    breadcrumbs = [
      {
        label: 'Pathway Templates',
        path: '/admin/pathwayTemplates',
      },
    ]
  } else {
    breadcrumbs = [{ label: 'Pathways', path: '/pathways' }]
  }
  return (
    <View layout="sidebar" title={props.pathway.name}>
      <PageHeader
        breadcrumbs={breadcrumbs}
        title={props.pathway.name}
        navItems={[
          { label: 'Steps', route: `${routePrefix}/pathways/${pathway._id}` },
          {
            label: 'Goals',
            route: `${routePrefix}/pathways/${pathway._id}/goals`,
          },
          {
            label: 'Summaries',
            route: `${routePrefix}/pathways/${pathway._id}/summaries`,
          },
          {
            label: 'Notifications',
            route: `${routePrefix}/pathways/${pathway._id}/notifications`,
            exact: false,
          },
          {
            label: 'Settings',
            route: `${routePrefix}/pathways/${pathway._id}/settings`,
            exact: false,
          },
        ]}
      >
        <PageActions>
          <PageAction
            button
            toolbar
            tag="a"
            tagProps={{
              href: `https://${pathway.getPrimaryURL()}`,
              target: '_blank',
              noopener: 'noopener',
              noreferrer: 'noreferrer',
            }}
          >
            <FontAwesomeIcon icon={faEye} /> View
          </PageAction>
          <PageAction tag="div">
            <PathwayManageDropdownOptions pathway={pathway} company={company} deleteRedirect="/pathways" />
          </PageAction>
        </PageActions>
      </PageHeader>

      <div className="content">
        <Switch>
          <PathwayRoute
            path={`${routePrefix}/pathways/:pathwayId`}
            exact
            component={StepIndex}
            pathway={pathway}
          />
          <PathwayRoute
            path={`${routePrefix}/pathways/:pathwayId/summaries`}
            exact
            component={ReportIndex}
            pathway={pathway}
          />
          <PathwayRoute
            path={`${routePrefix}/pathways/:pathwayId/notifications`}
            component={PathwayNotificationIndex}
            pathway={pathway}
          />
          <PathwayRoute
            path={`${routePrefix}/pathways/:pathwayId/settings`}
            component={PathwaySettings}
            pathway={pathway}
            company={company}
            routePrefix={routePrefix}
          />
          <PathwayRoute
            path={`${routePrefix}/pathways/:pathwayId/goals`}
            component={GoalIndex}
            pathway={pathway}
          />
          <NotFound />
        </Switch>
      </div>
    </View>
  )
}

PathwayView.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default PathwayView
