import React from 'react'
import KbLatest from '../../components/Dashboard/KbLatest'
import Panel from '../../components/Panel'
import { Row, Col } from 'reactstrap'
import PromotedBundles from '../../components/PromotedBundles'
import { DefaultOptions } from '../../components/Datepicker'
import { StyledStatisticsIndexContent } from '../statistics/StatisticsIndex'
import { useSelector } from 'react-redux'
import { AppState } from '../../types'
import { User } from '../../../../_shared/models/user/user.client'
import { Company } from '../../../../_shared/models/company/company.client'

interface Props {
  company: Company
}

const DefaultDashboard: React.FC<Props> = ({ company }) => {
  const user = useSelector<AppState, User>((state) => state.user)
  const dateRange = DefaultOptions.LAST_30_DAYS.dates()

  return (
    <Row>
      <Col xs="12" md="7">
        {user.canViewStatistics() && <StyledStatisticsIndexContent dateRange={dateRange} />}
      </Col>
      <Col xs="12" md="5">
        <PromotedBundles company={company} />
        <Panel>
          <h4>Latest Knowledgebase Updates</h4>
          <KbLatest />
        </Panel>
      </Col>
    </Row>
  )
}

export default DefaultDashboard
