import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../components/PageHeader'
import View from '../components/View'
import Panel, { PanelForm } from '../components/Panel'
import { Button } from 'reactstrap'
import { withAlert } from 'react-alert'
import { Meteor } from 'meteor/meteor'
import Form from '../../../_shared/components/Form'
import Input from '../../../_shared/components/Form/Input'
import FormError from '../../../_shared/components/Form/FormError'
import { connect } from 'react-redux'
import apiFetch from '../../../_shared/api/apiFetch'

const AccountSettingsSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    newPassword: {
      title: 'New Password (optional)',
      type: 'string',
    },
  },
  required: ['name'],
}

class AccountView extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    alert: PropTypes.object,
  }

  state = {
    error: null,
    isLoading: false,
  }

  onSubmit = ({ formData }) => {
    if (this.state.isLoading) {
      return
    }
    const { alert } = this.props
    this.setState(
      {
        isLoading: true,
        error: null,
      },
      async () => {
        try {
          await apiFetch('POST', '/users/updateAccount', {
            doc: formData,
          })
          this.setState({
            error: null,
            isLoading: false,
          })
          alert.show('Account updated')
        } catch (e) {
          this.setState({
            error: e,
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { error, isLoading } = this.state
    const { user } = this.props

    const formData = {
      name: user.profile?.name,
    }

    const title = 'Account Settings'
    return (
      <View title={title}>
        <PageHeader title={title} />
        <div className="content">
          <Panel>
            <PanelForm>
              <Form
                formData={formData}
                schema={AccountSettingsSchema}
                onValidSubmit={this.onSubmit}
              >
                <Input type="text" name="name" />
                <Input
                  type="password"
                  name="newPassword"
                  help="Leave blank if you don't want to change your password"
                />

                <hr />
                {error && <FormError>{error}</FormError>}
                <Button color="primary" type="submit" disabled={isLoading}>
                  Save Changes
                </Button>
              </Form>
            </PanelForm>
          </Panel>
        </div>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(withAlert()(AccountView))
