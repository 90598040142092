import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import PageActions, { PageAction } from '../../components/PageActions'
import UserForm from './UserForm'
import ResetPasswordModal from '../../components/modals/ResetPasswordModal'
import DeleteUserModal from '../../components/modals/DeleteUserModal'
import View from '../../components/View'
import NotFound from '../NotFound'
import { redirect } from '../../components/Link'
import { withAlert } from 'react-alert'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'
class UserUpdate extends React.Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  state = {
    isLoading: false,
    error: null,
  }

  docToForm(user) {
    return {
      name: user.profile.name,
      email: user.username,
      permissions: user.roles,
      campusView: user.campusView,
      campuses: user.campuses,
      monthlyReport: user.monthlyReport,
    }
  }

  updateUser = ({ formData }) => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/users/update', {
            userId: this.props.user._id,
            doc: formData,
          })
          this.setState({
            isLoading: false,
          })
          const { location, history } = this.props
          redirect({
            location,
            history,
            pathname: `/users`,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e,
          })
        }
      }
    )
  }

  render() {
    const { user } = this.props
    return (
      <View title={`Edit ${user.profile.name}`}>
        <PageHeader
          title={`${user.profile.name}`}
          breadcrumbs={[{ label: 'Users', path: '/users' }]}
        >
          <PageActions>
            {/* <PageAction toolbar button>
              <FontAwesomeIcon icon={faUserSecret} /> Impersonate
            </PageAction> */}
            <PageAction button>
              <ResetPasswordModal user={user} />
            </PageAction>
            <PageAction button>
              <DeleteUserModal user={user} redirect="/users" />
            </PageAction>
          </PageActions>
        </PageHeader>
        <div className="content">
          <Panel>
            <UserForm
              user={this.docToForm(user)}
              buttonLabel="Save Changes"
              onSubmit={this.updateUser}
              isUpdate
              error={this.state.error}
            />
          </Panel>
        </div>
      </View>
    )
  }
}

const UserUpdateWithAlert = withAlert()(UserUpdate)

const UserUpdateLoader = (props) => (
  <SubscriptionLoader
    resource="user"
    cache={shortCache}
    query={{ _id: props.match.params.userId, companyId: props.company._id }}
    showLoader={true}
  >
    {({ data }) => {
      const { users } = data
      if (!users || users.length === 0) {
        return <NotFound />
      }

      return <UserUpdateWithAlert {...props} user={data.users[0]} />
    }}
  </SubscriptionLoader>
)

export default UserUpdateLoader
