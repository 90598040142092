import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import PageHeader from '../../../components/PageHeader'
import { redirect } from '../../../components/Link'
import ProgramGroupForm from './ProgramGroupForm'
import apiFetch from '../../../../../_shared/api/apiFetch'

class ProgramGroupCreate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    company: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = ({ formData }) => {
    const { _id: companyId } = this.props.company
    if (this.state.isLoading) {
      return
    }

    formData.companyId = companyId

    this.setState({ isLoading: true }, async () => {
      try {
        const result = await apiFetch('POST', '/programGroups/create', {
          companyId: this.props.company._id,
          doc: formData,
        })
        const { location, history } = this.props
        redirect({
          location,
          history,
          pathname: `/organization/programGroups/${result.programGroupId}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { company } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[
            { label: company.name, path: '/organization' },
            { label: 'Programs Groups', path: `/organization/programGroups` },
          ]}
          title="New Program Group"
        />
        <div className="content">
          <Panel>
            <ProgramGroupForm
              program={{}}
              onSubmit={this.onSubmit}
              isLoading={this.state.isLoading}
              company={company}
              buttonLabel="Create Program Group"
              error={this.state.error}
            />
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

ProgramGroupCreate.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default ProgramGroupCreate
