export enum UserRoles {
  USER = 'user',
  STAFF = 'staff',
  ADMIN = 'admin',
  API = 'api',
  VIEW_RESULTS = 'view-results',
  VIEW_STATISTICS = 'view-statistics',
  MANAGE_USERS = 'manage-users',
  MANAGE_PATHWAYS = 'manage-pathways',
  MANAGE_ORGANIZATIONS = 'manage-organizations',
}

// todo: important! this is not compatible with meteor user format, services.password etc
export class User {
  _id: string
  username: string
  companyId: string
  roles: [UserRoles]

  constructor(props: NonFunctionProperties<User>) {
    Object.assign(this, props)
  }

  isAdmin(): boolean {
    return this.roles.includes(UserRoles.ADMIN)
  }

  isAPI(): boolean {
    return this.roles.includes(UserRoles.API)
  }

  canViewResults(): boolean {
    return this.isAdmin() || this.roles.includes(UserRoles.VIEW_RESULTS)
  }

  canViewStatistics(): boolean {
    return this.isAdmin() || this.roles.includes(UserRoles.VIEW_STATISTICS)
  }

  canManageUsers(): boolean {
    return this.isAdmin() || this.roles.includes(UserRoles.MANAGE_USERS)
  }

  canManagePathways(): boolean {
    return this.isAdmin() || this.roles.includes(UserRoles.MANAGE_PATHWAYS)
  }

  canManageOrganizations(): boolean {
    return this.isAdmin() || this.roles.includes(UserRoles.MANAGE_ORGANIZATIONS)
  }
}
