import React from 'react'
import styled from 'styled-components'
import Link from '../components/Link'

export interface Breadcrumb {
  path: string
  label: string
}

interface Props {
  breadcrumbs?: Breadcrumb[]
  className?: string
}

const Breadcrumbs: React.FC<Props> = (props) => {
  return (
    <ul className={props.className + ' breadcrumbs'}>
      {props.breadcrumbs &&
        props.breadcrumbs.map((crumb) => (
          <li key={crumb.path}>
            <Link to={crumb.path}>{crumb.label}</Link>
          </li>
        ))}
    </ul>
  )
}

export default styled(Breadcrumbs)`
  margin: 0 0 0.5rem 0;
  padding: 0;
  list-style: none;
  font-size: 0.875rem;

  &,
  a {
    color: var(--text-muted-blue);
    text-decoration: none;
    &:hover,
    &:focus {
      color: #2369a7;
    }
  }

  li {
    display: inline-block;
    &:after {
      position: static;
      margin: 0 0.5rem;
      display: inline-block;
      content: '/';
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }
`
