import React from 'react'
import styled from 'styled-components'
import Loader from '../Loader'
import { format, parseISO } from 'date-fns'
import apiFetch from '../../../../_shared/api/apiFetch'

const ArticleList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0 0 0;

  li {
    font-size: 0.9375rem;
    margin-bottom: 0.7em;
    p {
      margin-bottom: 0;
    }
  }
`

class KbLatest extends React.Component {
  state = {
    isLoading: true,
    error: false,
    data: null,
  }

  async componentDidMount() {
    try {
      const result = await apiFetch('GET', '/kbLatest')
      this.setState({
        isLoading: false,
        error: false,
        data: result,
      })
    } catch (e) {
      this.setState({
        isLoading: false,
        error: e.message,
        data: null,
      })
    }
  }

  render() {
    const { isLoading, error, data } = this.state

    if (error) {
      return <div className="alert alert-error">{error}</div>
    }

    if (isLoading) {
      return <Loader />
    }

    return (
      <ArticleList>
        {data.map((article, i) => (
          <li key={i}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://kb.enrollmentresources.com/books/${article.book_slug}/page/${article.slug}/`}
            >
              {article.name}
            </a>
            <p>{format(parseISO(article.updated_at.date), 'MMMM do yyyy')}</p>
          </li>
        ))}
      </ArticleList>
    )
  }
}

export default KbLatest
