import { applyDiff } from '../../pubsub/src/server/PubSub/store'
import { Company } from '../../_shared/models/company/company.client'
import { User } from '../../_shared/models/user/user.client'
import {
  AppState,
  ActionTypes,
  FINISH_LOGIN,
  BEGIN_LOGIN,
  UPDATE_SUBSCRIPTION,
  UPDATE_COMPANY,
  BEGIN_RECONNECTING,
  FINISH_RECONNECTING,
} from './types'
import ReactGA from 'react-ga'

const initialState: AppState = {
  user: null,
  isLoggingIn: false,
  subscriptions: {},
}

function rootReducer(state = initialState, action: ActionTypes): AppState {
  switch (action.type) {
    case 'UPDATE_USER':
      const user = new User(applyDiff<NonFunctionProperties<User>>(state.user, action.payload.diff))
      ReactGA.set({ userId: user._id })
      return {
        ...state,
        user,
      }
    case BEGIN_LOGIN:
      return {
        ...state,
        isLoggingIn: true,
      }
    case FINISH_LOGIN:
      return {
        ...state,
        isLoggingIn: false,
      }
    case UPDATE_SUBSCRIPTION:
      if (!action.key) {
        return
      }

      return {
        ...state,
        subscriptions: {
          ...state.subscriptions,
          [action.key]: { ready: action.subscription.ready, data: action.subscription.getStore() },
        },
      }
    case UPDATE_COMPANY:
      return {
        ...state,
        company: new Company(
          applyDiff<NonFunctionProperties<Company>>(state.company, action.payload.diff)
        ),
      }
    case BEGIN_RECONNECTING:
      return {
        ...state,
        isReconnecting: true,
      }
    case FINISH_RECONNECTING:
      return {
        ...state,
        isReconnecting: false,
      }
    default:
      return state
  }
}

export default rootReducer
