import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../../components/Panel'
import Form from '../../../../../_shared/components/Form'
import Input from '../../../../../_shared/components/Form/Input'
import FormError from '../../../../../_shared/components/Form/FormError'
import { Button } from 'reactstrap'
import PageHeader from '../../../components/PageHeader'
import { redirect } from '../../../components/Link'
import apiFetch from '../../../../../_shared/api/apiFetch'

const DomainFormSchema = {
  type: 'object',
  properties: {
    domain: {
      type: 'string',
    },
  },
  required: ['domain'],
}

class DomainCreate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { _id: companyId } = this.props.company
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/domains/create', { companyId, domain: data.formData.domain })
        const { location, history } = this.props
        redirect({
          location,
          history,
          pathname: `/organization/domains`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { company } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[
            { label: company.name, path: '/organization' },
            { label: 'Domains', path: `/organization/domains` },
          ]}
          title="New Domain"
        />
        <div className="content">
          <Panel>
            <Form formData={{}} schema={DomainFormSchema} onValidSubmit={this.onSubmit}>
              <PanelForm center={false}>
                <Input type="text" name="domain" help="e.g. va.domain.com" />

                <hr />
                {this.renderError()}

                <Button color="primary" type="submit" disabled={this.state.isLoading}>
                  Create Domain
                </Button>
              </PanelForm>
            </Form>
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

DomainCreate.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default DomainCreate
