import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import UserActivity from '../../../components/UserActivity'
import Loader from '../../../components/Loader'
import PathwayGoalsContainer from './PathwayGoals'
import { CampaignOverviewContainer } from '../components/StatOverview'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { Result } from 'postcss'

class PathwayUserActivity extends React.PureComponent {
  state = {
    isLoadingActivity: true,
    userActivity: null,
  }

  static propTypes = {
    dateRange: PropTypes.array.isRequired,
    pathway: PropTypes.object.isRequired,
    filter: PropTypes.object,
  }

  loadUserActivity = async () => {
    const { match, pathway, dateRange, filter } = this.props
    const start = dateRange[0]
    const end = dateRange[1]

    // load user activity
    try {
      const result = await apiFetch(
        'GET',
        '/statistics/userActivity',
        {},
        {
          pathwayId: match.params.pathwayId,
          start,
          end,
          filter,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        true
      )

      let registrations = 0
      let completions = 0
      let averageLength = 0
      let lengthSum = 0

      result.aggregateData.forEach((day) => {
        registrations += day.registrations
        completions += day.completions
        lengthSum += day.avg_time
      })

      averageLength = lengthSum / result.aggregateData.length
      averageLength = Math.round(averageLength / 1000 / 60)

      let registrationRate = Math.round((registrations / result.visitors) * 100)
      if (isNaN(registrationRate)) {
        registrationRate = 0
      }
      registrationRate += '%'

      let completionRate = Math.round((completions / registrations) * 100)
      if (isNaN(completionRate)) {
        completionRate = 0
      }
      completionRate += '%'

      this.setState({
        isLoadingActivity: false,
        userActivity: {
          data: result.aggregateData,
          visitors: result.visitors,
          registrations,
          registrationRate,
          completions,
          completionRate,
          averageLength,
        },
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  componentDidMount() {
    this.loadUserActivity()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter || prevProps.dateRange !== this.props.dateRange) {
      this.loadUserActivity()
    }
  }

  render() {
    const { pathway, dateRange } = this.props
    return (
      <Panel>
        {this.state.isLoadingActivity ? (
          <Loader />
        ) : (
          <UserActivity
            userActivity={this.state.userActivity}
            dateRange={dateRange}
            pathway={pathway}
          />
        )}

        {pathway.goals && (
          <PathwayGoalsContainer pathway={pathway} start={dateRange[0]} end={dateRange[1]} />
        )}
      </Panel>
    )
  }
}

export default PathwayUserActivity
