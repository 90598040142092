import React from 'react'
import { Sidebar, SidebarSection, SidebarLink } from './Sidebar'
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faChartPie } from '@fortawesome/pro-solid-svg-icons/faChartPie'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { faRoute } from '@fortawesome/pro-solid-svg-icons/faRoute'
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons/faBuilding'
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink'
import { faBookOpen } from '@fortawesome/pro-solid-svg-icons/faBookOpen'
import { faTools } from '@fortawesome/pro-solid-svg-icons/faTools'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
import { useSelector } from 'react-redux'
import { AppState } from '../types'
import { Company } from '../../../_shared/models/company/company.client'
import { User } from '../../../_shared/models/user/user.client'

interface Props {
  id: string
  className?: string
  isOpen: boolean
}

const UserSidebar: React.FC<Props> = (props) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => (state.subscriptions.companyContext.data.companies[0] as Company) as Company
  )
  const user = useSelector<AppState, User>((state) => state.user)
  return (
    <Sidebar {...props}>
      <SidebarSection title="Menu">
        <SidebarLink icon={faHome} route="/" exact>
          Dashboard
        </SidebarLink>
        <SidebarLink icon={faAddressCard} route="/results">
          Results
        </SidebarLink>
        <SidebarLink icon={faUsers} route="/people">
          People
        </SidebarLink>
        {user?.canViewStatistics() && (
          <SidebarLink icon={faChartPie} route="/statistics">
            Statistics
          </SidebarLink>
        )}
      </SidebarSection>
      {(user?.canManagePathways() || user.canManageUsers()) && (
        <SidebarSection title="Manage">
          {user?.canManagePathways() && (
            <React.Fragment>
              <SidebarLink icon={faRoute} route="/pathways">
                Pathways
              </SidebarLink>
              <SidebarLink icon={faLink} route="/integrations">
                Integrations
              </SidebarLink>
            </React.Fragment>
          )}
          {selectedCompany?.isAgency() && (
            <SidebarLink icon={faBuilding} route="/organizations">
              Organizations
            </SidebarLink>
          )}
          {user?.canManageUsers() && (
            <SidebarLink icon={faUserFriends} route="/users">
              Users
            </SidebarLink>
          )}
          {user?.canManagePathways() && (
            <SidebarLink icon={faCog} route="/organization">
              Account
            </SidebarLink>
          )}
        </SidebarSection>
      )}
      <SidebarSection title="Help">
        <SidebarLink
          icon={faBookOpen}
          to="https://kb.enrollmentresources.com/magiclink/2:umwYfwM9JQ5l6l8879jKBCqKZntv44agMEtGZhAPyHNo0iunWw1m5945RtoNCYRC"
          external
        >
          Knowledge Base
        </SidebarLink>
        {/* <SidebarLink icon={faQuestionCircle} route="/support">
          Support
        </SidebarLink> */}
      </SidebarSection>
      <SidebarSection bottom>
        {user?.isAdmin() && (
          <SidebarLink icon={faTools} route="/admin">
            Admin
          </SidebarLink>
        )}

        <SidebarLink icon={faSignOut} route="/logout">
          Logout
        </SidebarLink>
      </SidebarSection>
    </Sidebar>
  )
}

export default UserSidebar
