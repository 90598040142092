import React from 'react'
import styled from 'styled-components'
import AiDisclaimer from './components/AiDisclaimer'
import HelpTooltip from '../../../components/HelpTooltip'

interface Props {
  className?: string
  children?: any
  title: string
  aiDisclaimer?: boolean
  helpTitle?: string
  helpDescription?: string | JSX.Element
}

const PersonaPanel: React.FC<Props> = ({
  className,
  children,
  title,
  aiDisclaimer,
  helpTitle,
  helpDescription,
}) => {
  return (
    <div className={className}>
      <div className="persona-panel-head">
        {aiDisclaimer && <AiDisclaimer />}
        <h4 className="persona-panel-title">
          {title}
          {helpTitle && <HelpTooltip title={helpTitle} description={helpDescription} />}
        </h4>
      </div>
      <div className="persona-panel-content">{children}</div>
    </div>
  )
}

export default styled(PersonaPanel)`
  background: #fff;
  margin: 0 0 2rem 0;
  border-radius: 4px;

  padding: 1.25rem;
  .persona-panel-title {
    color: #2d2d2d;
    font-weight: 600;
    font-size: 1.125rem;
    margin: 0 0 1rem 0;
  }
  .persona-panel-content {
  }
`
