import React from 'react'
import InputGroup from './InputGroup'
import styled from 'styled-components'
import AutosizeInput from 'react-input-autosize'

const StyledURLInput = styled.div`
  .input-group-inner {
    display: inline-block;
    border-radius: 4px;
    &:focus-within {
      * {
        border-color: #5cacf6;
        transition: border-color 0.15s ease-in-out;
      }
      box-shadow: 0 0 0 0.2rem rgba(11, 109, 199, 0.25);
      transition: box-shadow 0.15s ease-in-out;
    }
  }
  .input-group-text {
    background: none;
    color: var(--text-muted);
  }
  .input-group-prepend .input-group-text {
    padding-right: 0;
  }
  .input-group-append .input-group-text {
    border-left: 0;
    padding-left: 0;
  }
  input {
    height: calc(2.5rem + 2px);
    padding: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0;
    padding-right: 0;
    padding-left: 0;
    border-left: 0;
    border-right: 0;
    min-width: 100px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
`

const URLInput = (props) => {
  return (
    <StyledURLInput>
      <InputGroup type="text" tag={AutosizeInput} {...props} />
    </StyledURLInput>
  )
}

export default URLInput
