import React from 'react'
import WidthOptions from './Width/WidthOptions'
import HeightOptions from './Height/HeightOptions'
import AlignmentOptions from './Alignment/AlignmentOptions'
import MarginOptions from './Margin/MarginOptions'
import PaddingOptions from './Padding/PaddingOptions'
import VisibilityOptions from './Visibility/VisibilityOptions'

const ContentAppearanceSettings = (props) => (
  <React.Fragment>
    <VisibilityOptions path="visibility" />
    <WidthOptions path="appearance" />
    <HeightOptions path="appearance" />
    <AlignmentOptions path="appearance" />
    <MarginOptions path="appearance" />
    <PaddingOptions path="appearance" />
  </React.Fragment>
)

export default ContentAppearanceSettings
