import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { PersonaColors } from '../PersonaColors'

interface BarChartItem {
  label: string
  value: number
  valuePercentage: number
  color?: string
  image?: string
}

interface Props {
  className?: string
  label?: string | ReactNode
  items: BarChartItem[]
}

const PersonaHorizontalBarChart: React.FC<Props> = ({ className, label, items = [] }) => {
  return (
    <div className={className}>
      {label && <div className="bar-chart-label">{label}</div>}
      <ul className="bar-chart">
        {items.map((item, index) => {
          // If the item label is an empty string, render a 'space' component
          if (item.label === '') {
            return <li style={{ height: '20px' }} key={index} /> // Adjust the height as needed
          }

          // Otherwise, render your normal bar chart component
          return (
            <li className="bar-chart-item" key={index}>
              <div className="bar-chart-item-header">
                <div className="bar-chart-item-label">{item.label}</div>
                <div className="bar-chart-item-value">
                  {item.valuePercentage}% ({item.value.toLocaleString()})
                </div>
              </div>
              <div className="bar-chart-bar-track">
                <div
                  className="bar-chart-bar"
                  style={{ width: item.valuePercentage + '%', backgroundColor: item.color }}
                ></div>
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default styled(PersonaHorizontalBarChart)`
  margin: 0 0 2rem 0;
  &:last-child {
    margin-bottom: 0;
  }

  font-size: 0.875rem;

  .bar-chart-label {
    color: #505050;
    font-weight: 600;
    margin: 0 0 0.4em 0;
    font-size: 1rem;
  }
  .bar-chart {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .bar-chart-item {
    margin: 0 0 1em 0;
  }
  .bar-chart-item-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 0 0 0.4em 0;
  }
  .bar-chart-item-label {
    color: #585858;
  }
  .bar-chart-item-value {
    font-weight: 600;
    color: #2d2d2d;
  }
  .bar-chart-bar-track {
    height: 8px;
    border-radius: 3px;
    background: #e9e9e9;
  }
  .bar-chart-bar {
    background: #0486fd;
    height: 100%;
    border-radius: 3px;
  }
`
