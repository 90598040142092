import React from 'react'
import PropTypes from 'prop-types'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'

class MobileOptionsWrapper extends React.Component {
  static propTypes = {
    controls: PropTypes.any,
    path: PropTypes.string,
  }

  state = {
    activeTab: 'desktop',
  }

  toggle = activeTab => {
    this.setState({ activeTab })
  }

  render() {
    const { controls: Controls, path } = this.props
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'desktop',
              })}
              onClick={() => {
                this.toggle('desktop')
              }}
            >
              Desktop
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'tablet',
              })}
              onClick={() => {
                this.toggle('tablet')
              }}
            >
              Tablet
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab === 'mobile',
              })}
              onClick={() => {
                this.toggle('mobile')
              }}
            >
              Mobile
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="desktop">
            <Controls path={path} device="desktop" />
          </TabPane>
          <TabPane tabId="mobile">
            <Controls path={path} device="mobile" />
          </TabPane>
          <TabPane tabId="tablet">
            <Controls path={path} device="tablet" />
          </TabPane>
        </TabContent>
      </React.Fragment>
    )
  }
}

export default MobileOptionsWrapper
