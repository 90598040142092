import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons/faAsterisk'
import Panel, { PanelTable } from '../../components/Panel'
import styled from 'styled-components'
import View from '../../components/View'
import FieldModel from '../../../../pagebuilder/blocks/Field'

const RequiredIcon = styled(FontAwesomeIcon)`
  color: var(--danger);
`

const Field = (props) => {
  const { field } = props
  return (
    <tr key={field._id}>
      <td>{field.isRequired ? <strong>{field.key}</strong> : field.key}</td>
      <td>{field.label}</td>
      <td>{field.isRequired ? <RequiredIcon icon={faAsterisk} size="xs" /> : ''}</td>
      <td>
        <code>String</code>
      </td>
    </tr>
  )
}

const StyledStep = styled.div`
  h3 {
    margin: 0;
  }
  .badge {
    font-weight: 600;
  }
  .row {
    margin-bottom: 1rem;
  }
  hr {
    margin: 1rem;
  }
`

const Step = (props) => {
  const { step } = props
  let fields = []
  step.forEachBlock((block) => {
    if (block instanceof FieldModel) {
      fields.push({
        _id: block.id,
        key: block.data.advanced?.name || block.id,
        label: block.data.config?.label,
        isRequired: block.isRequired && block.isRequired(),
      })
    }
  })
  fields.sort((f) => f.isRequired)
  return (
    <StyledStep>
      <div className="row align-items-center">
        <div className="col-auto">
          <h3>{step.name}</h3>
        </div>
        <div className="col">
          <span className="badge badge-secondary">ID: {step._id}</span>
        </div>
      </div>
      {fields.length > 0 ? (
        <PanelTable>
          <thead>
            <tr>
              <th width="130">Field</th>
              <th width="300">Label</th>
              <th width="110">Required</th>
              <th>Accepted Values</th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field) => (
              <Field key={field._id} field={field} />
            ))}
          </tbody>
        </PanelTable>
      ) : (
        'This step has no fields.'
      )}
      <br />
      <hr />
      <br />
    </StyledStep>
  )
}

const PathwayAPI = (props) => {
  const { pathway } = props
  const title = 'API Documentation'
  return (
    <View layout="sidebar" title={title}>
      <PageHeader
        breadcrumbs={[
          { label: 'Pathways', path: '/pathways' },
          { label: pathway.name, path: `/pathways/${pathway._id}` },
        ]}
        title={title}
      />
      <div className="content">
        <Panel>
          <h2>HTTP Submissions</h2>
          <p>You can send submissions to this pathway from any third party system via HTTP.</p>
          <br />
          <h4>Parameters</h4>
          <PanelTable striped borderless responsive>
            <tbody>
              <tr>
                <td width="150" className="th">
                  Request URL:
                </td>
                <td>
                  <code>
                    https://
                    {pathway.getPrimaryURL()}
                  </code>
                </td>
              </tr>
              <tr>
                <td className="th">HTTP Method:</td>
                <td>
                  <code>POST</code>
                </td>
              </tr>

              <tr>
                <td className="th">Content-Type:</td>
                <td>
                  <code>application/x-www-form-urlencoded</code> or <code>application/json</code>
                </td>
              </tr>
            </tbody>
          </PanelTable>
          <br />
          <h4>Optional Parameters</h4>
          <PanelTable striped borderless responsive>
            <thead>
              <tr>
                <th width="150">Field</th>
                <th>Description</th>
                <th>Accepted Values</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>_redirectUrl</strong>
                </td>
                <td>
                  The website that the user should be redirected to after a successful submission.
                  Omitting this value will redirect to the next step in the pathway.
                </td>
                <td>
                  Must be a valid URL, beginning with <code>http://</code> or <code>https://</code>.
                  Must be shorter than 500 characters.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>_isTest</strong>
                </td>
                <td>Indicates whether or not this submission is a test.</td>
                <td>
                  <code>true</code> or <code>1</code> will indicate a test. <code>false</code>,{' '}
                  <code>0</code> or omitting this field will indicate a real submission. Test
                  submissions are excluded from statistics and appear watermarked in the UI.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>_stepId</strong>
                </td>
                <td>
                  Indicates the step the submission is for. If this value is omitted, the first
                  active step will be used.
                </td>
                <td>Must be a valid step ID.</td>
              </tr>
              {/* <tr>
              <td>
                <strong>_token</strong>
              </td>
              <td>
                The user&apos;s login token when proxying requests through other
                applications.
              </td>
              <td>Must be a valid authentication token.</td>
            </tr> */}
            </tbody>
          </PanelTable>
          <br />
          <h2>Steps</h2>
          <hr />
          {pathway.getSteps().map((step) => (
            <Step key={step._id} step={step} />
          ))}
        </Panel>
      </div>
    </View>
  )
}

PathwayAPI.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default PathwayAPI
