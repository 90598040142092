import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import Panel, { PanelForm } from '../../components/Panel'
import IntegrationAdapters from '../../../../_shared/models/IntegrationAdapters/IntegrationAdapters'
import { redirect } from '../../components/Link'
import media from '../../css/_media'
import styled from 'styled-components'
import { FormGroup, Button, Input as StrapInput } from 'reactstrap'
import remove from 'lodash/remove'
import View from '../../components/View'
import { withAlert } from 'react-alert'
import { useSelector } from 'react-redux'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import FormError from '../../../../_shared/components/Form/FormError'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import NotFound from '../NotFound'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'

const FormSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    config: {
      type: 'object',
    },
    duplicates: {
      type: 'string',
    },
    stepTriggers: {
      title: 'Steps',
      type: 'array',
    },
    eventTriggers: {
      title: 'Events',
      type: 'array',
    },
  },
  required: ['name', 'config', 'duplicates'],
  additionalProperties: false,
}

const SideForm = styled.div`
  margin: 1rem 0;
  max-width: 600px;
  color: var(--text-muted);
  @media (min-width: ${media.xl}) {
    height: 100%;
    margin: 0 0 0 2rem;
    margin-bottom: 0;
    padding-left: 3rem;
    border-left: 1px solid var(--border-color);
  }
`
export class NotificationUpdate extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired,
    integration: PropTypes.object,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    mock: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    const doc = this.formToDoc(data.formData)

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/notifications/update', {
          notificationId: this.props.notification._id,
          doc,
        })
        const { location, history } = this.props
        this.props.alert.show('Notification saved')
        redirect({
          location,
          history,
          pathname: `/pathways/${this.props.pathway._id}/notifications`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  docToForm(doc) {
    const triggers = doc.triggers?.slice(0) || []
    const stepTriggers = remove(triggers, (t) => t.type === 'submitStep').map((t) => t.target)

    return {
      name: doc.name,
      config: doc.config || {},
      duplicates: doc.duplicates,
      stepTriggers: stepTriggers,
      eventTriggers: triggers ? triggers.map((t) => t.type) : [],
    }
  }

  formToDoc(data) {
    const formData = Object.assign({}, data)
    const triggers = []
    formData.stepTriggers?.forEach((t) => triggers.push({ type: 'submitStep', target: t }))
    formData.eventTriggers?.forEach((t) => triggers.push({ type: t }))

    return {
      _id: this.props.notification._id,
      name: formData.name,
      config: formData.config,
      duplicates: formData.duplicates,
      triggers,
    }
  }

  getSchema() {
    const adapter = IntegrationAdapters.getAdapter(this.props.notification.type)
    if (adapter) {
      const schema = Object.assign({}, FormSchema)
      Object.assign(schema.properties, { config: adapter.schema })
      schema.required = ['name', 'config', 'duplicates']
      return schema
    } else {
      return FormSchema
    }
  }

  renderIntegration() {
    const { notification, integration } = this.props
    if (integration) {
      return (
        <FormGroup className="form-group">
          <label>Integration</label>
          <StrapInput type="text" value={integration.name} disabled={true} readOnly={true} />
        </FormGroup>
      )
    }
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway, notification, integration } = this.props
    const adapter = IntegrationAdapters.getAdapter(notification.type)

    return (
      <View title={`Edit ${notification.name} - ${pathway.name}`}>
        <PageHeader
          breadcrumbs={[
            {
              label: pathway.name,
              path: `/pathways/${pathway._id}/notifications`,
            },
          ]}
          title={notification.name}
        />
        <div className="content">
          <Panel>
            <Form
              formData={this.docToForm(notification)}
              schema={this.getSchema()}
              onValidSubmit={this.onSubmit}
            >
              <PanelForm center={false}>
                <Input type="text" name="name" />
                {!adapter && this.renderIntegration()}
              </PanelForm>

              {adapter && (
                <div>
                  {adapter.renderForm(pathway)}
                  <hr className="breathe" />
                </div>
              )}

              {integration?.adapter?.renderPartialForm && (
                <div>
                  {integration.adapter.renderPartialForm(pathway)}
                  <hr className="breathe" />
                </div>
              )}

              <h3>Triggers</h3>
              <p>When should the notification be sent?</p>
              <div className="row">
                <div className="col-lg-5">
                  <Cardbox
                    thin
                    vertical
                    multiple
                    name="stepTriggers"
                    help={
                      <span>
                        Trigger when the following steps are <strong>submitted</strong>:
                      </span>
                    }
                  >
                    {pathway.getSteps().map((step) => (
                      <CardboxItem key={step._id} value={step._id} title={step.name} />
                    ))}
                  </Cardbox>
                </div>
                <div className="col-lg-7">
                  <SideForm>
                    <Cardbox
                      thin
                      vertical
                      multiple
                      name="eventTriggers"
                      help={<span>Trigger when the following events occur:</span>}
                    >
                      <CardboxItem
                        value="completePathway"
                        title="Complete Pathway"
                        image="/images/goal.png"
                        description="Occurs when a user reaches the last step in a pathway"
                      />
                      <CardboxItem
                        value="abandonPathway"
                        title="Abandon Pathway"
                        image="/images/abandon.png"
                        description="Occurs when a user leaves a pathway before reaching the last step"
                      />
                      <CardboxItem
                        value="resumePathway"
                        title="Resume Pathway"
                        image="/images/resume.png"
                        description="Occurs when a user returns to a pathway they previously abandoned"
                      />
                    </Cardbox>
                  </SideForm>
                </div>
              </div>

              <hr />

              <Cardbox name="duplicates" thin>
                <CardboxItem
                  value="allow"
                  title="Allow Duplicates"
                  description="Can trigger more than once for a single person"
                />
                <CardboxItem
                  value="avoid"
                  title="Avoid Duplicates"
                  description="Can only trigger once for a single person within 30 days"
                />
              </Cardbox>

              {this.renderError()}

              <hr />
              <Button size="lg" color="primary" type="submit" disabled={this.state.isLoading}>
                Save Changes
              </Button>
            </Form>
          </Panel>
        </div>
      </View>
    )
  }
}

const NotificationUpdateWithAlert = withAlert()(NotificationUpdate)

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  const selectedCompany = useSelector(
    (state) => state.subscriptions.companyContext.data.companies[0]
  )

  return (
    <SubscriptionLoader
      resource="notification"
      cache={shortCache}
      query={{
        _id: props.match.params.notificationId,
        companyId: selectedCompany._id,
      }}
      showLoader={true}
    >
      {({ data }) => {
        const { integrations, notifications } = data
        if (!notifications || notifications.length === 0) {
          return <NotFound />
        }

        const integration = integrations ? integrations[0] : null

        return (
          <NotificationUpdateWithAlert
            integration={integration}
            notification={notifications[0]}
            {...props}
          />
        )
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
