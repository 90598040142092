import React from 'react'
import PropTypes from 'prop-types'
import View from '../../components/View'
import PageHeader from '../../components/PageHeader'
import CampaignForm from './CampaignForm'
import { withRouter } from 'react-router-dom'
import { redirect } from '../../components/Link'
import NotFound from '../NotFound'
import { withAlert } from 'react-alert'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'

function breadcrumbs(props) {
  return [{ label: 'Pathways', path: '/pathways' }]
}

class CampaignUpdate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      error: false,
    }
  }

  static propTypes = {
    campaign: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { location, history, campaign, alert } = this.props

    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/camapigns/update', {
          campaignId: campaign._id,
          doc: data.formData,
        })
        alert.show('Campaign saved')
        redirect({
          location,
          history,
          pathname: '/pathways',
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { campaign } = this.props
    const title = 'Edit Campaign'
    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={breadcrumbs(this.props)} />
        <div className="content">
          <CampaignForm
            formData={campaign}
            onSubmit={this.onSubmit}
            isLoading={this.state.isLoading}
            buttonLabel="Save Changes"
          />
        </div>
      </View>
    )
  }
}
CampaignUpdate.propTypes = {
  mock: PropTypes.object,
  selectedCompany: PropTypes.object.isRequired,
}

const CampaignUpdateWithRouter = withAlert()(withRouter(CampaignUpdate))

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="campaign"
      cache={shortCache}
      query={{ _id: props.match.params.campaignId }}
      showLoader={true}
    >
      {({ data }) => {
        const { campaigns } = data
        if (!campaigns || campaigns.length === 0) {
          return <NotFound />
        }

        return <CampaignUpdateWithRouter campaign={campaigns[0]} />
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
