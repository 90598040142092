export default class Campaign {
  _id?: string
  name: string
  companyId: string
  pathways?: string[]
}

export const GlobalCampaigns = {
  website: {
    _id: 'website',
    name: 'Website',
  },
  landingpages: {
    _id: 'landingpages',
    name: 'Landing Pages',
  },
  admissions: {
    _id: 'admissions',
    name: 'Admissions',
  },
  retention: {
    _id: 'retention',
    name: 'Retention',
  },
  other: {
    _id: 'other',
    name: 'Other Pathways',
  },
  archive: {
    _id: 'archive',
    name: 'Archive',
  },
}
