import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import { Company } from '../../../_shared/models/company/company.client'
import { User } from '../../../_shared/models/user/user.client'
import { longCache } from '../api/subscriptionCache'
import { AppState } from '../types'
import NotFound from '../views/NotFound'
import SubscriptionLoader from './SubscriptionLoader'

const CompanyContext: React.FC = (props) => {
  const { children } = props

  const user = useSelector<AppState, User>((state) => state.user)
  const location = useLocation()

  const query = new URLSearchParams(location.search).get('a')

  if (!query) {
    return <Redirect to={{ ...location, search: '?a=' + user.companyId }} />
  }

  return (
    <SubscriptionLoader
      resource="company"
      showLoader={true}
      subKey="companyContext"
      query={{ _id: query }}
    >
      {({ data, subscription }) => {
        if (subscription.ready && !data.companies) {
          // todo: why doesnt this render a 404? (because we lose the entire UI)
          return <Redirect to={{ ...location, search: '?a=' + user.companyId }} />
        }
        return children
      }}
    </SubscriptionLoader>
  )
}

export default CompanyContext
