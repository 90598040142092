import React from 'react'
import styled from 'styled-components'
import { Persona } from '../../../../../_shared/models/persona/persona.types'
import { PersonaColors } from './PersonaColors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'
import Link from '../../../components/Link'
import HelpTooltip from '../../../components/HelpTooltip'

interface PersonaFunnelColumnProps {
  label: string
  value: number
  total?: number
  nextFunnelValue?: number
  customPercentage?: number
  index: number
  tooltip?: { title: string; description: string | JSX.Element }
}

const PersonaFunnelColumn: React.FC<PersonaFunnelColumnProps> = ({
  label,
  value,
  total,
  nextFunnelValue = 0,
  customPercentage,
  index,
  tooltip,
}) => {
  const startY = 300 - (value / total) * 300
  const bezierOneY = Math.max(0, startY)
  const bezierTwoY = Math.min(300, startY + 50)
  const endY = 300 - (nextFunnelValue / total) * 300

  return (
    <div className="funnel-column">
      <div className="funnel-column-label">
        <div className="funnel-column-label-text">{label}</div>
        {tooltip && <HelpTooltip title={tooltip.title} description={tooltip.description} />}
      </div>
      <div className="funnel-column-value">
        {customPercentage || Math.round((value / total) * 100)}%
      </div>
      {total && <div className="funnel-column-percentage">{value.toLocaleString()}</div>}
      <div className="funnel-column-chart">
        <svg
          viewBox="0 -10 400 320"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          <defs>
            <clipPath id={`clip${index}`}>
              <path
                d={`M 0 ${startY} C 200 ${bezierOneY} 200 ${bezierTwoY} 400 ${endY} L 400 300 L 0 300 Z`}
                fill="white"
              />
            </clipPath>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop stopColor={PersonaColors.tertiary} stopOpacity="0.3" />
              <stop offset="1" stopColor={PersonaColors.secondary} stopOpacity="0.03" />
            </linearGradient>
          </defs>
          <rect
            x="0"
            y="0"
            width="400"
            height="300"
            fill="url(#gradient)"
            clipPath={`url(#clip${index})`}
          />
          <g>
            <path
              d={`M 0 ${startY} C 200 ${bezierOneY} 200 ${bezierTwoY} 400 ${endY}`}
              stroke="#009af2"
              strokeWidth={6}
              opacity="1"
            />
          </g>
        </svg>
      </div>
    </div>
  )
}

interface PersonaFunnelProps {
  className?: string
  persona: Persona
}

const PersonaFunnel: React.FC<PersonaFunnelProps> = ({ className, persona }) => {
  return (
    <div className={className}>
      <div className="funnel-columns">
        {persona.category === 'overall' && (
          <PersonaFunnelColumn
            index={1}
            label="Overall Population"
            value={persona.sampleSize}
            total={persona.sampleSize}
            nextFunnelValue={persona.metrics.numTours.value}
            tooltip={{
              title: 'Overall Population',
              description:
                'The total number of people that have interacted with VirtualAdviser pathways.',
            }}
          />
        )}
        {persona.category !== 'overall' && (
          <PersonaFunnelColumn
            index={1}
            label="Lens Population"
            value={persona.sampleSize}
            total={persona.sampleSize}
            customPercentage={Math.round((persona.sampleSize / persona.overallSampleSize) * 100)}
            nextFunnelValue={persona.metrics.numTours.value}
            tooltip={{
              title: 'Lens Population',
              description: 'The total number of people represented in this Lens.',
            }}
          />
        )}
        <PersonaFunnelColumn
          index={2}
          label="Tour Requests"
          value={persona.metrics.numTours.value}
          total={persona.sampleSize}
          nextFunnelValue={persona.metrics.numInterviewed.value}
          tooltip={{
            title: 'Tour Requests',
            description:
              'The number of people that requested a tour or meeting from a VirtualAdviser pathway.',
          }}
        />
        <PersonaFunnelColumn
          index={3}
          label="Interviews"
          value={persona.metrics.numInterviewed.value}
          total={persona.sampleSize}
          nextFunnelValue={persona.metrics.numEnrolled.value}
          tooltip={{
            title: 'Interviews',
            description: (
              <>
                <p>
                  The number of people that interviewed with a representative from your institution,{' '}
                  <Link to={'/organization/crmImport'}>as reported by our CRM Import tool</Link>.
                </p>
              </>
            ),
          }}
        />
        <PersonaFunnelColumn
          index={4}
          label="Enrollments"
          value={persona.metrics.numEnrolled.value}
          total={persona.sampleSize}
          nextFunnelValue={persona.metrics.numEnrolled.value}
          tooltip={{
            title: 'Enrollments',
            description: (
              <>
                <p>
                  The number of people that enrolled at your institution,{' '}
                  <Link to={'/organization/crmImport'}>as reported by our CRM Import tool</Link>.
                </p>
              </>
            ),
          }}
        />
      </div>
    </div>
  )
}

export default styled(PersonaFunnel)`
  .funnel-columns {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 1rem;
    border-radius: 3px;
    margin: 0 0 2rem 0;
    background: white;
    overflow: hidden;
  }

  .funnel-column {
    position: relative;
    padding: 0.75rem 1rem;
    border-radius: 10px;
    flex: 0 0 25%;
    height: 240px;
    position: relative;
    //border-right: 2px solid rgba(0, 0, 0, 0.05);

    &:last-child {
      border-right: 0;
    }

    &:hover {
      background-color: #f5f5f5; // or any other color you prefer
    }

    &:nth-child(3),
    &:nth-child(4) {
      ${HelpTooltip} {
        .help-tooltip {
          right: 0;
          left: auto;
        }
      }
    }
  }

  .funnel-column-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    .funnel-column-label-text {
      color: #2d2d2d;
      font-weight: 500;
      font-size: 1.125rem;
    }
  }
  .funnel-column-value {
    font-size: 2rem;
    color: #26447b;
    font-weight: bold;
  }
  .funnel-column-percentage {
    font-size: 1rem;
    color: #9e9e9e;
    font-weight: 600;
  }

  .funnel-column-chart {
    height: 105px;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;

    svg {
      width: 100%;
      height: 105px;
    }
  }
`
