import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Result from './Result'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../../components/Loader'
import filterToQuery from '../../../../_shared/models/response/ResponsesFilter'
import { connect } from 'react-redux'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import ClientResponse from '../../../../_shared/models/response/response.client'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'
import { DatepickerValue } from '../../components/Datepicker'
import { Company } from '../../../../_shared/models/company/company.client'
import { AppState } from '../../types'
import ClientPerson from '../../../../_shared/models/person/person.client'
import { sort } from 'fast-sort'

interface ResultsListProps {
  data: {
    responses: any[]
    people: any[]
    pathways: Pathway[]
  }
  className?: string
  selectResponse: (responseId: string) => void
  selectedResponse?: string
  isLoading: boolean
  innerRef?: React.Ref<any>
}

export class ResultsList extends React.Component<ResultsListProps> {
  selectFirstResult(): void {
    const { data, selectedResponse, selectResponse } = this.props
    if (data?.responses.length > 0 && !selectedResponse) {
      selectResponse(data.responses[0]._id)
    }
  }

  // shouldComponentUpdate(prevProps) {
  //   return (
  //     prevProps.results !== this.props.results ||
  //     prevProps.isLoading !== this.props.isLoading
  //   )
  // }

  // componentDidUpdate() {
  //   console.log(this.props)
  //   if (!this.props.isLoading && this.props.results.length > 0) {
  //     // this.selectFirstResult()
  //   }
  // }

  render(): React.ReactNode {
    const { isLoading, data, selectedResponse, selectResponse, className, innerRef } = this.props

    return (
      <ul>
        {!isLoading && (!data || !data.responses || data.responses.length === 0) && (
          <li className="empty">
            <img src="/images/globe-hands.png" width="64" />
            No results found. Try changing the date range or filter.
          </li>
        )}
        {data?.responses?.map((response, index) => {
          const person = data?.people?.find((p) => p._id === response.personId)
          const pathway = data?.pathways?.find((p) => p._id === response.pathwayId)
          return (
            <li key={response._id}>
              <Result
                active={response._id === selectedResponse}
                response={response}
                profile={person}
                pathway={pathway}
                onClick={(e) => {
                  if (selectResponse) {
                    e.preventDefault()
                    selectResponse(response._id)
                  }
                }}
              />
            </li>
          )
        })}
      </ul>
    )
  }
}

interface ResultsListContainerProps {
  limit?: number
  perPage?: number
  dateRange: DatepickerValue
  selectedCompany: Company
  filter?: any
  selectedResponse?: string
  selectResponse: (responseId: string) => void
}

interface ResultsListContainerState {
  limit: number
  hasMore: boolean
  currentPage: number
}

class ResultsListContainer extends React.PureComponent<
  ResultsListContainerProps,
  ResultsListContainerState
> {
  perPage = 20
  state = {
    limit: 20,
    currentPage: 0,
    hasMore: false,
  }

  loadMore = () => {
    const newLimit = this.state.limit + this.perPage
    this.setState({
      limit: newLimit,
      currentPage: this.state.currentPage + 1,
      hasMore: false,
    })
  }

  setCount = (store) => {
    const hasMore = store?.responses?.length >= this.state.limit
    this.setState({
      hasMore,
    })
  }

  getQuery() {
    return {
      companyId: this.props.selectedCompany?._id,
      createdAt: {
        $lte: this.props.dateRange[1],
        $gte: this.props.dateRange[0],
      },
      ...filterToQuery(this.props.filter),
    }
  }

  render() {
    return (
      <div className={'results-list'}>
        {/* // todo: infinite scroller needs to be upgraded to work with subscriptionloader
        // subscriptionloader needs to be fed limit and skip variables in order to continue to load data
        // it will accummulate data in its store and receive updates for any data for this companyid
        // right now updates are via rooms e.g. model.property.value (responses.companyId.enrollmentresources)
        // but we could possibly upgrade pubsub to know the query of each subscription, so it knows whether
        // or not a given update is relevant for that subscription */}
        <InfiniteScroll
          pageStart={0}
          loadMore={this.loadMore}
          hasMore={this.state.hasMore}
          // initialLoad={true}
          useWindow={false}
          // loader={<Loader test={console.log('RENDER LOADER')} key={0} />}
        >
          <SubscriptionLoader
            resource="responses"
            query={this.getQuery()}
            showLoader={true}
            showLoaderInline={true}
            limit={this.state.limit}
            setCount={this.setCount}
            sort={{
              createdAt: -1,
            }}
            transform={(store) => ({
              ...store,
              responses: sort(store.responses).desc((r: any) => r.createdAt),
            })}
          >
            {({ data, subscription }) => {
              // this.setCount(data.responses?.length || 0)
              return (
                <ResultsList
                  {...this.props}
                  isLoading={!subscription.ready}
                  data={data}
                  // ref={this.scrollContainerRef}
                />
              )
            }}
          </SubscriptionLoader>
        </InfiniteScroll>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  selectedCompany: (state.subscriptions.companyContext.data.companies[0] as Company) as Company,
})

export default connect(mapStateToProps)(ResultsListContainer)
