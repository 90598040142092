import React from 'react'
import PropTypes from 'prop-types'
import toPath from 'lodash/toPath'
import get from 'lodash/get'
import SettingsSection from '../../settings/SettingsSection'
import Input from '../../../_shared/components/Form/Input'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import withForm from '../../../_shared/components/withForm'

export const WidthOptionsSchema = {
  width: {
    type: 'object',
    additionalProperties: false,
    properties: {
      type: {
        type: 'string',
      },
      custom: {
        type: 'object',
        additionalProperties: false,
        properties: {
          value: {
            title: 'Custom width',
            type: 'string',
          },
          unit: {
            type: 'string',
            enum: ['px', '%', 'rem', 'em', 'vw', 'vh'],
          },
        },
        required: ['value', 'unit'],
      },
    },
  },
}

const CustomWidth = (props) => (
  <div className="row no-gutters">
    <div className="col">
      <Input name={`${props.path}.width.custom.value`} showLabel={false} label="Custom width" />
    </div>
    <div className="col-auto" style={{ marginLeft: '1rem' }}>
      <Dropdown name={`${props.path}.width.custom.unit`} showLabel={false}>
        <option value="px">px</option>
        <option value="em">em</option>
        <option value="rem">rem</option>
        <option value="%">%</option>
      </Dropdown>
    </div>
  </div>
)
CustomWidth.propTypes = {
  path: PropTypes.string,
}

const WidthOptions = ({
  formData,
  options = [
    { label: 'Default', value: 'default' },
    { label: 'Full', value: 'full' },
    { label: 'Custom', value: 'custom' },
  ],
  path,
}) => (
  <SettingsSection title="Width">
    <Dropdown name={`${path}.width.type`} label="Width" showLabel={false}>
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </Dropdown>
    {get(formData, toPath(`${path}.width.type`)) === 'custom' && <CustomWidth path={path} />}
  </SettingsSection>
)

WidthOptions.propTypes = {
  formData: PropTypes.object,
  options: PropTypes.array,
  path: PropTypes.string,
}

export default withForm(WidthOptions, true)
