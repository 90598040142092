import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../../components/Panel'
import Form from '../../../../../_shared/components/Form'
import Input from '../../../../../_shared/components/Form/Input'
import FormError from '../../../../../_shared/components/Form/FormError'
import { Button } from 'reactstrap'
import PageHeader from '../../../components/PageHeader'
import { redirect } from '../../../components/Link'
import NotFound from '../../NotFound'
import { withAlert } from 'react-alert'
import apiFetch from '../../../../../_shared/api/apiFetch'

const CareerFieldFormSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
  },
  required: ['name'],
}

class CareerFieldUpdate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { company, careerField } = this.props
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/careerFields/update', {
          companyId: company._id,
          careerFieldId: careerField._id,
          doc: data.formData,
        })
        const { location, history, alert } = this.props
        alert.show('Career Field saved')
        redirect({
          location,
          history,
          pathname: `/organization/careerFields`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error.message}</FormError>
    }
  }

  render() {
    console.log('where')
    const { company, careerField } = this.props

    return (
      <React.Fragment>
        <PageHeader
          breadcrumbs={[
            { label: company.name, path: '/organization' },
            { label: 'Career Fields', path: `/organization/careerFields` },
          ]}
          title={`Edit ${careerField.name}`}
        />

        <div className="content">
          <Panel>
            <Form formData={careerField} schema={CareerFieldFormSchema} onValidSubmit={this.onSubmit}>
              <PanelForm center={false}>
                <Input type="text" name="name" />

                <hr />
                {this.renderError()}

                <Button color="primary" type="submit" disabled={this.state.isLoading}>
                  Save Changes
                </Button>
              </PanelForm>
            </Form>
          </Panel>
        </div>
      </React.Fragment>
    )
  }
}

CareerFieldUpdate.propTypes = {
  company: PropTypes.object.isRequired,
  careerField: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

const CareerFieldUpdateWithAlert = withAlert()(CareerFieldUpdate)

const CareerFieldUpdateLoader = (props) => {
  console.log('what')
  const { company, match } = props
  console.log(props)
  const careerField = company.careerFields?.find((c) => c._id === match.params.careerFieldId)
  if (!careerField) {
    return <NotFound />
  }

  return <CareerFieldUpdateWithAlert {...props} careerField={careerField} />
}

CareerFieldUpdateLoader.propTypes = {
  company: PropTypes.object.isRequired,
  match: PropTypes.object,
}

export default CareerFieldUpdateLoader
