import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Stat = ({
  className,
  value,
  valuePrefix = '',
  valueSuffix = '',
  compareValue,
  compareLabel,
  label,
  labelPosition = 'top',
  align = 'center',
}) => {
  let change, changePercent
  if (compareValue) {
    change = value - compareValue
    changePercent = (change / compareValue) * 100

    if (changePercent === Infinity) {
      changePercent = 0
    }
    if (isNaN(change)) {
      change = '--'
    }
    if (isNaN(changePercent)) {
      changePercent = '--'
    }
  }

  return (
    <div className={className}>
      {labelPosition === 'top' && <div className={`label label-top`}>{label}</div>}
      <div className="value-container">
        {compareValue && (
          <div className="comparison-bar-container">
            <div
              className={`comparison-bar ${change < 0 ? 'negative' : 'positive'}`}
              style={{ height: Math.abs(changePercent) + '%' }}
            ></div>
          </div>
        )}
        <div className="value">
          {valuePrefix}
          {value}
          {valueSuffix}
        </div>
      </div>
      {labelPosition === 'bottom' && <div className={`label label-${labelPosition}`}>{label}</div>}

      {compareValue && (
        <div className="compare-value-container">
          <div className={`compare-value ${change < 0 ? 'negative' : 'positive'}`}>
            {change} ({changePercent}%)
          </div>
          {compareLabel && <div className="compare-label">{compareLabel}</div>}
        </div>
      )}
    </div>
  )
}

Stat.propTypes = {
  className: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  compareValue: PropTypes.number,
  compareLabel: PropTypes.string,
  labelPosition: PropTypes.string,
  align: PropTypes.string,
  label: PropTypes.string.isRequired,
  valuePrefix: PropTypes.string,
  valueSuffix: PropTypes.string,
}

export default styled(Stat)`
  display: inline-block;
  vertical-align: top;
  text-align: ${({ align }) => align};
  .label-bottom {
    margin-top: 0.2rem;
  }
  .label-top {
    margin-bottom: 0.5rem;
  }
  .label-center {
    text-align: center;
  }
  .value {
    color: var(--primary);
    line-height: 0.74;
    font-size: 2rem;
    font-weight: 600;
  }
  .value-container {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: stretch;
  }
  .comparison-bar-container {
    margin: 0 0.4rem 0 0;
    width: 3px;
    background: #e7e7e7;
    position: relative;
  }
  .comparison-bar {
    width: 100%;
    position: absolute;
    bottom: 0;

    &.positive {
      background: var(--success);
    }
    &.negative {
      background: var(--danger);
    }
  }
  .compare-value-container {
    margin: 0 0 0 calc(0.4rem + 3px);
  }
  .compare-value {
    font-weight: 600;

    &.positive {
      color: var(--success);
    }
    &.negative {
      color: var(--danger);
    }
  }
  .compare-label {
    color: var(--text-muted);
    font-size: 0.75rem;
  }
`
