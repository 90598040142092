export default {
  type: 'object',
  additionalProperties: false,
  properties: {
    _id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
  },
  required: ['name'],
}
