import React from 'react'
import PropTypes from 'prop-types'
import ReportView from './ReportView'
import NotFound from '../NotFound'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { shortCache } from '../../api/subscriptionCache'

const ReportViewContainer = (props) => {
  return (
    <SubscriptionLoader
      resource="response"
      query={{
        _id: props.match.params.responseId,
        companyId: props.match.params.companyId,
      }}
      showLoader={true}
    >
      {({ data }) => {
        if (!data?.responses) {
          return <NotFound />
        }

        const response = data.responses[0]

        return (
          <SubscriptionLoader
            resource="report"
            cache={shortCache}
            query={{
              _id: props.match.params.reportId,
              companyId: props.match.params.companyId,
            }}
            showLoader={true}
          >
            {({ data, isLoading }) => {
              if (!data?.reports || !data.pathways) {
                return <NotFound />
              }

              const report = data.reports[0]
              const pathway = data.pathways[0]
              return <ReportView report={report} pathway={pathway} response={response} />
            }}
          </SubscriptionLoader>
        )
      }}
    </SubscriptionLoader>
  )
}

ReportViewContainer.propTypes = {
  match: PropTypes.object.isRequired,
}

export default ReportViewContainer
