import React from 'react'
import styled from 'styled-components'
import PathwayBundles, { PathwayBundle } from '../../../_shared/api/PathwayBundles/PathwayBundles'

interface BundleProps {
  bundle?: PathwayBundle
}

const Bundle: React.FC<BundleProps> = ({ bundle }) => (
  <div className="bundle">
    <div className="name">
      <a href={bundle.link} target="_blank" rel="noopener noreferrer">
        {bundle.name}
      </a>
    </div>
  </div>
)

interface Props {
  company?: any
  className?: string
}

const PromotedBundles: React.FC<Props> = ({ className, company }) => (
  <div className={className}>
    <div className="bundle bundle-intro">
      <div className="row align-items-center">
        <div className="col-auto">
          <img src="/images/gauge.png" alt="Warning" width="90" />
        </div>
        <div className="col">
          <h5>Additional Pathways Are Available</h5>
          <p>
            Convert more prospects, retain more students.
            <br />
            Contact us for more information.
          </p>
        </div>
      </div>
    </div>
    {Object.entries(PathwayBundles).map(([key, value]) => {
      if (company.bundles.indexOf(key) !== -1) {
        return null
      }

      return <Bundle key={key} bundle={value} />
    })}
  </div>
)

export default styled(PromotedBundles)`
  margin: 0 0 1rem 0;
  border: 1px solid #bbd6ef;
  border-radius: 3px;
  .bundle {
    font-size: 0.9375rem;
    padding: 1rem;
    background: #f8fcff;
    border-bottom: 1px solid var(--border-color);
    color: #515151;
    &:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom: 0;
    }

    .name {
      font-size: 0.9375rem;
      font-weight: 600;
      color: #333;
    }
    p {
      margin: 0;
    }

    ul {
      margin: 0.25rem 0 0 0;
      padding: 0 0 0 1.2rem;
      li {
        margin: 0;
        padding: 0;
      }
    }
  }

  .bundle-intro {
    font-size: 0.875rem;
    color: #063967;
    background-color: #ddefff;
    h5 {
      color: #063967;
    }
  }
`
