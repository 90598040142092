export default {
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    type: {
      type: 'string',
      enum: ['html', 'script'],
    },
    location: {
      type: 'string',
      enum: ['head', 'body'],
    },
    content: {
      type: 'string',
    },
    stepTarget: {
      type: 'string',
      enum: ['all', 'specific'],
    },
    steps: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['name', 'type', 'content'],
}
