import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../components/Panel'
import { redirect } from '../../components/Link'
import PageHeader from '../../components/PageHeader'
import { Button } from 'reactstrap'
import IntegrationSchema from '../../../../_shared/models/integration/IntegrationSchema'
import { IntegrationManageDropdownOptions } from './IntegrationManageDropdown'
import PageActions, { PageAction } from '../../components/PageActions'
import IntegrationAdapters from '../../../../_shared/models/IntegrationAdapters/IntegrationAdapters'
import View from '../../components/View'
import { withAlert } from 'react-alert'
import FormError from '../../../../_shared/components/Form/FormError'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import NotFound from '../NotFound'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'

const { name, config } = IntegrationSchema.properties

const IntegrationFormSchema = {
  type: 'object',
  properties: { name, config },
  required: ['name', 'config'],
}

class IntegrationUpdate extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  static propTypes = {
    integration: PropTypes.object.isRequired,
    mock: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/integrations/update', {
          integrationId: this.props.integration._id,
          doc: data.formData,
        })
        const { location, history, alert } = this.props
        alert.show('Integration saved')
        redirect({ location, history, pathname: '/integrations' })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  getSchema() {
    const adapter = IntegrationAdapters.getAdapter(this.props.integration.type)
    if (adapter) {
      const schema = Object.assign({}, IntegrationFormSchema)
      Object.assign(schema.properties, { config: adapter.schema })
      return schema
    }
  }

  renderIntegrationForm() {
    const { integration } = this.props
    const adapter = IntegrationAdapters.getAdapter(integration.type)
    if (adapter) {
      return adapter.renderForm()
    }
  }

  render() {
    const { integration } = this.props
    return (
      <View layout="sidebar" title={integration.name}>
        <PageHeader
          breadcrumbs={[
            {
              label: 'Integrations',
              path: `/integrations`,
            },
          ]}
          title={integration.name}
        >
          <PageActions>
            <PageAction tag="div">
              <IntegrationManageDropdownOptions
                integration={integration}
                copyRedirectBase="/integrations/"
                deleteRedirect="/integrations"
              />
            </PageAction>
          </PageActions>
        </PageHeader>
        <div className="content">
          <Panel>
            <Form formData={integration} schema={this.getSchema()} onValidSubmit={this.onSubmit}>
              <PanelForm center={false}>
                <Input type="text" name="name" />
              </PanelForm>

              {this.renderIntegrationForm()}

              <hr />
              {this.renderError()}
              <Button color="primary" type="submit" size="lg" disabled={this.state.isLoading}>
                Save Changes
              </Button>
            </Form>
          </Panel>
        </div>
      </View>
    )
  }
}

const IntegrationUpdateWithAlert = withAlert()(IntegrationUpdate)

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="integration"
      cache={shortCache}
      query={{
        _id: props.match.params.integrationId,
        companyId: props.company._id,
      }}
      showLoader={true}
    >
      {({ data }) => {
        if (!data?.integrations || data.integrations.length === 0) {
          return <NotFound />
        }
        return <IntegrationUpdateWithAlert integration={data.integrations[0]} {...props} />
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
}

export default DataLoader
