/* eslint-disable react/display-name */
import IntegrationAdapters from '../../IntegrationAdapters'
import HTTPAdapter from './HTTPAdapter'
import React from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-javascript'
import Dropdown from '../../../../components/Form/Dropdown'
import Input from '../../../../components/Form/Input'
import Link from '../../../../../backend/client/components/Link'
import Code from '../../../../components/Form/Code'

import ace from 'ace-builds'
import IntegrationOverview from '../../../../../backend/client/components/IntegrationOverview'

ace.config.setModuleUrl(
  'ace/mode/javascript_worker',
  'https://cdn.jsdelivr.net/npm/ace-builds@1.4.12/src-noconflict/worker-javascript.js'
)

const forceWebpackToLoad = [AceEditor]

const resizeEditor = function (aceEditor) {
  const newHeight =
    aceEditor.getSession().getScreenLength() *
    (aceEditor.renderer.lineHeight + aceEditor.renderer.scrollBar.getWidth())
  aceEditor.container.style.height = `${newHeight}px`
  aceEditor.resize()
}

class HTTPAdapterClient extends HTTPAdapter {
  /**
   * Renders the configuration form for this integration.
   *
   * @memberof HTTPAdapterClient
   */
  renderForm = (pathway) => {
    return (
      <div className="HTTPAdapterConfig">
        <div className="row">
          <div className="col-auto">
            <Dropdown name="config.method" label="Method">
              <option value="POST">POST</option>
              <option value="GET">GET</option>
            </Dropdown>
          </div>
          <div className="col">
            <Input
              type="text"
              name="config.url"
              label="Destination URL"
              help={
                <span>
                  Must be a valid URL and start with <code>https://</code> or <code>http://</code>.
                  We highly recommend using <code>https</code> for security purposes.
                </span>
              }
            />
          </div>
        </div>
        <IntegrationOverview/>
        <br />

        <h4>HTTP Parameters</h4>

        <p className="text-small">
          Use JavaScript to specify the parameters to send with the request.{' '}
          {pathway && (
            <Link to={`/pathways/${pathway._id}/vars`} target="_blank">
              See the available variables here.
            </Link>
          )}
          <br />
          You may mutate the <code>headers</code> or <code>config</code> objects. ES6/ES2015 is
          accepted.
          <br />
          You must <code>return</code> one of the following:
          <br />
          <small>
            <ul>
              <li>
                a <code>string</code>
              </li>
              <li>
                an <code>object</code> (will be parameterized as{' '}
                <strong>application/x-www-form-urlencoded</strong> unless otherwise specified in{' '}
                <code>headers[&apos;content-type&apos;]</code>)
              </li>
              <li>
                <code>false</code> will skip the integration
              </li>
            </ul>
          </small>
        </p>

        <Code
          showLabel={false}
          tabSize={2}
          wrapEnabled={true}
          editorProps={{
            $blockScrolling: Infinity,
          }}
          setOptions={{
            wrap: true,
            displayIndentGuides: false,
            showPrintMargin: false,
            showFoldWidgets: false,
          }}
          mode="javascript"
          name="config.mappingScript"
        />

        <br />
        <br />
        <h4>HTTP Response Handling</h4>
        <p>Use the regular expressions below to determine if the action was successful.</p>

        <div className="row">
          <div className="col-8">
            <Input
              type="text"
              label="Successful response pattern"
              name="config.successRegex"
              help="Response bodies that match will be considered successful"
            />
          </div>
          <div className="col-4">
            <Dropdown
              name="config.successStatusRange"
              label="Successful status code range"
              help="Consider HTTP status codes within this range to be successful"
            >
              <option value="">200-399</option>
              <option value="4XX">200 - 499</option>
              <option value="5XX">200 - 599</option>
            </Dropdown>
          </div>
        </div>
        <Input
          type="text"
          label="Failure response pattern"
          name="config.abortRegex"
          help="Response bodies that match will be considered a failure and will prevent retries"
        />
      </div>
    )
  }
}

const instance = new HTTPAdapterClient()
IntegrationAdapters.register(instance)

export default HTTPAdapterClient
