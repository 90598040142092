import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../../components/Panel'
import Link from '../../../components/Link'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../../components/RoundButton'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import NewButton from '../../../components/NewButton'
import View from '../../../components/View'
import BasicModal from '../../../components/BasicModal'
import { PanelList, PanelListItem } from '../../../components/PanelList'
import apiFetch from '../../../../../_shared/api/apiFetch'

class DeleteCampus extends React.PureComponent {
  static propTypes = {
    company: PropTypes.object.isRequired,
    campus: PropTypes.object.isRequired,
  }

  callMethod = async () => {
    try {
      await apiFetch('DELETE', '/campuses/delete', {
        programGroupId: this.props.company._id,
        campusId: this.props.campus._id,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Campus?"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.campus.name}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
      >
        <RoundButton size="sm" onClick={this.delete}>
          <FontAwesomeIcon icon={faTimes} />
        </RoundButton>
      </BasicModal>
    )
  }
}

const FirstCampus = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/building.png" width="128" />
      <h3>Create your first campus</h3>
      <h6>Group your program offerings by campus</h6>
      <Link tag="button" to={`/organization/campuses/new`} className="btn btn-lg btn-primary">
        <FontAwesomeIcon icon={faPlus} /> New Campus
      </Link>
    </PanelHero>
  </Panel>
)

const Campus = (props) => {
  const { campus, company } = props
  return (
    <PanelListItem>
      <div className="row align-items-center flex-nowrap">
        <div className="col">
          <div className="panel-list-item-title">
            <Link to={`/organization/campuses/${campus._id}`}>{campus.name}</Link>
          </div>
        </div>
        <div className="d-flex flex-nowrap col-auto buttons">
          <RoundButton
            size="sm"
            tag={Link}
            to={`/organization/campuses/${campus._id}`}
            className="d-none d-md-inline-block"
          >
            <FontAwesomeIcon icon={faPencil} />
          </RoundButton>
          <DeleteCampus campus={campus} company={company} />
        </div>
      </div>
    </PanelListItem>
  )
}
Campus.propTypes = {
  campus: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

const CampusIndex = (props) => {
  const { company } = props

  if (!company.campuses || company.campuses.length === 0) {
    return <FirstCampus company={company} />
  }

  return (
    <View title={`Campuses - ${company.name}`}>
      <PanelList>
        {company.campuses &&
          company.campuses
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1
              }
              if (a.name > b.name) {
                return 1
              }
              return 0
            })
            .map((campus) => <Campus key={campus._id} company={company} campus={campus} />)}
      </PanelList>
      <NewButton label="New Campus" tag={Link} center to={`/organization/campuses/new`} />
    </View>
  )
}

CampusIndex.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default CampusIndex
