import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { PanelForm } from '../../../components/Panel'
import { withAlert } from 'react-alert'
import PathwaySchema from '../../../../../_shared/models/pathway/schema/PathwaySchema'
import FormError from '../../../../../_shared/components/Form/FormError'
import Form, { FormContext } from '../../../../../_shared/components/Form'
import Dropdown from '../../../../../_shared/components/Form/Dropdown'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'

const { bundle } = PathwaySchema.properties

const PathwayFormSchema = {
  title: 'Pathway Form',
  type: 'object',
  properties: {
    bundle,
  },
  required: ['bundle'],
}

class PathwaySettingsBundle extends React.Component {
  state = {
    isLoading: false,
    error: null,
    validURL: true,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    alert: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true, error: false }, async () => {
      try {
        await apiFetch('POST', '/pathways/update', {
          pathwayId: this.props.pathway._id,
          doc: data.formData,
        })
        this.props.alert.show('Pathway saved')
        this.setState({ isLoading: false })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway } = this.props
    const { bundle } = pathway
    const formData = {
      bundle,
    }

    return (
      <Form formData={formData} schema={PathwayFormSchema} onValidSubmit={this.onSubmit}>
        <h4>Bundle</h4>
        <br />

        <PanelForm center={false}>
          <SubscriptionLoader
            resource="pathwayBundle"
            query={{}}
            cache={shortCache}
            showLoader={true}
            sort={{ name: 1 }}
          >
            {({ data }) => {
              if (!data || data.length === 0) {
                return <div className="alert alert-info text-smaller">No bundles found</div>
              }

              return (
                <React.Fragment>
                  <Dropdown
                    name="bundle.bundleId"
                    label="Assigned Bundle"
                    placeholder="(Select One)"
                  >
                    {data &&
                      data.map((bundle) => (
                        <option value={bundle._id} key={bundle._id}>
                          {bundle.name}
                        </option>
                      ))}
                  </Dropdown>

                  <FormContext.Consumer>
                    {({ formData }) => {
                      if (!formData.bundle) {
                        return (
                          <Dropdown
                            name="bundle.itemId"
                            label="Item"
                            placeholder="(Select a bundle first)"
                          />
                        )
                      }

                      // Find the bundle that is selected
                      const selectedBundle = data.find((b) => b._id === formData.bundle.bundleId)
                      if (!selectedBundle?.items) {
                        return null
                      }

                      return (
                        <Dropdown name="bundle.itemId" label="Item" placeholder="(Select One)">
                          {selectedBundle.items.map((item) => (
                            <option value={item._id} key={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </Dropdown>
                      )
                    }}
                  </FormContext.Consumer>
                </React.Fragment>
              )
            }}
          </SubscriptionLoader>
        </PanelForm>
        <hr />
        {this.renderError()}
        <Button color="primary" type="submit" disabled={this.state.isLoading}>
          Save Changes
        </Button>
      </Form>
    )
  }
}

export default withAlert()(PathwaySettingsBundle)
