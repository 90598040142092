import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../components/Panel'
import Link from '../../components/Link'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../components/RoundButton'
import NewButton from '../../components/NewButton'
import View from '../../components/View'
import { PanelList, PanelListItem } from '../../components/PanelList'
import DeleteGoalModal from '../../components/modals/DeleteGoalModal'

const FirstGoal = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/ribbon-cog.png" width="128" />
      <h3>Create your first goal</h3>
      <h6>Customize progress tracking, pathway completion, and more</h6>
      <Link
        tag="button"
        to={`/pathways/${props.pathway._id}/newGoal`}
        className="btn btn-lg btn-primary"
      >
        <FontAwesomeIcon icon={faPlus} /> New Goal
      </Link>
    </PanelHero>
  </Panel>
)

const Goal = (props) => (
  <PanelListItem>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <div className="panel-list-item-title">
          <Link to={`/pathways/${props.pathway._id}/goals/${props.goal._id}`}>
            {props.goal.name}
          </Link>
        </div>
      </div>
      <div className="d-flex flex-nowrap col-auto buttons">
        <RoundButton
          size="sm"
          tag={Link}
          to={`/pathways/${props.pathway._id}/goals/${props.goal._id}`}
          className="d-none d-md-inline-block"
        >
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <DeleteGoalModal
          pathway={props.pathway}
          goal={props.goal}
          buttonTag={RoundButton}
          buttonProps={{
            size: 'sm',
          }}
        />
      </div>
    </div>
  </PanelListItem>
)

Goal.propTypes = {
  goal: PropTypes.object.isRequired,
  pathway: PropTypes.object.isRequired,
}

class GoalIndex extends React.Component {
  render() {
    const { pathway, className } = this.props

    if (!pathway.goals || pathway.goals.length === 0) {
      return <FirstGoal pathway={pathway} />
    }

    return (
      <div className={className}>
        <View title={pathway.name}>
          <PanelList>
            {pathway.goals?.map((goal) => (
              <Goal key={goal._id} goal={goal} pathway={pathway} />
            ))}
          </PanelList>
          <NewButton label="New Goal" tag={Link} center to={`/pathways/${pathway._id}/newGoal`} />
        </View>
      </div>
    )
  }
}

GoalIndex.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default styled(GoalIndex)``
