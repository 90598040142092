import React from 'react'
import View from '../../components/View'
import PageHeader from '../../components/PageHeader'
import CompanyForm from './CompanyForm'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { AppState } from '../../types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Company } from '../../../../_shared/models/company/company.client'
import apiFetch from '../../../../_shared/api/apiFetch'

function breadcrumbs(props) {
  return [{ label: 'Organizations', path: '/companies' }]
}

interface Props extends RouteComponentProps<any> {
  selectedCompany: Company
}

interface State {
  isLoading: boolean
  error: string
}

class CompanyCreate extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      error: null,
    }
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    data.formData.parentCompanyId = this.props.selectedCompany._id

    this.setState({ isLoading: true }, async () => {
      try {
        const company = await apiFetch('POST', '/companies/create', { doc: data.formData })
        this.props.history.push(`/?a=${company.companyId}`)
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const title = 'Create Organization'
    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={breadcrumbs(this.props)} />
        <div className="content">
          <CompanyForm
            onSubmit={this.onSubmit}
            isLoading={this.state.isLoading}
            buttonLabel="Create Organization"
          />
        </div>
      </View>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0] as Company,
})

export default compose(withRouter, connect(mapStateToProps))(CompanyCreate)
