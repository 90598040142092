import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { PanelFormSide } from '../../components/Panel'
import RestrictCampus from './RestrictCampus'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import FormError from '../../../../_shared/components/Form/FormError'

const UserFormSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    email: {
      title: 'Email Address',
      type: 'string',
    },
    permissions: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    campusView: {
      type: 'string',
      enum: ['all', 'restrict'],
    },
    campuses: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['name', 'email', 'permissions'],
}

const UserForm = (props) => {
  const { onSubmit, buttonLabel, user, error, isUpdate, isLoading } = props
  return (
    <Form formData={user || {}} schema={UserFormSchema} onValidSubmit={onSubmit}>
      <div className="row align-items-stretch">
        <div className="col-lg-12 col-xl-5">
          <Input type="text" name="name" />
          <Input type="email" name="email" disabled={isUpdate} />
          {!user ? (
            <div className="alert alert-primary">
              A welcome email will be sent with their password.
            </div>
          ) : null}
          <br />
          <Cardbox
            thin
            vertical
            name="campusView"
            showLabel={false}
            defaultValue="all"
            selectionRequired={true}
          >
            <CardboxItem
              title="View All Campuses"
              value="all"
              description="Can view results for all campuses"
            />
            <CardboxItem
              title="Restrict Campuses"
              value="restrict"
              description="Can only view results for certain campuses"
            />
          </Cardbox>

          <RestrictCampus />

          <hr />

          <Cardbox showLabel={false} name="monthlyReport" thin vertical defaultValue={false}>
            <CardboxItem
              value={true}
              title="Receive Monthly Statistics Report"
              image="/images/email.png"
              description="View receive the monthly statistics report"
            />
          </Cardbox>
        </div>
        <div className="col-lg-12 col-xl-7 ">
          <PanelFormSide>
            <h4>Permissions</h4>
            <p className="text-smaller">
              Control what information this user can access in VirtualAdviser.
            </p>
            <Cardbox
              multiple
              showLabel={false}
              name="permissions"
              thin
              vertical
              defaultValue={['view-results']}
            >
              <CardboxItem
                value="view-results"
                title="View Results & People"
                image="/images/team-work.png"
                description="Can view pathway results and prospect profiles"
              />
              <CardboxItem
                value="view-statistics"
                title="View Statistics"
                image="/images/dashboard.png"
                description="Can view your account statistics"
              />
              <CardboxItem
                value="manage-users"
                title="Manage Users"
                image="/images/users.png"
                description="Can create, edit, and delete users"
              />
              <CardboxItem
                value="manage-pathways"
                title="Manage Pathways"
                image="/images/map.png"
                description="Can create, edit, and delete pathways"
              />
              <CardboxItem
                value="manage-organizations"
                title="Manage Organizations"
                image="/images/building.png"
                description="Can create, edit, and delete organizations"
              />
            </Cardbox>
          </PanelFormSide>
        </div>
      </div>

      <br />

      <hr />
      {error && <FormError>{error}</FormError>}
      <Button size="lg" color="primary" type="submit" disabled={isLoading}>
        {buttonLabel || 'Submit'}
      </Button>
    </Form>
  )
}

UserForm.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
  isUpdate: PropTypes.bool,
}

export default UserForm
