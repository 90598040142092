import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import GoalSchema from '../../../../_shared/models/goal/GoalSchema'
import media from '../../css/_media'
import styled from 'styled-components'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import FormError from '../../../../_shared/components/Form/FormError'

const SideForm = styled.div`
  margin: 1rem 0;
  max-width: 600px;
  color: var(--text-muted);
  @media (min-width: ${media.xl}) {
    height: 100%;
    margin: 0 0 0 2rem;
    margin-bottom: 0;
    padding-left: 3rem;
    border-left: 1px solid var(--border-color);
  }
`

const GoalForm = (props) => {
  const { pathway, buttonLabel, goal, onSubmit, error, isLoading } = props

  return (
    <Form formData={goal || {}} schema={GoalSchema} onValidSubmit={onSubmit}>
      <div className="row">
        <div className="col">
          <Input type="text" name="name" />
        </div>
        <div className="col-divider" />
        <div className="col">
          <Cardbox
            thin
            name="trigger"
            vertical
            defaultValue="submit"
            selectionRequired
            help="Trigger this goal when the following events occur:"
          >
            <CardboxItem
              title="Step Submitted"
              value="submit"
              description="When any of the selected steps are submitted"
            />
            <CardboxItem
              title="Step Viewed"
              value="view"
              description="When any of the selected steps are viewed"
            />
          </Cardbox>
        </div>
      </div>

      <Cardbox
        name="steps"
        vertical
        thin
        multiple
        selectionRequired
        help="Which steps should trigger this goal?"
      >
        {pathway.getSteps().map((step) => (
          <CardboxItem key={step._id} value={step._id} title={step.name} />
        ))}
      </Cardbox>
      <hr />

      <Cardbox showLabel={false} name="includeInPersona" thin vertical defaultValue={false}>
        <CardboxItem
          value={true}
          title="Include in Persona"
          image="/images/users.png"
          description="When triggered, include this data in Persona generation"
        />
      </Cardbox>

      <hr />
      {error && <FormError>{error}</FormError>}

      <Button size="lg" color="primary" type="submit" disabled={isLoading}>
        {buttonLabel}
      </Button>
    </Form>
  )
}

GoalForm.propTypes = {
  pathway: PropTypes.object.isRequired,
  goal: PropTypes.object,
  error: PropTypes.any,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  mock: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default GoalForm
