import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Styles = styled.div`
  background: #15457d;
  color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  border-radius: 3px;
`

const AlertTemplate = ({ style, options, message, close, className }) => (
  <Styles className={className} style={style}>
    {message}
  </Styles>
)

AlertTemplate.propTypes = {
  style: PropTypes.object,
  options: PropTypes.object,
  message: PropTypes.string,
  className: PropTypes.string,
  close: PropTypes.func,
}

export default AlertTemplate
