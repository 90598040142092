import CampusSchema from './CampusSchema'
import ProgramSchema from './ProgramSchema'

export default {
  title: 'Company',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },
    name: {
      title: 'Name',
      type: 'string',
    },
    type: {
      title: 'Account Type',
      type: 'string',
    },
    bundles: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    notes: {
      title: 'Notes',
      type: 'string',
    },
    website: {
      title: 'Website',
      type: 'string',
    },
    phone: {
      title: 'Phone',
      type: 'string',
    },
    logo: {
      title: 'Logo',
      type: 'string',
    },
    tags: {
      title: 'Tags',
      type: 'array',
      items: {
        type: 'string',
      },
    },
    features: {
      title: 'Features',
      type: 'array',
      items: {
        type: 'string',
      },
      uniqueItems: true,
    },
    isAllowedChildCompanies: {
      title: 'Allow Child Companies',
      type: 'boolean',
    },
    parentCompanyId: {
      title: 'Parent Company',
      type: 'string',
    },
    parentCompanyIds: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    campuses: {
      type: 'array',
      items: CampusSchema,
    },
    programs: {
      type: 'array',
      items: ProgramSchema,
    },
    reporterApiKey: {
      title: 'Reporter API Key',
      type: 'string',
    },
    domains: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    reps: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    googleAnalytics: {
      type: 'object',
      properties: {
        config: {
          type: 'object',
          additionalProperties: false,
          properties: {
            account: {
              type: 'string',
            },
            property: {
              type: 'string',
            },
            view: {
              type: 'string',
            },
            timezone: {
              type: 'string',
            },
          },
        },
      },
      additionalProperties: false,
    },
    status: {
      type: 'string',
    },
    maxio: {
      type: 'object',
      properties: {
        customerId: {
          title: 'Customer',
          type: 'string',
        },
        subscriptionId: {
          title: 'Subscription',
          type: 'string',
        },
        componentId: {
          title: 'Metered Component',
          type: 'string',
        },
        componentPricePointId: {
          title: 'Component Price Point',
          type: 'string',
        },
      },
      additionalProperties: false,
      required: ['customerId', 'subscriptionId', 'componentId'],
    },
    careerFields: {
      type: 'object',
      items: {
        type: 'string',
      },
    },
    persona: {
      type: 'object',
      properties: {
        date: {
          type: "date",
        },
        completed: {
          type: "number",
        },
        skipped: {
          type: "number",
        },
        incomplete: {
          type: "number",
        },
        errorMessages: [{
          type: "string",
        }],
        errorCategories: [{
          type: "string",
        }],

      },
    }
  },
  required: ['name'],
  additionalProperties: false,
}
