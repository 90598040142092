import { escapeRegExp } from '../../../../../_shared/utilities'

export const filterToQuery = (filter) => {
  if (!filter) {
    return {}
  }
  const query = {
    ...filter,
  }

  if (filter.campuses) {
    delete query.campuses
    query['campus.value'] = { $in: filter.campuses.map((p) => p.value) }
  }
  if (filter.programs) {
    delete query.programs
    query['program.value'] = { $in: filter.programs.map((p) => p.value) }
  }

  if (filter.progress) {
    delete query.progress
    if (filter.progress === 'inprogress') {
      query.progress = { $lt: 100 }
    } else {
      query.progress = 100
    }
  }

  if (filter.score) {
    delete query.score
    query.score = { $gte: filter.score[0], $lte: filter.score[1] }
  }

  if (filter.lead_src) {
    query.lead_src = {
      $regex: new RegExp(escapeRegExp(filter.lead_src), 'i'),
    }
  }

  if (filter.referrer) {
    query.referrer = {
      $regex: new RegExp(escapeRegExp(filter.referrer), 'i'),
    }
  }

  query.$and = []
  if (filter.answers) {
    delete query.answers
    filter.answers.forEach((answer) => {
      const stepId = answer.field.split('.')[0]
      const fieldId = answer.field.split('.')[1]

      let valueOperator
      switch (answer.comparator) {
        case 'is not':
        case 'does not equal':
          valueOperator = { $ne: answer.value }
          break
        case 'contains':
          valueOperator = {
            $regex: `.*${escapeRegExp(answer.value)}.*`,
          }
          break
        case 'does not contain':
          valueOperator = {
            $not: { $regex: `.*${escapeRegExp(answer.value)}.*` },
          }
          break
        case 'equals':
        case 'is':
        default:
          valueOperator = answer.value
      }

      query.$and.push({
        $or: [
          {
            [`steps.fields`]: {
              $elemMatch: { _id: fieldId, value: valueOperator },
            },
          },
          {
            [`steps.fields`]: {
              $elemMatch: {
                _id: fieldId,
                'data.value': valueOperator,
              },
            },
          },
        ],
      })
    })
  }

  if (query.$and.length === 0) {
    delete query.$and
  }

  return query
}

const PathwayStatisticsFilterSchema = {
  type: 'object',
  properties: {
    campuses: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
      },
    },
    programs: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
      },
    },
    progress: {
      type: 'string',
    },
    score: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
    lead_src: {
      type: 'string',
      title: 'Lead Source',
    },
    referrer: {
      type: 'string',
    },
    answers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          field: {
            type: 'string',
          },
          comparator: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
        required: ['field', 'comparator', 'value'],
      },
    },
  },
}

export default PathwayStatisticsFilterSchema
