import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import TimeAgo from 'react-timeago'
import formatDistance from 'date-fns/formatDistance'
import format from 'date-fns/format'
import { Tooltip } from 'reactstrap'
import classnames from 'classnames'
import Timestamp from '../../components/Timestamp'
import { parseISO } from 'date-fns'

const Wrapper = (props) => props.children

class NewTooltip extends React.PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    placement: PropTypes.string,
    children: PropTypes.node,
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element]),
  }
  static defaultProps = {
    placement: 'top',
  }
  state = {
    open: false,
  }
  toggle = () => {
    this.setState({
      open: !this.state.open,
    })
  }
  render() {
    const { id, placement, content, children } = this.props
    return (
      <Wrapper>
        <span id={id}>{children}</span>
        <Tooltip
          placement={placement}
          isOpen={this.state.open}
          toggle={this.toggle}
          target={id}
          delay={500}
        >
          {content}
        </Tooltip>
      </Wrapper>
    )
  }
}

const StyledTimestamp = styled.span`
  border-bottom: 1px dashed var(--border-color);
`

const ResponseShortMeta = (props) => {
  const { response, pathway, isOnline, className } = props
  const { completedAt, lastModified, createdAt, nextStep } = response

  let status

  if (completedAt) {
    status = (
      <li>
        <FontAwesomeIcon icon={faCheckCircle} fixedWidth size="sm" transform="up-1" /> Completed{' '}
        <Timestamp date={completedAt} placement="right" />
      </li>
    )
  } else {
    if (isOnline) {
      status = (
        <li>
          <FontAwesomeIcon icon={faClock} fixedWidth size="sm" transform="up-1" /> Started{' '}
          <Timestamp date={createdAt} placement="right" />
        </li>
      )
    } else {
      status = (
        <li>
          <FontAwesomeIcon icon={faTimesCircle} fixedWidth size="sm" transform="up-1" /> Abandoned{' '}
          {nextStep && (
            <span>
              on <strong>{nextStep.name}</strong>
            </span>
          )}{' '}
          after{' '}
          <NewTooltip
            placement="right"
            id="abandonedTooltip"
            content={format(lastModified, "MMM do yyyy 'at' hh:mma")}
          >
            <StyledTimestamp>{formatDistance(lastModified, createdAt)}</StyledTimestamp>
          </NewTooltip>
        </li>
      )
    }
  }

  return (
    <ul className={classnames('list-unstyled meta', className)}>
      {status}
      {response.isInvalid && (
        <li className="invalid-reason">
          <FontAwesomeIcon icon={faTimesCircle} fixedWidth size="sm" /> <strong>Invalid:</strong>{' '}
          {response.invalidReason}
        </li>
      )}
    </ul>
  )
}
ResponseShortMeta.propTypes = {
  response: PropTypes.object.isRequired,
  pathway: PropTypes.object,
  isOnline: PropTypes.bool,
}

export default styled(ResponseShortMeta)`
  strong {
    font-weight: 600;
  }
  color: var(--text-muted-blue);
  font-size: 0.8125rem;
  li {
    line-height: 1.5;
  }
  .svg-inline--fa {
    color: #9eaebd;
  }
  .invalid-reason {
    color: var(--danger);
    .svg-inline--fa {
      color: var(--danger);
    }
  }
`
