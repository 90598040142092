import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CampaignOverviewContainer } from '../statistics/components/CampaignOverview'
import Stat from '../../components/Stat'
import SparklineChart from '../../components/SparklineChart'
import Link from '../../components/Link'
import classnames from 'classnames'
import { PathwayStatisticsTableContainer } from '../../components/PathwayStatisticsTable'
import { PathwayStatisticsTableWithTrafficContainer } from '../../components/PathwayStatisticsTableWithTraffic'
import { withRouter } from 'react-router'
import parse from 'date-fns/parse'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import format from 'date-fns/format'
import { GlobalCampaigns } from '../../../../_shared/models/campaign/campaign.client'
import { compose } from 'redux'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import apiFetch from '../../../../_shared/api/apiFetch'
import { lowerCase } from 'voca'

class CampaignComparisonTable extends React.Component {
  state = {
    isLoading: true,
    data: null,
    error: null,
  }

  componentDidMount() {
    this.loadData()
  }

  loadData = () => {
    const { dateRange, campaignId, companyId } = this.props

    this.setState(
      {
        isLoading: true,
        data: null,
      },
      async () => {
        try {
          const data = await apiFetch(
            'GET',
            '/statistics/campaignComparisonTable',
            {},
            {
              start: dateRange[0],
              end: dateRange[1],
              campaignId,
              // todo: calculate their reports using their timezone
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
              companyId,
            },
            true
          )
          this.setState({
            isLoading: false,
            data,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { isLoading, data, error } = this.state
    if (isLoading) {
      return null
    }

    if (error) {
      return <div className="alert alert-danger text-smaller">{error}</div>
    }

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Month</th>
            {data && data.headers?.map((header, index) => <th key={index}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.rows &&
            Object.entries(data.rows).map(([month, rowData]) => (
              <tr key={month}>
                <td>{month}</td>
                {rowData && rowData.map((d, index) => <td key={index}>{d}</td>)}
              </tr>
            ))}
        </tbody>
      </table>
    )
  }
}

const CampaignOverview = ({
  className,
  campaign,
  statData,
  chartData,
  hasBundle,
  companyId,
  noPathways,
  dateRange,
  error,
}) => {
  let TableComponent

  switch (campaign._id) {
    case 'admissions':
      TableComponent = PathwayStatisticsTableContainer
      break
    default:
      TableComponent = PathwayStatisticsTableWithTrafficContainer
  }

  return (
    <div className={classnames(className, { noBundle: !hasBundle || noPathways })}>
      <div className="print-nobreak">
        <div className="campaign-name text-center">
          <Link to={`/statistics/campaign/${campaign._id}`}>
            {campaign.name} {!hasBundle && '(Inactive)'}
          </Link>
        </div>
        {hasBundle && chartData && (
          <div className="chart-container">
            <SparklineChart
              height={140}
              hideLabels={false}
              labels={chartData.labels}
              animation={0}
              datasets={[
                {
                  data: chartData.values,
                  borderColor: '#15457d',
                  backgroundColor: 'rgba(21,69,125,0.1)',
                  pointHoverBackgroundColor: '#0b6dc7',
                  pointRadius: 0,
                  pointBackgroundColor: '#0b6dc7',
                },
              ]}
            />
          </div>
        )}
      </div>

      <h3 className="text-center"></h3>
      {!hasBundle && (
        <div className="inactive-bundle">Please contact us to enable this campaign.</div>
      )}
      <div className="row quick-stats">
        {statData &&
          statData.map((data, index) => (
            <div key={index} className="col">
              <Stat value={data.value} label={data.label} align="center" labelPosition="bottom" />
            </div>
          ))}
      </div>
      <br />
      {hasBundle && (
        <React.Fragment>
          <h5 style={{ marginBottom: '0.5rem' }}>Monthly Comparison</h5>

          <CampaignComparisonTable
            dateRange={dateRange}
            campaignId={campaign._id}
            companyId={companyId}
          />
        </React.Fragment>
      )}

      {hasBundle && (
        <React.Fragment>
          <h5 style={{ margin: '2rem 0 -2rem 0' }}>Pathways</h5>

          <TableComponent
            id={`${lowerCase(campaign.name)}-pathway-table`}
            campaignId={campaign._id}
            companyId={companyId}
            start={dateRange[0]}
            end={dateRange[1]}
          />
        </React.Fragment>
      )}
    </div>
  )
}

CampaignOverview.propTypes = {
  statData: PropTypes.array,
  chartData: PropTypes.object,
  campaign: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  error: PropTypes.string,
  hasBundle: PropTypes.bool,
  noPathways: PropTypes.bool,
}

const StyledCampaignOverview = styled(CampaignOverview)`
  position: relative;
  padding-bottom: 4rem;
  border-bottom: 10px solid #f9f9f9;
  margin: 0 0 4rem 0;

  .print-nobreak {
    page-break-inside: avoid;
  }

  &:last-child {
    border: 0;
  }

  .campaign-name {
    font-weight: 600;
    font-size: 1.3rem;
    margin: 0 0 1rem 0;
  }

  h5 {
    margin-top: 2rem;
    /* border-bottom: 1px solid blue; */
  }

  table {
    font-size: 0.75rem !important;
  }

  .quick-stats {
    max-width: none;
    margin: 2rem 0 0 0;
    font-size: 0.875rem;
  }

  table th {
    border-top: 0;
  }

  .chart-container {
    max-width: 860px;
    margin: 0 auto;
  }

  .chart-legend {
    display: none;
  }

  ${Stat} {
    .label {
      font-size: 0.875rem;
    }
    .value {
      font-size: 1.3rem;
    }
  }

  .inactive-bundle {
    margin: 0 0 1.5rem 0;
    text-align: center;
    font-style: italic;
  }

  &.noBundle {
    a {
      color: var(--text-muted);
      pointer-events: none;
      cursor: default;
    }
    ${Stat} {
      &,
      .value {
        color: var(--text-muted) !important;
      }
    }
  }
`

class MonthlyReport extends React.Component {
  state = {}

  render() {
    const { className, company, location, match } = this.props

    const date = parse(match.params.date, 'yyyy-MM', new Date())
    const dateRange = [startOfMonth(date), endOfMonth(date)]

    return (
      <div className={className}>
        <div className="row align-items-center">
          <div className="col">
            <img
              src="https://enrollmentresources.com/wp-content/uploads/2019/02/New-ER-signature.png"
              height={60}
            />
          </div>
          <div className="col text-right">
            <img src="https://cdn.filestackcontent.com/FlQv2TBsQmKT2mnJbnpU" height={20} />
          </div>
        </div>
        <h2 className="text-center">{company.name}</h2>
        <h3 className="text-center">VirtualAdviser Statistics - {format(date, 'MMMM yyyy')}</h3>

        <div className="text-center text-smaller font-italic text-muted">
          Clickable links are shown in <span style={{ color: 'var(--primary)' }}>blue</span> and
          will take you to the corresponding Statistics page in VirtualAdviser.
        </div>

        <br />
        <br />

        {Object.values(GlobalCampaigns).map((campaign) => {
          return (
            <CampaignOverviewContainer
              key={campaign._id}
              dateRange={dateRange}
              Component={StyledCampaignOverview}
              companyId={company._id}
              campaign={campaign}
              hasBundle={
                campaign._id === 'other' ? true : company.bundles.indexOf(campaign._id) !== -1
              }
            />
          )
        })}

        <SubscriptionLoader
          resource="campaigns"
          query={{ companyId: company._id }}
          sort={{ name: 1 }}
          showLoader={true}
        >
          {({ data: campaigns }) => {
            campaigns = campaigns.campaigns
            return (
              <React.Fragment>
                {campaigns &&
                  campaigns.map((campaign) => (
                    <CampaignOverviewContainer
                      companyId={company._id}
                      dateRange={dateRange}
                      Component={StyledCampaignOverview}
                      campaign={campaign}
                      key={campaign._id}
                    />
                  ))}
              </React.Fragment>
            )
          }}
        </SubscriptionLoader>

        <div className="text-center" style={{ fontSize: '0.75rem' }}>
          Monthly report provided by Virtual Adviser. This report is generated using Pacific
          Standard Time.
        </div>
      </div>
    )
  }
}

export default styled(compose(withRouter)(MonthlyReport))`
  background: #fff;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  padding: 1rem;

  .link-example {
    color: var(--primary);
  }

  .alert {
    &,
    * {
      font-size: 0.875rem;
    }
  }

  @media print {
    & {
      height: auto;
    }
  }

  .link-google-analytics {
    display: none;
  }
`
