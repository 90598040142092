import React from 'react'
import PropTypes from 'prop-types'
import toPath from 'lodash/toPath'
import get from 'lodash/get'
import SettingsSection from '../../settings/SettingsSection'
import Input from '../../../_shared/components/Form/Input'
import Dropdown from '../../../_shared/components/Form/Dropdown'
import withForm from '../../../_shared/components/withForm'

const CustomHeight = (props) => (
  <div className="row no-gutters">
    <div className="col">
      <Input name={`${props.path}.height.custom`} showLabel={false} />
    </div>
    <div className="col-auto" style={{ marginLeft: '1rem' }}>
      <Dropdown name={`${props.path}.height.customUnits`} showLabel={false}>
        <option value="px">px</option>
        <option value="em">em</option>
        <option value="rem">rem</option>
        <option value="%">%</option>
      </Dropdown>
    </div>
  </div>
)
CustomHeight.propTypes = {
  path: PropTypes.string,
}

const HeightOptions = ({
  formData,
  options = [
    { label: 'Default', value: 'default' },
    { label: 'Custom', value: 'custom' },
  ],
  path,
}) => (
  <SettingsSection title="Height">
    <Dropdown name={`${path}.height.type`} label="Height" showLabel={false}>
      {options.map((option) => (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      ))}
    </Dropdown>
    {get(formData, toPath(`${path}.height.type`)) === 'custom' && <CustomHeight path={path} />}
  </SettingsSection>
)

HeightOptions.propTypes = {
  formData: PropTypes.object,
  options: PropTypes.array,
  path: PropTypes.string,
}

export default withForm(HeightOptions, true)
