import React from 'react'
import { useSelector } from 'react-redux'
import { Company } from '../../../../_shared/models/company/company.client'
import PageHeader from '../../components/PageHeader'
import View from '../../components/View'
import { AppState } from '../../types'
import DefaultDashboard from './DefaultDashboard'
import ReporterDashboard from './ReporterDashboard'

const DashboardIndex: React.FC = (props) => {
  const companyContext = useSelector<AppState, Company>(
    (state) => (state.subscriptions.companyContext.data.companies[0] as Company) as Company
  )

  return (
    <View title="Dashboard">
      <PageHeader title="Dashboard" />
      <div className="content">
        {companyContext.reporterApiKey ? (
          <ReporterDashboard company={companyContext} />
        ) : (
          <DefaultDashboard company={companyContext} />
        )}
      </div>
    </View>
  )
}

export default DashboardIndex
