import React from 'react'
import PropTypes from 'prop-types'
import { DefaultOptions } from '../../components/Datepicker'
import { redirect } from '../../components/Link'
import Results from './Results'

class ResultsViewContainer extends React.Component {
  constructor(props) {
    let dateRange
    let dateRangeKey
    let filter

    if (window.localStorage) {
      const savedKey = window.localStorage?.getItem('results-daterange-key')
      if (savedKey) {
        const option = Object.values(DefaultOptions).find((o) => o.key === savedKey)
        if (option) {
          dateRange = option.dates()
          dateRangeKey = savedKey
        }
      } else {
        let savedStart = window.localStorage?.getItem('results-daterange-start')
        if (savedStart) {
          savedStart = new Date(savedStart)
        }
        let savedEnd = window.localStorage?.getItem('results-daterange-end')
        if (savedEnd) {
          savedEnd = new Date(savedEnd)
        }
        if (savedStart && savedEnd) {
          dateRange = [savedStart, savedEnd]
        }
      }

      filter = window.localStorage.getItem('results-filter')
      if (filter) {
        filter = JSON.parse(filter)
        if (Object.values(filter).length === 0) {
          filter = null
        }
      }
    }

    if (!dateRange) {
      dateRange = DefaultOptions.LAST_7_DAYS.dates()
    }

    super(props)
    this.state = {
      dateRange,
      dateRangeKey,
      filter,
    }
  }

  static propTypes = {
    company: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    mock: PropTypes.object,
  }

  refreshDateRange = () => {
    // If using one of the "LAST X" presets, update the date range if the day rolls over
    const { dateRangeKey } = this.state
    const option = Object.values(DefaultOptions).find((o) => o.key === dateRangeKey)
    switch (option?.key) {
      case '0d':
      case '-1d':
      case '-7d':
      case '-30d':
      case '-365d':
        this.dateRangeRefreshTimeout = setTimeout(() => {
          this.setState({
            dateRange: option.dates(),
          })
        }, 60 * 1000)
    }
  }

  componentDidMount() {
    this.refreshDateRange()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dateRange !== this.state.dateRange) {
      clearTimeout(this.dateRangeRefreshTimeout)
      this.refreshDateRange()
    }
  }

  componentWillUnmount() {
    clearTimeout(this.dateRangeRefreshTimeout)
  }

  toggleExportPanel = () => {
    document.body.click()
    this.setState({
      exportOpen: !this.state.exportOpen,
    })
  }

  selectResponse = (r) => {
    const { location, history } = this.props
    redirect({
      location,
      history,
      pathname: '/results/' + r,
    })
  }

  changeFilter = ({ formData }) => {
    const filter = {
      ...formData,
    }
    // remove 'any' states
    if (filter.progress === 'any') {
      delete filter.progress
    }
    if (filter.status === 'any') {
      delete filter.status
    }
    if (filter.notifications === 'any') {
      delete filter.notifications
    }

    if (Object.keys(filter).length === 0) {
      this.setState({
        filter: null,
      })
    } else {
      this.setState({
        filter,
      })
    }

    if (window.localStorage) {
      window.localStorage.setItem('results-filter', JSON.stringify(filter))
    }

    const { location, history } = this.props
    redirect({
      location,
      history,
      pathname: '/results/',
    })
  }

  changeDate = (value, key) => {
    const { location, history } = this.props
    this.setState({
      dateRange: value,
      dateRangeKey: key,
    })

    if (window.localStorage) {
      if (key) {
        window.localStorage.setItem('results-daterange-key', key)
      } else {
        window.localStorage.setItem('results-daterange-start', value[0].toISOString())
        window.localStorage.setItem('results-daterange-end', value[1].toISOString())
      }
    }

    redirect({
      location,
      history,
      pathname: '/results/',
    })
  }

  render() {
    return (
      <Results
        {...this.state}
        mock={this.props.mock}
        selectResponse={this.selectResponse}
        toggleExportPanel={this.toggleExportPanel}
        changeDate={this.changeDate}
        changeFilter={this.changeFilter}
        filter={this.state.filter}
        selectedResponse={
          this.props.mock?.selectedResponse.response._id || this.props.match?.params?.responseId
        }
        activeTab={this.props.match?.params.tab}
      />
    )
  }
}

export default ResultsViewContainer
