import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import Panel, { PanelForm } from '../../components/Panel'
import { Button } from 'reactstrap'
import IntegrationAdapters from '../../../../_shared/models/IntegrationAdapters/IntegrationAdapters'
import NotificationSchema from '../../../../_shared/models/notification/NotificationSchema'
import { redirect } from '../../components/Link'
import View from '../../components/View'
import FormError from '../../../../_shared/components/Form/FormError'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import { sort } from 'fast-sort'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'

const FormSchema = Object.assign({}, NotificationSchema)
FormSchema.required = ['name', 'type']

export default class NotificationCreate extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    mock: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }
    const { pathway } = this.props

    const doc = Object.assign({}, data.formData)
    doc.pathwayId = pathway._id

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/notifications/create', { doc })
        const { location, history } = this.props
        redirect({
          location,
          history,
          pathname: `/pathways/${this.props.pathway._id}/notifications`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway } = this.props
    const title = `New Notification`
    return (
      <View layout="sidebar" title={title}>
        <PageHeader
          breadcrumbs={[
            {
              label: pathway.name,
              path: `/pathways/${pathway._id}/notifications`,
            },
          ]}
          title={title}
        />
        <div className="content">
          <Panel>
            <SubscriptionLoader
              resource="integrations"
              cache={shortCache}
              query={{ companyId: pathway.companyId }}
              sort={{ name: 1 }}
              showLoader={true}
              fields={['_id', 'name']}
              transform={(store) => ({
                ...store,
                integrations: sort(store.integrations).asc((r) => r.name),
              })}
            >
              {({ data }) => {
                return (
                  <Form formData={{}} schema={FormSchema} onValidSubmit={this.onSubmit}>
                    <PanelForm center={false}>
                      <Input type="text" name="name" />
                    </PanelForm>
                    <Cardbox name="type" thin onChange={this.changeType}>
                      {data.integrations?.length > 0 &&
                        data.integrations?.map((i) => {
                          return (
                            <CardboxItem
                              key={i._id}
                              value={i._id}
                              title={i.name}
                              description={i.description}
                              image="/images/globe_cog.png"
                            />
                          )
                        })}
                      {IntegrationAdapters.getAdapters().map((i) => {
                        return (
                          <CardboxItem
                            key={i.key}
                            value={i.key}
                            title={i.name}
                            description={i.description}
                            image={`/images/${i.image}.png`}
                          />
                        )
                      })}
                    </Cardbox>
                    {this.renderError()}

                    <hr />
                    <Button color="primary" type="submit" disabled={this.state.isLoading}>
                      Create Notification
                    </Button>
                  </Form>
                )
              }}
            </SubscriptionLoader>
          </Panel>
        </div>
      </View>
    )
  }
}
