import React from 'react'
import styled from 'styled-components'

const StatDiv = styled.div`
  margin: 0 0 20px 0;
  &:first-child {
    padding-left: 15px;
  }
  .stat-title {
    font-size: 1em;
  }
  .stat-value {
    color: var(--heading-color);
    font-size: 1.8em;
    font-weight: bold;
  }
  .stat-big {
    font-size: 2em;
  }
`

interface Props {
  label?: string
  value?: number
  prefix?: string
  postfix?: string
  large?: boolean
}

const Stat: React.FC<Props> = (props) => {
  const { label, value, prefix, postfix, large } = props
  return (
    <StatDiv>
      <div className="stat-title">{label}</div>
      <div className={'stat-value ' + (large && 'stat-big')}>
        {prefix && prefix}
        {value}
        {postfix && postfix}
      </div>
    </StatDiv>
  )
}

export default Stat
