import { parseISO } from 'date-fns'

export interface AttemptResult {
  processedAt: Date
  status: string
  error: string
  context: string | Record<string, unknown>
}

export default class IntegrationAttempt {
  _id: string
  companyId: string
  responseId: string
  notificationId: string
  personId: string
  name: string
  type: string
  lastStatus: string
  lastProcessedAt: Date
  isCancelled: boolean
  isRetrying?: boolean
  trigger: string
  numTries: number
  results: AttemptResult[]

  constructor(props: NonFunctionProperties<IntegrationAttempt>) {
    Object.assign(this, props)
    if (typeof props.lastProcessedAt === 'string') {
      this.lastProcessedAt = parseISO(props.lastProcessedAt)
    }
    if (props.results) {
      this.results = props.results.map((r) => ({
        ...r,
        processedAt: typeof r.processedAt === 'string' ? parseISO(r.processedAt) : r.processedAt,
      }))
    }
  }
}
