import React from 'react'
import styled from 'styled-components'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import PersonaPanel from '../PersonaPanel'
import { PersonaColors } from '../PersonaColors'

interface CareerMotivation {
  label: string
  value: number
  total: number
}

interface CareerMotivationTableProps {
  label: string
  motivations: CareerMotivation[]
}

const CareerMotivationTable: React.FC<CareerMotivationTableProps> = ({ label, motivations }) => {
  return (
    <div>
      <div className="career-motivation-label">{label}</div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th>Change</th>
            <th>Prospects</th>
            <th colSpan={2}>% of Total</th>
          </tr>
        </thead>
        <tbody>
          {motivations?.map((motivation) => (
            <tr key={motivation.label}>
              <td key={motivation.label}>{motivation.label}</td>
              <td width={100}>{motivation.value.toLocaleString()}</td>
              <td width={100}>{Math.round((motivation.value / motivation.total) * 100)}%</td>
              <td width={250}>
                <div className="career-motivations-bar">
                  <div
                    className="career-motivations-bar-inner"
                    style={{ width: `${Math.round((motivation.value / motivation.total) * 100)}%` }}
                  ></div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

interface Props {
  className?: string
  persona: Persona
}
const PersonaCareerMotivations: React.FC<Props> = ({ className, persona }) => {
  let motivations = Object.entries(persona.desiredChange).map(([key, value]) => {
    return {
      label: key,
      value,
      total: persona.sampleSize,
    }
  })

  motivations = motivations.sort((a, b) => {
    if (a.value < b.value) return 1
    if (a.value > b.value) return -1
    return 0
  })

  return (
    <div className={className}>
      <PersonaPanel title="Career Motivations">
        <CareerMotivationTable
          label="What would you change about your current situation?"
          motivations={motivations}
        />
      </PersonaPanel>
    </div>
  )
}

export default styled(PersonaCareerMotivations)`
  font-size: 0.875rem;

  .career-motivation-label {
    color: #505050;
    font-weight: 600;
    margin: 1.8em 0 0.4em 0;
    font-size: 1rem;
  }

  .career-motivations-bar {
    background: #eaeaea;
    height: 14px;
    border-radius: 6px;
    overflow: hidden;
  }

  .career-motivations-bar-inner {
    background: #029af2;
    height: 100%;
    border-radius: 6px;
  }

  .table,
  th {
    border-top: 0;
  }

  th {
    color: #ababab;
    font-size: 0.75rem;
  }

  td {
    font-size: 0.875rem;
    color: #393939;
    vertical-align: middle;
    font-weight: 600;

    &:first-child {
      font-weight: 400;
    }
  }
`
