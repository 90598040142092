import React from 'react'
import WidthOptions from './Width/WidthOptions'
import AlignmentOptions from './Alignment/AlignmentOptions'
import BackgroundOptions from './Background/BackgroundOptions'
import MarginOptions from './Margin/MarginOptions'
import PaddingOptions from './Padding/PaddingOptions'
import SettingsSection from './SettingsSection'
import HeightOptions from './Height/HeightOptions'
import TextOptions from './Text/TextOptions'
import VisibilityOptions from './Visibility/VisibilityOptions'

const RowAppearanceSettings = (props) => {
  let columns = 0
  switch (props.block.data.config.layout) {
    case 'one':
      columns = 1
      break
    case 'two':
      columns = 2
      break
    case 'three':
      columns = 3
      break
    case 'four':
      columns = 4
      break
    case 'six':
      columns = 5
      break
  }

  return (
    <React.Fragment>
      <TextOptions path="appearance" />
      <BackgroundOptions path="appearance" />
      <WidthOptions
        path="appearance"
        options={[
          { label: 'Default', value: 'container' },
          { label: 'Full', value: 'full' },
          { label: 'Custom', value: 'custom' },
        ]}
      />
      <HeightOptions path="appearance" />
      <AlignmentOptions path="appearance" />
      <MarginOptions path="appearance" />
      <PaddingOptions path="appearance" />
      <VisibilityOptions path="visibility" />

      {Array.apply(null, { length: columns }).map((a, index) => {
        const path = `config.column${index}`
        return (
          <SettingsSection title={`Column ${index + 1}`} key={index} indent>
            <BackgroundOptions path={path} />
            <WidthOptions
              path={path}
              options={[
                { label: 'Default', value: 'default' },
                { label: 'Custom', value: 'custom' },
              ]}
            />
            <HeightOptions path={path} />
            <AlignmentOptions path={path} />
            <MarginOptions path={path} />
            <PaddingOptions path={path} />
          </SettingsSection>
        )
      })}
    </React.Fragment>
  )
}

export default RowAppearanceSettings
