import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { AppState } from '../types'

interface Props {
  className?: string
  isReconnecting?: boolean
}

interface State {
  isReconnecting: boolean
}

class DisconnectedNotice extends React.Component<Props, State> {
  state = {
    isReconnecting: false,
  }

  _timeout = null

  componentDidUpdate(prevProps) {
    if (prevProps.isReconnecting !== this.props.isReconnecting) {
      clearTimeout(this._timeout)
      if (this.props.isReconnecting) {
        // wait 10 seconds before setting the state to true
        this._timeout = setTimeout(() => {
          this.setState({
            isReconnecting: true,
          })
        }, 10000)
      } else {
        this.setState({
          isReconnecting: false,
        })
      }
    }
  }

  render() {
    if (this.state.isReconnecting !== true) {
      return null
    }

    return (
      <div className={classNames(['alert', 'alert-danger', this.props.className])}>
        <strong>Lost connection to VirtualAdviser.</strong> Trying to reconnect...
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  isReconnecting: state.isReconnecting,
})

const DisconnectedNoticeWithStore = connect(mapStateToProps)(DisconnectedNotice)

export default styled(DisconnectedNoticeWithStore)`
  margin: 0;
  border-radius: 0;
  border-top: 0;
  font-size: 0.875rem;
`
