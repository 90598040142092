import React from 'react'
import PropTypes from 'prop-types'
import PathwayView from './views/pathway/PathwayView'
import PathwayAPI from './views/pathway/PathwayAPI'
import StepCreate from './views/step/StepCreate'
import ReportCreate from './views/report/ReportCreate'
import NotificationCreate from './views/notification/NotificationCreate'
import NotificationUpdate from './views/notification/NotificationUpdate'
import { Route, Switch } from 'react-router-dom'
import NotFound from './views/NotFound'
import GoalCreate from './views/goal/GoalCreate'
import GoalUpdate from './views/goal/GoalUpdate'
import PathwayVars from './views/pathway/PathwayVars'
import TrackingCodeCreate from './views/trackingCode/TrackingCodeCreate'
import TrackingCodeUpdate from './views/trackingCode/TrackingCodeUpdate'
import SubscriptionLoader from './containers/SubscriptionLoader'
import { useSelector } from 'react-redux'
import { longCache, shortCache } from './api/subscriptionCache'

const PathwayRoute = ({ component: Component, pathway, ...rest }) => (
  <Route {...rest} render={(props) => <Component pathway={pathway} {...props} />} />
)

const DataLoader = (props) => {
  const company = useSelector((state) => state.subscriptions.companyContext.data.companies[0])
  if (!props.match) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="pathway"
      query={{
        _id: props.match.params.pathwayId,
        companyId: company._id,
      }}
      cache={shortCache}
      showLoader={true}
    >
      {({ data, subscription }) => {
        const { pathways } = data
        if (!pathways || pathways.length === 0) {
          return <NotFound />
        }
        const pathway = pathways[0]
        const prefix = props.prefix || ''
        return (
          <Switch>
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/api`}
              exact
              component={PathwayAPI}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/vars`}
              exact
              component={PathwayVars}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/newStep`}
              exact
              component={StepCreate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/goals/:goalId`}
              exact
              component={GoalUpdate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/newGoal`}
              exact
              component={GoalCreate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/newReport`}
              exact
              component={ReportCreate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/trackingCodes/new`}
              exact
              component={TrackingCodeCreate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/trackingCodes/:codeId`}
              exact
              component={TrackingCodeUpdate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/notifications/new`}
              exact
              component={NotificationCreate}
              pathway={pathway}
            />
            <PathwayRoute
              path={`${prefix}/pathways/:pathwayId/notifications/:notificationId`}
              exact
              component={NotificationUpdate}
              pathway={pathway}
            />
            <Route
              render={(props) => (
                <PathwayView {...props} company={company} pathway={pathway} routePrefix={prefix} />
              )}
            />
          </Switch>
        )
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  match: PropTypes.object,
  company: PropTypes.object,
}

export default DataLoader
