import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../components/Panel'
import { Button } from 'reactstrap'
import PageHeader from '../../components/PageHeader'
import ReportSchema from '../../../../_shared/models/report/ReportSchema'
import View from '../../components/View'
import { redirect } from '../../components/Link'
import FormError from '../../../../_shared/components/Form/FormError'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import apiFetch from '../../../../_shared/api/apiFetch'

const { name } = ReportSchema.properties

const ReportFormSchema = {
  type: 'object',
  properties: { name },
  required: ['name'],
}

class ReportCreate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { _id: pathwayId } = this.props.pathway
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const result = await apiFetch('POST', '/reports/create', {
          companyId: this.props.pathway.companyId,
          pathwayId,
          doc: data.formData,
        })
        const { location, history } = this.props

        redirect({
          location,
          history,
          pathname: `/pathways/${pathwayId}/reports/${result.reportId}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway } = this.props

    const title = `New Summary`
    return (
      <View layout="sidebar" title={title}>
        <PageHeader
          breadcrumbs={[{ label: pathway.name, path: `/pathways/${pathway._id}/reports` }]}
          title={title}
        />
        <div className="content">
          <Panel>
            <PanelForm>
              <Form formData={{}} schema={ReportFormSchema} onValidSubmit={this.onSubmit}>
                <Input type="text" name="name" />

                <hr />
                {this.renderError()}

                <Button size="lg" color="primary" type="submit" disabled={this.state.isLoading}>
                  Create Summary
                </Button>
              </Form>
            </PanelForm>
          </Panel>
        </div>
      </View>
    )
  }
}

ReportCreate.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default ReportCreate
