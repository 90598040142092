import React from 'react'
import SettingsSection from '../SettingsSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowToTop } from '@fortawesome/pro-solid-svg-icons/faArrowToTop'
import { faArrowToLeft } from '@fortawesome/pro-solid-svg-icons/faArrowToLeft'
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons/faArrowToRight'
import { faArrowToBottom } from '@fortawesome/pro-solid-svg-icons/faArrowToBottom'
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows'
import PropTypes from 'prop-types'
import { FormGroup } from 'reactstrap'
import styled from 'styled-components'
import classnames from 'classnames'
import {
  FormErrors,
  FormLabel,
  getFormGroupClasses,
  HelpText,
  ValidFeedback,
} from '../../../_shared/components/Form/helpers'
import withForm from '../../../_shared/components/withForm'
import Colorpicker from '../../../_shared/components/Form/Colorpicker'
import ImageUpload from '../../../_shared/components/Form/ImageUpload'
import ButtonGroup, { ButtonGroupButton } from '../../../_shared/components/Form/ButtonGroup'

class ImageAlignment extends React.PureComponent {
  static propTypes = {
    showLabel: PropTypes.bool,
    label: PropTypes.string,
    id: PropTypes.string,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    errors: PropTypes.array,
    showValid: PropTypes.bool,
    validFeedback: PropTypes.string,
    onChange: PropTypes.func,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    formSchema: PropTypes.object,
    className: PropTypes.string,
  }

  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  render() {
    const {
      showLabel,
      name,
      label,
      formSchema,
      id,
      errors,
      showValid,
      help,
      validFeedback,
      value,
      className,
    } = this.props

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid) + ' ' + className}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />

        <div className="button-container">
          <div className="row text-center">
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'left top',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'left top')
                }}
              >
                <FontAwesomeIcon icon={faArrowToTop} transform="rotate--45" />
              </div>
            </div>
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'center top',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'center top')
                }}
              >
                <FontAwesomeIcon icon={faArrowToTop} />
              </div>
            </div>
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'right top',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'right top')
                }}
              >
                <FontAwesomeIcon icon={faArrowToTop} transform="rotate-45" />
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'left center',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'left center')
                }}
              >
                <FontAwesomeIcon icon={faArrowToLeft} />
              </div>
            </div>
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'center center',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'center center')
                }}
              >
                <FontAwesomeIcon icon={faArrows} />
              </div>
            </div>
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'right center',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'right center')
                }}
              >
                <FontAwesomeIcon icon={faArrowToRight} />
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'left bottom',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'left bottom')
                }}
              >
                <FontAwesomeIcon icon={faArrowToBottom} transform="rotate-45" />
              </div>
            </div>
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'bottom center',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'bottom center')
                }}
              >
                <FontAwesomeIcon icon={faArrowToBottom} />
              </div>
            </div>
            <div className="col">
              <div
                className={classnames('button', {
                  active: value === 'right bottom',
                })}
                onClick={() => {
                  this.props.setValue(this.props, 'right bottom')
                }}
              >
                <FontAwesomeIcon icon={faArrowToBottom} transform="rotate--45" />
              </div>
            </div>
          </div>
        </div>

        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

const StyledImageAlignment = styled(withForm(ImageAlignment))`
  .button-container {
    width: 170px;
  }
  .button {
    height: 36px;
    width: 36px;
    line-height: 35px;
    text-align: center;
    margin: 0.3rem 0;
    cursor: pointer;
    border-radius: 3px;
    display: inline-block;
    &:hover {
      color: var(--primary);
    }
    &.active {
      background-color: var(--primary);
      color: #fff;
    }
  }
`

const BackgroundOptions = ({ path }) => (
  <SettingsSection title="Background">
    <Colorpicker name={`${path}.background.color`} />
    <ImageUpload name={`${path}.background.image`} label="Image" />
    <ButtonGroup
      name={`${path}.background.fillStyle`}
      label="Image Fill Style"
      defaultValue={'fill'}
    >
      <ButtonGroupButton label="Fill" value="fill" />
      <ButtonGroupButton label="Fit" value="fit" />
      <ButtonGroupButton label="Actual size" value="actualSize" />
    </ButtonGroup>
    <ButtonGroup name={`${path}.background.repeat`} label="Image Repeat" defaultValue={'no-repeat'}>
      <ButtonGroupButton label="None" value="no-repeat" />
      <ButtonGroupButton label="Repeat" value="repeat" />
    </ButtonGroup>
    <ButtonGroup name={`${path}.background.attachment`} label="Attachment" defaultValue={'scroll'}>
      <ButtonGroupButton label="Scroll" value="scroll" />
      <ButtonGroupButton label="Fixed" value="fixed" />
    </ButtonGroup>

    <StyledImageAlignment
      name={`${path}.background.align`}
      label="Image Alignment"
      defaultValue={'left top'}
    />
  </SettingsSection>
)

export default BackgroundOptions
