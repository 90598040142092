import React from 'react'
import ButtonGroup, { ButtonGroupButton } from '../../../_shared/components/Form/ButtonGroup'
import SettingsSection from '../../settings/SettingsSection'

const AlignmentOptions = ({ path }) => (
  <SettingsSection title="Alignment">
    <ButtonGroup name={`${path}.align`} label="Alignment" defaultValue={'center'} showLabel={false}>
      <ButtonGroupButton label="Left" value="left" color="outline-secondary" />
      <ButtonGroupButton label="Center" value="center" color="outline-secondary" />
      <ButtonGroupButton label="Right" value="right" color="outline-secondary" />
    </ButtonGroup>
  </SettingsSection>
)

export default AlignmentOptions
