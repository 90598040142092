import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import apiFetch from '../../../../_shared/api/apiFetch'

const schema = {
  type: 'object',
  properties: {
    reason: {
      type: 'string',
      title: 'Please provide a reason this result is invalid:',
    },
  },
  required: ['reason'],
  additionalProperties: false,
}

class FlagInvalidModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }

  static propTypes = {
    response: PropTypes.object.isRequired,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  flagInvalid = async ({ formData }) => {
    try {
      await apiFetch('POST', '/responses/invalid', {
        responseId: this.props.response._id,
        reason: formData.reason,
      })
      this.toggle()
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { response, tag, tagProps } = this.props

    const HandleTag = tag || 'div'
    return (
      <div>
        <HandleTag {...tagProps} onClick={this.toggle}>
          <FontAwesomeIcon icon={faBan} fixedWidth />
          Flag as invalid
        </HandleTag>
        <Modal centered isOpen={this.state.modal} toggle={this.toggle}>
          <Form formData={{}} schema={schema} onValidSubmit={this.flagInvalid}>
            <ModalHeader toggle={this.toggle}>Flag as invalid</ModalHeader>
            <ModalBody>
              <Input
                type="text"
                name="reason"
                multipleLines
                help="Invalid leads are excluded from statistics and billing calculations"
              />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Flag as invalid
              </Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    )
  }
}

export default FlagInvalidModal
