import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly'

import Link from '../../Link'
import { withRouter } from 'react-router-dom'
import Form from '../../../../../_shared/components/Form'
import apiFetch from '../../../../../_shared/api/apiFetch'
import Loader from '../../Loader'
import styled from 'styled-components'
import HelpToolTip from '../../HelpTooltip'
import PersonaSelector from './PersonaSelector'
import TargetAudiencePreview from './TargetAudiencePreview'

import {
  amiableTone,
  analyticalTone,
  driverTone,
  expressiveTone,
} from '../../../../server/methods/generateAiCopy/utils/selectedToneDescription'
import AILPForm from './AILPForm'
import { Persona } from '../../../../../_shared/models/persona/persona.types'
import ClientPathway from '../../../../../_shared/models/pathway/pathway.client'
import { Company } from '../../../../../_shared/models/company/company.client'

const AiCopyFormSchema = {
  title: 'Step',
  type: 'object',
  properties: {
    targetAudience: {
      title: 'Target Audience',
      type: 'array',
    },
    program: {
      type: 'object',
    },
    companyName: {
      title: 'Company Name',
      type: 'string',
    },
    programPageUrl: {
      title: 'Website Program Page (Recommended)',
      type: 'string',
    },
    keywords: {
      title: 'Keywords (Optional)',
      type: 'string',
    },
    // additionalContext: {
    //   title: 'Additional Context (Optional)',
    //   type: 'string',
    // },
    // targetProgram: {
    //   title: 'Target Program',
    //   type: 'array',
    // },
    // targetPathway: {
    //   title: 'Pathway for Audience Data',
    //   type: 'object',
    // },
    selectedTone: {
      title: 'Select Tone',
      type: 'string',
    },
    iconColor: {
      title: 'Icon Color',
      type: 'object',
    },
  },
  required: ['program', 'companyName'],
  additionalProperties: false,
}
interface AiCopyModalProps {
  pathway: ClientPathway
  tag?: React.ElementType
  company?: Company
  location?: Location
  history?: History
  staticContext?: any
  match?: any
  buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>
}

interface AiCopyModalState {
  error: string | null
  modal: boolean
  redirect: string | null
  isLoading: boolean
  complete: string | null
  colors: { value: string; label: string }[]
  iconColor: string | null
  personas: Persona[] | null
  selectedPersona: string | null
  selectedTone: string | null
}
class AiCopyModal extends React.Component<AiCopyModalProps, AiCopyModalState> {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      modal: false,
      redirect: null,
      isLoading: false,
      complete: null,
      colors: [],
      iconColor: null,
      personas: null,
      selectedPersona: null,
      selectedTone: null,
    }
  }

  async fetchColors() {
    try {
      const colors = await getIconColourFromPathwaySections(this.props.pathway)
      this.setState({ colors })
      this.setState({ iconColor: colors[0] })
    } catch (error) {
      console.error('Error fetching colors:', error)
    }
  }
  onColorChange = (color) => {
    if (this.state.iconColor !== color) {
      this.setState({ iconColor: color })
    }
  }
  handleSelect = (personaId) => {
    const persona = this.state.personas.find((persona) => persona._id === personaId)
    if (persona) {
      this.setState({
        selectedPersona: personaId,
        selectedTone: this.findHighestSocialStyle(persona.socialStyles),
      })
    }
  }

  setSelectedTone = (tone) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedTone: tone,
    }))
  }

  async fetchPersonas() {
    try {
      const personasGroup = await apiFetch('GET', '/statistics/persona/', null, {
        companyId: this.props.company._id,
      })
      const personas = personasGroup.personas
      this.setState({ personas })
      const overallPersona = personas.find((persona) => persona.category === 'overall')
      if (overallPersona) {
        this.setState({
          selectedPersona: overallPersona._id,
          selectedTone: this.findHighestSocialStyle(overallPersona.socialStyles),
        })
      }
    } catch (e) {
      console.error('Error fetching personas:', e)
    }
  }
  findHighestSocialStyle = (socialStyle) => {
    if (!socialStyle) {
      return 'analytical'
    }
    return Object.keys(socialStyle).reduce((a, b) => (socialStyle[a] > socialStyle[b] ? a : b))
  }

  toggle = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      async () => {
        if (this.state.modal) {
          await this.fetchColors()
          await this.fetchPersonas()
        } else {
          document.body.click()
        }
      }
    )
  }

  generate = async (data) => {
    const { pathway, location, history } = this.props
    const { isLoading } = this.state
    if (!pathway || isLoading) return

    this.setState(
      {
        isLoading: true,
        complete: null,
        error: null,
      },
      async () => {
        try {
          const iconColor = this.state.iconColor
          const personaToUse = this.state.personas.find(
            (persona) => persona._id === this.state.selectedPersona
          )
          const targetAudience = personaToUse ? personaToUse.targetAudience : null

          const result = await apiFetch(
            'POST',
            '/pathways/aiGenerate',
            {
              ...data.formData,
              iconColor,
              pathwayId: pathway._id,
              persona: this.state.selectedPersona,
              targetAudience,
              selectedTone: this.state.selectedTone,
            },
            null,
            true
          )
          this.setState({
            isLoading: false,
            complete: result.pathwayId,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e.message,
          })
        }
      }
    )
  }

  render() {
    const { pathway, company, tag, location, history, staticContext, match, buttonProps, ...rest } =
      this.props
    const HandleTag = tag || 'div'
    const { colors, iconColor, selectedTone } = this.state
    const persona = this.state.personas
      ? this.state.personas.find((persona) => persona._id === this.state.selectedPersona)
      : null

    return (
      <div style={{ position: 'relative' }}>
        <HandleTag {...buttonProps} onClick={this.toggle}>
          <FontAwesomeIcon fixedWidth icon={faBracketsCurly} /> Generate AI Variation
        </HandleTag>

        <AILPModal isOpen={this.state.modal} toggle={this.toggle}>
          <Form
            schema={AiCopyFormSchema}
            onValidSubmit={this.generate}
            formData={{ companyName: company?.name }}
          >
            <ModalHeader toggle={this.toggle}>Generate AI Variation</ModalHeader>
            <AILPModalBody>
              <div className="row" style={{ borderBottom: '6px solid #41566F', margin: '1rem' }}>
                <AILPColumn>
                  {this.state.personas ? (
                    <PersonaSelector
                      personas={this.state.personas}
                      selected={this.state.selectedPersona}
                      onSelect={this.handleSelect}
                    />
                  ) : null}
                </AILPColumn>
                <AILPColumn>
                {persona?.targetAudience ? (
                    <TargetAudiencePreview persona={persona} />
                  ) : (
                    <p>
                    Error retrieving target audience.
                    <br /> Please contact support
                  </p>
                )}
                </AILPColumn>
                {/* THIS IS THE FORM */}
                <AILPColumn>
                  <AILPForm
                    company={company}
                    onColorChange={(color) => this.setState({ iconColor: color })}
                    colors={colors}
                    iconColor={iconColor}
                    setSelectedTone={(tone) => this.setState({ selectedTone: tone })}
                    selectedTone={selectedTone}
                  />
                </AILPColumn>
              </div>
            </AILPModalBody>

            <AILPModalFooter>
              {this.state.isLoading && (
                <AILPAlertToast>
                  <Loader />
                  <div
                    className="alert alert-info"
                    role="alert"
                    style={{
                      margin: '0.25rem 1rem',
                      padding: '0.25rem',
                      textAlign: 'center',
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      // typescript doesn't like textWrap, but it's a valid property
                      textWrap: 'balance',
                    }}
                  >
                    Your page is currently being generated. This process usually completes within
                    1-2 minutes, though it may take up to 10 minutes on occasion. Should you
                    encounter any issues, please attempt to submit your request again. You may
                    prepare another submission while you wait. We will notify you via email once
                    your new page is ready.
                  </div>
                </AILPAlertToast>
             )}

              {this.state.error && (
                <AILPAlertToast>
                  <div className="alert alert-danger" role="alert" style={{ margin: '0' }}>
                    {this.state.error}
                  </div>
                </AILPAlertToast>
              )}
              {this.state.complete && (
                <AILPAlertToast>
                  <div className="alert alert-primary" role="alert" style={{ margin: '0' }}>
                    Generation complete! &nbsp;&nbsp;
                    <Link to={`/pathways/${this.state.complete}`}>View your new pathway</Link>
                  </div>
                </AILPAlertToast>
             )}
              <Button color="secondary" onClick={this.toggle}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={this.state.isLoading}>
                Generate Variation
              </Button>
            </AILPModalFooter>
          </Form>
        </AILPModal>
      </div>
    )
  }
}

export default withRouter(AiCopyModal)

const getIconColourFromPathwaySections = async (pathway: ClientPathway) => {
  try {
    //gets unique colors from the pathway section backgrounds that arent grayscale
    const uniqueColors = []

    // Check if pathway has steps
    if (pathway.steps) {
      // Iterate over steps
      Object.values(pathway.steps).forEach((step) => {
        // Check if step has content
        if (step.content) {
          // Iterate over content
          step.content.forEach((contentItem: any) => {
            // Check if content item is a Section with a defined background color
            if (contentItem.type === 'Section' && contentItem.data?.appearance?.background?.color) {
              const color = contentItem.data.appearance.background.color
              if (!containsColor(uniqueColors, color) && !isGrayscale(color.r, color.g, color.b)) {
                uniqueColors.push(color)
              }
            }
          })
        }
      })
    }
    if (uniqueColors.length === 0) {
      uniqueColors.push({ r: 0, g: 0, b: 0, a: 1 }) // Add black color if no non-greyscale colors found
    }
    return uniqueColors
  } catch (e) {
    console.error('Error fetching colors:', e)
    throw new Error('Error fetching colors')
  }
  function isGrayscale(r, g, b, tolerance = 10) {
    const maxDiff = tolerance
    return Math.abs(r - g) <= maxDiff && Math.abs(g - b) <= maxDiff && Math.abs(b - r) <= maxDiff
  }
  function containsColor(array, color) {
    return array.some(
      (c) => c.r === color.r && c.g === color.g && c.b === color.b && c.a === color.a
    )
  }
}

export const SpecialHelpToolTip = styled(HelpToolTip)`
  .help-tooltip {
    padding: 2rem;
    left: auto;
    right: 0;
    background-color: #fff;
    color: #000;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.6);
  }
`
const modalSize = {
  width: 'calc(100vw - 4rem)',
  height: 'calc(100vh - 4rem)',
}
const AILPModal = styled(Modal)`
  margin: 2rem;
  padding: 0;
  .modal-dialog {
    width: 100vw !important;
    max-width: 100vw !important;
  }

  .modal-content {
    position: relative;
    padding: 0.75rem;
    width: ${modalSize.width} !important;
    
  }
  .modal-body {
    // this had a position of relative , the loader needed to be aligned with the parents position
    position: static;
    margin: 0;
    padding: 0;
  }
  .row {
    height: 100%;
    display: flex;
    flex-direction: row;
    @media (width < 926px){
      flex-direction: column;
    }
  }
`
const AILPModalBody = styled(ModalBody)`
  width: 100%;
`

const AILPColumn = styled.div`
  max-height: calc(${modalSize.height} - 14rem);
  padding: 0 1rem;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  @media (width < 926px){
    flex-basis: auto;
    max-height: 100%;
    overflow-y: hidden;
  }
`
const AILPModalFooter = styled(ModalFooter)`
  position: relative;
  min-height: 6rem !important;
  padding: 1rem;
  padding-top: 0;
  margin-top: 0;
  border-top: none;
`
const AILPAlertToast = styled.div`
  width: 70%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  vertical-align: middle;
  z-index: 1000;
`
