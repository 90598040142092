import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import PageActions, { PageAction } from '../../components/PageActions'
import Panel, { PanelHero } from '../../components/Panel'
import Link from '../../components/Link'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../components/RoundButton'
import PathwayManageDropdown from './PathwayManageDropdown'
import View from '../../components/View'
import { PanelList, PanelListItem } from '../../components/PanelList'
import DeleteCampaignModal from '../../components/modals/DeleteCampaignModal'
import { GlobalCampaigns } from '../../../../_shared/models/campaign/campaign.client'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import { longCache, shortCache } from '../../api/subscriptionCache'

const FirstPathway = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/map.png" width="128" />
      <h3>Create your first pathway</h3>
      <h6>Gather prospects, view statistics, insights and more</h6>
      <Link className="btn btn-lg btn-primary" to="/pathways/new">
        <FontAwesomeIcon icon={faPlus} /> New Pathway
      </Link>
    </PanelHero>
  </Panel>
)

const FirstCampaign = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/map.png" width="128" />
      <h3>Create your first campaign</h3>
      <h6>Campaigns group pathways for a common goal</h6>
      <Link className="btn btn-lg btn-primary" to="/campaigns/new">
        <FontAwesomeIcon icon={faPlus} /> New Campaign
      </Link>
    </PanelHero>
  </Panel>
)

const CampaignHeader = styled(({ className, campaign }) => (
  <div className={className}>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <h2>{campaign.name}</h2>
      </div>
      {!GlobalCampaigns[campaign._id] && (
        <div className="d-none d-md-flex col-auto buttons">
          <RoundButton size="sm" tag={Link} to={`/campaigns/${campaign._id}`}>
            <FontAwesomeIcon icon={faPencil} />
          </RoundButton>
          <DeleteCampaignModal
            buttonTag={RoundButton}
            buttonProps={{ size: 'sm' }}
            campaign={campaign}
          />
        </div>
      )}
    </div>
  </div>
))`
  background: #fff;
  border-radius: 3px;
  padding: 1rem;
  margin: 2rem 0 0 0;
  border-bottom: 2px solid #f0f2f6;

  h2 {
    font-size: 1.3rem;
    margin: 0;
  }

  &:first-child {
    margin: 0;
  }

  .buttons .btn {
    margin-right: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }
`

const PathwayC = (props) => (
  <PanelListItem className={props.className}>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <Link className="panel-list-item-title" to={`/pathways/${props.pathway._id}`}>
          {props.pathway.name}
        </Link>
        <div className="url">
          <a
            href={`https://${props.pathway.getPrimaryURL()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            https://
            {props.pathway.getPrimaryURL()}
          </a>
        </div>
      </div>
      <div className="d-none d-md-flex col-auto buttons">
        <RoundButton size="sm" tag={Link} to={`/pathways/${props.pathway._id}/settings`}>
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <PathwayManageDropdown pathway={props.pathway} company={props.company} />
      </div>
    </div>
  </PanelListItem>
)
PathwayC.propTypes = {
  pathway: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  className: PropTypes.string,
}

const Pathway = styled(PathwayC)`
  .url a {
    font-size: 0.875rem;
    &,
    &:hover {
      color: var(--text-muted-blue);
    }
  }
  .buttons .btn {
    margin-right: 0.75rem;
  }

  .buttons .dropdown .btn {
    margin-right: 0;
  }
`

const PathwayIndex = (props) => {
  const title = 'Pathways'
  return (
    <View title={title}>
      <PageHeader title={title}>
        <PageActions>
          <PageAction toolbar button color="alt" tag={Link} tagProps={{ to: '/campaigns/new' }}>
            <FontAwesomeIcon icon={faPlus} /> New Campaign
          </PageAction>
          <PageAction toolbar button color="primary" tag={Link} tagProps={{ to: '/pathways/new' }}>
            <FontAwesomeIcon icon={faPlus} /> New Pathway
          </PageAction>
        </PageActions>
      </PageHeader>

      <div className="content">
        <SubscriptionLoader
          resource="campaigns"
          query={{ companyId: props.company._id }}
          sort={{ name: 1 }}
          transform={(store) => ({
            ...store,
            campaigns: sort(store.campaigns).desc((r) => r.name),
          })}
          showLoader={true}
          cache={shortCache}
        >
          {({ data }) => {
            const  {archive, ...campaignsWithoutArchive} = GlobalCampaigns
            const campaigns = [...Object.values(campaignsWithoutArchive), ...(data?.campaigns || []), archive]

            return (
              <SubscriptionLoader
                resource="pathways"
                query={{ companyId: props.company._id }}
                sort={{ name: 1 }}
                cache={shortCache}
                transform={(store) => ({
                  ...store,
                  pathways: sort(store.pathways).asc((r) => r.name),
                })}
                fields={['_id', 'name', 'campaignId', 'urls', 'type']}
                showLoader={true}
              >
                {({ data }) => {
                  if (!data?.pathways || data.pathways.length === 0) {
                    return <FirstPathway />
                  }

                  const pathwayCampaignMap = {}
                  data.pathways.forEach((pathway) => {
                    if (!pathwayCampaignMap[pathway.campaignId || 'none']) {
                      pathwayCampaignMap[pathway.campaignId || 'none'] = []
                    }
                    pathwayCampaignMap[pathway.campaignId || 'none'].push(pathway)
                  })

                  const pathwaysByCampaign = (
                    <React.Fragment>
                      {campaigns.map((campaign) => {
                        const campaignPathways = pathwayCampaignMap[campaign._id]
                        const hasPathways = campaignPathways?.length > 0

                        return (
                          <React.Fragment key={campaign._id}>
                            <CampaignHeader campaign={campaign} />
                            {!hasPathways && (
                              <div className="alert alert-info">No pathways in this campaign</div>
                            )}

                            <PanelList>
                              {campaignPathways &&
                                campaignPathways.map((pathway) => (
                                  <Pathway key={pathway._id} pathway={pathway} company={props.company} />
                                ))}
                            </PanelList>
                          </React.Fragment>
                        )
                      })}
                    </React.Fragment>
                  )

                  return <React.Fragment>{pathwaysByCampaign}</React.Fragment>
                }}
              </SubscriptionLoader>
            )
          }}
        </SubscriptionLoader>
      </div>
    </View>
  )
}

PathwayIndex.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export { Pathway, FirstPathway }
export default PathwayIndex
