import React from 'react'
import { Line } from 'react-chartjs-2'
import styled from 'styled-components'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import PersonaPanel from '../PersonaPanel'
import { PersonaColors } from '../PersonaColors'

interface Props {
  className?: string
  persona: Persona
}

const PersonaAge: React.FC<Props> = ({ className, persona }) => {
  // find the lowest and highest ages
  // fill in the blanks with zeros

  const chartData = []
  const chartLabels = []

  const chartDataChild = [],
    chartDataYoung = [],
    chartDataAdult = []

  if (persona.ageDistribution) {
    // let highestAge = 0
    // Object.keys(persona.ageDistribution).forEach((knownAge) => {
    //   if (Number(knownAge) > highestAge) {
    //     highestAge = Number(knownAge)
    //   }
    // })

    const isAgePersona = ['child', 'youngAdult', 'fullAdult'].includes(persona.category)

    // start at 0, loop to max age, lookup in agedistribution
    var startAge = 0,
      endAge = 0
    if (persona.metrics.youngAdult.value == 0 && persona.metrics.fullAdult.value == 0) {
      ;(startAge = 13), (endAge = 20)
    } else if (persona.metrics.child.value == 0 && persona.metrics.fullAdult.value == 0) {
      ;(startAge = 21), (endAge = 29)
    } else if (persona.metrics.child.value == 0 && persona.metrics.youngAdult.value == 0) {
      ;(startAge = 29), (endAge = 65)
    } else {
      ;(startAge = 13), (endAge = 65)
    }

    for (let i = startAge; i <= endAge; i++) {
      const data = persona.ageDistribution[i]
      chartLabels.push(i)
      chartData.push(data || 0)

      if (i == 20) {
        chartDataChild.push(data || 0)
        if (!isAgePersona) {
          chartDataChild.push(persona.ageDistribution[i + 1] || 0)
        }
        chartDataYoung.push(null)
        chartDataAdult.push(null)
      } else if (i == 29) {
        chartDataYoung.push(data || 0)
        if (!isAgePersona) {
          chartDataYoung.push(persona.ageDistribution[i + 1] || 0)
        }
        chartDataAdult.push(null)
      } else if (i < 21) {
        chartDataChild.push(data || 0)
        chartDataYoung.push(null)
        chartDataAdult.push(null)
      } else if (i >= 21 && i <= 29) {
        //chartDataChild.push(null)
        chartDataYoung.push(data || 0)
        chartDataAdult.push(null)
      } else {
        //chartDataChild.push(null)
        chartDataYoung.push(null)
        chartDataAdult.push(data || 0)
      }
    }

    // Object.entries(persona.ageDistribution).forEach(([key, value]) => {
    //   chartLabels.push(key)
    //   chartData.push(value)
    // })
  }

  const datasets = []

  if (['youngAdult', 'fullAdult'].includes(persona.category) === false) {
    datasets.push({
      label: 'Under 21',
      lineTension: 0.25,
      backgroundColor: PersonaColors.primary, //'#6fce4e',
      fill: true,
      borderColor: PersonaColors.primary, //'#4eb12b',
      borderWidth: 2,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: PersonaColors.primary,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: PersonaColors.primary,
      pointRadius: 0,
      pointHitRadius: 10,
      data: chartDataChild,
    })
  }

  if (['child', 'fullAdult'].includes(persona.category) === false) {
    datasets.push({
      label: '21-29',
      lineTension: 0.25,
      backgroundColor: PersonaColors.secondary, //'#ffd02a', //'#1EAFFF',
      fill: true,
      borderColor: PersonaColors.secondary, //'#edb800',
      borderWidth: 2,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: PersonaColors.secondary,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: PersonaColors.secondary,
      pointRadius: 0,
      pointHitRadius: 10,
      data: chartDataYoung,
    })
  }

  if (['child', 'youngAdult'].includes(persona.category) === false) {
    datasets.push({
      label: '30+',
      lineTension: 0.25,
      backgroundColor: PersonaColors.tertiary, //'#fd9131', //'#006DFF',
      fill: true,
      borderColor: PersonaColors.tertiary, //'#d26300',
      borderWidth: 2,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: PersonaColors.tertiary,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: PersonaColors.tertiary,
      pointRadius: 0,
      pointHitRadius: 10,
      data: chartDataAdult,
    })
  }

  return (
    <div className={className}>
      <PersonaPanel
        title="Age"
        helpTitle="Age"
        helpDescription="This measure is derived from the year the prospect graduated from high school, under the assumption that they were 18 years old at the time of their graduation."
      >
        <div className="age-content">
          <div className="age-meta">
            <div className="average-age">
              <div className="average-age-label">Average Age</div>
              <div className="average-age-value">{persona.medianAge}</div>
            </div>
          </div>
          <div className="age-chart">
            <div className="age-chart-cols">
              {persona.metrics.child.value > 0 && (
                <div className="age-chart-col">
                  <div className="age-chart-label">
                    <div
                      className="age-chart-legend"
                      style={{ backgroundColor: PersonaColors.primary }}
                    ></div>
                    Under 21
                  </div>
                  <div className="age-chart-value">
                    {persona.metrics.child.value.toLocaleString()}
                  </div>
                </div>
              )}

              {persona.metrics.youngAdult.value > 0 && (
                <div className="age-chart-col">
                  <div className="age-chart-label">
                    <div
                      className="age-chart-legend"
                      style={{ backgroundColor: PersonaColors.secondary }}
                    ></div>
                    21-29
                  </div>
                  <div className="age-chart-value">
                    {persona.metrics.youngAdult.value.toLocaleString()}
                  </div>
                </div>
              )}

              {persona.metrics.fullAdult.value > 0 && (
                <div className="age-chart-col">
                  <div className="age-chart-label">
                    <div
                      className="age-chart-legend"
                      style={{ backgroundColor: PersonaColors.tertiary }}
                    ></div>
                    30+
                  </div>
                  <div className="age-chart-value">
                    {persona.metrics.fullAdult.value.toLocaleString()}
                  </div>
                </div>
              )}

              {persona.metrics.unknownAge.value > 0 && (
                <div className="age-chart-col">
                  <div className="age-chart-label">
                    <div className="age-chart-legend" style={{ backgroundColor: '#a7a7a7' }}></div>
                    Unknown
                  </div>
                  <div className="age-chart-value">
                    {persona.metrics.unknownAge.value.toLocaleString()}
                  </div>
                </div>
              )}
            </div>

            <div className="age-chartjs-wrapper">
              <div className="age-chartjs">
                <Line
                  data={{
                    labels: chartLabels,
                    datasets,
                  }}
                  redraw={false}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    hover: {
                      mode: 'index',
                      intersect: false,
                    },
                    scales: {
                      x: {
                        display: false,
                      },
                      y: {
                        display: false,
                      },
                    },
                    plugins: {
                      tooltip: {
                        mode: 'index',
                        intersect: false,
                        enabled: false,
                        external(context) {
                          // Tooltip Element
                          let tooltipEl = document.getElementById('chartjs-tooltip')

                          // Create element on first render
                          if (!tooltipEl) {
                            tooltipEl = document.createElement('div')
                            tooltipEl.id = 'chartjs-tooltip'
                            tooltipEl.classList.add('persona-age-chartjs-tooltip')
                            tooltipEl.innerHTML = '<table></table>'
                            document.body.appendChild(tooltipEl)
                          }

                          const tooltipModel = context.tooltip
                          if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = '0'
                            return
                          }

                          const html = `<strong class="tooltip-title">${
                            tooltipModel.title[0]
                          }</strong>: ${
                            tooltipModel.dataPoints[tooltipModel.dataPoints.length - 1]
                              .formattedValue
                          }`

                          tooltipEl.innerHTML = html

                          const position = context.chart.canvas.getBoundingClientRect()

                          // Display, position, and set styles for font
                          tooltipEl.style.opacity = '1'
                          tooltipEl.style.position = 'absolute'
                          tooltipEl.style.left =
                            position.left + window.pageXOffset + tooltipModel.caretX + 10 + 'px'
                          tooltipEl.style.top =
                            position.top + window.pageYOffset + tooltipModel.caretY + 10 + 'px'
                          tooltipEl.style.pointerEvents = 'none'
                        },
                      },
                      legend: {
                        display: false,
                        position: 'bottom',
                        labels: {
                          padding: 20,
                          usePointStyle: true,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </PersonaPanel>
    </div>
  )
}

export default styled(PersonaAge)`
  ${PersonaPanel} {
    padding-bottom: 0;
  }
  .age-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin: 0 -1.25rem;
    margin-top: -2rem;
  }
  .age-meta {
    padding-left: 1rem;
    padding-right: 3rem;
    flex: 0 1 auto;
  }
  .age-chart-legend {
    width: 10px;
    height: 10px;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.3em;
    position: relative;
    top: -1px;
  }
  .age-chart {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    font-size: 0.875rem;
    position: relative;
  }
  .age-chartjs-wrapper {
    height: 79px;
    overflow: hidden;
  }
  .age-chartjs {
    //z-index: 10;
    height: 80px;
  }
  .age-chart-cols {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 0 1rem 0;
  }
  .average-age {
  }
  .average-age-label {
    color: #a7a7a7;
    font-weight: 600;
    font-size: 0.875rem;
    margin-bottom: 0.2em;
  }
  .average-age-value {
    color: #26447b;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
  }

  .age-chart-col {
    width: 33%;
    border-left: 1px solid #f0f2f6;
    padding: 0 1rem;
    // height: 120px;
    position: relative;
    z-index: 20;
    pointer-events: none;
  }
  .age-chart-label {
    color: #a7a7a7;
    font-weight: 600;
  }
  .age-chart-value {
    color: #2d2d2d;
    font-weight: bold;
  }
`
