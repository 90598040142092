import React from 'react'
import styled from 'styled-components'
import PersonaPanel from '../PersonaPanel'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import { PersonaColors } from '../PersonaColors'
import AiDisclaimer from './AiDisclaimer'

interface PersonaInsightsProps {
  className?: string
  persona: Persona
}

const PersonaInsights: React.FC<PersonaInsightsProps> = ({ className, persona }) => {

  function formatMarketingSuggestions(content: string[]) {
    const listClass = 'marketing-list custom-insights-list'

    return (
      <ol className={listClass}>
        {content.map((item, index) => (
          <li className="custom-list  bullet-7" key={index}>
            <div className={`custom-list-bullet`}>{index + 1}</div>
            <div className="custom-list-output">{item}</div>
          </li>
        ))}
      </ol>
    )
  }

  const formatHighlightsAndCautions = (content, index, isCaution) => {
    const bulletClass = isCaution ? `bullet-${index + 1}` : `bullet-${index + 3}`;
    return (
      <li className={`custom-list ${bulletClass}`} key={index}>
        <div className={`custom-list-bullet`}>{index + 1}</div>
        <div className="custom-list-output">{content}</div>
      </li>
    );
  };

  const formatBarriersAndHighlightsGroup = () => {
    const { cautions, highlights } = persona.barriersAndHighlights;
    return (
      <ol className="insights-list custom-insights-list">
        <div className="pair-container">
          <div className="pair-descriptor" style={{ color: PersonaColors.cautions }}>
            Cautions
          </div>
          <ol className="pair-items">
            {cautions.map((caution, index) => formatHighlightsAndCautions(caution, index, true))}
          </ol>
        </div>
        <div className="pair-container">
          <div className="pair-descriptor" style={{ color: PersonaColors.highlights }}>
            Highlights
          </div>
          <ol className="pair-items">
            {highlights.map((highlight, index) => formatHighlightsAndCautions(highlight, index, false))}
          </ol>
        </div>
      </ol>
    );
  };
  return (
    <div className={className}>
      <PersonaPanel title="Summary" aiDisclaimer={true}>
        <div className="content-container ai-overview">
          <div>{persona.insights}</div>
        </div>
      </PersonaPanel>
      <PersonaPanel title="Insights" aiDisclaimer={true}>
        <div className="content-container ai-insights">
          <div>{formatBarriersAndHighlightsGroup()}</div>
        </div>
      </PersonaPanel>
      <PersonaPanel title="Marketing Suggestions" aiDisclaimer={true}>
        <div className="content-container ai-suggestions">
          <div>{formatMarketingSuggestions(persona.marketingInsights)}</div>
        </div>
      </PersonaPanel>
    </div>
  )
}
//parse sources section, have link

export default styled(PersonaInsights)`
  .pair-container {
    margin: 0 0 1.25rem 0;
  }
  .pair-descriptor {
    // position: relative;
    // width: 30px;
    //border: solid;
    margin: 1rem 0 0.75em 0;
    font-weight: 600;
    font-size:1.1em;
  }

  .pair-items {
    list-style-type: none;
    // display: flex;
    // flex-direction: column;
    // width: 95%;
    margin: 0;
    padding: 0;
  }

  .custom-insights-list li {
    color: black;
  }
  .custom-insights-list .bullet-1 {
    .custom-list-bullet {
      background-color: ${PersonaColors.cautions};
    }
  }
  
  .custom-insights-list .bullet-2 {
    .custom-list-bullet {
      background-color: ${PersonaColors.cautions};
    }
  }
  
  .custom-insights-list .bullet-3 {
    .custom-list-bullet {
      background-color: ${PersonaColors.highlights};
    }
  }
  .custom-insights-list .bullet-4 {
    .custom-list-bullet {
      background-color: ${PersonaColors.highlights};
    }
  }
  
  .custom-insights-list .bullet-5,
  .custom-insights-list .bullet-6 {
    .custom-list-bullet {
      background-color: ${PersonaColors.highlights};
    }
  }
  
  .custom-insights-list .bullet-7 {
    .custom-list-bullet {
      background-color: ${PersonaColors.secondary};
    }
  }

  p {
    padding: 0;
    margin: 0;
  }

  .content-container {
    display: block;
    justify-content: left;
    align-items: left;
    //border: solid;
  }

  .insights-list {
    counter-reset: section; /* Reset the counter inside the parent container */
    max-width: 100%;
    padding: 0px;
    // margin: 10px;
    list-style-type: none; /* Remove the default bullet points */
    //border: solid red;
  }

  .marketing-list {
    counter-reset: section; /* Reset the counter inside the parent container */
    padding: 0;
    margin: 1.5rem 0 0 0;
    list-style-type: none; /* Remove the default bullet points */
    //border: solid blue;
  }

  .custom-list {
    // border-left: 2px dashed;
    border-color: #e8e8e8 !important;
    margin: 0 0 1rem 0;
    padding: 0;
    counter-increment: section; /* Increment the counter for each .custom-list item */
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .custom-list-bullet {
    content: counter(section);
    margin-top:4px;
    margin-right: 10px;
    border-radius: 50%;
    padding: 10px;
    height: 30px;
    width: 30px;
    background-color: #9688ff;
    color: white;
    font-size: 110%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .custom-list-output {
    flex: 1 1 auto;
  }

  .ai-overview {
    ol {
      margin: 0;
      padding: 0;
    }
  }

  .ai-insights {
    .pair-container {
      display: block;
      .pair-descriptor {
      }
    }
  }
`
