import React from 'react'
import styled from 'styled-components'
import format from 'date-fns/format'
import TimeAgo from 'react-timeago'
import { UncontrolledTooltip } from 'reactstrap'
import random from 'lodash/random'
import { parseISO } from 'date-fns'

const TimeAgoTimestamp = ({ date, id, label, placement }) => (
  <React.Fragment>
    <span className="time-ago" id={id}>
      <TimeAgo date={date} title={label} />
    </span>
    <UncontrolledTooltip placement={placement || 'bottom'} target={id}>
      {format(date, "MMM do yyyy 'at' hh:mma")}
    </UncontrolledTooltip>
  </React.Fragment>
)

const Timestamp = (props) => {
  const { className } = props
  const urlParams = new URLSearchParams(window.location.search)
  const pdf = urlParams.get('_pdf')
  const id = 't' + random(10000, 99999)
  let date = props.date instanceof Date ? props.date : parseISO(props.date)

  return (
    <span className={className}>
      {!pdf && <TimeAgoTimestamp {...props} date={date} id={id} />}
      {pdf &&
        date.toLocaleString('en-US', {
          hour12: true,
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          timeZoneName: 'short',
          timeZone: urlParams.get('tz') || 'America/Los_Angeles',
        })}
    </span>
  )
}

export default styled(Timestamp)`
  .time-ago {
    cursor: default;
    border-bottom: 1px dashed var(--border-color);
  }
`
