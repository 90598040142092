export const WageDataSchema = {
  type: 'object',
  additionalProperties: false,
  properties: {
    campus: {
      type: 'string',
    },
    low: {
      type: 'number',
    },
    high: {
      type: 'number',
    },
    disclosureUrl: {
      type: 'string',
    },
  },
  required: ['campus', 'low', 'high', 'disclosureUrl'],
}

export const CareerSchema = {
  type: 'object',
  additionalProperties: false,
  properties: {
    name: {
      type: 'string',
    },
    disclosureUrl: {
      type: 'string',
    },
    wageData: {
      type: 'array',
      items: WageDataSchema,
    },
  },
  required: ['name', 'disclosureUrl'],
}

export default {
  type: 'object',
  additionalProperties: false,
  properties: {
    _id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    description: {
      type: 'string',
    },
    availability: {
      type: 'string',
    },
    campuses: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    careers: {
      type: 'array',
      items: CareerSchema,
    },
    careerField: {
      type: 'string',
      title: 'Career Field',
    },
  },
  required: ['name', 'careerField'],
}
