import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router'
import { User, UserRoles } from '../../../_shared/models/user/user.client'
import { AppState } from '../types'
import * as H from 'history'
import NotFound from '../views/NotFound'
import Loader from '../components/Loader'

interface Props {
  redirect?: string | H.Location
  role?: UserRoles
  Component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> // eslint-disable-line
}

type ProtectedRouteComponentProps = Props & RouteComponentProps

const ProtectedRouteComponent: React.FC<ProtectedRouteComponentProps> = ({
  redirect,
  role,
  Component, // eslint-disable-line
  children,
  ...rest
}) => {
  const user = useSelector<AppState, User>((state) => state.user)
  const isLoggingIn = useSelector<AppState, boolean>((state) => state.isLoggingIn)

  if (!user && isLoggingIn) {
    return <Loader />
  }
  if (!user) {
    window._previousLocation = window.location.pathname + window.location.search
    return <Redirect to={redirect} />
  }

  // if (!user.roles.includes(UserRoles.ADMIN) && !user.roles.includes(UserRoles.STAFF)) {
  //   if (!user.roles.includes(role)) {
  //     return <NotFound />
  //   }
  // }

  if (Component) {
    return <Component {...rest} />
  }

  return <>{children}</>
}

type ProtectedRouteProps = Props & RouteProps

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  redirect = '/login',
  role = UserRoles.USER,
  children,
  component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <ProtectedRouteComponent {...props} Component={component} redirect={redirect} role={role}>
          {children}
        </ProtectedRouteComponent>
      )}
    />
  )
}

export default ProtectedRoute
