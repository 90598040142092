import React, { useEffect, useState } from 'react'
import apiFetch from '../../../_shared/api/apiFetch'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import NotificationType from '../../../_shared/models/notification/notification.client'
import { PanelList } from './PanelList'
import { useLocation } from 'react-router-dom'
import NotificationStatus from './NotificationStatus'
import { faSquareDown } from '@fortawesome/pro-solid-svg-icons/faSquareDown'
import { faSquareUp } from '@fortawesome/pro-solid-svg-icons/faSquareUp'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IntegrationOverview: React.FC = () => {
  const location = useLocation()
  const isGlobalIntegration = !location.pathname.includes('pathways')
  //if its a pathway specific integration, we don't want to show the integration overview
  if (isGlobalIntegration) {
    const integrationId = location.pathname.split('/')[2]
    const searchParams = new URLSearchParams(location.search)
    const companyId = searchParams.get('a')
    const [pathways, setPathways] = useState<Pathway[]>([])
    const [notifications, setNotifications] = useState<NotificationType[]>([])
    const [notificationStatusChanged, setNotificationStatusChanged] = useState(false)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
      fetchData().catch(console.error)
    }, [notificationStatusChanged, companyId, integrationId])

    const fetchData = async () => {
      try {
        await Promise.all([fetchPathways(companyId), fetchNotification(companyId)])
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    const fetchPathways = async (companyId: string) => {
      try {
        const pathways = await apiFetch('GET', '/pathways', {}, { companyId }, true)
        pathways.sort((a, b) => {
          // Push 'archive' to the end
          if (a.campaignId === 'archive' && b.campaignId !== 'archive') return 1;
          if (a.campaignId !== 'archive' && b.campaignId === 'archive') return -1;
          return b.campaignId.localeCompare(a.campaignId);
        });
        setPathways(pathways)
      } catch (error) {
        console.error('Error fetching pathways:', error)
      }
    }
    const fetchNotification = async (companyId: string) => {
      try {
        const notifs = await apiFetch('GET', `/notifications`, {}, { companyId }, false)
        setNotifications(notifs)
      } catch (error) {
        console.error('Error fetching notifications:', error)
      }
    }
    const toggleNotificationStatus = async (notification: NotificationType) => {
      try {
        await apiFetch('POST', '/notifications/toggle', {
          notificationId: notification._id,
        })
        setNotificationStatusChanged((status) => !status)
      } catch (error) {
        console.error('Error toggling notification status:', error)
      }
    }
    const toggleIsOpen = () => {
      setIsOpen((isOpen) => !isOpen)
    }
    //determines if this specific pathway has this specific integration
    //Does career quiz have this shape integration?
    const doesThisPathwayHaveThisIntegration = (pathwayId: string) => {
      const matchingNotifications = notifications.filter(
        (notification) =>
          notification.pathwayId === pathwayId && notification.type === integrationId
      )
      const isEnabled = matchingNotifications.some((notification) => notification.isEnabled)
      const hasTriggers = matchingNotifications.some((notification) => {
        if (notification.duplicates && notification.triggers.length > 0) {
          return true
        } else {
          return false
        }
      })
      return {
        isInPathway: matchingNotifications.length > 0,
        isEnabled,
        hasTriggers,
        matchingNotifications,
      }
    }

    return (
      <React.Fragment>
        <div style={{ width: 'fit-content' }}>
          <h4
            style={{
              padding: '1rem',
              marginTop: '1rem',
              border: '1px solid grey',
              borderRadius: '5px',
            }}
            onClick={toggleIsOpen}
          >
            <span style={{ paddingRight: '1.25rem' }}>Integration Overview</span>
            <FontAwesomeIcon icon={isOpen ? faSquareUp : faSquareDown} />
          </h4>
        </div>
        {isOpen ? (
          <PanelList>
            {pathways.map((pathway, i) => (
              <div key={pathway._id} style={{ backgroundColor: '#F0F2F6', padding: '0' }}>
                {i === 0 || pathway.campaignId !== pathways[i - 1].campaignId ? (
                  <h5 style={{ padding: '1.25rem', marginTop: '1rem' }}>
                    {pathway.campaignId.toUpperCase()}
                  </h5>
                ) : null}

                <NotificationStatus
                  pathway={pathway}
                  toggleNotificationStatus={toggleNotificationStatus}
                  doesThisPathwayHaveThisIntegration={doesThisPathwayHaveThisIntegration}
                />
              </div>
            ))}
          </PanelList>
        ) : null}
      </React.Fragment>
    )
  } else {
    return null
  }
}

export default IntegrationOverview
