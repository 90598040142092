import React from 'react'
import PropTypes from 'prop-types'
import { Form, FormGroup } from 'reactstrap'
import withForm from '../withForm'
import { getFormGroupClasses, FormErrors, HelpText, ValidFeedback, FormLabel } from './helpers'
import AsyncSelect from 'react-select/lib/Async'
import { FormContextProps } from '../Form'

export type SelectValue = string | string[]

export interface SelectProps extends Partial<FormContextProps> {
  name: string
  showLabel?: boolean
  label?: string
  id?: string
  help?: string
  errors?: string[]
  showValid?: boolean
  validFeedback?: string
  onChange?: (newValue: SelectValue) => void
  defaultOptions?: any
  value?: SelectValue
  selectProps?: Record<string, any>
}

class Select extends React.PureComponent<SelectProps> {
  static defaultProps = {
    errors: [],
    showValid: false,
    showLabel: true,
  }

  componentWillUnmount() {
    this.props.setValue(this.props, undefined)
  }

  onChange = (val: SelectValue) => {
    if (Array.isArray(val) && val.length === 0) {
      val = undefined
    }
    this.props.setValue(this.props, val)
  }

  render() {
    const {
      showLabel,
      name,
      id,
      errors,
      label,
      formSchema,
      validFeedback,
      showValid,
      help,
      value,
      selectProps,
    } = this.props

    const hasErrors = errors.length > 0

    return (
      <FormGroup className={getFormGroupClasses(errors, showValid)}>
        <FormLabel
          showLabel={showLabel}
          id={id}
          name={name}
          label={label}
          formSchema={formSchema}
        />
        <AsyncSelect
          name={name}
          id={id}
          classNamePrefix="react-select"
          onChange={this.onChange}
          value={value}
          valid={!hasErrors && showValid}
          invalid={hasErrors}
          {...selectProps}
        />
        <ValidFeedback showValid={showValid} validFeedback={validFeedback} />
        <FormErrors errors={errors} />
        <HelpText help={help} />
      </FormGroup>
    )
  }
}

export default withForm(Select)
