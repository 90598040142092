import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faKey } from '@fortawesome/pro-solid-svg-icons/faKey'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withAlert } from 'react-alert'
import apiFetch from '../../../../_shared/api/apiFetch'

class ResetPasswordModal extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object.isRequired,
  }

  callMethod = async (toggle) => {
    try {
      await apiFetch('POST', '/users/resetPassword', { userId: this.props.user._id })
      this.props.alert.show('Password reset successfully')
      toggle()
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Reset Password"
        message={`Are you sure you want to reset ${this.props.user.profile.name}'s password? They will receive an email with a new secure password.`}
        confirmLabel="Reset Password"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faKey} /> Reset Password
      </BasicModal>
    )
  }
}

export default withAlert()(ResetPasswordModal)
