import React from 'react'
import View from '../components/View'
import styled from 'styled-components'

const Error = (props) => {
  return (
    <View title="Error">
      <div className={`content ${props.className}`}>
        <img src="/images/alert.png" width="128" alt="404 Not Found" />
        <h2>An unexpected error has occured</h2>
        <p>
          Please try again, or <a href="#">contact support</a> if the problem persists.
        </p>
      </div>
    </View>
  )
}

export default styled(Error)`
  text-align: center;
  padding: 2rem 1rem;
  h2 {
    margin: 1rem 0 1rem 0;
  }
  p {
    color: var(--text-muted);
  }
`
