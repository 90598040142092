import React from 'react'
import { PropTypes } from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import ReactDOM from 'react-dom'

let portal
if (process.env.IS_CLIENT) {
  portal = document.querySelectorAll('.sliding-panels')[0]
  if (!portal) {
    portal = document.createElement('div')
    portal.classList.add('sliding-panels')
    document.body.appendChild(portal)
  }
}

class SlidingPanel extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
    className: PropTypes.string,
  }

  render() {
    const { title, children, isOpen, toggle } = this.props
    return ReactDOM.createPortal(
      <div className={`${isOpen ? 'isOpen' : ''} ${this.props.className || ''}`}>
        <div className="closePanel" onClick={toggle}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        {title ? <div className="title">{title}</div> : ''}
        <div className="content">{children}</div>
      </div>,
      portal
    )
  }
}

export default styled(SlidingPanel)`
  z-index: 900;
  position: absolute;
  top: 3rem;
  margin-top: -1px;
  right: 0;
  ${(props) => (props.fullHeight ? 'bottom: 0' : '')};
  width: 350px;
  background: #fff;
  border: 1px solid var(--border-color);
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.15);
  transform: translateX(110%);
  transition: transform 0.3s ease-out;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 3rem);

  &.isOpen {
    transform: translateX(0);
  }
  .title {
    padding: 0.75rem 1rem;
    text-align: center;
    border-bottom: 1px solid var(--border-color);
    font-size: 1rem;
    font-weight: 600;
  }
  .content {
    font-size: 0.875rem;
    padding: 1rem;
    overflow-y: auto;
  }
  .form-group {
    margin-bottom: 0.6rem;
    label {
      margin-bottom: 0.3rem;
    }
  }
  .rc-slider .rc-slider-handle {
    width: 24px;
    height: 24px;
    margin-left: -12px;
    margin-top: -9px;
  }
  .closePanel {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    padding: 0.5rem;
    line-height: 1;
    font-size: 1.25rem;
    color: #a0a0a0;
    &:hover {
      color: #333;
    }
  }
  .TabNav {
    margin-top: -1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    box-shadow: inset 0 -1px 0px 0px #eef1f3;
  }
`
