import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import Form from '../../../../../_shared/components/Form'
import Input from '../../../../../_shared/components/Form/Input'
import FormError from '../../../../../_shared/components/Form/FormError'

const AdminUserFormSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    email: {
      title: 'Email Address',
      type: 'string',
    },
  },
  required: ['name', 'email'],
}

const AdminUserForm = (props) => {
  const { onSubmit, buttonLabel, user, error, isUpdate, isLoading } = props

  return (
    <Form formData={user || {}} schema={AdminUserFormSchema} onValidSubmit={onSubmit}>
      <div className="row align-items-stretch">
        <div className="col-lg-12 col-xl-5">
          <Input type="text" name="name" />
          <Input type="email" name="email" disabled={isUpdate} />
          {!user ? (
            <div className="alert alert-primary">
              A welcome email will be sent with their password.
            </div>
          ) : null}
        </div>
      </div>
      <hr />
      {error && <FormError>{error}</FormError>}
      <Button size="lg" color="primary" type="submit" disabled={isLoading}>
        {buttonLabel || 'Submit'}
      </Button>
    </Form>
  )
}

AdminUserForm.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  error: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
  isUpdate: PropTypes.bool,
}

export default AdminUserForm
