import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav, NavItem, NavLink as BootstrapNavLink, Button, UncontrolledTooltip } from 'reactstrap'
import media from '../css/_media'
import Link from './Link'
import { RouteProps, useLocation } from 'react-router-dom'
import classnames from 'classnames'
import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleLeft'
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons/faChevronDoubleRight'

export const StyledSidebar = styled.section`
  background: #15457d;
  position: fixed;
  display: none;
  top: 0;
  bottom: 0;
  color: #fff;
  z-index: 1000;
  width: 15rem;
  padding: 0;
  transition: width 0.25s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
  height: 100vh;

  @media print {
    display: none;
  }

  .group {
    margin: 1rem 0 2rem 0;
  }
  .title {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #acbcc7;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    padding: 0 1rem;
    white-space: nowrap;
  }
  .logo {
    text-align: center;
    background: #2d7ec5;
    height: calc(3rem - 1px);
    line-height: 2.7rem;
    .logo-full {
      width: 140px;
      height: 20px;
    }
    .logo-small {
      height: 20px;
      display: none;
    }
    margin: 0 0 1rem 0;
  }
  .content {
    flex: 1 1 1rem;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    max-height: 100vh;
    padding: 0;
    margin-bottom: 1rem;
    width: 100%;
    background: #15457d;
    display: flex;
    flex-direction: column;
    justify-content: stretch;

    .nav {
      flex-wrap: nowrap;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    > * {
      flex: 0 0 auto;
    }
    > .bottom {
      flex: 1 0 auto;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .toggle-sidebar {
    display: none;
    padding: 0 0.75rem;
  }
  .toggle-sidebar .btn {
    color: #7ac6ff;
    outline: none;
    background: #042f56;
    border: 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }
  @media (min-width: ${media.lg}) {
    .toggle-sidebar {
      display: block;
    }
  }

  .sidebar-link-label {
    transition: opacity 0.25s ease-out;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.9375rem;
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
  }

  & + #main {
    transition: margin-left 0.25s ease-out;
    margin-left: 15rem;
  }

  &.collapsed {
    width: 4rem;
    & + #main {
      margin-left: 4rem;
      @media print {
        margin-left: 0;
      }
    }
    .title {
      font-size: 0.625rem;
      color: #92a9b9;
      padding: 0;
      text-align: center;
    }
    .content {
      overflow-x: hidden;
    }
    .logo-full {
      display: none;
    }
    .logo-small {
      display: inline-block;
    }
    .sidebar-link-label {
      opacity: 0;
    }
  }

  @media (max-width: ${media.sm}) {
    width: 0;
    & + #main {
      margin-left: 0;
    }
  }

  @media (min-width: ${media.lg}) {
    display: flex;
    top: auto;
    bottom: auto;
    /* box-shadow: inset -10px 0 20px -10px rgba(0, 0, 0, 0.45); */
    transform: none;
  }

  .sidebar-link {
    display: block;
    text-decoration: none;
    padding: 0.5rem 1.25rem;
    color: #eff1f3;
    margin: 0;
    transition: background 0.15s ease-out;
    white-space: nowrap;

    &:hover {
      color: #fff;
      background: #255998;
    }
    &.active {
      &,
      &:hover,
      &:active {
        color: #fff;
        background: #163963;
      }
    }
  }
`

const SidebarIcon = styled(FontAwesomeIcon)`
  color: #7ac6ff;
  margin-right: 0.45rem;
  font-size: 1.125rem;
  vertical-align: middle;
`

interface SidebarSectionProps {
  title?: string
  bottom?: boolean
}

export const SidebarSection: React.FC<SidebarSectionProps> = (props) => (
  <div className={`group ${props.bottom ? 'bottom' : ''}`}>
    {props.title && <p className="title">{props.title}</p>}
    <Nav vertical>{props.children}</Nav>
  </div>
)

interface SidebarLinkProps extends RouteProps {
  icon: any
  route?: string
  to?: string
  external?: boolean
  children?: any
}

export const SidebarLink: React.FC<SidebarLinkProps> = (props) => {
  const location = useLocation()
  const { icon, route, children, external, ...linkProps } = props
  const id = children.toString().replace(' ', '_')
  const to = {
    pathname: route,
    search: location.search,
  }

  let Tag
  let tagProps
  if (external) {
    Tag = 'a'
    tagProps = {
      className: 'sidebar-link',
      href: props.to,
      id,
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  } else {
    Tag = Link
    tagProps = {
      className: 'sidebar-link',
      navLink: true,
      activeClassName: 'active',
      id,
      to,
      ...linkProps,
    }
  }

  return (
    <React.Fragment>
      <NavItem>
        <Tag {...tagProps}>
          <SidebarIcon fixedWidth icon={icon} />{' '}
          <span className="sidebar-link-label">{children}</span>
        </Tag>
      </NavItem>
      <UncontrolledTooltip
        className="animated faster fadeIn"
        placement="left"
        delay={{ show: 300, hide: 0 }}
        boundariesElement={document.body}
        target={id}
      >
        {children}
      </UncontrolledTooltip>
    </React.Fragment>
  )
}

interface SidebarProps {
  id?: string
  className?: string
  isOpen?: boolean
  toggleSidebar?: () => void
}

export const Sidebar: React.FC<SidebarProps> = (props) => {
  return (
    <StyledSidebar
      id={props.id}
      className={classnames(props.className, {
        collapsed: !props.isOpen,
      })}
    >
      <div className="content">
        <div className="logo">
          <img className="logo-full" src="/logo-white.svg" />
          <img className="logo-small" src="/logo-white-small.svg" />
        </div>
        {props.children}
      </div>
      <div className="toggle-sidebar text-center">
        <Button color="outline-primary" block onClick={props.toggleSidebar}>
          <FontAwesomeIcon icon={props.isOpen ? faChevronDoubleLeft : faChevronDoubleRight} />
        </Button>
      </div>
    </StyledSidebar>
  )
}
