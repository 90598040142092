/* eslint-disable react/display-name */
import IntegrationAdapters from '../../IntegrationAdapters'
import React, { useCallback, useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import Input from '../../../../components/Form/Input'
import CopperAdapter from './CopperAdapter'
import Cardbox, { CardboxItem } from '../../../../components/Form/Cardbox'
import 'ace-builds/src-noconflict/mode-javascript'
import Code from '../../../../components/Form/Code'
import Dropdown from '../../../../components/Form/Dropdown'
import Link from '../../../../../backend/client/components/Link'
import withForm from '../../../../components/withForm'
import FormError from '../../../../components/Form/FormError'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/pro-solid-svg-icons/faSyncAlt'

interface ActivityTypeProps {}

const ActivityTypeDropdown = ({ formData }) => {
  const isEnabled = formData?.config?.accessToken && formData?.config?.apiUser
  const [activityTypes, setActivityTypes] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>(null)

  const fetchActivityTypes = useCallback(async () => {
    const { accessToken, apiUser } = formData?.config
    if (isLoading || !accessToken || !apiUser) {
      return
    }

    setError(null)
    setIsLoading(true)

    try {
      const response = await fetch(
        process.env.API_URL_LONG + '/integrations/copper/activityTypes',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // eslint-disable-line
          },
          body: JSON.stringify({
            token: accessToken,
            username: apiUser,
          }),
        }
      )
      const data = await response.json()

      if (!data.success) {
        setError(data.error)
      }

      setActivityTypes(data)
      setIsLoading(false)
    } catch (e) {
      setError(e.message)
      setIsLoading(false)
    }
  }, [isLoading, formData])

  useEffect(() => {
    if (isEnabled) {
      fetchActivityTypes()
    }
  }, [isEnabled])

  return (
    <>
      <div className="row">
        <div className="col">
          <Dropdown
            placeholder="(Select One)"
            name="config.activityTypeId"
            disabled={!isEnabled || isLoading}
            help={
              <>
                <div style={{ marginBottom: '0.25rem' }}>
                  Manage activity types here:{' '}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://app.copper.com/companies/49573/app#/settings/activity-types"
                  >
                    https://app.copper.com/companies/49573/app#/settings/activity-types
                  </a>
                </div>
                <Button
                  type="button"
                  onClick={fetchActivityTypes}
                  disabled={!isEnabled || isLoading}
                  size="sm"
                  color="alt"
                >
                  <FontAwesomeIcon icon={faSyncAlt} spin={isLoading} /> Refresh Activity Types
                </Button>
              </>
            }
          >
            {!error &&
              activityTypes?.map((type) => (
                <option key={type.id} value={type.id}>
                  {type.name}
                </option>
              ))}
          </Dropdown>
        </div>
        <div className="col"></div>
      </div>
      {error && (
        <FormError>
          <strong>Copper API Error:</strong>&nbsp;{error}
        </FormError>
      )}
    </>
  )
}
ActivityTypeDropdown.displayName = 'ActivityTypeDropdown'

const ActivityTypeDropdownWithForm = withForm<ActivityTypeProps>(ActivityTypeDropdown, true)

class CopperAdapterClient extends CopperAdapter {
  renderForm = (pathway) => {
    // return <CopperAdapterClientForm pathway={pathway} />

    return (
      <div className="CopperAdapterConfig">
        <div className="row"></div>
        <Input type="text" name="config.apiUser" />
        <Input
          type="text"
          name="config.accessToken"
          // help={
          //   <span>
          //     Get your credentials here:{' '}
          //     <a
          //       target="_blank"
          //       rel="noopener noreferrer"
          //       href="https://app.copper.com/companies/49573/app#/settings/api-keys"
          //     >
          //       https://app.copper.com/companies/49573/app#/settings/api-keys
          //     </a>
          //   </span>
          // }
        />

        <ActivityTypeDropdownWithForm />

        <div className="row">
          <div className="col">
            <Cardbox thin name="config.targetObject" defaultValue="leads" selectionRequired>
              <CardboxItem title="Leads" value="leads" />
              <CardboxItem title="People" value="people" />
            </Cardbox>
          </div>
        </div>

        <h4>Mapping Script</h4>
        <p className="text-small">
          Return a JavaScript object to specify what data should be sent to Copper.
          <br />
          You can also mutate <code>config</code> if necessary.
          <br />
          <code>copperRecord</code> will reference the person's record in Copper if found.
          {pathway && (
            <Link
              to={`/pathways/${pathway._id}/vars`} /*rel="noopener noreferrer" target="_blank"*/
            >
              See the available variables here.
            </Link>
          )}
        </p>
        <Code
          showLabel={false}
          tabSize={2}
          // wrapEnabled={true}
          editorProps={{
            $blockScrolling: Infinity,
          }}
          setOptions={{
            wrap: true,
            displayIndentGuides: false,
            showPrintMargin: false,
            showFoldWidgets: false,
          }}
          mode="javascript"
          name="config.mappingScript"
          defaultValue={`const tags = copperRecord ? copperRecord.tags : []

switch(pathway._id) {
  case 'your_pathway_id':
    tags.push('some_tag')
    break;
}

// For more fields, see https://developer.copper.com/
return {
  name: profile.firstName + ' ' + profile.lastName,
  emails: [{
    email: profile.email,
    category:"work"
  }],
  phone_numbers: [{
    number: profile.phone,
    category:"mobile"
  }],
  tags
}`}
        />
      </div>
    )
  }
}

const instance = new CopperAdapterClient()
IntegrationAdapters.register(instance)

export default CopperAdapterClient
