import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const PpcBreakdown = (props) => {
  const { ppcLeads, ppcCalls } = props
  return (
    <React.Fragment>
      <React.Fragment>
        PPC Forms: {ppcLeads.ppc}
        <br />
      </React.Fragment>
      {ppcLeads.va > 0 && (
        <React.Fragment>
          VA: {ppcLeads.va}
          <br />
        </React.Fragment>
      )}
      {ppcLeads.facebook > 0 && (
        <React.Fragment>
          Facebook: {ppcLeads.facebook}
          <br />
        </React.Fragment>
      )}
      <React.Fragment>
        Valid Calls: {ppcCalls}
        <br />
      </React.Fragment>
    </React.Fragment>
  )
}

PpcBreakdown.propTypes = {
  ppcLeads: PropTypes.object,
  ppcCalls: PropTypes.number,
}

const WebBreakdown = (props) => {
  const { webLeads } = props
  return (
    <React.Fragment>
      <React.Fragment>
        Web Forms: {webLeads.web}
        <br />
      </React.Fragment>
      {webLeads.vaa > 0 && (
        <React.Fragment>
          VA: {webLeads.vaa}
          <br />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

WebBreakdown.propTypes = {
  webLeads: PropTypes.object,
}

const BreakdownDiv = styled.div`
  margin: 0 0 20px 0;
  &:first-child {
    padding-left: 15px;
  }
  .stat-value {
    font-size: 36px;
    font-weight: bold;
  }
`

const Breakdown = (props) => {
  const { label, data, type } = props
  return (
    <BreakdownDiv>
      <div className="breakdown-title">{label}</div>
      {type === 'ppc' && (
        <PpcBreakdown ppcLeads={data.leads.totals.uniquePPC} ppcCalls={data.calls.totals.ppc} />
      )}
      {type === 'web' && <WebBreakdown webLeads={data.leads.totals.uniqueWeb} />}
    </BreakdownDiv>
  )
}

Breakdown.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.object,
}

export default Breakdown
