import { SubscriptionParams } from './types'
import { DataStore } from './store'
import { ClientSubscription } from './client'

interface Cache {
  key: string
  subscription: ClientSubscription
}

export default class SubscriptionCache {
  _cache: Cache[] = []

  maxCacheSize = 10

  constructor(maxCacheSize = 10) {
    this.maxCacheSize = maxCacheSize
  }

  getKey(topic: string, queryParams: SubscriptionParams['queryParams']): string {
    return topic + JSON.stringify(queryParams)
  }

  addSubscription(subscription: ClientSubscription): void {
    subscription.cache = this
    this._cache.push({
      key: this.getKey(subscription.topic, subscription._queryParams),
      subscription,
    })
    this.checkCacheSize()
  }

  checkCacheSize() {
    // if _cache greater than maxCacheSize then pull off oldest subscription with removeSubscription
    if (this._cache.length >= this.maxCacheSize) {
      const removed = this._cache.shift()
      removed.subscription.unsubscribe(true)
    }
  }

  getSubscription(
    topic: string,
    queryParams: SubscriptionParams['queryParams']
  ): ClientSubscription {
    const key = this.getKey(topic, queryParams)
    const cacheEntry = this._cache.find((c) => c.key === key)
    return cacheEntry?.subscription
  }

  removeSubscription(subscription: ClientSubscription): void {
    const key = this.getKey(subscription.topic, subscription._queryParams)
    const index = this._cache.findIndex((c) => c.key === key)
    const removed = this._cache[index]
    this._cache.splice(index, 1)
    removed.subscription.unsubscribe(true)
  }

  // getCacheData(resource: string, params: SubscriptionParams['queryParams']): DataStore {
  //   const key = this.getKey(resource, params)
  //   const cacheObj = this._cache.find((i) => i.key === key)
  //   return cacheObj?.data
  // }

  // saveCache(resource: string, params: SubscriptionParams['queryParams'], data: DataStore): void {
  //   const key = this.getKey(resource, params)

  //   const exists = this._cache.find((i) => i.key === key)
  //   if (exists) {
  //     exists.data = data
  //   } else {
  //     if (this._cache.length > this.maxCacheSize) {
  //       this._cache.shift()
  //     }

  //     this._cache.push({
  //       key,
  //       data,
  //     })
  //   }
  // }
}
