export default {
  type: 'object',
  additionalProperties: false,
  properties: {
    _id: {
      type: 'string',
    },
    name: {
      type: 'string',
    },
    companyId: {
      type: 'string',
    },
    pathways: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
  },
  required: ['name', 'companyId'],
}
