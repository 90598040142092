import React from 'react'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { Button } from 'reactstrap'
import View from '../components/View'
import SimpleLayout from '../components/layouts/SimpleLayout'
import Form from '../../../_shared/components/Form'
import Input from '../../../_shared/components/Form/Input'
import FormError from '../../../_shared/components/Form/FormError'

const FormSchema = {
  type: 'object',
  properties: {
    email: {
      title: 'Email Address',
      type: 'string',
    },
  },
  required: ['email'],
}

interface Props extends RouteComponentProps {}

interface State {
  error: string
  message: string
  isLoading: boolean
}

class ForgotPassword extends React.Component<Props, State> {
  state = {
    error: null,
    message: null,
    isLoading: false,
  }

  onSubmit = (data) => {
    const { email } = data.formData
    this.setState({ isLoading: true, error: null, message: null }, async () => {
      try {
        const result = await fetch(process.env.API_URL + '/forgotPassword', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json', // eslint-disable-line
          },
          body: JSON.stringify({
            username: email,
          }),
        })
        const json = await result.json()

        if (json.error) {
          this.setState({
            isLoading: false,
            error: json.error,
          })
        } else {
          this.setState({
            isLoading: false,
            message: 'Check your email for further instructions to reset your password.',
          })
        }
      } catch (e) {
        this.setState({
          isLoading: false,
          error: e.reason,
        })
      }
    })
  }

  render() {
    const { error, message, isLoading } = this.state
    return (
      <View title="Forgot Password">
        <SimpleLayout>
          <Form schema={FormSchema} onValidSubmit={this.onSubmit} warnOnUnsavedLeave={false}>
            <img src="/logo-white.svg" className="logo" width="60%" />
            <Input type="text" name="email" label="What is your email address?" />

            {error && <FormError>{error}</FormError>}
            {message && <div className="alert alert-primary">{message}</div>}
            {!message && (
              <Button color="primary" type="submit" block disabled={isLoading}>
                Send recovery email
              </Button>
            )}
            <br />
            <div className="links text-center">
              <Link to="/login">or return to login</Link>
            </div>
          </Form>
        </SimpleLayout>
      </View>
    )
  }
}

export default withRouter(ForgotPassword)
