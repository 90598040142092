import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Link from '../../../components/Link'
import Loader from '../../../components/Loader'
import SparklineChart from '../../../components/SparklineChart'
import Stat from '../../../components/Stat'
import classnames from 'classnames'
import generateChartData from '../../../components/generateChartData'
import apiFetch from '../../../../../_shared/api/apiFetch'
import Log from '../../../../../_shared/log'

const CampaignOverview = ({
  className,
  campaign,
  statData,
  chartData,
  hasBundle,
  noPathways,
  error,
}) => (
  <div className={classnames(className, { noBundle: !hasBundle || noPathways })}>
    <h3 className="text-center">
      <Link to={`/statistics/campaign/${campaign._id}`}>
        {campaign.name} {!hasBundle && '(Inactive)'}
      </Link>
    </h3>
    {error && <div className="alert alert-danger">{String(error)}</div>}
    {!hasBundle && (
      <div className="inactive-bundle">Please contact us to enable this campaign.</div>
    )}
    {!noPathways && hasBundle && chartData && (
      <div className="chart-container">
        <SparklineChart
          height={80}
          labels={chartData.labels}
          datasets={[
            {
              data: chartData.values,
              borderColor: '#15457d',
              backgroundColor: 'rgba(21,69,125,0.1)',
              pointHoverBackgroundColor: '#0b6dc7',
              pointRadius: 0,
              pointBackgroundColor: '#0b6dc7',
              tension: 0.4,
              fill: true,
            },
          ]}
        />
      </div>
    )}
    <div className="row quick-stats">
      {statData &&
        statData.map((data, index) => (
          <div key={index} className="col">
            <Stat value={data.value} label={data.label} align="center" labelPosition="bottom" />
          </div>
        ))}
    </div>
  </div>
)

CampaignOverview.propTypes = {
  statData: PropTypes.array,
  chartData: PropTypes.object,
  campaign: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
  error: PropTypes.string,
  hasBundle: PropTypes.bool,
  noPathways: PropTypes.bool,
}

const StyledCampaignOverview = styled(CampaignOverview)`
  position: relative;
  h3 {
    margin: 0 0 1rem 0;
  }
  .chart-container {
    margin: 2rem 0 3rem 0;
  }
  ${Stat} {
    .value {
      font-size: 1.6rem;
    }
  }

  .inactive-bundle {
    margin: 0 0 1.5rem 0;
    text-align: center;
    font-style: italic;
  }

  &.noBundle {
    a {
      color: var(--text-muted);
      pointer-events: none;
      cursor: default;
    }
    ${Stat} {
      &,
      .value {
        color: var(--text-muted) !important;
      }
    }
  }
`

export default StyledCampaignOverview

export class CampaignOverviewContainer extends React.Component {
  state = {
    isLoadingStatOverview: true,
    isLoadingChart: false,
    statOverviewData: null,
    chartData: null,
    error: null,
  }

  static propTypes = {
    companyId: PropTypes.string.isRequired,
    campaign: PropTypes.object.isRequired,
    hasBundle: PropTypes.bool,
    dateRange: PropTypes.array.isRequired,
    Component: PropTypes.any,
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.dateRange !== this.props.dateRange) {
      this.loadData()
    }
  }

  prepareChartData(aggregateResult) {
    const { dateRange } = this.props
    const [start, end] = dateRange
    return generateChartData(aggregateResult, start, end)
  }

  loadStatOverview = () => {
    const { campaign, dateRange, companyId } = this.props
    this.setState(
      {
        isLoadingStatOverview: true,
      },
      async () => {
        let result
        try {
          result = await apiFetch(
            'GET',
            '/statistics/statOverview',
            {},
            {
              campaignId: campaign._id,
              start: dateRange[0],
              end: dateRange[1],
              companyId,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            true
          )

          this.setState({
            isLoadingStatOverview: false,
            noPathways: result.noPathways,
            statOverviewData: result.stats,
            chartData: result.data ? this.prepareChartData(result.data) : null,
          })
        } catch (e) {
          Log.error(e)
          this.setState({
            isLoadingStatOverview: false,
            error: e.message,
          })
        }
      }
    )
  }

  loadData = () => {
    this.loadStatOverview()
  }

  render() {
    const {
      campaign,
      hasBundle,
      companyId,
      dateRange,
      Component = StyledCampaignOverview,
    } = this.props
    const {
      isLoadingStatOverview,
      isLoadingChart,
      statOverviewData,
      chartData,
      noPathways,
      error,
    } = this.state

    if (isLoadingStatOverview || isLoadingChart) {
      return <Loader />
    }

    if (noPathways) {
      return null
    }

    return (
      <Component
        campaign={campaign}
        statData={statOverviewData}
        chartData={chartData}
        companyId={companyId}
        dateRange={dateRange}
        noPathways={noPathways}
        hasBundle={hasBundle}
        error={error}
      />
    )
  }
}
