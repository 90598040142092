import GoalSchema from '../../goal/GoalSchema'
import StepSchema from '../../step/StepSchema'
import TrackingCodeSchema from './TrackingCodeSchema'

export default {
  title: 'Pathway',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },

    name: {
      type: 'string',
    },

    urls: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'string',
            enum: ['main', 'custom'],
          },
          value: {
            type: 'string',
          },
        },
        additionalProperties: false,
      },
    },

    companyId: {
      type: 'string',
    },

    campaignId: {
      type: 'string',
    },

    type: {
      type: 'string',
    },

    templateId: {
      type: 'string',
    },

    goals: {
      type: 'array',
      items: GoalSchema,
    },

    steps: {
      type: 'object',
      propertyPattern: {
        '.*': StepSchema,
      },
    },

    bundle: {
      type: 'object',
      properties: {
        bundleId: {
          type: 'string',
        },
        itemId: {
          type: 'string',
        },
      },
      required: ['bundleId', 'itemId'],
      additionalProperties: false,
    },

    description: {
      type: 'string',
    },

    googleAnalyticsUA: {
      type: 'string',
    },

    gaMeasurementId: {
      type: 'string',
    },

    scoringAlgorithm: {
      type: 'string',
      enum: ['linear', 'fractionalPower'],
    },

    isTemplate: {
      type: 'boolean',
    },

    lastModified: {
      type: 'object',
    },

    linkedBlocks: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          key: {
            type: 'string',
          },
          type: {
            type: 'string',
            enum: ['config', 'content', 'all'],
          },
        },
        required: ['key', 'type'],
      },
    },

    trackingCodes: {
      type: 'array',
      items: TrackingCodeSchema,
    },

    maxScore: {
      type: 'number',
    },
    sessionLengthMinutes: {
      type: 'number',
    },
    inactivityTimeoutSeconds: {
      type: 'number',
    },

    programGroupId: {
      type: 'string',
    },

    cookieConsentBanner: {
      type: 'boolean',
    },
  },

  if: {
    properties: {
      isTemplate: false,
    },
  },
  then: { required: ['companyId'] },

  required: ['name', 'urls'],
  additionalProperties: false,
}
