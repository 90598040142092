import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { withAlert } from 'react-alert'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import RoundButton from '../../../components/RoundButton'
import Link from '../../../components/Link'
import DeleteTrackingCodeModal from '../../../components/modals/DeleteTrackingCodeModal'
import styled from 'styled-components'

class PathwaySettingsCodes extends React.Component {
  static propTypes = {
    pathway: PropTypes.object.isRequired,
    alert: PropTypes.object,
  }

  render() {
    const { pathway, className } = this.props

    return (
      <div className={className}>
        <div className="row align-items-center">
          <div className="col">
            <h4>Tracking Codes</h4>
          </div>
          <div className="col-auto">
            <Button
              color="primary"
              size="sm"
              tag={Link}
              to={`/pathways/${pathway._id}/trackingCodes/new`}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Tracking Code
            </Button>
          </div>
        </div>
        <br />
        <table className="table table-striped">
          <thead>
            <tr>
              <th colSpan="3">Name</th>
            </tr>
          </thead>
          <tbody>
            {pathway.trackingCodes &&
              pathway.trackingCodes.map((code, index) => {
                return (
                  <tr key={code._id}>
                    <td>
                      <Link
                        to={`/pathways/${pathway._id}/trackingCodes/${
                          code._id
                        }`}
                      >
                        {code.name}
                      </Link>
                    </td>
                    <td width="20">
                      <DeleteTrackingCodeModal
                        code={code}
                        pathway={pathway}
                        buttonTag={RoundButton}
                        buttonProps={{ size: 'sm', type: 'button' }}
                      />
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default styled(PathwaySettingsCodes)`
  .table {
    td {
      vertical-align: middle;
    }
    th {
      font-size: 1rem;
      font-weight: 500;
      border-top: 0;
      color: #212529;
    }
  }
  .btn.btn-alt {
    font-size: 0.75rem;
    line-height: 2.1em;
  }
`
