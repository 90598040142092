import React from 'react'
import PageHeader from '../../components/PageHeader'
import Datepicker, { DatepickerValue, DefaultOptions } from '../../components/Datepicker'
import View from '../../components/View'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import PathwayUserActivity from './pathway/PathwayUserActivity'
import PathwayAnswers from './pathway/PathwayAnswers'
import PageActions, { PageAction } from '../../components/PageActions'
import PathwayStepAbandonment from './pathway/PathwayStepAbandonment'
import PathwayScoresContainer from './pathway/PathwayScores'
import PathwayTrafficSourcesContainer from './pathway/PathwayTrafficSources'
import PathwayStatisticsFilter from './pathway/PathwayStatisticsFilter'
import { filterToQuery } from './pathway/PathwayStatisticsFilterSchema'
import omit from 'lodash/omit'
import withDateRange from '../../containers/DateRangeSelectorContainer'
import { DateOptions } from './StatisticsIndex'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { useSelector } from 'react-redux'
import { AppState } from '../../types'
import { Company } from '../../../../_shared/models/company/company.client'
import { ClientSubscription } from '../../../../pubsub/src/server/PubSub/client'
import RESTDataLoader from '../../containers/RESTDataLoader'

const Styles = styled.div`
  .filter-alert {
    margin-top: -1rem;
  }
  .table th {
    border-top: 0;
  }
`

const breadcrumbs = [{ label: 'Statistics', path: '/statistics' }]

const PathwayRoute = (
  { component: Component, pathway, dateRange, filter, ...rest } // eslint-disable-line
) => (
  <Route
    {...rest}
    render={(p) => <Component {...p} pathway={pathway} dateRange={dateRange} filter={filter} />}
  />
)

interface Props {
  className?: string
  dateRange?: DatepickerValue
  onChangeDates?: () => void
  userActivity?: any
  pathway?: Pathway
  isLoadingUserActivity?: boolean
  changeDateRange?: () => void
}

interface State {
  filter?: Record<string, any>
}

class PathwayStatistics extends React.Component<Props, State> {
  state = {
    filter: null,
  }

  changeFilter = ({ formData }) => {
    const filter = {
      ...formData,
    }
    // remove 'any' states
    if (filter.progress === 'any') {
      delete filter.progress
    }

    if (Object.keys(filter).length === 0) {
      this.setState({
        filter: null,
      })
    } else {
      this.setState({
        filter,
      })
    }

    if (window.localStorage) {
      window.localStorage.setItem('statistics-pathway-filter', JSON.stringify(filter))
    }
  }

  getFilterQuery() {
    let query
    const { filterAnswers, ...filter } = this.state.filter
    if (this.state.filter) {
      query = filterToQuery(filter)
    }
    console.warn(query)
    return query
  }

  render() {
    const { className, pathway, dateRange, changeDateRange } = this.props

    const filter = this.state.filter ? omit(this.state.filter, ['filterAnswers']) : null

    return (
      <View title="Statistics">
        <PageHeader
          title={pathway.name}
          breadcrumbs={breadcrumbs}
          navItems={[
            {
              label: 'Activity',
              route: `/statistics/pathway/${pathway._id}`,
              exact: true,
            },
            {
              label: 'Answers',
              route: `/statistics/pathway/${pathway._id}/answers`,
              exact: true,
            },
            {
              label: 'Scores',
              route: `/statistics/pathway/${pathway._id}/scores`,
              exact: true,
            },
            {
              label: 'Traffic Sources',
              route: `/statistics/pathway/${pathway._id}/sources`,
              exact: true,
            },
            {
              label: 'Abandonment',
              route: `/statistics/pathway/${pathway._id}/abandonment`,
              exact: true,
            },
          ]}
        >
          <PageActions>
            <PageAction toolbar>
              <Datepicker onChange={changeDateRange} value={dateRange} options={DateOptions} />
            </PageAction>
            <PageAction toolbar>
              <PathwayStatisticsFilter
                pathway={pathway}
                filter={this.state.filter}
                onChange={this.changeFilter}
              />
            </PageAction>
          </PageActions>
        </PageHeader>
        {filter && (
          <div className="filter-alert">
            You have {Object.keys(filter).length} filter
            {Object.keys(filter).length > 1 ? 's' : ''} enabled.{' '}
            <a
              href="#"
              onClick={() => {
                this.changeFilter({ formData: null })
              }}
            >
              Clear filters.
            </a>
          </div>
        )}
        <div className="content">
          <Styles className={className}>
            <Switch>
              <PathwayRoute
                path="/statistics/pathway/:pathwayId"
                exact
                component={PathwayUserActivity}
                pathway={pathway}
                dateRange={dateRange}
                filter={filter}
              />
              <PathwayRoute
                path="/statistics/pathway/:pathwayId/answers"
                exact
                component={PathwayAnswers}
                pathway={pathway}
                dateRange={dateRange}
                filter={filter}
              />
              <PathwayRoute
                path="/statistics/pathway/:pathwayId/abandonment"
                exact
                component={PathwayStepAbandonment}
                pathway={pathway}
                dateRange={dateRange}
                filter={filter}
              />
              <PathwayRoute
                path="/statistics/pathway/:pathwayId/scores"
                exact
                component={PathwayScoresContainer}
                pathway={pathway}
                dateRange={dateRange}
                filter={filter}
              />
              <PathwayRoute
                path="/statistics/pathway/:pathwayId/sources"
                exact
                component={PathwayTrafficSourcesContainer}
                pathway={pathway}
                dateRange={dateRange}
                filter={filter}
              />
            </Switch>
          </Styles>
        </div>
      </View>
    )
  }
}

const DataLoader = (props) => {
  if (!props.match) {
    return null
  }

  const selectedCompany = useSelector<AppState, Company>(
    (state) => (state.subscriptions.companyContext.data.companies[0] as Company) as Company
  )

  return (
    <RESTDataLoader
      resource="pathways"
      action={props.match.params.pathwayId}
      params={{}}
      showLoader={true}
      transform={(p) => new Pathway(p)}
    >
      {(data, isLoading) => {
        return <PathwayStatistics pathway={data} selectedCompany={selectedCompany} {...props} />
      }}
    </RESTDataLoader>
  )
}

export default withDateRange(DataLoader, DefaultOptions.LAST_30_DAYS.dates())
