import { PersonaStatus } from '../../../backend/server/methods/personas/utils/processCategories'

interface CampusDocument {
  _id?: string
  name: string
}

interface ProgramDocument {
  _id?: string
  campus: string
  low: number
  high: number
  disclosureUrl: string
  name: string
  description: string
  careers: string
}

interface CareerFieldDocument {
  _id?: string
  name: string
}

export interface MaxioConfig {
  customerId: string
  subscriptionId: string
  componentId: string
  componentPricePointId: string
}

export interface GoogleAnalyticsOAuthConfig {
  identity: string
  tokens: Record<string, any>
  config: Record<string, any>
}

export interface CalendlyConfig {
  accessToken: string
  refreshToken: string
}

export enum CompanyStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export class Company {
  declare _id?: string
  declare name: string
  declare type?: string
  declare bundles?: string[]
  declare notes?: string
  declare website?: string
  declare phone?: string
  declare logo?: string
  declare tags?: string[]
  declare features?: string[]
  declare isAllowedChildCompanies?: boolean
  declare parentCompanyId?: string
  declare parentCompanyIds?: string[]
  declare campuses?: CampusDocument[]
  declare programs?: ProgramDocument[]
  declare reporterApiKey?: string
  declare domains?: string[]
  declare reps?: string[]
  declare googleAnalytics?: GoogleAnalyticsOAuthConfig
  declare activeBundles?: string[]
  declare status?: CompanyStatus
  declare maxio?: MaxioConfig
  declare careerFields?: CareerFieldDocument[]
  declare calendly?: CalendlyConfig
  persona: PersonaStatus

  constructor(props: NonFunctionProperties<Company>) {
    Object.assign(this, props)
  }

  isAgency(): boolean {
    return this.type === 'agency'
  }

  isActive(): boolean {
    return this.status === CompanyStatus.ACTIVE
  }
}
