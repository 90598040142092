import React from 'react'
import styled from 'styled-components'

interface Props {
  id?: string
  className?: string
}

const Footer: React.FC<Props> = (props) => {
  return (
    <footer id={props.id} className={props.className}>
      <a
        href="https://virtualadviser.com"
        title="Virtual Adviser website"
        target="_blank"
        rel="noopener noreferrer"
      >
        Virtual Adviser
      </a>{' '}
      v5 ({process.env.COMMIT.substring(0, 8)}) &copy; 2014 - {new Date().getFullYear()}{' '}
      <a href="http://enrollmentresources.com" target="_blank" rel="noopener noreferrer">
        Enrollment Resources Inc
      </a>
      . All Rights Reserved.{' '}
      <a
        href="https://virtualadviser.com/agreement.html"
        title="SaaS Agreement"
        target="_blank"
        rel="noopener noreferrer"
      >
        SaaS Agreement
      </a>{' '}
      -{' '}
      <a
        target="_blank"
        href="http://virtualadviser.com/privacypolicy.html"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>{' '}
      -{' '}
      <a target="_blank" rel="noopener noreferrer" href="http://virtualadviser.com/termsofuse.html">
        Terms of Use
      </a>
    </footer>
  )
}

export default styled(Footer)`
  padding: 0.5rem 1rem;
  text-align: center;
  font-size: 0.65rem;

  &,
  a,
  a:hover,
  a:active,
  a:visited {
    color: var(--text-muted);
  }
`
