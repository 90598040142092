const CRMImportSchema = {
  type: 'object',
  properties: {
    companyId: {
      type: 'string',
    },
    content: {
      type: 'string',
    },
    importType: {
      type: 'string',
      enum: ['interview', 'enrollment'],
    },
  },
  required: ['companyId', 'content', 'importType'],
}

export interface CRMImportResult {
  updated: number
  notfound: number
}

export default CRMImportSchema
