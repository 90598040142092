import Ajv from 'ajv'

const StepSchema = {
  title: 'Step',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },

    name: {
      type: 'string',
    },

    pageTitle: {
      type: 'string',
    },

    content: {
      type: 'array',
    },

    order: {
      type: 'number',
    },
  },
  required: ['_id', 'name', 'order'],
  additionalProperties: false,
}

const ajv = new Ajv({ allErrors: true, removeAdditional: true })
export const StepValidator = ajv.compile<any>(StepSchema)

export default StepSchema
