import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import Loader from './Loader'
import palette from 'google-palette'
import apiFetch from '../../../_shared/api/apiFetch'

// This component renders an activity chart for all pathways belonging to a specific company

const datasetTemplate = {
  label: '',
  lineTension: 0.25,
  borderColor: '#0b6dc7',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBackgroundColor: '#0b6dc7',
  pointHoverRadius: 5,
  pointHoverBackgroundColor: '#0b6dc7',
  pointRadius: 0,
  pointHitRadius: 10,
  data: [],
  fill: false,
}

// Chart itself
export const PathwaysActivityChart = ({ data }) => (
  <div className="chart-container">
    <Line
      data={{
        labels: data.dates,
        datasets: data.datasets,
      }}
      // redraw={false}
      height={200}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        scales: {
          y: {
            ticks: {
              beginAtZero: true,
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            intersect: false,
          },
        },
      }}
    />
  </div>
)
PathwaysActivityChart.propTypes = {
  data: PropTypes.object.isRequired,
}

// Container
class PathwaysActivity extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    campaignId: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    start: PropTypes.object.isRequired,
    end: PropTypes.object.isRequired,
    showStats: PropTypes.bool,
  }

  state = {
    isLoading: true,
    data: null,
  }

  loadChartData = () => {
    const { campaignId, companyId, start, end } = this.props
    this.setState(
      {
        isLoading: true,
        data: null,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/statistics/pathwayActivity',
            {},
            {
              companyId,
              campaignId,
              start,
              end,
              timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
            true
          )

          this.setState({
            error: null,
            isLoading: false,
            data: result,
          })
        } catch (e) {
          this.setState({
            error: e,
            isLoading: false,
            data: null,
          })
          throw new Error(e)
        }
      }
    )
  }

  componentDidMount() {
    this.loadChartData()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.filter !== this.props.filter ||
      prevProps.start !== this.props.start ||
      prevProps.end !== this.props.end
    ) {
      this.loadChartData()
    }
  }

  getChartData = () => {
    const { start, end } = this.props
    const rawData = this.state.data
    if (!rawData) {
      return
    }

    // Choose smart day formats based on the date range
    const diff = differenceInDays(end, start)
    let dateFormat = 'MMM d yyyy'
    if (diff < 365) {
      dateFormat = 'MMM d'
    }

    const dates = []
    let day = start
    while (isBefore(day, end) || isSameDay(day, end)) {
      dates.push(format(day, dateFormat))
      day = addDays(day, 1)
    }

    const colors = palette('tol-rainbow', rawData.length).map(function (hex) {
      return '#' + hex
    })

    const datasets = rawData.map((data, index) => {
      const dataset = []
      let day = start
      const dataMap = data.data.reduce((map, obj) => {
        map[obj._id] = obj
        return map
      }, {})

      while (isBefore(day, end) || isSameDay(day, end)) {
        const dayFormatted = format(day, 'yyyy-MM-dd')
        dataset.push(dataMap[dayFormatted]?.registrations || 0)
        day = addDays(day, 1)
      }
      return {
        ...datasetTemplate,
        borderColor: colors[index],
        backgroundColor: colors[index],
        pointHoverBackgroundColor: colors[index],
        pointBackgroundColor: colors[index],
        label: data.pathway.name,
        data: dataset,
      }
    })

    return {
      dates,
      datasets,
    }
  }

  getTotals() {
    const { data } = this.state
    const totals = {
      registrations: 0,
      completions: 0,
    }

    if (data) {
      data.forEach((pathwayData) => {
        pathwayData.data.forEach((lineData) => {
          totals.registrations += lineData.registrations
          totals.completions += lineData.completions
        })
      })
    }

    totals.completionRate = Number((totals.completions / totals.registrations) * 100).toFixed(0)

    return totals
  }

  render() {
    const { className, showStats = true } = this.props
    const { isLoading } = this.state

    if (isLoading) {
      return <Loader />
    }

    const chartData = this.getChartData()
    const totals = this.getTotals()
    return (
      <div className={className}>
        {showStats && (
          <div className="row quick-stats">
            <div className="col">
              <div className="metric">{totals.registrations}</div>
              Registrations
            </div>
            <div className="col">
              <div className="metric">{totals.completions}</div>
              Completions
            </div>
            <div className="col">
              <div className="metric">{totals.completionRate}%</div> Completion Rate
            </div>
          </div>
        )}
        <PathwaysActivityChart data={chartData} />
      </div>
    )
  }
}

export default styled(PathwaysActivity)`
  .chart-container {
    height: 260px;
    width: 100%;
    position: relative;
  }
`
