import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'
import { parse, format } from 'date-fns'

const CampaignChartWrapper = (props) => {
  let days = []
  let leads = []
  Object.keys(props.data).forEach((day) => {
    days.push(format(parse(day, 'yyyy-mm-dd', new Date()), 'Do'))
    leads.push(props.data[day])
  })

  const chartData = {
    labels: days,
    datasets: [
      {
        label: 'Cumulative Leads',
        lineTension: 0.2,
        backgroundColor: 'rgba(64,86,111,0.4)',
        borderColor: 'rgba(64,86,111,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(64,86,111,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 0,
        pointHoverRadius: 6,
        pointHoverBackgroundColor: 'rgba(64,86,111,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 6,
        data: leads,
        fill: 'origin',
      },
    ],
  }
  const options = {
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    axes: {
      gridLines: {
        display: false,
      },
    },
    scales: {
      y: {
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        ticks: {
          display: false,
        },
        gridLines: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        tooltipCaretSize: 0,
      },
    },
  }

  return <Line data={chartData} options={options} height={25} />
}

CampaignChartWrapper.propTypes = {
  data: PropTypes.object,
}

const CampaignRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  div {
    display: flex;
    flex: 0 0 auto;
    align-items: center;

    font-weight: bold;
    font-size: 1em;
  }
  .campaign-title {
    width: 15%;
  }
  .campaign-value {
    color: var(--heading-color);
    width: 5%;
  }
  .campaign-chart {
    width: 80%;
  }
`

const CampaignChart = (props) => {
  const { label, value, data } = props
  return (
    <CampaignRow>
      <div className="campaign-title">{label}</div>
      <div className="campaign-value">{value}</div>
      <div className="campaign-chart">
        <CampaignChartWrapper data={data} />
      </div>
    </CampaignRow>
  )
}

CampaignChart.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  data: PropTypes.object,
}

export default CampaignChart
