import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import PersonaPanel from '../PersonaPanel'
import { faArrowCircleDown } from '@fortawesome/pro-solid-svg-icons/faArrowCircleDown'
import { faArrowCircleUp } from '@fortawesome/pro-solid-svg-icons/faArrowCircleUp'
import classNames from 'classnames'
import {
  Persona,
  StatisticalSignificance,
} from '../../../../../../_shared/models/persona/persona.types'
import InsightDictionary from '../InsightDictionary'
import { PersonaColors } from '../PersonaColors'
import Log from '../../../../../../_shared/log'

interface PersonaHoldingBackProps {
  className?: string
  persona: Persona
}

const PersonaHoldingBack: React.FC<PersonaHoldingBackProps> = ({ className, persona }) => {
  if(persona.holdingBack) {
    persona.holdingBack.sort((a, b) => b.count - a.count)
  }
  return (
    <div className={className}>
      <PersonaPanel title="Enrollment Roadblocks" aiDisclaimer={true}>
        <div>
          <ul className="insights-list">
            {persona.holdingBack && persona.holdingBack.length > 0 ? (
              persona.holdingBack.map((block, index) => (
                <li key={index} className="custom-list">
                  <div className="custom-list-bullet">{index + 1}</div>
                  <div className="custom-list-output">
                    <div className="theme-title">
                      {block.theme || 'Error generating theme'} ({block.count || '?'} responses)
                    </div>
                    <p>{block.impact || 'Error generating description'}</p>
                    <blockquote>"{block.example || 'Error retrieving example response'}"</blockquote>
                  </div>
                </li>
              ))
            ) : (
              <p>No roadblocks are available for this segment.</p>
            )}
          </ul>
        </div>
      </PersonaPanel>
    </div>
  )
}
//parse sources section, have link

export default styled(PersonaHoldingBack)`
  .theme-title {
    padding: 2px 0 0 0;
    color: ${PersonaColors.barriers};
    // font-size: 1.1rem;
    font-weight: bold;
  }

  p {
    color: #2d2d2d;
    font-size: 1rem;
    margin: 0.5rem 0;
  }

  blockquote {
    border-left: 4px solid #8a8a8a;
    margin: 1rem 0;
    padding: 0.5rem;
    font-style: italic;
    background-color: #f5f5f5;
  }

  .content-container {
    display: flex;
    justify-content: left;
    align-items: left;
  }

  .insights-list {
    /*border: solid;
    border-color: #e9e9e9;
    border-radius: 10px;*/
    list-style-type: none; /* Remove the default bullet points */
    //border: solid;
    margin: 0;
    padding: 0;
  }

  .custom-list {
    // border-left: 2px dashed;
    border-color: #e8e8e8 !important;
    margin: 0 0 1rem 0;
    padding: 0;
    counter-increment: section; /* Increment the counter for each .custom-list item */
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .custom-list-bullet {
    content: counter(section);
    margin-right: 10px;
    border-radius: 50%;
    padding: 10px;
    height: 30px;
    width: 30px;
    background-color: #c42636;
    color: white;
    font-size: 110%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
