import React from 'react'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import palette from 'google-palette'
import Pie from '../../../components/Pie'
import Pathway from '../../../../../_shared/models/pathway/pathway.backend.client'
import apiFetch from '../../../../../_shared/api/apiFetch'

interface Props {
  className?: string
  goals?: any
  pathway: Pathway
}

const PathwayGoals: React.FC<Props> = (props) => {
  const { className, goals, pathway } = props

  const pathwayGoals =
    pathway.goals?.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    }) || []

  const colors = palette('tol-rainbow', pathwayGoals.length).map(function (hex) {
    return '#' + hex
  })

  if (!goals || goals.length <= 0) {
    return null
  }

  return (
    <div className={className}>
      <Table striped>
        <thead>
          <tr>
            <th>Goal</th>
            <th /* width="150" */>Triggers</th>
            <th /* width="180" */>Trigger Rate</th>
          </tr>
        </thead>
        <tbody>
          {pathwayGoals.map((goal, index) => {
            const count = goals[0][goal._id] || 0
            const rate = Math.round((count / goals[0].registrations) * 100)
            return (
              <tr key={goal._id}>
                <td>
                  <div className="chart-legend" style={{ backgroundColor: colors[index] }} />
                  {goal.name}
                </td>
                <td>{count}</td>
                <td>
                  <Pie value={rate} />
                  <span className="completion-rate">{rate}%</span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

const PathwayGoalsStyled = styled(PathwayGoals)`
  font-size: 0.875rem;

  .table th {
    font-weight: 600;
  }

  .csspie-wrapper {
    margin-right: 0.7rem;
    font-size: 0.875rem;
  }
  .csspie-wrapper,
  .completion-rate {
    display: inline-block;
    vertical-align: middle;
  }
  .chart-legend {
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
  }
`

interface PathwayGoalsContainerProps {
  pathway: Pathway
  start: Date
  end: Date
}

interface PathwayGoalsContainerState {
  isLoading: boolean
  goals: any
  error: string
}

export default class PathwayGoalsContainer extends React.Component<
  PathwayGoalsContainerProps,
  PathwayGoalsContainerState
> {
  state = {
    isLoading: false,
    goals: null,
    error: null,
  }

  loadPathwayGoals = (): void => {
    const { pathway, start, end } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const goals = await apiFetch(
            'GET',
            `/goals`,
            {},
            { pathwayId: pathway._id, start: start.toDateString(), end: end.toDateString() }
          )
          this.setState({
            isLoading: false,
            error: null,
            goals,
          })
        } catch (e) {
          this.setState({
            error: e,
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  componentDidMount(): void {
    this.loadPathwayGoals()
  }

  componentDidUpdate(prevProps: PathwayGoalsContainerProps): void {
    if (prevProps.start !== this.props.start || prevProps.end !== this.props.end) {
      this.loadPathwayGoals()
    }
  }

  render(): React.ReactNode {
    if (this.state.isLoading) {
      return <Loader />
    }
    return <PathwayGoalsStyled goals={this.state.goals} {...this.props} />
  }
}
