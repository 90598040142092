export default {
  title: 'Integration',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },
    name: {
      title: 'Name',
      type: 'string',
    },
    companyId: {
      type: 'string',
    },
    type: {
      title: 'Type',
      type: 'string',
    },
    config: {
      type: 'object',
    },
  },
  required: ['name', 'companyId'],
  additionalProperties: false,
}
