export const expressiveTone = `Use a warm, enthusiastic tone with expressive individuals, employing vivid language and storytelling. Focus on success stories and the emotional benefits of your proposal, engaging their imagination and passion.`
export const analyticalTone = `Employ a logical, detailed tone for analytical individuals, structuring your message with evidence and data. Provide thorough explanations and specifications, anticipating questions and patiently explaining the rationale behind your proposal.`
export const amiableTone = `Adopt a friendly and supportive tone that emphasizes collaboration and focuses on their goals and needs. Instead of pushing for quick decisions, provide reassurance and support. Strive to create a sense of belonging and community.`
export const driverTone = `Adopt a direct, decisive tone with drivers, focusing on efficiency and outcomes. Clearly outline the benefits and practical results of your proposal, using facts to support your claims and respecting their time.`
const selectedToneDescription = (selectedTone: string): string => {
  let insertTone: string
  switch (selectedTone) {
    case 'expressive':
      insertTone = expressiveTone
      break
    case 'analytical':
      insertTone = analyticalTone
      break
    case 'driver':
      insertTone = driverTone
      break
    case 'amiable':
      insertTone = amiableTone
      break
    default:
      //fallback in case selected tone is not found which shouldnt happen
      insertTone = 'Be creative and enthusiastic.'
  }
  return insertTone
}
export default selectedToneDescription
