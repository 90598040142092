import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import Panel from '../../../components/Panel'
import { Line } from 'react-chartjs-2'
import apiFetch from '../../../../../_shared/api/apiFetch'

const PathwayScores = (props) => {
  const { className, data, pathway } = props

  console.log(
    JSON.stringify([
      {
        label: 'Results',
        lineTension: 0.25,
        backgroundColor: 'rgba(11,109,199,0.05)',
        fill: true,
        borderColor: '#0b6dc7',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#0b6dc7',
        pointHoverRadius: 5,
        pointHoverBackgroundColor: '#0b6dc7',
        pointRadius: 0,
        pointHitRadius: 10,
        data,
        // fill: false,
      },
    ])
  )
  return (
    <div className={className}>
      <Panel>
        <h4 className="text-center">Score Distribution</h4>
        <br />
        <div className="chart-container" style={{ position: 'relative', height: '350px' }}>
          <Line
            data={{
              datasets: [
                {
                  label: 'Results',
                  lineTension: 0.25,
                  backgroundColor: 'rgba(11,109,199,0.05)',
                  fill: true,
                  borderColor: '#0b6dc7',
                  borderCapStyle: 'butt',
                  borderDash: [],
                  borderDashOffset: 0.0,
                  borderJoinStyle: 'miter',
                  pointBackgroundColor: '#0b6dc7',
                  pointHoverRadius: 5,
                  pointHoverBackgroundColor: '#0b6dc7',
                  pointRadius: 0,
                  pointHitRadius: 10,
                  data,
                  // fill: false,
                },
              ],
            }}
            redraw={false}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              hover: {
                mode: 'index',
                intersect: false,
              },
              scales: {
                x: {
                  scaleLabel: {
                    display: true,
                    labelString: 'Score',
                  },
                  type: 'linear',
                  beginAtZero: true,
                  ticks: {
                    max: 100,
                  },
                },
                y: {
                  scaleLabel: {
                    display: true,
                    labelString: 'Results',
                  },
                  beginAtZero: true,
                },
              },
              plugins: {
                tooltip: {
                  mode: 'index',
                  intersect: false,
                },
                legend: {
                  display: false,
                  position: 'bottom',
                  labels: {
                    padding: 20,
                    usePointStyle: true,
                  },
                },
              },
            }}
          />
        </div>
      </Panel>
    </div>
  )
}
PathwayScores.propTypes = {
  className: PropTypes.string,
}

const PathwayScoresStyled = styled(PathwayScores)`
  font-size: 0.875rem;
`

export default class PathwayScoresContainer extends React.Component {
  state = {
    isLoading: true,
    loadPathwayScores: null,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    dateRange: PropTypes.array.isRequired,
  }

  loadPathwayScores = () => {
    const { pathway, dateRange, filter } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/statistics/pathwayScores',
            {},
            {
              pathwayId: pathway._id,
              start: dateRange[0],
              end: dateRange[1],
              filter,
            },
            true
          )

          this.setState({
            isLoading: false,
            error: null,
            scores: result,
          })
        } catch (e) {
          this.setState({
            error: e,
            isLoading: false,
          })
        }
      }
    )
  }

  componentDidMount() {
    this.loadPathwayScores()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter || prevProps.dateRange !== this.props.dateRange) {
      this.loadPathwayScores()
    }
  }

  getChartData() {
    const { scores } = this.state
    return scores.map((item) => {
      return { x: item._id, y: item.count }
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }
    return <PathwayScoresStyled data={this.getChartData()} {...this.props} />
  }
}
