// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import styled from 'styled-components'
import { PersonaCategories } from '../../../../../_shared/models/persona/persona.types'

const GridContainer = styled.div`
  
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 1rem;
`

const Title = styled.h6`
  grid-column: 1 / -1;
  margin-top: 0.5rem;
`
// Define interface for Persona props
interface PersonaSelectorProps {
  selected: boolean
}

const Persona = styled.div<PersonaSelectorProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.25rem 0;
  padding: 0.75rem;
  outline: ${props => props.selected ? '1px solid black' : '1px solid grey'};
  background-color: ${props => props.selected ? '#0A6DC7' : 'transparent'};
  color: ${props => props.selected ? 'white' : '#41566F'};
  cursor: pointer;
  font-size: 0.9em;
`
const SampleSize = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.5rem;
  .user-icon {
    margin-right: 0.25rem;
    height: 0.6em;
  }
  span{
    font-size: 0.6em;
    text-align: left;
    width: 6ch;
  }
`
function PersonaSelector({
  personas,
  selected,
  onSelect,
}: {
  personas: any[]
  selected: string
  onSelect: (index: number) => void
}) {

  // Function to determine the sort order of personas
  const getSortOrder = (persona) => {
    if (persona.category === 'overall') {
      return 1
    }
    if (Object.keys(PersonaCategories.age).includes(persona.category)) {
      return 2
    }
    if (Object.keys(PersonaCategories.careerFields).includes(persona.category)) {
      return 3
    }
    if (persona.category.startsWith('program')) {
      return 4
    }
    if (persona.category.startsWith('campus')) {
      return 5
    }
    return 6
  }

  // Sort the personas array
  const sortedPersonas = [...personas].sort((a, b) => getSortOrder(a) - getSortOrder(b))

  // Group the personas for rendering with titles
  const groupTitles = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '1': 'Everyone',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '2': 'Age Groups',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '3': 'Career Fields',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '4': 'Programs',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '5': 'Campuses',
  }
  interface Group {
    title: string;
    personas: any[];
  }
  const groupedPersonas: Record<string, Group> = sortedPersonas.reduce((groups: Record<string, Group>, persona:any ) => {
    const order = getSortOrder(persona).toString();
    if (!groups[order]) {
      groups[order] = {
        title: groupTitles[order],
        personas: [],
      };
    }
    groups[order].personas.push(persona);
    return groups;
  }, {});

  // Render the grouped and sorted personas
 return (
    <GridContainer>
      <h5>Select Target Audience</h5>
      {Object.entries(groupedPersonas).map(([order, group]) => (
        <React.Fragment key={order}>
          <Title>{group.title}</Title>
          {group.personas.map((persona, index) => (
            <Persona
              key={index}
              onClick={() => onSelect(persona._id)}
              selected={selected === persona._id}
            >
              {persona.name}
              <SampleSize>
                <FontAwesomeIcon className='user-icon' icon={faUser} />
                <span>{persona.sampleSize}</span>
              </SampleSize>
            </Persona>
          ))}
        </React.Fragment>
      ))}
      <br />
    </GridContainer>
  )
 }

export default PersonaSelector
