import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../components/Panel'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff'
import RoundButton from '../../components/RoundButton'
import { Button } from 'reactstrap'
import NewButton from '../../components/NewButton'
import Link from '../../components/Link'
import NotificationManageDropdown from './NotificationManageDropdown'
import View from '../../components/View'
import { PanelList, PanelListItem } from '../../components/PanelList'
import { UncontrolledToggleButton } from '../../components/ToggleButton'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import apiFetch from '../../../../_shared/api/apiFetch'
import { shortCache } from '../../api/subscriptionCache'

const FirstNotification = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/map.png" width="128" />
      <h3>Create your first notification</h3>
      <h6>Gather prospects, view statistics, insights and more</h6>
      <Button
        tag={Link}
        color="primary"
        size="lg"
        to={`/pathways/${props.pathway._id}/notifications/new`}
      >
        <FontAwesomeIcon icon={faPlus} /> New Notification
      </Button>
    </PanelHero>
  </Panel>
)

export class Notification extends React.PureComponent {
  state = {
    isLoading: false,
  }

  toggle = () => {
    if (this.state.isLoading) {
      return
    }

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/notifications/toggle', {
            notificationId: this.props.notification._id,
          })
          this.setState({
            isLoading: false,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { notification, pathway } = this.props
    return (
      <PanelListItem>
        <div className="row align-items-center flex-nowrap">
          <div className="col">
            <div className="panel-list-item-title">
              <Link to={`/pathways/${pathway._id}/notifications/${notification._id}`}>
                {notification.name}
              </Link>
            </div>
          </div>
          <div className="d-flex flex-nowrap col-auto buttons">
            <UncontrolledToggleButton
              activeLabel="Enabled"
              inactiveLabel="Disabled"
              icon={faPowerOff}
              value={notification.isEnabled}
              toggle={this.toggle}
            />
            <RoundButton
              size="sm"
              tag={Link}
              to={`/pathways/${pathway._id}/notifications/${notification._id}`}
              className="d-none d-md-inline-block"
            >
              <FontAwesomeIcon icon={faPencil} />
            </RoundButton>
            <NotificationManageDropdown notification={notification} />
          </div>
        </div>
      </PanelListItem>
    )
  }
}

Notification.propTypes = {
  notification: PropTypes.object.isRequired,
  pathway: PropTypes.object.isRequired,
}

const PathwayNotificationIndex = (props) => {
  const { pathway } = props

  return (
    <View title={`Notifications - ${pathway.name}`}>
      <SubscriptionLoader
        resource="notifications"
        cache={shortCache}
        query={{ pathwayId: pathway._id, companyId: pathway.companyId }}
        sort={{ name: 1 }}
        showLoader={true}
        fields={['_id', 'name', 'companyId', 'pathwayId', 'isEnabled']}
        transform={(store) => ({
          ...store,
          notifications: sort(store.notifications).asc((r) => r.name),
        })}
      >
        {({ data }) => {
          if (!data?.notifications || data.notifications.length === 0) {
            return <FirstNotification pathway={pathway} />
          }
          return (
            <React.Fragment>
              <PanelList>
                {data.notifications.map((i) => (
                  <Notification key={i._id} notification={i} pathway={pathway} />
                ))}
              </PanelList>
              <NewButton
                tag={Link}
                center
                to={`/pathways/${pathway._id}/notifications/new`}
                label="New Notification"
              />
            </React.Fragment>
          )
        }}
      </SubscriptionLoader>
    </View>
  )
}

PathwayNotificationIndex.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default PathwayNotificationIndex
