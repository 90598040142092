const ResultsFilterSchema = {
  type: 'object',
  properties: {
    pathways: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
      },
    },
    campuses: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
      },
    },
    programs: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          label: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
      },
    },
    progress: {
      type: 'string',
    },
    score: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
    lead_src: {
      type: 'string',
      title: 'Lead Source',
    },
    referrer: {
      type: 'string',
    },
    status: {
      type: 'string',
    },
    notifications: {
      type: 'string',
    },
    filterAnswers: {
      type: 'boolean',
    },
    answers: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          field: {
            type: 'string',
          },
          comparator: {
            type: 'string',
          },
          value: {
            type: 'string',
          },
        },
        required: ['field', 'comparator', 'value'],
      },
    },
  },
}

export default ResultsFilterSchema
