import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../../components/Panel'
import Link from '../../../components/Link'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../../components/RoundButton'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import NewButton from '../../../components/NewButton'
import View from '../../../components/View'
import BasicModal from '../../../components/BasicModal'
import { PanelListItem, PanelList } from '../../../components/PanelList'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'

class DeleteMapping extends React.PureComponent {
  static propTypes = {
    company: PropTypes.object.isRequired,
    programGroup: PropTypes.object.isRequired,
  }

  callMethod = async () => {
    try {
      await apiFetch('DELETE', '/programGroups/delete', {
        programGroupId: this.props.programGroup._id,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Program?"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.programGroup.name}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
      >
        <RoundButton size="sm" onClick={this.delete}>
          <FontAwesomeIcon icon={faTimes} />
        </RoundButton>
      </BasicModal>
    )
  }
}

const FirstMapping = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/education.png" width="128" />
      <h3>Create your first program &amp; group</h3>
      <h6>Group your program offerings by campus, add wage data, disclosures and more</h6>
      <Link tag="button" to={`/organization/programGroups/new`} className="btn btn-lg btn-primary">
        <FontAwesomeIcon icon={faPlus} /> New Group
      </Link>
    </PanelHero>
  </Panel>
)

const ProgramGroup = (props) => {
  const { programGroup, company } = props
  return (
    <PanelListItem>
      <div className="row align-items-center flex-nowrap">
        <div className="col">
          <div className="panel-list-item-title">
            <Link to={`/organization/programGroups/${programGroup._id}`}>{programGroup.name}</Link>
          </div>
        </div>
        <div className="d-flex flex-nowrap col-auto buttons">
          <RoundButton
            tag={Link}
            size="sm"
            to={`/organization/programGroups/${programGroup._id}`}
            className="d-none d-md-inline-block"
          >
            <FontAwesomeIcon icon={faPencil} />
          </RoundButton>
          <DeleteMapping programGroup={programGroup} company={company} />
        </div>
      </div>
    </PanelListItem>
  )
}
ProgramGroup.propTypes = {
  programGroup: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
}

const ProgramGroupIndex = (props) => {
  const { company } = props
  return (
    <View title={`Program Groups - ${company.name}`}>
      <SubscriptionLoader
        resource="programGroups"
        cache={shortCache}
        query={{ companyId: company._id }}
        sort={{ name: 1 }}
        showLoader={true}
        transform={(store) => ({
          ...store,
          programGroups: sort(store.programGroups).asc((r) => r.name),
        })}
      >
        {({ data }) => {
          const { program_groups: programGroups } = data
          if (!programGroups || programGroups.length === 0) {
            return <FirstMapping />
          }

          return (
            <React.Fragment>
              <PanelList>
                {programGroups &&
                  programGroups.map((c) => (
                    <ProgramGroup key={c._id} company={company} programGroup={c} />
                  ))}
              </PanelList>
              <NewButton
                label="New Program Group"
                tag={Link}
                center
                to={`/organization/programGroups/new`}
              />
            </React.Fragment>
          )
        }}
      </SubscriptionLoader>
    </View>
  )
}

ProgramGroupIndex.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default ProgramGroupIndex
