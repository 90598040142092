import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../components/Panel'
import { Button } from 'reactstrap'
import PageHeader from '../../components/PageHeader'
import StepSchema from '../../../../_shared/models/step/StepSchema'
import get from 'lodash/get'
import { redirect } from '../../components/Link'
import withForm from '../../../../_shared/components/withForm'
import FormError from '../../../../_shared/components/Form/FormError'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import apiFetch from '../../../../_shared/api/apiFetch'

const { name } = StepSchema.properties

const StepFormSchema = {
  type: 'object',
  properties: {
    name,
    template: {
      type: 'string',
    },
    stepId: {
      title: 'Step',
      type: 'string',
    },
  },
  required: ['name', 'template'],
}

const StepSelector = (props) => {
  const { formData, pathway } = props
  if (get(formData, 'template') === 'step') {
    return (
      <Dropdown name="stepId">
        {pathway.getSteps().map((step) => (
          <option key={step._id} value={step._id}>
            {step.name}
          </option>
        ))}
      </Dropdown>
    )
  } else {
    return null
  }
}
const StepSelectorWithForm = withForm(StepSelector, true)

class StepCreate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { location, history } = this.props
    const { _id: pathwayId } = this.props.pathway
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        const result = await apiFetch('POST', '/steps/create', { pathwayId, doc: data.formData })
        redirect({
          location,
          history,
          pathname: `/pathways/${pathwayId}/steps/${result.stepId}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error('CreateStep', e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway } = this.props

    return (
      <div>
        <PageHeader
          breadcrumbs={[{ label: pathway.name, path: `/pathways/${pathway._id}` }]}
          title="New Step"
        />
        <div className="content">
          <Panel>
            <Form formData={{}} schema={StepFormSchema} onValidSubmit={this.onSubmit}>
              <PanelForm center={false}>
                <Input type="text" name="name" />
              </PanelForm>

              <Cardbox name="template" defaultValue="blank" thin selectionRequired>
                <CardboxItem title="Blank" value="blank" description="A blank layout" />
                <CardboxItem
                  title="Step"
                  value="step"
                  description="Use an existing step as a template"
                />
              </Cardbox>

              <PanelForm center={false}>
                <StepSelectorWithForm pathway={pathway} />
              </PanelForm>

              <hr />
              {this.renderError()}

              <Button size="lg" color="primary" type="submit" disabled={this.state.isLoading}>
                Create Step
              </Button>
            </Form>
          </Panel>
        </div>
      </div>
    )
  }
}

StepCreate.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default StepCreate
