import React from 'react'
import PageHeader from '../../components/PageHeader'
import Datepicker, { DatepickerValue, DefaultOptions } from '../../components/Datepicker'
import Panel from '../../components/Panel'
import View from '../../components/View'
import PageActions, { PageAction } from '../../components/PageActions'
import { startOfMonth, endOfMonth, subMonths } from 'date-fns'
import withDateRange from '../../containers/DateRangeSelectorContainer'
import CampaignOverview, { CampaignOverviewContainer } from './components/CampaignOverview'
import styled from 'styled-components'
import Link from '../../components/Link'
import { Company } from '../../../../_shared/models/company/company.client'
import Campaign, { GlobalCampaigns } from '../../../../_shared/models/campaign/campaign.client'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { useSelector } from 'react-redux'
import { AppState } from '../../types'
import { ClientSubscription } from '../../../../pubsub/src/server/PubSub/client'
import { shortCache } from '../../api/subscriptionCache'
import { Route, Switch } from 'react-router-dom'
import PersonaStatisticsStyled, { PersonasLoader } from './personas/PersonaStatisticsIndex'

export const DateOptions = [
  DefaultOptions.LAST_7_DAYS,
  DefaultOptions.LAST_30_DAYS,
  DefaultOptions.LAST_365_DAYS,
  {
    label: 'This Month',
    key: 'thismonth',
    dates(): [Date, Date] {
      return [startOfMonth(new Date()), endOfMonth(new Date())]
    },
  },
  {
    label: 'Last Month',
    key: 'lastmonth',
    dates(): [Date, Date] {
      const lastMonth = subMonths(new Date(), 1)
      return [startOfMonth(lastMonth), endOfMonth(lastMonth)]
    },
  },
]

interface Props {
  className?: string
  dateRange: DatepickerValue
}

export const StatisticsIndexContent: React.FC<Props> = ({ className, dateRange }) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => state.subscriptions.companyContext.data.companies[0] as Company as Company
  )

  if (selectedCompany._id === 'demoaccount') {
    selectedCompany.googleAnalytics = { identity: '', tokens: {}, config: {} }
  }

  return (
    <div className={className}>
      <Panel>
        {!selectedCompany.googleAnalytics?.config && (
          <div className="alert alert-info text-center link-google-analytics">
            <strong>
              <Link to="/organization">Link your Google Analytics account</Link>
            </strong>{' '}
            to enable Website statistics.
          </div>
        )}
        {selectedCompany._id === 'demoaccount' && (
          <div className="alert alert-warning text-center">
            <strong>Demo Data</strong>: This data is for demonstration purposes only.
          </div>
        )}
        {Object.values(GlobalCampaigns).map((campaign) => {
          return (
            <CampaignOverviewContainer
              key={campaign._id}
              dateRange={dateRange}
              companyId={selectedCompany._id}
              campaign={campaign}
              hasBundle={
                campaign._id === 'other'
                  ? true
                  : selectedCompany.bundles.indexOf(campaign._id) !== -1
              }
            />
          )
        })}

        <SubscriptionLoader
          query={{ companyId: selectedCompany._id }}
          resource="campaigns"
          cache={shortCache}
          showLoader={true}
        >
          {(subscription: ClientSubscription) => {
            const campaigns = subscription.store?.data?.campaigns
            return (
              <React.Fragment>
                {campaigns &&
                  campaigns.map((campaign: Campaign) => (
                    <CampaignOverviewContainer
                      companyId={selectedCompany._id}
                      dateRange={dateRange}
                      campaign={campaign}
                      key={campaign._id}
                    />
                  ))}
              </React.Fragment>
            )
          }}
        </SubscriptionLoader>
      </Panel>
    </div>
  )
}

interface StatisticsIndexProps {
  className?: string
  dateRange: DatepickerValue
  changeDateRange: (value: DatepickerValue, key: string) => void
}

const StatisticsIndex: React.FC<StatisticsIndexProps> = ({ dateRange, changeDateRange }) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => state.subscriptions.companyContext.data.companies[0] as Company as Company
  )

  return (
    <View title="Statistics">
      <PageHeader
        title="Statistics"
        navItems={[
          { label: 'Activity', route: `/statistics` },
          {
            label: 'Lenses',
            route: `/statistics/lens`,
            isNew: true,
          },
        ]}
      >
        <PageActions>
          <PageAction toolbar>
            <Datepicker onChange={changeDateRange} value={dateRange} options={DateOptions} />
          </PageAction>
        </PageActions>
      </PageHeader>
      <div className="content">
        <Switch>
          <Route path="/statistics" exact>
            <StyledStatisticsIndexContent dateRange={dateRange} />
          </Route>
          <Route path="/statistics/lens" exact>
            <PersonasLoader companyId={selectedCompany._id} />
          </Route>
        </Switch>
      </div>
    </View>
  )
}

export const StyledStatisticsIndexContent = styled(StatisticsIndexContent)`
  ${CampaignOverview} {
    padding: 2rem 0 0 0;
    border-bottom: 2px solid #f0f2f6;
    &:first-child {
      padding: 0;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
`

export default withDateRange(StatisticsIndex, DefaultOptions.LAST_30_DAYS.dates())
