import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import styled from 'styled-components'
import { faMagic } from '@fortawesome/pro-solid-svg-icons/faMagic'

interface Props {
  className?: string
  title?: string
  description?: string
}

const AiDisclaimer = ({ title, description, className }: Props) => {
  return (
    <div className={className}>
      <div className="ai-disc-label">
        <FontAwesomeIcon fixedWidth icon={faMagic} /> AI Generated
      </div>
      <div className="ai-disc-tooltip">
        <div className="ai-disc-tooltip-title">{title || 'AI Generated Content'}</div>
        <div className="ai-disc-tooltip-description">
          {description ||
            'This content was created using artificial intelligence and, while we strive for accuracy, it may occasionally contain errors. It should be used as a guide, not as the sole source for decision-making. We accept no liability for any inaccuracies or mistakes arising from its use.'}
        </div>
      </div>
    </div>
  )
}

export default styled(AiDisclaimer)`
  float: right;
  position: relative;

  .ai-disc-label {
    color: #a7a7a7;
    font-size: 0.875rem;
  }
  .ai-disc-tooltip {
    display: none;
    z-index: 9000;
    position: absolute;
    top: 0;
    right: 0;
    background: #fffae8;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    padding: 1em;
    width: 100vw;
    min-width: 300px;
    max-width: 400px;
    font-size: 0.875rem;
  }
  .ai-disc-tooltip-title {
    font-weight: 600;
    margin: 0 0 0.2em 0;
  }
  .ai-disc-tooltip-description {
    color: #686154;
  }

  &:hover {
    .ai-disc-tooltip {
      display: block;
    }
  }
`
