import React from 'react'
import PropTypes from 'prop-types'
import { faFile } from '@fortawesome/pro-solid-svg-icons/faFile'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly'
import RoundButton from '../../components/RoundButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import MovePathwayModal from '../../components/modals/MovePathwayModal'
import AiCopyModal from '../../components/modals/AILPModal/AiCopyModal'
import DeletePathwayModal from '../../components/modals/DeletePathwayModal'
import Link, { Redirect } from '../../components/Link'
import Log from '../../../../_shared/log'
import apiFetch from '../../../../_shared/api/apiFetch'
import ReplaceRegStepModal from '../../components/modals/ReplaceRegStepModal'
import ArchivePathwayModal from '../../components/modals/ArchivePathwayModal'
import { connect } from 'react-redux'

export class PathwayManageDropdownOptions extends React.PureComponent {
  state = {
    isLoading: false,
    redirect: null,
  }
  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    deleteRedirect: PropTypes.string,
  }

  duplicate = () => {
    if (this.state.isLoading) {
      return
    }
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const result = await apiFetch('POST', '/pathways/copy', {
            pathwayId: this.props.pathway._id,
          })
          this.setState({
            isLoading: false,
            redirect: '/pathways/' + result.pathwayId,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { pathway, deleteRedirect, user, company } = this.props

    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }

    return (
      <div className="text-small">
        <DropdownItem tag={Link} to={`/pathways/${pathway._id}/api`}>
          <FontAwesomeIcon fixedWidth icon={faFile} /> API
        </DropdownItem>
        <DropdownItem onClick={this.duplicate} toggle={false}>
          <FontAwesomeIcon fixedWidth icon={faCopy} /> Duplicate
        </DropdownItem>
        {/* <MovePathwayModal tag={DropdownItem} toggle={false} pathway={pathway} /> */}
        <DropdownItem divider />
        {/* <DropdownItem>
          <FontAwesomeIcon fixedWidth icon={faPowerOff} /> Disable
        </DropdownItem> */}

        {/* todo: this should only show up for admins */}
        {/* <CreateTemplateModal
          pathway={pathway}
          buttonTag={DropdownItem}
          buttonProps={{ toggle: false }}
        /> */}
        <MovePathwayModal tag={DropdownItem} buttonProps={{ toggle: false }} pathway={pathway} />

        {user?.isAdmin() && (
          <>
            <AiCopyModal
              tag={DropdownItem}
              buttonProps={{ toggle: false }}
              pathway={pathway}
              company={company}
            />
            <ReplaceRegStepModal
              tag={DropdownItem}
              buttonProps={{ toggle: false }}
              pathway={pathway}
            />
            {pathway.campaignId !== 'archive' && (
            <ArchivePathwayModal
              tag={DropdownItem}
              buttonProps={{ toggle: false }}
              pathway={pathway}
            />
            )}
          </>
        )}

        <DeletePathwayModal
          redirect={deleteRedirect}
          buttonTag={DropdownItem}
          buttonProps={{ toggle: false }}
          pathway={pathway}
        />
      </div>
    )
  }
}

class PathwayManageDropdown extends React.Component {
  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }

    this.dropdownRef = React.createRef()
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidCatch(error, info) {
    Log.error(error, info)
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path?.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest" right persist>
          <PathwayManageDropdownOptions
            pathway={this.props.pathway}
            user={this.props.user}
            company={this.props.company}
          />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(PathwayManageDropdown)
