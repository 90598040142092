import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import PageActions, { PageAction } from '../../components/PageActions'
import Panel, { PanelHero } from '../../components/Panel'
import Link from '../../components/Link'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import { faArrows } from '@fortawesome/pro-solid-svg-icons/faArrows'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faPowerOff } from '@fortawesome/pro-solid-svg-icons/faPowerOff'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import RoundButton from '../../components/RoundButton'
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import View from '../../components/View'
import DeleteCompanyModal from '../../components/modals/DeleteCompanyModal'
import { Company } from '../../../../_shared/models/company/company.client'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { ClientSubscription } from '../../../../pubsub/src/server/PubSub/client'
import { changeCompanyContext } from '../../actions'
import { sort } from 'fast-sort'

interface CompanyDropdownProps {
  company: Company
}

interface CompanyDropdownState {
  dropdownOpen: boolean
}

class CompanyDropdown extends React.PureComponent<CompanyDropdownProps, CompanyDropdownState> {
  private dropdownRef = React.createRef<Dropdown>()

  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  componentDidMount() {
    // todo: type this properly
    const current = this.dropdownRef.current as any
    const originalClick = current.handleDocumentClick
    current.handleDocumentClick = (e) => {
      if (e.path.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(current, e)
    }
  }

  render() {
    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} ref={this.dropdownRef}>
        <DropdownToggle tag={RoundButton} size="sm" className="btn btn-alt">
          <FontAwesomeIcon icon={faEllipsisV} />
        </DropdownToggle>
        <DropdownMenu className="fadeIn animated fastest" right>
          {/* <DropdownItem>
            <FontAwesomeIcon fixedWidth icon={faArrows} /> Move
          </DropdownItem>
          <DropdownItem>
            <FontAwesomeIcon fixedWidth icon={faPowerOff} /> Disable
          </DropdownItem>
          <DropdownItem divider /> */}
          <DeleteCompanyModal
            company={this.props.company}
            buttonTag={DropdownItem}
            buttonProps={{ toggle: false }}
          />
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const StyledPanel = styled(Panel)`
  h4 {
    margin: 0;
    a {
      text-decoration: none;
    }
  }
  .buttons .btn {
    margin-right: 0.75rem;
  }

  .buttons .dropdown .btn {
    margin-right: 0;
  }
`

const FirstCompany: React.FC = () => (
  <Panel>
    <PanelHero>
      <img src="/images/building.png" width="128" />
      <h3>Create your first organization</h3>
      <h6>Easily organize pathways &amp; users</h6>
      <Button size="lg" color="primary" tag={Link} to={`/organizations/new`}>
        <FontAwesomeIcon icon={faPlus} /> New Organization
      </Button>
    </PanelHero>
  </Panel>
)

interface CompanyPanelProps {
  company: Company
}

const CompanyPanel: React.FC<CompanyPanelProps> = (props) => (
  <StyledPanel thin>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <a
          href={`/?a=${props.company._id}`}
          onClick={(event) => {
            event.preventDefault()
            changeCompanyContext(props.company._id)
          }}
        >
          {props.company.name}
        </a>
      </div>
      <div className="d-none d-md-flex col-auto buttons">
        <RoundButton size="sm" tag={Link} to={`/organizations/${props.company._id}`}>
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <CompanyDropdown company={props.company} />
      </div>
    </div>
  </StyledPanel>
)

interface CompanyIndexProps {
  company: Company
}

const CompanyIndex: React.FC<CompanyIndexProps> = (props) => {
  const title = 'Organizations'
  return (
    <View title={title}>
      <PageHeader title={title}>
        <PageActions>
          <PageAction
            toolbar
            button
            color="primary"
            tag={Link}
            tagProps={{
              to: '/organizations/new',
            }}
          >
            <FontAwesomeIcon icon={faPlus} /> New Organization
          </PageAction>
        </PageActions>
      </PageHeader>
      <div className="content">
        <SubscriptionLoader
          resource="companies"
          fields={['_id', 'name']}
          query={{
            parentCompanyId: props.company._id,
          }}
          sort={{ name: 1 }}
          showLoader={true}
          transform={(store) => ({
            ...store,
            companies: sort(store.companies).asc((r: Company) => r.name),
          })}
        >
          {({ data }) => {
            const { companies } = data
            if (!companies || companies.length === 0) {
              return <FirstCompany />
            }

            return companies.map((c) => <CompanyPanel key={c._id} company={c} />)
          }}
        </SubscriptionLoader>
      </div>
    </View>
  )
}

export default CompanyIndex
