import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const UnstyledToggleButton = ({
  className,
  value,
  toggle,
  activeLabel,
  inactiveLabel,
  icon,
  iconProps,
}) => (
  <button
    type="button"
    className={`btn btn-alt ${className} ${value ? 'toggle-active' : ''}`}
    onClick={() => {
      toggle(!value)
    }}
  >
    {icon && <FontAwesomeIcon icon={icon} {...iconProps} />}
    <span className="toggle-label">{value ? activeLabel : inactiveLabel}</span>
  </button>
)
UnstyledToggleButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.object,
  iconProps: PropTypes.object,
  activeLabel: PropTypes.string,
  inactiveLabel: PropTypes.string,
  value: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
}

export const UncontrolledToggleButton = styled(UnstyledToggleButton)`
  border-radius: 1.5rem;
  padding: 0 0.7rem;
  height: 2.5em;
  line-height: 2.4em;
  font-size: 0.875rem;
  color: var(--text-muted-blue);

  .toggle-label {
    font-size: 0.8125rem;
  }

  .svg-inline--fa {
    margin-right: 0.3rem;
  }

  &.toggle-active {
    background: var(--primary);
    border-color: var(--primary);
    color: #fff;
  }
`

export default class ToggleButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: props.initialValue,
    }
  }

  static propTypes = {
    initialValue: PropTypes.bool,
    onToggle: PropTypes.func,
  }

  static defaultProps = {
    initialValue: false,
  }

  toggle = value => {
    this.setState({
      value: !this.state.value,
    })
    this.props.onToggle && this.props.onToggle(value)
  }

  render() {
    return (
      <UncontrolledToggleButton
        {...this.props}
        value={this.state.value}
        toggle={this.toggle}
      />
    )
  }
}
