import React from 'react'
import PropTypes from 'prop-types'
import Loader from '../../../components/Loader'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import Panel from '../../../components/Panel'
import Pie from '../../../components/Pie'
import apiFetch from '../../../../../_shared/api/apiFetch'

const PathwayTrafficSources = (props) => {
  const { className, sources } = props
  return (
    <Panel className={className}>
      <h4 className="text-center">Lead Sources</h4>
      <p className="text-center text-small">How are prospects reaching your pathway?</p>
      <Table striped>
        <thead>
          <tr>
            <th>Tag (lead_src)</th>
            <th width="130">Registrations</th>
            <th width="130">Completions</th>
            <th width="160">Completion Rate</th>
          </tr>
        </thead>
        <tbody>
          {sources.leadSources.map((source, index) => {
            let completionRate = (source.completions / source.registrations) * 100
            if (isNaN(completionRate)) {
              completionRate = 0
            }
            return (
              <tr key={index}>
                <td>{source._id}</td>
                <td>{source.registrations}</td>
                <td>{source.completions}</td>
                <td>
                  <Pie value={parseInt(completionRate, 10)} />
                  <span className="completion-rate">{completionRate.toFixed(0)}%</span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <br />
      <h4 className="text-center">Referrals</h4>
      <Table striped>
        <thead>
          <tr>
            <th>Referrer</th>
            <th width="130">Registrations</th>
            <th width="130">Completions</th>
            <th width="160">Completion Rate</th>
          </tr>
        </thead>
        <tbody>
          {sources.referrers.map((source, index) => {
            let completionRate = (source.completions / source.registrations) * 100
            if (isNaN(completionRate)) {
              completionRate = 0
            }
            return (
              <tr key={index}>
                <td>{source._id}</td>
                <td>{source.registrations}</td>
                <td>{source.completions}</td>
                <td>
                  <Pie value={parseInt(completionRate, 10)} />
                  <span className="completion-rate">{completionRate.toFixed(0)}%</span>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </Panel>
  )
}
PathwayTrafficSources.propTypes = {
  className: PropTypes.string,
  sources: PropTypes.object.isRequired,
}

const PathwayTrafficSourcesStyled = styled(PathwayTrafficSources)`
  .table {
    font-size: 0.875rem;
  }
  .table th {
    font-weight: 600;
  }
  .csspie-wrapper {
    margin-right: 0.7rem;
    font-size: 0.875rem;
  }
  .csspie-wrapper,
  .completion-rate {
    display: inline-block;
    vertical-align: middle;
  }
`

export default class PathwayTrafficSourcesContainer extends React.Component {
  state = {
    isLoading: true,
    sources: null,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    filter: PropTypes.object,
    dateRange: PropTypes.array.isRequired,
  }

  loadPathwayTrafficSources = () => {
    const { pathway, dateRange, filter } = this.props
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/statistics/trafficSources',
            {},
            {
              pathwayId: pathway._id,
              start: dateRange[0],
              end: dateRange[1],
              filter,
            },
            true
          )
          this.setState({
            isLoading: false,
            error: null,
            sources: result,
          })
        } catch (e) {
          this.setState({
            error: e,
            isLoading: false,
          })
        }
      }
    )
  }

  componentDidMount() {
    this.loadPathwayTrafficSources()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter || prevProps.dateRange !== this.props.dateRange) {
      this.loadPathwayTrafficSources()
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Loader />
    }
    return <PathwayTrafficSourcesStyled sources={this.state.sources} {...this.props} />
  }
}
