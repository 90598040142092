import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'

const ProfilePic = props => {
  const { className, profile, isOnline } = props
  return (
    <div className={classnames('pic', className)}>
      {isOnline && (
        <div className="online-icon">
          <FontAwesomeIcon icon={faUser} />
        </div>
      )}
      <img src={profile.getPic()} />
    </div>
  )
}
ProfilePic.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired,
  isOnline: PropTypes.bool,
}

export default styled(ProfilePic)`
  position: relative;
  img {
    border-radius: 100%;
    width: 60px;
    height: 60px;
    margin-right: 1.25rem;
  }
  .online-icon {
    color: var(--success);
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--success);
    border-radius: 100%;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 8px;
    text-align: center;
  }
`
