import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import media from '../css/_media'
import AccountMenu from './AccountMenu'
import CompanySelector from './CompanySelector'
import GlobalSearch from './GlobalSearch'
import { useSelector } from 'react-redux'
import { AppState } from '../types'
import { User } from '../../../_shared/models/user/user.client'
import { Company } from '../../../_shared/models/company/company.client'

const StyledTopNav = styled.section`
  padding: 0.4em 1em 0.4em 1.5em;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  align-items: center;
  background: #fff;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-size: 0.875rem;
  height: 3rem;

  @media print {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    > * {
      margin-left: 1rem;
    }
    &:first-child {
      > * {
        margin-left: 0;
      }
    }
  }

  @media (min-width: ${media.lg}) {
    /* padding-left: var(--page-gutter); */
  }
`

const ToggleSidebar = styled.button`
  color: #15457d;
  margin-right: 1rem;
  background: none;
  border: 0;
  font-size: 1.4rem;
  padding: 0;

  outline: none !important;
  &:focus,
  &:hover {
    color: var(--primary);
  }

  @media (min-width: ${media.lg}) {
    display: none;
  }
`

interface Props {
  id?: string
  className?: string
  toggleSidebar?: () => void
}

const TopNav: React.FC<Props> = (props) => {
  const user = useSelector<AppState, User>((state) => state.user)
  const userCompany = useSelector<AppState, Company>((state) => state.company)
  return (
    <StyledTopNav id={props.id} className={props.className}>
      <div>
        <ToggleSidebar id="toggleSidebar" onClick={props.toggleSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </ToggleSidebar>
        <GlobalSearch />
      </div>
      <div>
        {(userCompany?.isAgency() || user?.isAdmin()) && <CompanySelector />}
        <AccountMenu />
      </div>
    </StyledTopNav>
  )
}

export default TopNav
