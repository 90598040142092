import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Panel, { PanelHero } from '../../../components/Panel'
import CareerFieldMap from '../../../../../_shared/models/programGroup/careerFields'
import Link from '../../../components/Link'
import {
  Persona,
  PersonaCategories,
  PersonaDescriptions,
} from '../../../../../_shared/models/persona/persona.types'
import apiFetch from '../../../../../_shared/api/apiFetch'
import Loader from '../../../components/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faFlask } from '@fortawesome/pro-solid-svg-icons/faFlask'

interface PersonasLoaderProps {
  companyId?: string
}

interface PersonaData {
  personas: Partial<Persona>[]
  careerFields: Record<string, string>
}

export const PersonasLoader: React.FC<PersonasLoaderProps> = ({ companyId }) => {
  const [data, setData] = useState<PersonaData>(null)

  const loadData = useRef(async (companyId: string) => {
    setData(null)
    const data = await apiFetch('GET', '/statistics/persona/', null, { companyId })
    setData(data)
  })

  useEffect(() => {
    loadData.current(companyId)
  }, [companyId])

  if (!data) {
    return <Loader />
  }

  return <PersonaStatisticsStyled personas={data.personas} careerFields={data.careerFields} />
}

interface PersonaStatisticsProps {
  className?: string
  personas: Partial<Persona>[]
  careerFields: Record<string, string>
}

const PersonaStatistics: React.FC<PersonaStatisticsProps> = ({
  className,
  personas,
  careerFields,
}) => {
  const overall = personas.find((p) => p.category === 'overall')
  return (
    <div className={className}>
      <div className="row">
        <div className="col" style={{ maxWidth: '45rem' }}>
          <Panel>
            <h4>Introducing Lenses: Gain a Fresh Perspective on Your Data </h4>
            <p>
              <strong>Welcome to Lenses</strong>, the cutting-edge market research tool that
              revolutionizes the way you view your data. Powered by AI, Lenses offers invaluable
              insights and tailored marketing suggestions for your admissions and marketing teams.
              Each lens represents a segment of your prospects, offering a unique window into the
              mindset and circumstances of that particular group.
            </p>
            <p>
              Uncover the key factors influencing your target audience: delve into social styles,
              age demographics, education and employment histories, while also unmasking your
              prospects' challenges, career interests, and motivations.{' '}
              <strong>
                With Lenses, prepare to unlock a new realm of understanding, empowering you to make
                informed decisions and drive remarkable results.
              </strong>
            </p>

            <div className="alert alert-warning">
              <div className="alert-icon-heading">
                <div className="alert-icon">
                  <FontAwesomeIcon icon={faFlask} />
                </div>
                <strong>Experimental Feature:</strong>
              </div>
              Lenses is currently in beta and utilizes AI technology that may have inaccuracies.
              While every effort has been made to provide valuable insights and suggestions, please
              be aware that the AI-generated information should be interpreted with caution. The
              predictions, analysis, and recommendations provided by Lenses are based on available
              data and patterns, but they may not always reflect the exact or complete picture of
              your specific circumstances. It is always recommended to exercise your own judgment
              and corroborate the findings with additional research or expert advice.
            </div>
          </Panel>
        </div>
        <div className="col  col-lenses">
          {!overall && (
            <Panel>
              <div style={{ maxWidth: '40rem', margin: '0 auto' }}>
                <PanelHero>
                  <img src="/images/alert.png" width="128" />
                  <h3>Unable to generate Lenses</h3>
                  <h6>
                    Unfortunately, we are currently experiencing difficulties generating Lenses at
                    this time. Please be assured that our team is actively engaged in addressing
                    this issue. We sincerely apologize for the inconvenience.
                  </h6>
                </PanelHero>
              </div>
            </Panel>
          )}
          {overall && (
            <>
              <ul className="persona-list">
                <li>
                  <Link to={`/statistics/lens/${overall._id}`}>
                    <div className="persona-meta">
                      <div className="persona-link">Everyone</div>
                      <div className="persona-count">
                        <FontAwesomeIcon icon={faUser} /> {overall.sampleSize.toLocaleString()}
                      </div>
                    </div>
                    <div className="persona-desc">All of your prospects combined</div>
                  </Link>
                </li>
              </ul>
              <h3 className="lenses-heading">Age Groups</h3>
              <ul className="persona-list">
                {Object.entries(PersonaCategories.age).map(([key, value]) => {
                  const data = personas.find((p) => p.category == key)
                  if (!data) {
                    return null
                  }

                  return (
                    <li key={key}>
                      <Link to={`/statistics/lens/${data._id}`}>
                        <div className="persona-meta">
                          <div className="persona-link">{value}</div>
                          <div className="persona-count">
                            <FontAwesomeIcon icon={faUser} /> {data.sampleSize.toLocaleString()}
                          </div>
                        </div>
                        <div className="persona-desc">{PersonaDescriptions[key]}</div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
              <h3 className="lenses-heading">Career Fields</h3>
              <ul className="persona-list">
                {Object.entries(careerFields).map(([key, value]) => {
                  const data = personas.find((p) => p.category == key)
                  if (!data) {
                    return null
                  }

                  return (
                    <li key={key}>
                      <Link to={`/statistics/lens/${data._id}`}>
                        <div className="persona-meta">
                          <div className="persona-link">{value}</div>
                          <div className="persona-count">
                            <FontAwesomeIcon icon={faUser} /> {data.sampleSize.toLocaleString()}
                          </div>
                        </div>
                        <div className="persona-desc">{PersonaDescriptions[key]}</div>
                      </Link>
                    </li>
                  )
                })}
              </ul>
              <h3 className="lenses-heading">Campuses</h3>
              <ul className="persona-list">
                {personas
                  .filter((persona) => persona.category.includes('campus'))
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1
                    }
                    if (a.name > b.name) {
                      return 1
                    }
                    return 0
                  })
                  .map((persona) => {
                    return (
                      <li key={persona._id}>
                        <Link to={`/statistics/lens/${persona._id}`}>
                          <div className="persona-meta">
                            <div className="persona-link">{persona.name}</div>
                            <div className="persona-count">
                              <FontAwesomeIcon icon={faUser} />{' '}
                              {persona.sampleSize.toLocaleString()}
                            </div>
                          </div>
                          {/* <div className="persona-desc">{PersonaDescriptions[persona]}</div> */}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
              <h3 className="lenses-heading">Programs</h3>
              <ul className="persona-list">
                {personas
                  .filter((persona) => persona.category.includes('program'))
                  .sort((a, b) => {
                    if (a.name < b.name) {
                      return -1
                    }
                    if (a.name > b.name) {
                      return 1
                    }
                    return 0
                  })
                  .map((persona) => {
                    return (
                      <li key={persona._id}>
                        <Link to={`/statistics/lens/${persona._id}`}>
                          <div className="persona-meta">
                            <div className="persona-link">{persona.name}</div>
                            <div className="persona-count">
                              <FontAwesomeIcon icon={faUser} />{' '}
                              {persona.sampleSize.toLocaleString()}
                            </div>
                          </div>
                          {/* <div className="persona-desc">{PersonaDescriptions[persona]}</div> */}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const PersonaStatisticsStyled = styled(PersonaStatistics)`
  h4 {
    margin-bottom: 1rem;
  }
  .col-lenses {
    width: 20rem;
  }
  .lenses-heading {
    font-size: 1rem;
    font-weight: 600;
    color: #546170;
    margin: 0.7rem 0 0.8rem 0;
  }
  .alert-icon-heading {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .alert-icon {
    // float: left;
    margin: 0 0.4em 0em 0;
    font-size: 1.6em;
    line-height: 1.43;
  }
  .persona-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    > li {
      margin: 0 1rem 1rem 0;
      width: calc(50% - 1rem);
      min-width: 18rem;
      // max-width: 28rem;

      @media (max-width: 1346px) {
        & {
          margin-right: 0;
          width: 100%;
        }
      }

      a {
        border: 1px solid #fff;
        border-radius: 4px;
        // width: 20rem;
        // width: 100%;
        // max-width: 22rem;

        padding: 1rem;
        display: block;
        // display: flex;
        justify-content: space-between;
        // background-color: var(--primary);
        background-color: #fff;
        // color: #fff;

        &:hover {
          text-decoration: none;
          border-color: #5ca4e6;
        }
      }

      .persona-icon {
        display: none;
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 0.5rem;
      }

      .persona-meta {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
      }
      .persona-link {
        font-weight: 600;
      }

      .persona-count {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        // font-size: 0.875rem;
        // color: #666;

        .fa-user {
          color: #bccbd6;
          font-size: 0.8em;
          margin-right: 0.4em;
        }
      }

      .persona-desc {
        // display:none;
        font-size: 0.875rem;
        opacity: 0.7;
        color: #666;
      }
    }
  }
`

export default PersonaStatisticsStyled
