import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ScoreGauge from '../../components/ScoreGauge'
import TabNav from '../../components/TabNav'
import ResponseAnswers from './ResponseAnswers'
import ResponseViewSidebar from './ResponseViewSidebar'
import NotFound from '../NotFound'
import ResponseShortMeta from './ResponseShortMeta'
import classnames from 'classnames'
import { Route, Switch, withRouter } from 'react-router-dom'
import ResponseNotifications from './ResponseNotifications'
import Panel from '../../components/Panel'
import ReportView from '../report/ReportView'
import { connect } from 'react-redux'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { shortCache } from '../../api/subscriptionCache'

const ReportPanel = styled(Panel)`
  margin: 0 0 0.75rem 0;
  padding: 0.75rem 0.5rem;
`

const ResponseViewUnstyled = (props) => {
  const { linkPrefix, response, pathway, reports, className, match } = props
  let selectedTab = match.params.tab
  let selectedReport
  let defaultReport

  if (!response || !pathway) {
    return <NotFound />
  }

  switch (selectedTab) {
    case 'notifications':
    case 'answers':
      break
    default:
      selectedReport = selectedTab
      selectedTab = null
  }

  const tabItems = [
    {
      label: 'Answers',
      route: `/${linkPrefix}/${response._id}`,
    },
    {
      label: 'Notifications',
      route: `/${linkPrefix}/${response._id}/notifications`,
    },
  ]

  if (reports?.length > 0) {
    // Find the default report
    defaultReport = reports.find((r) => r.isDefault === true)
    if (defaultReport) {
      tabItems[0].route += '/answers'
      if (!selectedReport) {
        selectedReport = defaultReport._id
      }
    }

    tabItems.splice(0, 0, {
      label: 'Summaries',
      active: !selectedTab && (defaultReport || selectedReport),
      subItems: reports
        .sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
        .map((r) => ({
          label: r.name,
          active: selectedReport === r._id,
          route: r.isDefault
            ? `/${linkPrefix}/${response._id}`
            : `/${linkPrefix}/${response._id}/${r._id}`,
        })),
    })
  }

  let responseStatus
  if (response.invalidReason) {
    responseStatus = 'Invalid'
  } else if (response.isOnline) {
    responseStatus = 'In Progress'
  } else if (response.progress >= 100) {
    responseStatus = 'Completed'
  } else {
    responseStatus = 'Incomplete'
  }

  return (
    <div className={className}>
      <div
        className={classnames('main', {
          online: response.isOnline,
          invalid: response.isInvalid,
        })}
      >
        <div className="main-header">
          <div className="response-status">{responseStatus}</div>
          <h2 className="pathway-name">{pathway.name}</h2>
          <ResponseShortMeta
            showDetails
            response={response}
            pathway={pathway}
            isOnline={response.isOnline}
          />
        </div>
        <TabNav items={tabItems} />

        <div className="content">
          <Switch>
            <Route
              path={`/${linkPrefix}/:responseId${defaultReport ? '/answers' : ''}`}
              exact
              render={(props) => <ResponseAnswers response={response} pathway={pathway} />}
            />
            <Route
              path={`/${linkPrefix}/:responseId/notifications`}
              exact
              render={(props) => <ResponseNotifications response={response} />}
            />
            <Route
              path={`/${linkPrefix}/:responseId/:reportId?`}
              exact
              render={(props) => {
                const report = reports.find((r) => r._id === selectedReport)
                if (!report) {
                  return <NotFound />
                }
                return (
                  <SubscriptionLoader
                    resource="report"
                    cache={shortCache}
                    query={{
                      _id: report._id,
                      companyId: report.companyId,
                    }}
                    showLoader={true}
                  >
                    {({ data }) => {
                      const report = data.reports[0]
                      const pathway = data.pathways[0]
                      return (
                        <ReportPanel>
                          <ReportView report={report} pathway={pathway} response={response} />
                        </ReportPanel>
                      )
                    }}
                  </SubscriptionLoader>
                )
              }}
            />
          </Switch>
        </div>
      </div>
      <ResponseViewSidebar {...props} />
    </div>
  )
}
ResponseViewUnstyled.propTypes = {
  className: PropTypes.string,
}

export const ResponseViewNew = styled(withRouter(ResponseViewUnstyled))`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 0.875rem;
  flex: 1 1 auto;
  overflow: hidden;

  @media print {
    font-size: 12px;
  }

  .content {
    padding: 0.75rem;
  }
  .currently-viewing {
    color: var(--success);
  }

  .enrolled,
  .interviewed {
    color: var(--success);
  }

  .response-status {
    float: right;
    margin-left: 0.5rem;
    background: var(--bg-color);
    padding: 0.5em 1em;
    border-radius: 3px;
    font-weight: 500;
    color: #6f7982;
  }
  .online .response-status {
    background: var(--success);
    color: #fff;
  }
  .invalid .response-status {
    background: var(--danger);
    color: #fff;
  }

  .main {
    flex: 1 1 auto;
    height: 100%;
    /* display: flex; */
    /* flex-direction: column; */
    overflow: auto;
    background: var(--bg-color);

    @media print {
      background: none;
    }

    .main-header {
      padding: 1.5em 1.5em 0;
      background: #fff;
      ${ResponseShortMeta} {
        margin: 0;
      }
      @media print {
        padding-bottom: 1.5em;
      }
    }

    .pathway-name {
      margin: 0 0 0.3em 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 1.3125rem;
      @media print {
        font-size: 16px;
      }
    }
  }

  .TabNav {
    border-bottom: 1px solid var(--border-color);
    margin: 0;
    padding: 0 1.5em;
    background: #fff;

    @media print {
      display: none;
    }
  }

  .sidebar {
    background: #fff;

    color: #6f7982;
    border-left: 1px solid var(--border-color);
    flex: 0 0 18em;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .svg-inline--fa {
      margin-right: 0.3em;
      /* vertical-align: middle; */
      font-size: 1em;
      display: inline-block;
    }
    .icon-label {
      display: inline-block;
      vertical-align: middle;
    }

    .profile {
      border-bottom: 1px solid var(--border-color);
      padding: 1em;
      font-size: 0.95em;

      .meta-col {
        flex: 1 1 auto;
        overflow: hidden;
      }

      .pic {
        margin: 0 auto;
        display: block;
        border-radius: 100%;
        width: 70px;
      }
      .meta {
        margin-right: 0.75em;
        li {
          vertical-align: middle;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0.1em 0;
        }
        .name {
          color: var(--primary);
          font-weight: 600;
          font-size: 1em;
        }
      }
    }

    .result-meta {
      border-bottom: 1px solid var(--border-color);
      font-size: 0.9em;

      strong {
        font-weight: 600;
      }

      .svg-inline--fa {
        font-size: 0.9em;
      }

      .icon-label {
        display: inline;
      }

      ul {
        padding: 0.7em 1em 1em;
        li {
          line-height: 1.25;
          vertical-align: middle;
          padding: 0;
          margin: 0 0 0.4em 0;
          &:last-child {
            margin: 0;
          }
        }
      }
      .result-label {
        vertical-align: middle;
        display: inline-block;
      }
    }

    .score {
      ${ScoreGauge} {
        margin: -0.3em auto 0;
        width: 130px;
        height: 85px;
      }
      padding: 1em;
      border-bottom: 1px solid var(--border-color);
    }

    .actions {
      border-bottom: 1px solid var(--border-color);
      padding: 1em;
      .title {
        font-weight: 600;
        margin-bottom: 0.4em;
        color: var(--heading-color);
      }
      .svg-inline--fa {
        color: var(--text-muted-blue);
      }
      a {
        &:hover {
          text-decoration: underline !important;
        }
      }
      ul {
        li {
          padding: 0.15em 0;
        }
      }
    }

    .metadata {
      font-size: 0.95em;
      padding: 1em;
      // border-bottom: 1px solid var(--border-color);
      li {
        margin: 0 0 0.5em 0;
      }
      .metadata-title {
        font-weight: 500;
        color: var(--heading-color);
        margin-right: 0.3em;
      }
      .metadata-value {
        display: block;
        word-wrap: break-word;
      }
      .nowrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
`

const DataLoader = (props) => {
  if (!props.selectedResponse) {
    return null
  }

  return (
    <SubscriptionLoader
      resource="response"
      query={{
        _id: props.selectedResponse,
        companyId: props.selectedCompany._id,
      }}
      showLoader={true}
    >
      {({ data }) => {
        const { responses, pathways, people, reports } = data
        if (!responses || !pathways || !people) {
          return <NotFound />
        }

        return (
          <ResponseViewNew
            linkPrefix={props.linkPrefix || 'response'}
            {...props}
            response={responses[0]}
            pathway={pathways[0]}
            profile={people[0]}
            reports={reports}
          />
        )
      }}
    </SubscriptionLoader>
  )
}

DataLoader.propTypes = {
  selectedResponse: PropTypes.string,
  linkPrefix: PropTypes.string,
}

const mapStateToProps = (state) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0],
})

export default connect(mapStateToProps)(DataLoader)
