import io, { Socket } from 'socket.io-client/dist/socket.io.js'
import PubSub from '../../../pubsub/src/server/PubSub/client'
import Log from '../../../_shared/log'
import { Action } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { AppState } from '../types'
import { BeginReconnecting, FinishReconnecting, subscribeUser } from '../actions'
import ClientPerson from '../../../_shared/models/person/person.client'
import { Company } from '../../../_shared/models/company/company.client'
import Response from '../../../_shared/models/response/response.backend.client'
import Pathway from '../../../_shared/models/pathway/pathway.backend.client'
import IntegrationAttempt from '../../../_shared/models/integrationAttempt/integrationAttempt.client'
import { CREDENTIALS } from '../../../_shared/api/apiFetch'

export let socket: Socket
export let pubSub: PubSub

declare global {
  interface Window {
    pubSub: any
  }
}

export function initSocket(
  dispatch: ThunkDispatch<AppState, unknown, Action<string>>
): Promise<Socket> {
  Log.info('Connect to socket')

  return new Promise<Socket>(function (resolve) {
    socket = io(process.env.SOCKET_URL, {
      transports: ['websocket', 'polling'],
    })
    pubSub = new PubSub(socket, {
      loginToken: CREDENTIALS.token,
      collectionTransforms: {
        people: (r: ClientPerson) => new ClientPerson(r),
        companies: (r: Company) => new Company(r),
        responses: (r: Response) => new Response(r),
        pathways: (r: Pathway) => new Pathway(r),
        integrationAttempts: (r: IntegrationAttempt) => new IntegrationAttempt(r),
      },
      onLogin: () => {
        Log.info('Socket connected')
        dispatch(subscribeUser())
        resolve(socket)
      },
      onError: (error: string) => {
        Log.error(new Error('PubSub error: ' + error))
        if (error.includes('User does not exist') || error.includes('JWT')) {
          window.location.href = '/logout'
        }
      },
      onReconnecting: () => {
        dispatch(BeginReconnecting())
      },
      onReconnected: () => {
        dispatch(FinishReconnecting())
      },
    })
    window.pubSub = pubSub

    socket.on('error', (e: Error) => {
      Log.error(e)
    })
    socket.on('connect_error', (err) => {
      Log.error(new Error('Socket connection failed: ' + err.message)) // not authorized

      if (err.message.includes('User does not exist')) {
        window.location.href = `${process.env.API_URL}/logout`
      }
    })
  })
}
