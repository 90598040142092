import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import media from '../css/_media'
import { Button } from 'reactstrap'

const NewButton = props => (
  <div className={props.className}>
    <Button color="primary" tag={props.tag} to={props.to}>
      <FontAwesomeIcon icon={faPlus} /> {props.label}
    </Button>
  </div>
)

NewButton.propTypes = {
  className: PropTypes.string,
  center: PropTypes.bool,
  label: PropTypes.string,
  tag: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.func,
  ]),
  to: PropTypes.string,
}

export default styled(NewButton)`
  margin-bottom: 1rem;
  text-align: right;

  ${props => (props.center ? 'margin:1rem 0 0 0;text-align:center;' : '')}

  .svg-inline--fa {
    margin-right: 0.2rem;
  }

  button {
    display: block;
    width: 100%;
  }

  @media (min-width: ${media.md}) {
    button {
      display: inline-block;
      width: auto;
    }
  }
`
