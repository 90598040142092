import React from 'react'
import styled from 'styled-components'
import media from '../css/_media'
import { Table } from 'reactstrap'

export const PanelFormSide = styled.div`
  margin: 1rem 0;
  @media (min-width: ${media.xl}) {
    height: 100%;
    margin: 0 0 0 2rem;
    margin-bottom: 0;
    padding-left: 3rem;
    border-left: 1px solid var(--border-color);
  }
`

interface PanelFormProps {
  center?: boolean
}

export const PanelForm = styled.div<PanelFormProps>`
  max-width: 500px;
  margin: 0 ${(props) => (props.center === false ? '' : 'auto')};

  label:not(.form-check-label) {
    font-weight: 500;
  }
`

export const PanelHero = styled.div`
  text-align: center;
  color: var(--text-muted);

  img {
    margin-bottom: 1.5rem;
  }

  h3 {
    color: #40566f;
    margin-bottom: 0.5rem;
  }

  h5,
  h6 {
    color: var(--text-muted);
    margin-bottom: 2rem;
  }

  .btn .svg-inline--fa {
    margin-right: 0.3rem;
  }
`

export const PanelTable = styled(Table)`
  font-size: 0.875rem;
  color: #454545;
  thead th {
    border-top: 0;
  }
  th,
  td {
    padding: 1em;
    vertical-align: middle;
  }

  td.th {
    font-weight: bold;
  }
`

interface Props {
  id?: string
  className?: string
  thin?: boolean
}

const Panel: React.FC<Props> = (props) => {
  return (
    <div id={props.id} className={props.className}>
      {props.children}
    </div>
  )
}

export default styled(Panel)`
  background: #fff;
  border-radius: 4px;
  margin: 0 0 1rem;
  padding: ${(props) => (props.thin ? '1.25rem 1.75rem' : '2rem')};
  color: var(--text-panel);

  p {
    margin-bottom: 1.5rem;
  }

  label {
    color: #212529;
  }
  hr {
    margin: 2rem 0;
    &.breathe {
      margin: 3rem 0;
    }
  }
`
