import React from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Form, { FormContext } from '../../../../_shared/components/Form'
import Checkbox from '../../../../_shared/components/Form/Checkbox'

const LinkedPathwaysModal = ({ isOpen, toggle, save, linkedBlocks, linkedPathways }) => {
  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <Form>
        <ModalHeader toggle={toggle}>Linked Blocks</ModalHeader>

        <ModalBody>
          <p className="text-small">
            This step has linked blocks that exist on other pathways. You can optionally select the
            pathways you wish you update with the new linked blocks.
          </p>
          <Checkbox
            showLabel={false}
            name="pathways"
            className="text-small"
            options={linkedPathways?.map((p) => ({
              label: p.name,
              value: p._id,
            }))}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <FormContext.Consumer>
            {({ formData }) => (
              <Button
                color="primary"
                onClick={(event) => {
                  event.currentTarget.disabled = 'disabled'
                  save(null, formData.pathways)
                }}
              >
                Save
              </Button>
            )}
          </FormContext.Consumer>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

export default LinkedPathwaysModal
