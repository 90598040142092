import React from 'react'
import PropTypes from 'prop-types'
import View from '../../components/View'
import NotFound from '../NotFound'
import { ResponseViewNew } from '../response/ResponseViewNew'
import { useSelector } from 'react-redux'
import SubscriptionLoader from '../../containers/SubscriptionLoader'

const PeopleResponseView = (props) => {
  const { profile, match, response, pathway, reports } = props

  return (
    <View title={`${profile.getFullName(true)}'s result for ${pathway.name}`}>
      <ResponseViewNew
        linkPrefix={'response'}
        response={response}
        pathway={pathway}
        profile={profile}
        reports={reports}
      />
    </View>
  )
}

PeopleResponseView.propTypes = {
  // profile: PropTypes.object.isRequired,
  match: PropTypes.object,
  user: PropTypes.object,
  className: PropTypes.string,
}

const PeopleViewLoader = (props) => {
  const selectedCompany = useSelector(
    (state) => state.subscriptions.companyContext.data.companies[0]
  )

  return (
    <SubscriptionLoader
      resource="response"
      query={{
        _id: props.match.params.responseId,
        companyId: selectedCompany._id,
      }}
      showLoader={true}
    >
      {({ data }) => {
        if (!data?.responses || data.responses.length === 0) {
          return <NotFound />
        }
        const response = data.responses[0]
        const pathway = data.pathways[0]
        const profile = data.people[0]
        const reports = data.reports

        return (
          <PeopleResponseView
            {...props}
            response={response}
            pathway={pathway}
            profile={profile}
            reports={reports}
          />
        )
      }}
    </SubscriptionLoader>
  )
}

export default PeopleViewLoader
