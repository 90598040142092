import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { redirect } from '../Link'
import { withRouter } from 'react-router-dom'
import apiFetch from '../../../../_shared/api/apiFetch'

class DeletePersonModal extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  callMethod = async () => {
    const { location, history, profile } = this.props
    try {
      await apiFetch('DELETE', '/people/delete', { personId: profile._id })
      redirect({
        location,
        history,
        pathname: '/people',
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Person"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.profile.getFullName(true)}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faTimesCircle} fixedWidth transform="down-1" />
        Delete
      </BasicModal>
    )
  }
}

export default withRouter(DeletePersonModal)
