export default {
  title: 'Step',
  type: 'object',
  properties: {
    _id: {
      type: 'string',
    },

    name: {
      type: 'string',
    },

    steps: {
      type: 'array',
      items: {
        type: 'string',
      },
    },

    trigger: {
      type: 'string',
      enum: ['submit', 'view'],
    },

    includeInPersona: {
      type: 'boolean',
    },
  },
  required: ['name', 'steps', 'trigger'],
  additionalProperties: false,
}
