import React from 'react'
import PropTypes from 'prop-types'
import IntegrationStatus from '../../views/integration/IntegrationStatus'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'

class ResponseNotifications extends React.Component {
  static propTypes = {
    response: PropTypes.object.isRequired,
    mock: PropTypes.object,
  }

  render() {
    const { response } = this.props
    return (
      <SubscriptionLoader
        resource="integrationAttempts"
        query={{
          responseId: response._id,
          companyId: response.companyId,
        }}
        sort={{ createdAt: -1 }}
        showLoader={true}
        transform={(store) => ({
          ...store,
          integrationAttempts: sort(store.integration_attempts).desc((r) => r.createdAt),
        })}
      >
        {({ data }) => {
          if (!data || !data?.integrationAttempts) {
            return null
          }
          return data?.integrationAttempts?.map((i) => (
            <IntegrationStatus attempt={i} key={i._id} response={response} />
          ))
        }}
      </SubscriptionLoader>
    )
  }
}

export default ResponseNotifications
