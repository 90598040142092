import React from 'react'
import styled from 'styled-components'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import PersonaPanel from '../PersonaPanel'
import PersonaHorizontalBarChart from './PersonaHorizontalBarChart'
import { PersonaColors } from '../PersonaColors'

interface Props {
  className?: string
  persona: Persona
}

function extractHSL(hsl) {
  const match = hsl.match(/hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/i)

  if (!match) {
    throw new Error('Invalid HSL color')
  }

  return {
    hue: match[1], // Hue
    saturation: match[2], // Saturation
    lightness: match[3], // Lightness
  }
}

const primary = extractHSL(PersonaColors.tertiary)
const secondary = extractHSL(PersonaColors.quaternary)
const tertiary = extractHSL(PersonaColors.quinary)

function calculateColorWhatWork(value, sampleSize) {
  const lightness = primary.lightness - (1 / 5) * Math.floor((value / sampleSize) * 100)
  const saturation = primary.saturation //+(1/7)*Math.floor((value / sampleSize) * 100);
  return `linear-gradient(to right, hsl(${primary.hue},${primary.saturation}%,${primary.lightness}%), hsl(${primary.hue}, ${saturation}%, ${lightness}%))`
}

function calculateColorHappy(value, sampleSize) {
  const lightness = secondary.lightness - (1 / 5) * Math.floor((value / sampleSize) * 100)
  const saturation = secondary.saturation //+(1/7)*Math.floor((value / sampleSize) * 100);
  return `linear-gradient(to right, hsl(${secondary.hue},${secondary.saturation}%,${secondary.lightness}%), hsl(${secondary.hue}, ${saturation}%, ${lightness}%))`
}

function calculateColorFeel(value, sampleSize) {
  const lightness = tertiary.lightness - (1 / 5) * Math.floor((value / sampleSize) * 100)
  const saturation = tertiary.saturation //+(1/7)*Math.floor((value / sampleSize) * 100);
  return `linear-gradient(to right, hsl(${tertiary.hue},${tertiary.saturation}%,${tertiary.lightness}%), hsl(${tertiary.hue}, ${saturation}%, ${lightness}%))`
}

const PersonaEmployment: React.FC<Props> = ({ className, persona }) => {
  let itemsWorking = [
    {
      label: 'Currently Working',
      image: calculateColorWhatWork(persona.metrics.currentlyWorking.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.currentlyWorking.value,
      valuePercentage: Math.floor(
        (persona.metrics.currentlyWorking.value / persona.sampleSize) * 100
      ),
    },
    {
      label: 'Not working',
      image: calculateColorWhatWork(persona.metrics.notWorking.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.notWorking.value,
      valuePercentage: Math.floor((persona.metrics.notWorking.value / persona.sampleSize) * 100),
    },
  ]

  //push unknown bar to working if there is unknown data
  if (persona.metrics.noWorkingData.value) {
    itemsWorking.push({
      label: 'Unknown',
      image: '',
      color: PersonaColors.tertiary,
      value: persona.metrics.noWorkingData.value,
      valuePercentage: Math.floor((persona.metrics.noWorkingData.value / persona.sampleSize) * 100),
    })
  }

  let itemsHappy = [
    {
      label: 'Yes',
      image: calculateColorHappy(persona.metrics.happyWork.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.happyWork.value,
      valuePercentage: Math.floor((persona.metrics.happyWork.value / persona.sampleSize) * 100),
    },
    {
      label: "It's okay",
      image: calculateColorHappy(persona.metrics.okWork.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.okWork.value,
      valuePercentage: Math.floor((persona.metrics.okWork.value / persona.sampleSize) * 100),
    },
    {
      label: 'No',
      image: calculateColorHappy(persona.metrics.unhappyWork.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.unhappyWork.value,
      valuePercentage: Math.floor((persona.metrics.unhappyWork.value / persona.sampleSize) * 100),
    },
  ]

  if (persona.metrics.noHappyData.value) {
    itemsHappy.push({
      label: 'Unknown',
      image: '',
      color: PersonaColors.tertiary,
      value: persona.metrics.noHappyData.value,
      valuePercentage: Math.floor((persona.metrics.noHappyData.value / persona.sampleSize) * 100),
    })
  }

  let itemsFrustrated = [
    {
      label: 'Frustrated',
      image: calculateColorFeel(persona.metrics.frustratedInYear.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.frustratedInYear.value,
      valuePercentage: Math.floor(
        (persona.metrics.frustratedInYear.value / persona.sampleSize) * 100
      ),
    },
    {
      label: 'About the same',
      image: calculateColorFeel(persona.metrics.sameInYear.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.sameInYear.value,
      valuePercentage: Math.floor((persona.metrics.sameInYear.value / persona.sampleSize) * 100),
    },
    {
      label: 'Content',
      image: calculateColorFeel(persona.metrics.contentInYear.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.contentInYear.value,
      valuePercentage: Math.floor((persona.metrics.contentInYear.value / persona.sampleSize) * 100),
    },
  ]

  if (persona.metrics.noFrustratedData.value) {
    itemsFrustrated.push({
      label: 'Unknown',
      image: '',
      color: PersonaColors.tertiary,
      value: persona.metrics.noFrustratedData.value,
      valuePercentage: Math.floor(
        (persona.metrics.noFrustratedData.value / persona.sampleSize) * 100
      ),
    })
  }

  return (
    <div className={className}>
      <PersonaPanel title="Employment">
        <PersonaHorizontalBarChart
          label="What is your current work situation?"
          //label="What is your current work situation?"
          items={itemsWorking}
        />
        <PersonaHorizontalBarChart
          label="Are you happy with your work situation?"
          items={itemsHappy}
        />
        <PersonaHorizontalBarChart
          label="A year from now if nothing changed, would you feel..."
          items={itemsFrustrated}
        />
      </PersonaPanel>
    </div>
  )
}

export default styled(PersonaEmployment)``
