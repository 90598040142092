import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import AsyncSelect from 'react-select/lib/Async'
import debounce from 'lodash/debounce'
import { redirect } from './Link'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../types'
import { Company } from '../../../_shared/models/company/company.client'
import { DebouncedFunc } from 'lodash'
import apiFetch from '../../../_shared/api/apiFetch'

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: '0',
    outline: 'none',
    'box-shadow': 'none',
    cursor: 'text',
    padding: 0,
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided) => ({
    ...provided,
    color: 'var(--primary)',
  }),
  input: (provided) => ({
    ...provided,
    marginLeft: '0.75rem',
  }),
  placeholder: (provided) => ({
    ...provided,
    marginLeft: '0.75rem',
  }),
}

const Option = (props) => {
  const {
    children,
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props

  console.log('reeee')
  return (
    <div {...restInnerProps} ref={ref} style={getStyles('option', props)}>
      <div className="label">{props.data.label}</div>
      {props.data.meta?.email && <div className="meta">{props.data.meta.email}</div>}
      {props.data.meta?.phone && <div className="meta phone">{props.data.meta.phone}</div>}
    </div>
  )
}

interface Props extends RouteComponentProps {
  className?: string
  companyContext?: Company
  selectedCompany?: Company
}

interface State {
  inputValue: string
  isLoading?: boolean
  error?: string
  results?: any[]
}

interface GlobalSearch {
  search: DebouncedFunc<(query: any, callback: any) => void>
}

class GlobalSearch extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.search = debounce(this._search, 150)
  }

  state = {
    inputValue: '',
  }

  onInputChange = (inputValue, { action }) => {
    switch (action) {
      case 'input-change':
        this.setState({ inputValue })
    }
  }

  onChange = (val) => {
    const { location, history } = this.props

    let pathname
    switch (val.type) {
      case 'pathway':
        pathname = `/pathways/${val.value}`
        break
      case 'person':
        pathname = `/people/${val.value}`
        break
    }

    if (pathname) {
      this.setState({ inputValue: '' })
      redirect({
        pathname,
      })
    }
  }

  _search = (query, callback) => {
    // if (query?.length < 3) {
    //   callback([
    //     { label: 'Please enter at least 3 characters', value: 'minlength' },
    //   ])
    //   return
    // }

    this.setState(
      {
        isLoading: false,
        results: null,
      },
      async () => {
        try {
          const result = await apiFetch(
            'GET',
            '/search',
            {},
            { companyId: this.props.companyContext._id, query }
          )
          callback(result)
        } catch (e) {
          throw new Error(e)
        }
      }
    )
  }

  render() {
    const { className, companyContext } = this.props
    if (!companyContext) {
      return null
    }
    const { inputValue } = this.state
    return (
      <div className={className}>
        <FontAwesomeIcon icon={faSearch} />
        <AsyncSelect
          components={{ Option }} // eslint-disable-line
          styles={customStyles}
          placeholder="Search for people or pathways"
          noOptionsMessage={() => 'No results found'}
          loadOptions={this.search}
          onChange={this.onChange}
          onInputChange={this.onInputChange}
          inputValue={inputValue}
          value={null}
        />
      </div>
    )
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    companyContext: state.subscriptions.companyContext?.data?.companies[0] as Company,
  }
}

const GlobalSearchWithRouter = compose(withRouter, connect(mapStateToProps))(GlobalSearch)

export default styled(GlobalSearchWithRouter)`
  font-size: 0.875rem;
  width: 20rem;
  position: relative;
  .svg-inline--fa {
    position: absolute;
    cursor: default;
    z-index: 10;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .meta {
    font-size: 0.8125rem;
    color: var(--text-muted);

    &.phone {
      font-size: 0.7125rem;
    }
  }

  color: #999;
`
