import BaseIntegrationAdapter from './adapters/BaseIntegrationAdapter'

//todo: interface for adapters
export default {
  _adapters: {},

  /**
   * Registers an integration for use in the application.
   *
   */
  register(adapter: BaseIntegrationAdapter): void {
    if (this._adapters[adapter.key]) {
      throw new Error(`Duplicate adapter key: ${adapter.key}`)
    }
    this._adapters[adapter.key] = adapter
  },

  /**
   * Returns all registered adapters.
   *
   */
  getAdapters() {
    return Object.values(this._adapters)
  },

  /**
   * Returns the integration with the specified key.
   *
   * @param {String} key
   */
  getAdapter(key: string): BaseIntegrationAdapter {
    return this._adapters[key]
  },
}
