import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CompanySchema from '../../../../../_shared/models/company/CompanySchema'
import { Button } from 'reactstrap'
import Panel from '../../../components/Panel'
import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-text'
import PathwayBundles from '../../../../../_shared/models/PathwayBundles/PathwayBundles'
import Form from '../../../../../_shared/components/Form'
import Input from '../../../../../_shared/components/Form/Input'
import Code from '../../../../../_shared/components/Form/Code'
import Cardbox, { CardboxItem } from '../../../../../_shared/components/Form/Cardbox'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import { shortCache } from '../../../api/subscriptionCache'
import CompanyMaxioConfig from './CompanyMaxioConfig'
import apiFetch from '../../../../../_shared/api/apiFetch'
import Select from '../../../../../_shared/components/Form/Select'

const forceWebpackToLoad = [AceEditor]

const { name, type, website, phone, parentCompanyId, status, notes, maxio } = CompanySchema.properties

const CompanyFormSchema = {
  type: 'object',
  properties: {
    name,
    type,
    bundles: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    reps: {
      type: 'array',
      items: {
        type: 'string',
      },
    },
    website,
    phone,
    parentCompanyId,
    notes,
    status,
    maxio,
  },
  required: ['name', 'bundles', 'status'],
}

class CompanyFormC extends React.Component {
  state = {
    parentCompanyId,
    selectedValue: {value: '', label: ''}
  }

  companyOptions = async (value) => {
    let companies = []
    if(value) {
      companies = await apiFetch('GET', '/companies/search', {}, { name: value })
    } else {
      companies = await apiFetch('GET', '/companies', {}, {query: {type: 'agency'}} )
    }
  
    const formattedCompanies = []
    companies.forEach((company) => {
      if(this.state.parentCompanyId === company._id) {
        this.setState({selectedValue: {value: company._id, label: company.name}})
      }
      formattedCompanies.push({value: company._id, label: company.name})
    })
    
    return formattedCompanies
  }

  componentWillMount() {
    const { formData } = this.props
    if(formData?.parentCompanyId) {
      this.setState({parentCompanyId: formData.parentCompanyId})
    }
  }

  render() {
  const { formData, onSubmit, buttonLabel, isLoading } = this.props

  return (
    <Form formData={formData} schema={CompanyFormSchema} onValidSubmit={onSubmit}>
      <Panel>
        <div className="row align-items-stretch">
          <div className="col">
            <Input type="text" name="name" />
            <Input type="text" name="website" />
            <Input type="text" name="phone" />
            {formData && (
              <Select name="parentCompanyId" value={[this.state.selectedValue]} selectProps={{
                isMulti: false,
                placeholder: 'Select Company',
                noOptionsMessage: () => 'No companies found',
                loadOptions: this.companyOptions,
                defaultOptions: true,
                value: this.state.selectedValue,
                onChange: (selectedOption) => {
                  this.setState({selectedValue: selectedOption})
                  if(formData) formData.parentCompanyId = selectedOption.value
                }
              }} />
            )}
            <Input type="text" name="reporterApiKey" label="Reporter API Key" />
            <Code
              tabSize={4}
              editorProps={{
                $blockScrolling: Infinity,
              }}
              setOptions={{
                fontSize: '14px',
                showLineNumbers: false,
                showGutter: false,
              }}
              mode="text"
              wrapEnabled={true}
              name="notes"
            />
          </div>
          <div className="col-divider" />
          <div className="col">
            <Cardbox
              name="status"
              label="Account Status"
              help="Determines whether or not pathways function and users can login"
              thin
              horizontal
              defaultValue="active"
            >
              <CardboxItem
                value="active"
                image="/images/tick.png"
                title="Active"
                // description="Enables all functionality"
              />
              <CardboxItem
                value="inactive"
                image="/images/cross.png"
                title="Inactive"
                // description="Disables pathway functionality and access to VirtualAdviser"
              />
            </Cardbox>
            <Cardbox
              name="type"
              label="Account Type"
              help="Determines this client's level of access"
              vertical
              thin
              defaultValue="standard"
            >
              <CardboxItem
                value="standard"
                image="/images/building2.png"
                title="Standard"
                description="Grants access to pathways, users, statistics, and integrations"
              />
              <CardboxItem
                value="agency"
                image="/images/building.png"
                title="Agency"
                description="Grants access to organizations"
              />
            </Cardbox>
          </div>
        </div>
      </Panel>

      <Panel>
        <div className="row">
          <div className="col">
            <Cardbox
              multiple
              name="bundles"
              help="Select the bundles of pathway templates that this
                    organization will have access to."
              label="Pathway Bundles"
              thin
              vertical
            >
              {Object.entries(PathwayBundles).map(([key, bundle]) => (
                <CardboxItem key={key} value={key} title={bundle.name} />
              ))}
            </Cardbox>
          </div>
        </div>
      </Panel>

      {process.env.NODE_ENV !== 'development' && (
        <Panel>
          <div className="row">
            <div className="col">
              <CompanyMaxioConfig />
            </div>
          </div>
        </Panel>
      )}

      <Panel>
        <div className="row">
          <div className="col">
            <SubscriptionLoader
              resource="users"
              cache={shortCache}
              query={{ roles: 'admin' }}
              fields={['_id', 'username', 'profile']}
              showLoader={true}
              transform={(store) => ({
                ...store,
                users: sort(store.users).asc((r) => r.profile?.name),
              })}
            >
              {({ data }) => {
                const { users } = data
                return (
                  <Cardbox
                    multiple
                    name="reps"
                    help="Select the account representatives for this client. They will receive the client's monthly report."
                    label="Account Reps"
                    thin
                    vertical
                  >
                    {users &&
                      users.map((u) => (
                        <CardboxItem
                          key={u._id}
                          value={u._id}
                          title={u.profile?.name || u.username}
                        />
                      ))}
                  </Cardbox>
                )
              }}
            </SubscriptionLoader>
          </div>
        </div>

        <hr />
        <Button color="primary" type="submit" disabled={isLoading}>
          {buttonLabel || 'Submit'}
        </Button>
      </Panel>
    </Form>
  )
}
}

CompanyFormC.propTypes = {
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
}

export default styled(CompanyFormC)``
