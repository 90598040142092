import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faTools } from '@fortawesome/pro-solid-svg-icons/faTools'
import { faSignOut } from '@fortawesome/pro-solid-svg-icons/faSignOut'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Link as RouterLink } from 'react-router-dom'
import Link from './Link'
import { connect } from 'react-redux'
import { AppState } from '../types'
import { User } from '../../../_shared/models/user/user.client'
import Cookies from 'js-cookie'

const StyledToggle = styled(DropdownToggle)`
  background: url('${(props) => props.user.profile?.pic || '/images/default.png'}') no-repeat center
    center;
  background-size: cover;
  border-radius: 100%;
  width: 32px;
  height: 32px;
`

interface Props {
  id?: string
  className?: string
  isOpen?: boolean
  user?: User
}

interface State {
  dropdownOpen: boolean
}

class AccountMenu extends React.PureComponent<Props, State> {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: !!props.isOpen,
    }
  }

  static propTypes = {}

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  logout() {
    Cookies.remove('authToken', {
      secure: process.env.NODE_ENV === 'production',
      sameSite: 'strict',
      domain: window.location.hostname,
    })
    window.location.reload()
  }

  render() {
    const { user } = this.props
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className={this.props.className}
        direction="down"
      >
        <StyledToggle id={this.props.id} user={user} />
        <DropdownMenu right={true} className="fadeIn animated fastest">
          <DropdownItem tag={Link} to="/account">
            <FontAwesomeIcon icon={faUser} /> Account
          </DropdownItem>

          {user.isAdmin() && (
            <DropdownItem tag={RouterLink} to="/admin">
              <FontAwesomeIcon icon={faTools} transform="up-1" /> Admin
            </DropdownItem>
          )}

          <DropdownItem divider />
          <DropdownItem onClick={this.logout}>
            <FontAwesomeIcon icon={faSignOut} /> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

const AccountMenuWithStore = connect(mapStateToProps)(AccountMenu)

export default styled(AccountMenuWithStore)`
  .dropdown {
    display: inline-block;
  }

  .dropdown-toggle {
    background: none;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 100%;

    &.open ${StyledToggle} {
      box-shadow: 0 0 2px 1px var(--primary);
    }
  }

  .dropdown-menu {
    font-size: 0.875rem;
  }

  .dropdown-item {
    padding: 0.25rem 1rem;
    .svg-inline--fa {
      margin-right: 0.2rem;
    }
  }
`
