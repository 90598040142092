import React from 'react'
import PropTypes from 'prop-types'
import Select from '../../../_shared/components/Form/Select'
import apiFetch from '../../../_shared/api/apiFetch'

class CompanySelect extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    multi: true,
  }

  searchCompanies = async (value, callback) => {
    try {
      const companies = await apiFetch('GET', '/companies/search', {}, { name: value })
      return companies.map((d) => {
        return { label: d.name, value: d._id }
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { name, multi } = this.props

    return (
      <Select
        name={name}
        onChange={this.props.onChange}
        selectProps={{
          isMulti: multi,
          placeholder: 'Search companies',
          noOptionsMessage: () => 'No companies found',
          loadOptions: this.searchCompanies,
        }}
      />
    )
  }
}

export default CompanySelect
