import React from 'react'
import styled from 'styled-components'
import Panel, { PanelTable } from '../../components/Panel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown'
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp'
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { Badge, Collapse, Button } from 'reactstrap'
import format from 'date-fns/format'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import {
  ResponseStatus,
  ResponseStatusSuccessLike,
} from '../../../../_shared/models/response/response.types'
import Response from '../../../../_shared/models/response/response.backend.client'
import apiFetch from '../../../../_shared/api/apiFetch'
import RetryNotificationModal from '../../components/modals/RetryNotificationModal'

const IntegrationDetails = styled.div`
  font-size: 0.8rem;
  border-bottom: 2px solid var(--border-color);
  padding: 2rem 0;
  overflow-x: auto;
  .attempt {
    font-weight: 500;
    margin-bottom: 0.3rem;
    font-size: 0.875rem;
  }
  .table td {
    font-size: 0.8rem;
    padding: 0.5rem;
  }
`

const IntegrationPanel = styled(Panel)`
  margin: 0 0 1rem 0;
  padding: 1rem;
  .name {
    font-weight: 600;
    margin-right: 0.5rem;
    &.failed {
      color: var(--red);
    }
    &.success {
      color: var(--green);
    }
    &.skipped {
      color: var(--yellow);
    }
    &.aborted {
      color: var(--orange);
    }
  }
  .badge {
    position: relative;
    top: -2px;
  }
`

const ProcessedAt = styled.div`
  color: var(--text-muted);
  font-size: 0.8125rem;
`

const SmallPanelTable = styled(PanelTable)`
  font-size: 0.875rem;
  .label {
    font-weight: 600;
    text-align: right;
  }
`

interface State {
  isRetrying: boolean
  collapse: boolean
  isCancelling: boolean
}

interface Props {
  attempt: any
  response: Response
}

export default class IntegrationStatus extends React.Component<Props, State> {
  state = {
    isRetrying: false,
    collapse: false,
    isCancelling: false,
  }

  toggle = (): void => {
    this.setState({ collapse: !this.state.collapse })
  }

  formatDate(date: string): string {
    return format(new Date(date), 'MM/dd/yyyy HH:mm:ss')
  }

  renderStatus(status: ResponseStatus): JSX.Element {
    switch (status) {
      case ResponseStatus.PENDING:
        return <Badge color="primary">Pending</Badge>
      case ResponseStatus.DELIVERED:
        return <Badge color="success">Delivered</Badge>
      case ResponseStatus.FAILED:
      default:
        return <Badge color="danger">Failed</Badge>
    }
  }

  objectToTable(obj: Record<string, any>): JSX.Element {
    if (obj !== null) {
      return (
        <table>
          <tbody>
            {Object.entries(obj).map(([key, value]) => {
              return (
                <tr key={key}>
                  <td className="label">{key}</td>
                  <td>{typeof value !== 'object' ? value : this.objectToTable(value)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )
    }
  }

  renderContext(context: string): JSX.Element {
    if (!context) {
      return
    }
    if (typeof context !== 'object') {
      return <code>{context}</code>
    } else {
      return this.objectToTable(context)
    }
  }

  renderTimestamp(attempt: any, isSuccesslike: boolean): JSX.Element {
    const formatted = format(new Date(attempt.lastProcessedAt), "MMM do yyyy 'at' hh:mm:ssa")

    let message
    if (isSuccesslike) {
      message = `Processed at ${formatted}`
    } else if (attempt.retryAt) {
      const formattedRetry = format(new Date(attempt.retryAt), "MMM do yyyy 'at' hh:mm:ssa")
      message = `Last attempted ${formatted}, next retry ${formattedRetry}`
    } else {
      message = `Last attempted ${formatted}`
    }

    return <ProcessedAt>{message} </ProcessedAt>
  }

  cancel(attemptId: string): void {
    this.setState(
      {
        isCancelling: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/notifications/cancel', {
            attemptId,
          })
        } catch (e) {
          this.setState({
            isCancelling: false,
          })
          throw new Error(e)
        }
      }
    )
  }

  renderActionButtons = (attempt: any): JSX.Element => {
    if (attempt.isRetrying) {
      return (
        <Button
          color="alt"
          size="sm"
          disabled={this.state.isCancelling}
          onClick={() => {
            this.cancel(attempt._id)
          }}
        >
          <FontAwesomeIcon icon={faTimes} /> Cancel
        </Button>
      )
    }

    return (
      <RetryNotificationModal
        notificationId={attempt.notificationId}
        responseId={attempt.responseId}
      />
    )
  }

  render(): React.ReactNode {
    const { attempt: i, response } = this.props

    const isSuccesslike = ResponseStatusSuccessLike.includes(i.lastStatus)

    return (
      <IntegrationPanel thin>
        <div className="row align-items-center">
          <div className="col">
            <span className={`name ${i.lastStatus}`}>{i.name}</span>{' '}
            {this.renderStatus(i.lastStatus)}
            {i.isRetrying && (
              <ProcessedAt>
                <FontAwesomeIcon icon={faSync} spin size="xs" transform="up-2" /> Currently Retrying
              </ProcessedAt>
            )}
            {this.renderTimestamp(i, isSuccesslike)}
          </div>
          <div className="col-auto">
            {this.renderActionButtons(i)}
            <Button color="alt" size="sm" onClick={this.toggle} style={{ marginLeft: '1rem' }}>
              Details <FontAwesomeIcon icon={this.state.collapse ? faCaretUp : faCaretDown} />
            </Button>
          </div>
        </div>
        <Collapse isOpen={this.state.collapse}>
          {this.state.collapse ? (
            <SubscriptionLoader
              resource="integrationAttempt"
              query={{ _id: i._id, companyId: response.companyId }}
              showLoader={true}
            >
              {({ data, subscription }) => {
                if (!data?.integration_attempts || data.integration_attempts.length === 0) {
                  return null
                }

                const results: any[] = Array.from(data.integration_attempts[0].results).reverse()

                return results.map((r, index) => {
                  return (
                    <IntegrationDetails key={index}>
                      <div className="attempt">Attempt #{results.length - index}</div>
                      <SmallPanelTable striped className="text-smaller">
                        <tbody>
                          <tr>
                            <td width="100" className="label">
                              Time:
                            </td>
                            <td>{this.formatDate(r.processedAt)}</td>
                          </tr>
                          <tr>
                            <td className="label">Status:</td>
                            <td>{this.renderStatus(r.status)}</td>
                          </tr>
                          <tr>
                            <td className="label">Error:</td>
                            <td>
                              <code>{r.error}</code>
                            </td>
                          </tr>
                          <tr>
                            <td className="label">Context:</td>
                            <td>{this.renderContext(r.context)}</td>
                          </tr>
                        </tbody>
                      </SmallPanelTable>
                    </IntegrationDetails>
                  )
                })
              }}
            </SubscriptionLoader>
          ) : (
            ''
          )}
        </Collapse>
      </IntegrationPanel>
    )
  }
}
