import BaseIntegrationAdapter from '../BaseIntegrationAdapter'

export interface IntegrationCopperConfig {
  accessToken: string
  apiUser: string
  targetObject: string
  postActivity: string
  mappingScript?: string
  activityTypeId?: string
}

export const CopperAdapterSchema = {
  type: 'object',
  properties: {
    accessToken: {
      title: 'Copper Access Token',
      type: 'string',
    },
    apiUser: {
      title: 'Copper Email Address',
      type: 'string',
    },
    targetObject: {
      title: 'Submit as',
      type: 'string',
      enum: ['leads', 'people'],
    },
    mappingScript: {
      title: 'Tagging Script',
      type: 'string',
    },
    activityTypeId: {
      title: 'Activity Type',
      type: 'string',
    },
  },
  required: ['accessToken', 'apiUser', 'targetObject', 'mappingScript', 'activityTypeId'],
  additionalProperties: false,
}

class CopperAdapter extends BaseIntegrationAdapter {
  name = 'Copper'
  key = 'copper'
  description = 'Post leads and activities to Copper CRM'
  image = 'copper'
  schema = CopperAdapterSchema
}

export default CopperAdapter
