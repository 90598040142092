import { applyChange, Diff } from 'deep-diff'
import { ClientSubscription } from './client'
import { Payload } from './types'

export type DataStore = Record<string, CollectionStore>
export type CollectionStore = Array<Record<string, any>>

interface PubSubStoreOptions {
  idKey?: string
}

export function applyDiff<T>(
  target: Record<string, any>,
  diff: Diff<Record<string, any>, any>[]
): T {
  if (!diff) {
    console.error('Missing diff for target', target)
    return target as T
  }
  // todo: keep an eye on "structuredClone" becoming a browser native function,
  // and track all the progress here: https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/10916838#10916838
  // and here: https://github.com/whatwg/html/issues/793
  const newObject = Object.assign({}, target)
  diff.forEach((change: Diff<Record<string, any>, any>) => {
    applyChange(newObject, null, change)
  })
  return newObject as T
}

export default class PubSubStore {
  data: DataStore = {}
  idKey = '_id'
  subscription: ClientSubscription

  constructor(subscription: ClientSubscription, options?: PubSubStoreOptions) {
    this.subscription = subscription
    if (options) {
      this.idKey = options.idKey || '_id'
    }
  }

  findDocumentIndex(store: CollectionStore, documentId: string): number {
    return store.findIndex((d) => d[this.idKey] === documentId)
  }

  getCollectionStore(collection: string): CollectionStore {
    if (!this.data[collection]) {
      this.data[collection] = []
    }
    return this.data[collection]
  }

  setData(data: DataStore): void {
    this.data = data
  }

  getData(): DataStore {
    return this.data
    // return Object.assign({}, this.data)
  }

  upsert({ collection, documentId, diff }: Payload): void {
    const collectionStore = this.getCollectionStore(collection)
    const index = this.findDocumentIndex(collectionStore, documentId)

    if (index === -1) {
      let doc = applyDiff({}, diff)
      const transform = this.subscription.pubSubManager.collectionTransforms[collection]
      if (transform) {
        doc = transform(doc)
      }
      this.data[collection].push(doc)
    } else {
      let doc = Object.assign({}, collectionStore[index] || {})
      doc = applyDiff(doc, diff)
      const transform = this.subscription.pubSubManager.collectionTransforms[collection]
      if (transform) {
        doc = transform(doc)
      }
      this.data[collection][index] = doc
    }
  }

  remove({ collection, documentId, query }: Payload): void {
    if (documentId) {
      const collectionStore = this.getCollectionStore(collection)
      const index = this.findDocumentIndex(collectionStore, documentId)
      if (index !== -1) {
        collectionStore.splice(index, 1)
        this.data[collection] = collectionStore
      }
    } else if (query) {
      const collectionStore = this.getCollectionStore(collection).filter((doc) => {
        // if doc matches query, return false, toherwise return true
        let match = true

        Object.values(query).forEach(([key, value]) => {
          if (doc[key] !== value) {
            match = false
          }
        })

        return !match
      })
      this.data[collection] = collectionStore
    }
  }

  empty(): void {
    this.data = {}
  }
}
