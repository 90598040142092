import { parseISO } from 'date-fns'

export interface PersonSocial {
  name: string
  url: string
}

export interface PersonCustomData {
  key: string
  label: string
  value: string | string[]
}

export enum PersonDataCategories {
  'contact',
  'prospects',
  'situation',
  'history',
  'logistics',
  'motivation',
}

export type PersonData = {
  [K in keyof typeof PersonDataCategories]?: Record<string, PersonCustomData>
}

export default class ClientPerson {
  declare _id?: string
  declare companyId: string
  declare lastModified: Date
  declare createdAt: Date
  declare pic?: string
  declare email?: string
  declare firstName?: string
  declare lastName?: string
  declare phone?: string
  declare social?: PersonSocial[]
  declare data?: PersonData
  declare isOnline?: boolean
  isInterviewed?: boolean = false
  isEnrolled?: boolean = false
  declare statisticsConsent?: boolean

  constructor(props?: NonFunctionProperties<ClientPerson>) {
    Object.assign(this, props)
    const { lastModified, createdAt } = props
    if (typeof lastModified === 'string') {
      this.lastModified = parseISO(lastModified)
    }
    if (typeof createdAt === 'string') {
      this.createdAt = parseISO(createdAt)
    }
  }

  /**
   * Returns the proper full name of the person
   *
   * @returns
   * @memberof Person
   */
  getFullName(emailFallback: boolean): string {
    let name

    if (this.firstName) {
      name = this.firstName
    }
    if (this.lastName) {
      name += ' ' + this.lastName
    }
    if ((!name || name.length === 0) && emailFallback) {
      name = this.email
    }

    if (!name) {
      name = '(unknown)'
    }
    return name
  }

  getPhone(): string {
    return this.phone
  }

  getPic(): string {
    return this.pic || '/images/default.png'
  }
}
