import React from 'react'
import styled from 'styled-components'
import Field from '../../../pagebuilder/blocks/Field'

const VariableDocs = ({ className, pathway }) => {
  return (
    <React.Fragment>
      <h4 style={{ marginBottom: '1rem' }}>Profile</h4>
      <table className={className + ' table table-striped'}>
        <thead>
          <tr>
            <th width="50%">Field</th>
            <th>Variable</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First Name</td>
            <td>
              <code>#&#123;profile.firstName&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>
              <code>#&#123;profile.lastName&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <code>#&#123;profile.email&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Phone</td>
            <td>
              <code>#&#123;profile.phone&#125;</code>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h4 style={{ marginBottom: '1rem' }}>Pathway</h4>
      <table className={className + ' table table-striped'}>
        <thead>
          <tr>
            <th width="50%">Field</th>
            <th>Variable</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>
              <code>#&#123;pathway._id&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              <code>#&#123;pathway.name&#125;</code>
            </td>
          </tr>
          <tr>
            <td>URL</td>
            <td>
              <code>#&#123;pathway.url&#125;</code>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <h4 style={{ marginBottom: '1rem' }}>Pathway Response</h4>
      <table className={className + ' table table-striped'}>
        <thead>
          <tr>
            <th width="50%">Field</th>
            <th>Variable</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="2">
              <strong>Common</strong>
            </td>
          </tr>
          <tr>
            <td>Progress</td>
            <td>
              <code>#&#123;response.progress&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Score</td>
            <td>
              <code>#&#123;response.score&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Lead Source</td>
            <td>
              <code>#&#123;response.lead_src&#125;</code>
            </td>
          </tr>
          <tr>
            <td>Referrer</td>
            <td>
              <code>#&#123;response.referrer&#125;</code>
            </td>
          </tr>
          <tr>
            <td>IP Adddress</td>
            <td>
              <code>#&#123;response.ipAddress&#125;</code>
            </td>
          </tr>
          {pathway.getSteps().map((step) => {
            return (
              <React.Fragment key={step._id}>
                <tr key={step.id}>
                  <td colSpan="2">
                    <strong>{step.name}</strong>
                  </td>
                </tr>

                {step.getBlocks().map((block) => {
                  if (block instanceof Field) {
                    const name = block.data.advanced.name
                    return (
                      <tr key={block.id}>
                        <td>
                          {block.getLabel()}
                          {block.isRequired() ? ' (required)' : ''}
                        </td>
                        <td>
                          {name && (
                            <React.Fragment>
                              <code>#&#123;response.{name}&#125;</code> or{' '}
                              <code>#&#123;response.{block.id}&#125;</code>
                            </React.Fragment>
                          )}
                          {!name && <code>#&#123;response.{block.id}&#125;</code>}
                        </td>
                      </tr>
                    )
                  } else {
                    return null
                  }
                })}
                <tr />
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </React.Fragment>
  )
}

export default styled(VariableDocs)`
  font-size: 0.875rem;
`
