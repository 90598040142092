import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import Panel, { PanelHero } from '../../../components/Panel'
import Link from '../../../components/Link'
import RoundButton from '../../../components/RoundButton'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import NewButton from '../../../components/NewButton'
import View from '../../../components/View'
import BasicModal from '../../../components/BasicModal'
import { PanelList, PanelListItem } from '../../../components/PanelList'
import apiFetch from '../../../../../_shared/api/apiFetch'

class DeleteDomain extends React.PureComponent {
  static propTypes = {
    company: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
  }

  callMethod = async () => {
    try {
      await apiFetch('DELETE', '/domains/delete', {
        companyId: this.props.company._id,
        domain: this.props.domain,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    return (
      <BasicModal
        title="Delete Domain?"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.domain}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
      >
        <RoundButton size="sm" onClick={this.delete}>
          <FontAwesomeIcon icon={faTimes} />
        </RoundButton>
      </BasicModal>
    )
  }
}

const FirstDomain = (props) => (
  <Panel>
    <PanelHero>
      <img src="/images/globe-hands.png" width="128" />
      <h3>Create your first custom domain</h3>
      <h6>Map your own domains to VirtualAdviser pathways</h6>
      <Link tag="button" to={`/organization/domains/new`} className="btn btn-lg btn-primary">
        <FontAwesomeIcon icon={faPlus} /> New Domain
      </Link>
    </PanelHero>
  </Panel>
)

const Domain = (props) => {
  const { domain, company } = props
  return (
    <PanelListItem>
      <div className="row align-items-center flex-nowrap">
        <div className="col">
          <div className="panel-list-item-title">{domain}</div>
        </div>
        <div className="d-flex flex-nowrap col-auto buttons">
          <DeleteDomain domain={domain} company={company} />
        </div>
      </div>
    </PanelListItem>
  )
}
Domain.propTypes = {
  domain: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
}

const DomainsIndex = (props) => {
  const { company } = props

  if (!company.domains || company.domains.length === 0) {
    return <FirstDomain company={company} />
  }

  return (
    <View title={`Domains - ${company.name}`}>
      <PanelList>
        {company.domains &&
          company.domains.map((domain, index) => (
            <Domain key={index} company={company} domain={domain} />
          ))}
      </PanelList>
      <NewButton label="New Domain" tag={Link} center to={`/organization/domains/new`} />
    </View>
  )
}

DomainsIndex.propTypes = {
  company: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default DomainsIndex
