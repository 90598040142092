import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import apiFetch from '../../../../_shared/api/apiFetch'

class DeleteStepModal extends React.PureComponent {
  static propTypes = {
    step: PropTypes.object.isRequired,
    pathway: PropTypes.object.isRequired,
  }

  state = {
    isLoading: false,
  }

  callMethod = async () => {
    const { pathway, step } = this.props
    if (this.state.isLoading) {
      return
    }
    try {
      await apiFetch('DELETE', '/steps/delete', { pathwayId: pathway._id, stepId: step._id })
    } catch (e) {
      throw new Error(e)
    }

    this.setState({
      isLoading: false,
    })
  }

  render() {
    return (
      <BasicModal
        title="Delete Step"
        message={
          <div>
            Are you sure you want to delete <strong>{this.props.step.name}</strong>?
          </div>
        }
        confirmLabel="Delete"
        confirmColor="outline-danger"
        cancelLabel="Cancel"
        confirm={this.callMethod}
        buttonTag={this.props.buttonTag}
        buttonProps={this.props.buttonProps}
      >
        <FontAwesomeIcon icon={faTimesCircle} fixedWidth transform="down-1" /> Delete
      </BasicModal>
    )
  }
}

export default DeleteStepModal
