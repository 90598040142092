import { ClientSubscription } from '../../pubsub/src/server/PubSub/client'
import { Company } from '../../_shared/models/company/company.client'
import { User } from '../../_shared/models/user/user.client'

export const UPDATE_USER = 'UPDATE_USER'
export const BEGIN_LOGIN = 'BEGIN_LOGIN'
export const FINISH_LOGIN = 'FINISH_LOGIN'
export const UPDATE_SUBSCRIPTION = 'UPDATE_SUBSCRIPTION'
export const UPDATE_COMPANY = 'UPDATE_COMPANY'
export const BEGIN_RECONNECTING = 'BEGIN_RECONNECTING'
export const FINISH_RECONNECTING = 'FINISH_RECONNECTING'

export interface ReduxSubscription {
  ready: boolean
  data: Record<string, any[]>
}
export interface AppState {
  user?: User
  company?: Company
  isLoggingIn?: boolean
  subscriptions: Record<string, ReduxSubscription>
  isReconnecting?: boolean
}
export interface UpdateUserAction {
  type: typeof UPDATE_USER
  payload: Record<string, any>
}

export interface BeginLogin {
  type: typeof BEGIN_LOGIN
}

export interface FinishLogin {
  type: typeof FINISH_LOGIN
}

export interface UpdateSubscription {
  type: typeof UPDATE_SUBSCRIPTION
  key: string
  subscription: ClientSubscription
}

export interface UpdateCompany {
  type: typeof UPDATE_COMPANY
  payload: Record<string, any>
}

export interface BeginReconnecting {
  type: typeof BEGIN_RECONNECTING
}

export interface FinishReconnecting {
  type: typeof FINISH_RECONNECTING
}

export type ActionTypes =
  | UpdateUserAction
  | BeginLogin
  | FinishLogin
  | UpdateSubscription
  | UpdateCompany
  | BeginReconnecting
  | FinishReconnecting
