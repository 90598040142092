import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScoreGauge from '../../components/ScoreGauge'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'
import { faArrowAltLeft } from '@fortawesome/pro-solid-svg-icons/faArrowAltLeft'
import Link from '../../components/Link'

const StatusIcon = (props) => {
  if (props.invalid) {
    return (
      <div className="invalid-icon status-icon">
        <FontAwesomeIcon fixedWidth icon={faTimes} />
      </div>
    )
  }

  if (props.isOnline) {
    return (
      <div className="online-icon status-icon">
        <FontAwesomeIcon fixedWidth icon={faUser} />
      </div>
    )
  }

  if (props.progress === 100) {
    return (
      <div className="complete-icon status-icon">
        <FontAwesomeIcon fixedWidth icon={faCheck} />
      </div>
    )
  }

  return (
    <div className="abandon-icon status-icon">
      <FontAwesomeIcon fixedWidth icon={faArrowAltLeft} />
    </div>
  )
}
StatusIcon.propTypes = {
  isOnline: PropTypes.bool,
  invalid: PropTypes.bool,
  progress: PropTypes.number,
}

const Result = (props) => {
  const { response, pathway, profile, active, className, onClick } = props
  return (
    <Link
      to={`/response/${response._id}`}
      tag="div"
      className={classnames(className, 'row flex-nowrap align-items-center no-gutters', {
        active: active,
      })}
      onClick={onClick}
    >
      <div className="col-auto pic">
        <StatusIcon
          progress={response.progress}
          isOnline={response.isOnline}
          invalid={response.isInvalid}
        />
        <img src={profile?.getPic()} alt={profile?.getFullName()} />
      </div>
      <div className="col details">
        <div className="name">{profile?.getFullName(true)}</div>
        <div className="pathway">{pathway?.name}</div>

        {response.progress < 100 && (
          <div className="progress">
            <div
              className={classnames('progress-bar', {
                'bg-success progress-bar-striped progress-bar-animated': response.isOnline,
              })}
              role="progressbar"
              aria-valuenow={response.progress}
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: `${response.progress}%` }}
            />
          </div>
        )}
      </div>
      {pathway?.maxScore !== 0 &&
        (response.scoringComplete || response.progress >= 100) &&
        response.score !== undefined && (
          <div className="col-auto score">
            <ScoreGauge value={response.score} />
          </div>
        )}
    </Link>
  )
}

Result.propTypes = {
  response: PropTypes.object.isRequired,
  pathway: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  active: PropTypes.bool,
  isOnline: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default styled(Result)`
  text-decoration: none !important;
  transition: all 0.15s ease-out;
  position: relative;
  overflow: hidden;
  padding: 0.75em 1em 0.75em 0.75em;
  font-size: 0.875rem;

  .details {
    margin-left: 1.25em;
    overflow: hidden;
  }

  &,
  &:hover {
    color: var(--text-muted-blue);
  }
  &:hover {
    background: #f6faff;

    border-color: ${(props) => (props.response.isOnline ? 'var(--success)' : 'var(--primary)')};
    .name {
      color: ${(props) => (props.response.isOnline ? '#1aa73b' : '#118dff')};
    }
  }

  &.active {
    border-color: ${(props) => (props.response.isOnline ? '#1aa73b' : '#118dff')};
  }

  .name {
    font-weight: 600;
    color: ${(props) => (props.response.isOnline ? 'var(--success)' : 'var(--primary)')};
  }

  .pathway,
  .status {
    font-size: 0.95em;
  }
  .status {
    color: ${(props) => (props.response.isOnline ? 'var(--success)' : 'inherit')};
  }

  .name,
  .pathway,
  .timestamp,
  .status {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .pic {
    position: relative;
    img {
      border-radius: 100%;
      width: 4em;
      height: 4em;
      margin: 2px;
      box-shadow: 0 0 0px 2px
        ${(props) => (props.response.isOnline ? 'var(--success)' : 'transparent')};
    }
  }

  .status-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--primary);
    border-radius: 100%;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 8px;
    text-align: center;
  }

  .online-icon {
    background-color: var(--success);
  }

  .abandon-icon {
    color: #444;
    background-color: #e9ecef;
  }
  .invalid-icon {
    background-color: var(--danger);
  }

  .progress {
    margin-top: 0.4rem;
    height: 5px;
    font-size: 0.6em;
    line-height: 11px;
  }

  .score {
    overflow: hidden;
    margin-left: 1em;
  }

  ${(props) => {
    if (props.active) {
      return {
        background: '#f6faff',
        boxShadow: `inset 0 0 0 2px ${props.isOnline ? 'var(--success)' : 'var(--primary)'}`,
      }
    }
  }};
`
