import React from 'react'
import PropTypes from 'prop-types'
import { StatOverviewContainer } from '../components/StatOverview'
import PathwaysActivity from '../../../components/PathwaysActivity'
import { PathwayStatisticsTableContainer } from '../../../components/PathwayStatisticsTable'
import { PathwayStatisticsTableWithTrafficContainer } from '../../../components/PathwayStatisticsTableWithTraffic'
import Panel from '../../../components/Panel'

const CampaignStatisticsOverview = ({ campaign, companyId, dateRange }) => {
  let TableComponent

  switch (campaign._id) {
    case 'admissions':
      TableComponent = PathwayStatisticsTableContainer
      break
    default:
      TableComponent = PathwayStatisticsTableWithTrafficContainer
  }

  return (
    <Panel>
      <StatOverviewContainer campaign={campaign} companyId={companyId} dateRange={dateRange} />
      <PathwaysActivity
        start={dateRange[0]}
        end={dateRange[1]}
        campaignId={campaign._id}
        companyId={companyId}
        dateRange={dateRange}
        showStats={false}
      />
      <TableComponent
        campaignId={campaign._id}
        companyId={companyId}
        start={dateRange[0]}
        end={dateRange[1]}
      />
    </Panel>
  )
}

CampaignStatisticsOverview.propTypes = {
  campaign: PropTypes.object.isRequired,
  dateRange: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
}

export default CampaignStatisticsOverview
