import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import Breadcrumbs from '../../components/Breadcrumbs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { Redirect } from '../../components/Link'
import { Button } from 'reactstrap'
import slugify from 'voca/slugify'
import View from '../../components/View'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Dropdown from '../../../../_shared/components/Form/Dropdown'
import { GlobalCampaigns } from '../../../../_shared/models/campaign/campaign.client'
import FormError from '../../../../_shared/components/Form/FormError'
import URLInput from '../../../../_shared/components/Form/URLInput'
import withForm from '../../../../_shared/components/withForm'
import apiFetch from '../../../../_shared/api/apiFetch'

const PathwayFormSchema = {
  title: 'Pathway Form',
  type: 'object',
  properties: {
    name: {
      type: 'string',
    },
    url: {
      type: 'string',
    },
    campaignId: {
      type: 'string',
    },
  },
  required: ['name', 'url', 'campaignId'],
}

const TemplateForm = (props) => {
  const { template, onSubmit, isLoading, error, company } = props

  const nameDefaultValue = template?.name !== 'Blank' ? company.name + ' ' + template.name : null

  return (
    <Panel>
      <Breadcrumbs
        breadcrumbs={[
          {
            label: (
              <span>
                <FontAwesomeIcon icon={faChevronLeft} size="xs" transform="up-2" /> Choose a
                different template
              </span>
            ),
            path: '/pathways/new',
          },
        ]}
      />

      <h3>Template: {template.name}</h3>
      <p>{template.shortDescription}</p>
      <hr />

      <SubscriptionLoader
        resource="campaigns"
        query={{ companyId: company._id }}
        sort={{ name: 1 }}
        showLoader={true}
      >
        {({ data }) => {
          const { campaigns } = data
          return (
            <Form formData={{}} schema={PathwayFormSchema} onValidSubmit={onSubmit}>
              <PanelForm center={false}>
                <Input
                  type="text"
                  name="name"
                  defaultValue={nameDefaultValue}
                  help="Public name of the pathway (will be visible to everyone who visits the pathway)"
                />
              </PanelForm>

              <URLGenerator name="url" />
              <URLInput
                name="url"
                prefix="https://"
                postfix={process.env.DOMAIN_SUFFIX}
                allowedRegex={new RegExp('^[\\w\\d\\-]+$', 'i')}
                maxLength={25}
                label="Web Address"
                // onChange={this.onChange}
                help={
                  <span>
                    The public URL used to access the pathway. We also support{' '}
                    <a href="#" onClick={() => false}>
                      unlimited custom domains
                    </a>
                    .
                  </span>
                }
              />

              <PanelForm center={false}>
                <Dropdown name="campaignId" label="Campaign">
                  {Object.values(GlobalCampaigns).map((campaign) => {
                    return (
                      <option key={campaign._id} value={campaign._id}>
                        {campaign.name}
                      </option>
                    )
                  })}
                  {campaigns &&
                    campaigns.map((campaign) => (
                      <option key={campaign._id} value={campaign._id}>
                        {campaign.name}
                      </option>
                    ))}
                </Dropdown>
              </PanelForm>

              <hr />
              {error && <FormError>{error}</FormError>}
              <Button size="lg" color="primary" type="submit" disabled={isLoading}>
                Create Pathway
              </Button>
            </Form>
          )
        }}
      </SubscriptionLoader>
    </Panel>
  )
}

class URLGen extends React.Component {
  state = {
    slug: null,
  }
  static propTypes = {
    formData: PropTypes.object,
    setFieldValue: PropTypes.func,
  }
  shouldComponentUpdate(nextProps, nextState) {
    return nextProps.formData?.name !== this.props.formData?.name
  }
  componentDidUpdate() {
    const { formData, setFieldValue } = this.props
    if (!formData.url || formData.url === this.state.slug) {
      const slug = slugify(formData.name)
      this.setState(
        {
          slug,
        },
        () => {
          setFieldValue('url', slug)
        }
      )
    }
  }
  render() {
    return null
  }
}

const URLGenerator = withForm(URLGen)

class CreatePathwayForm extends React.Component {
  state = {
    isLoading: false,
    error: false,
    redirect: null,
  }

  static defaultProps = {
    template: { _id: 'blank', name: 'Blank' },
  }

  static propTypes = {
    match: PropTypes.object,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    template: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    data.formData.companyId = this.props.company._id

    this.setState({ isLoading: true }, async () => {
      const pathway = data.formData
      pathway.companyId = this.props.company._id

      const templateId = this.props.match?.params?.templateId
      if (templateId !== 'blank') {
        pathway.templateId = templateId
      }

      try {
        const result = await apiFetch('POST', '/pathways/create', {
          doc: pathway,
        })
        this.setState({
          isLoading: false,
          redirect: `/pathways/${result.pathwayId}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { company, match } = this.props

    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }

    const templateId = match.params.templateId

    const title = 'Create Pathway'
    return (
      <View layout="sidebar" title={title}>
        <PageHeader
          title={title}
          breadcrumbs={[
            { label: 'Pathways', path: '/pathways' },
            { label: 'Choose Template', path: '/pathways/new' },
          ]}
        />
        <div className="content">
          {/* {templateId !== 'blank' && (
            <SubscriptionLoader
              resource="pathwayTemplate"
              query={{ _id: match.params.templateId }}
              showLoader={true}
            >
              {({ data: template }) => (
                <TemplateForm
                  template={template}
                  onSubmit={this.onSubmit}
                  isLoading={this.state.isLoading}
                  error={this.state.error}
                  company={company}
                />
              )}
            </SubscriptionLoader>
          )} */}

          {templateId === 'blank' && (
            <TemplateForm
              template={{ name: 'Blank' }}
              onSubmit={this.onSubmit}
              isLoading={this.state.isLoading}
              error={this.state.error}
              company={company}
            />
          )}
        </div>
      </View>
    )
  }
}

export default CreatePathwayForm
