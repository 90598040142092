import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import media from '../css/_media'

const StyledModal = styled(Modal)`
  max-width: none;
  margin: 0;
  color: #fff;
  padding: 2rem;
  text-align: center;
  display: flex;
  height: 100%;
  align-items: center;
  transform: none !important;

  .modal-content {
    background: none;
    border-radius: 0;
    border: 0;
    display: block;
    margin: 0 auto;
  }

  @media (min-width: ${media.md}) {
    padding: 10vh 10vw;
    .modal-content {
      max-width: 700px;
    }
  }

  .modal-header {
    display: block;
    padding: 0;
    border: 0;
    margin: 0 0 2rem 0;

    h1,
    h2,
    h3,
    h4,
    h5 {
      color: #fff;
    }
  }

  .modal-body {
    padding: 0;
    margin: 0 auto 3rem auto;
  }

  .modal-footer {
    padding: 0;
    border: 0;
    justify-content: center;
  }

  .btn {
    .svg-inline--fa {
      margin-right: 0.1rem;
    }
    border-width: 2px;
  }

  .btn-outline-success {
    color: #2ecc40;
    border-color: #2ecc40;
    &:hover {
      color: #fff;
      border-color: #2ecc40;
      background-color: #2ecc40;
    }
  }

  .btn-outline-danger {
    color: #ef4453;
    border-color: #ef4453;
    &:hover {
      color: #fff;
      border-color: #ef4453;
      background-color: #ef4453;
    }
  }

  .close {
    display: none;
  }

  .modal-footer {
    text-align: center;

    .btn {
      margin: 0 0.5rem;
    }
  }
`

class BasicModal extends React.PureComponent {
  state = {
    modal: false,
  }

  static propTypes = {
    confirmLabel: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    cancelLabel: PropTypes.string,
    confirm: PropTypes.func,
    children: PropTypes.node,
    confirmColor: PropTypes.string,
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.modal === true && this.state.modal === false) {
      document.body.click()
    }
  }

  confirm = () => {
    const { confirm } = this.props
    if (confirm) {
      confirm(this.toggle)
    }
  }

  render() {
    const {
      title,
      message,
      confirmLabel,
      cancelLabel,
      children,
      confirmColor,
      buttonTag,
      buttonProps,
    } = this.props

    const ButtonTag = buttonTag || 'div'

    return (
      <div onClick={this.handleClick}>
        <ButtonTag {...buttonProps} onClick={this.toggle}>
          {children}
        </ButtonTag>
        <StyledModal
          backdrop="static"
          isOpen={this.state.modal}
          toggle={this.toggle}
          fade={false}
          wrapClassName="basic-modal fadeIn animated faster"
          className="zoomIn animated faster"
        >
          <ModalHeader tag="h2" toggle={this.toggle}>
            {title}
          </ModalHeader>
          <ModalBody>{message}</ModalBody>
          <ModalFooter>
            <Button color="outline-secondary" onClick={this.toggle}>
              <FontAwesomeIcon icon={faTimes} /> {cancelLabel}
            </Button>
            <Button
              color={confirmColor || 'outline-success'}
              onClick={this.confirm}
            >
              <FontAwesomeIcon icon={faCheck} /> {confirmLabel}
            </Button>
          </ModalFooter>
        </StyledModal>
      </div>
    )
  }
}

export default BasicModal
