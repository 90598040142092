export interface PathwayBundle {
  link?: string
  name: string
}

const PathwayBundles: Record<string, PathwayBundle> = {
  website: {
    name: 'Core',
    link: 'https://virtualadviser.com/virtual-adviser-suite/',
  },
  admissions: {
    name: 'Admissions',
    link: 'https://virtualadviser.com/virtual-adviser-suite#admissions',
  },
  landingpages: {
    name: 'Landing Pages',
    link: 'https://virtualadviser.com/virtual-adviser-suite#landingpages',
  },
  retention: {
    name: 'Retention',
    link: 'https://virtualadviser.com/virtual-adviser-suite#retention',
  },
}

export default PathwayBundles
