import React from 'react'
import PropTypes from 'prop-types'
import { Line } from 'react-chartjs-2'
import { parse, format } from 'date-fns'

class LeadsByTimeContainer extends React.Component {
  chartData() {
    let days = []
    let leads = []
    const cumulativeLeadsByDay = this.props.data.results.charts.cumulativeLeadsByDay
    Object.keys(cumulativeLeadsByDay).forEach((day) => {
      days.push(format(parse(day, 'yyyy-mm-dd', new Date()), 'Do'))
      leads.push(cumulativeLeadsByDay[day])
    })

    return { days, leads }
  }

  render() {
    const cumulativeLeadsByDay = this.chartData()
    const chartData = {
      labels: cumulativeLeadsByDay.days,
      datasets: [
        {
          label: 'Cumulative Leads',
          lineTension: 0.2,
          backgroundColor: 'rgba(64,86,111,0.4)',
          borderColor: 'rgba(64,86,111,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(64,86,111,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 6,
          pointHoverBackgroundColor: 'rgba(64,86,111,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 6,
          data: cumulativeLeadsByDay.leads,
          fill: 'origin',
        },
      ],
    }
    const options = {
      hover: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        y: {
          ticks: {
            beginAtZero: true,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
      },
    }

    return (
      <div>
        <h3>Leads By Day</h3>
        <Line data={chartData} options={options} height={50} />
      </div>
    )
  }

  static propTypes = {
    data: PropTypes.object,
  }
}

export default LeadsByTimeContainer
