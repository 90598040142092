import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Panel, { PanelTable } from '../../components/Panel'
import '../../../../pagebuilder/blocks/index'
import FieldOutput from '../../../../pagebuilder/blocks/FieldOutput'

class ResponseAnswers extends React.Component {
  static propTypes = {
    response: PropTypes.object.isRequired,
    pathway: PropTypes.object.isRequired,
    mock: PropTypes.object,
    className: PropTypes.string,
  }

  render() {
    const { response, className } = this.props

    return (
      <div className={className}>
        {response.steps?.map((step) => {
          let hasAnswers = false

          const output = (
            <Panel thin key={step._id}>
              <h5 className="step-title">{step.name}</h5>
              <table className="table table-striped">
                <tbody>
                  {step?.fields?.map((field, i) => {
                    hasAnswers = true
                    return (
                      <tr key={field._id}>
                        <td className="label-cell">{field.label}</td>
                        <td>
                          <FieldOutput field={field} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Panel>
          )

          if (hasAnswers) {
            return output
          }
        })}
      </div>
    )
  }
}

export default styled(ResponseAnswers)`
  ${Panel} {
    margin: 0 0 0.75rem 0;
    padding: 0.75rem 0.5rem;
  }
  tbody > tr:first-child > td {
    border-top: 0;
  }

  .label-cell {
    font-weight: 600;
    width: 50%;
    text-align: right;
  }

  @media (min-width: 1300px) {
    .label-cell {
      width: 300px;
    }
  }

  .step-title {
    border-bottom: 1px solid var(--bg-color);
    padding-bottom: 0.75rem;
    text-align: center;
    font-size: 1rem;
    @media print {
      font-size: 13px;
    }
  }

  @media screen {
    tbody tr:nth-of-type(even) {
      background-color: #f9fafd;
    }
  }

  ul {
    margin: 0;
  }

  th,
  td {
    border-top: 0;
    padding: 0.75em 0.85em;
    font-size: 0.8125rem;
    color: #212529;
    word-break: break-word;

    @media print {
      font-size: 12px;
    }
  }
`
