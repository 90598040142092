import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import hoistNonReactStatic from 'hoist-non-react-statics'
import isValid from 'date-fns/isValid'

class DateRangeSelector extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    children: PropTypes.any,
  }

  getDateRange = () => {
    const { location } = this.props
    const queryParams = new URLSearchParams(location.search)
    let range = queryParams.get('dateRange')
    if (!range) {
      return null
    }
    range = range.split('-')
    const dateRange = [new Date(Number(range[0])), new Date(Number(range[1]))]
    if (!isValid(dateRange[0]) || !isValid(dateRange[1])) {
      return null
    }
    return dateRange
  }

  changeDateRange = (value) => {
    const { history, location } = this.props
    const queryParams = new URLSearchParams(location.search)

    const start = value[0].getTime()
    const end = value[1].getTime()

    queryParams.set('dateRange', `${start}-${end}`)
    history.push({
      search: queryParams.toString(),
    })
  }

  render() {
    const dateRange = this.getDateRange()
    // todo: turn into dates from text
    return this.props.children(dateRange, this.changeDateRange)
  }
}

const DateRangeSelectorContainer = withRouter(DateRangeSelector)

const withDateRange = function (Component, defaultDateRange) {
  const C = (props) => {
    return (
      <DateRangeSelectorContainer>
        {(dateRange, changeDateRange) => {
          return (
            <Component
              {...props}
              dateRange={dateRange || defaultDateRange}
              changeDateRange={changeDateRange}
            />
          )
        }}
      </DateRangeSelectorContainer>
    )
  }

  C.displayName = `withDateRange(${Component.displayName || Component.name})`

  return hoistNonReactStatic(C, Component)
}

export default withDateRange
