import React from 'react'
import classnames from 'classnames'

interface Props {
  value: number
}

const Pie: React.FC<Props> = (props) => (
  <div className="csspie-wrapper">
    <div
      className={classnames('csspie', {
        big: props.value >= 50,
      })}
      data-start="0"
      data-value={props.value}
    />
    <div
      className={classnames('csspie', {
        big: 100 - props.value > 50,
      })}
      data-start={props.value}
      data-value={100 - props.value}
    />
  </div>
)

export default Pie
