import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../components/PageHeader'
import View from '../../components/View'
import PageActions, { PageAction } from '../../components/PageActions'
import Datepicker from '../../components/Datepicker'
import styled from 'styled-components'
import ResponseViewContainer, { ResponseViewNew } from '../response/ResponseViewNew'
import ResultsListContainer, { ResultsList } from './ResultsList'
import Result from './Result'
import ResultsFilter from './ResultsFilter'
import ResultsExport from './ResultsExport'
import omit from 'lodash/omit'

class Results extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    dateRange: PropTypes.array,
    filterOpen: PropTypes.bool,
    exportOpen: PropTypes.bool,
    filter: PropTypes.object,
    mock: PropTypes.object,
    selectedResponse: PropTypes.string,
    selectResponse: PropTypes.func,
    changeDate: PropTypes.func,
    changeFilter: PropTypes.func,
    activeTab: PropTypes.string,
  }
  render() {
    const {
      className,
      mock,
      dateRange,
      selectedResponse,
      selectResponse,
      activeTab,
      changeDate,
      changeFilter,
    } = this.props
    const results = mock?.results

    const ResultsListComponent = mock?.results ? ResultsList : ResultsListContainer

    const ResponseViewComponent = mock?.selectedResponse ? ResponseViewNew : ResponseViewContainer

    const filter = this.props.filter ? omit(this.props.filter, ['filterAnswers']) : null

    return (
      <View layout="sidebar" title="Results">
        <div className={className}>
          <PageHeader title="Results">
            <PageActions>
              <PageAction toolbar>
                <Datepicker onChange={changeDate} value={dateRange} />
              </PageAction>
              <PageAction toolbar>
                <ResultsFilter filter={filter} onChange={changeFilter} />
              </PageAction>
              <PageAction toolbar>
                <ResultsExport filter={filter} dateRange={dateRange} />
              </PageAction>
            </PageActions>
          </PageHeader>
          {filter && (
            <div className="filter-alert">
              You have {Object.keys(filter).length} filter
              {Object.keys(filter).length > 1 ? 's' : ''} enabled.{' '}
              <a
                href="#"
                onClick={() => {
                  changeFilter({ formData: null })
                }}
              >
                Clear filters.
              </a>
            </div>
          )}
          <div className="row no-gutters flex-nowrap col-container">
            <div className="col-auto results-list-col">
              <ResultsListComponent
                results={results}
                selectedResponse={selectedResponse}
                selectResponse={selectResponse}
                dateRange={dateRange}
                filter={filter}
                scrollParent={this.scrollParentRef}
              />
            </div>
            <div className="col result-view">
              <ResponseViewComponent
                activeTab={activeTab}
                linkPrefix="results"
                selectedResponse={selectedResponse}
                response={mock?.selectedResponse?.response}
                pathway={mock?.selectedResponse?.pathway}
                profile={mock?.selectedResponse?.profile}
                isOnline={mock?.selectedResponse?.isOnline}
              />
            </div>
          </div>
        </div>
      </View>
    )
  }
}

export default styled(Results)`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;

  .col-container {
    overflow: hidden;

    @media print {
      height: auto;
      overflow: visible;
    }
  }

  @media print {
    ${PageHeader} {
      display: none;
    }
  }

  > .row {
    margin: 0;
    flex: 1 1 1rem;
    height: 100%;
  }
  .results-list-col {
    width: 20rem;

    flex: 0 0 auto;
    border-right: 1px solid var(--border-color);
    /* height: 100%; */

    @media print {
      display: none;
    }

    .results-list {
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background: #fff;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      height: 100%;

      .empty {
        img {
          display: block;
          margin: 0 auto 0.5rem auto;
        }
        padding: 1rem;
        border: 0;
        color: var(--text-muted);
        font-size: 0.875rem;
        text-align: center;
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        /* height: 100%; */

        > li {
          border-bottom: 1px solid var(--border-color);
        }
      }
    }

    ${Result} {
      padding-left: 0.95rem;
    }
  }
  .result-view {
    flex: 1 1 1rem;
    /* height: 100%; */
    overflow: hidden;
    /* overflow: auto;
    .content {
      margin-top: -1rem;
    } */
  }

  ${ResponseViewNew} {
    height: 100%;

    @media print {
      height: auto;
    }
  }
`
