import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../components/Panel'
import styled from 'styled-components'
import media from '../../css/_media'
import { Route, Switch } from 'react-router-dom'
import PathwaySettingsGeneral from './settings/PathwaySettingsGeneral'
import PathwaySettingsCodes from './settings/PathwaySettingsCodes'
import PathwaySettingsScoring from './settings/PathwaySettingsScoring'
import Link from '../../components/Link'
import View from '../../components/View'
import PathwaySettingsBundle from './settings/PathwaySettingsBundle'
import PathwaySettingsEmbed from './settings/PathwaySettingsEmbed'

const SideForm = styled.div`
  margin: 1rem 0;
  @media (min-width: ${media.xl}) {
    height: 100%;
    margin: 0 0 0 2rem;
    margin-bottom: 0;
    padding-left: 3rem;
    border-left: 1px solid var(--border-color);
  }
`

const PathwayRoute = ({ component: Component, company, pathway, ...rest }) => (
  <Route
    {...rest}
    render={p => <Component {...p} company={company} pathway={pathway} />}
  />
)

const PathwaySettings = props => {
  return (
    <View title={`Settings - ${props.pathway.name}`}>
      <Panel>
        <div className="row">
          <div className="col-3">
            <ul className="nav nav-pills flex-column">
              <li className="nav-item">
                <Link
                  navLink
                  exact
                  className="nav-link"
                  to={`/pathways/${props.pathway._id}/settings`}
                >
                  General
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  navLink
                  className="nav-link"
                  to={`/pathways/${props.pathway._id}/settings/scoring`}
                >
                  Scoring
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  navLink
                  className="nav-link"
                  to={`/pathways/${props.pathway._id}/settings/codes`}
                >
                  Tracking Codes
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  navLink
                  className="nav-link"
                  to={`/pathways/${props.pathway._id}/settings/embed`}
                >
                  Embed
                </Link>
              </li>
            </ul>
          </div>
          <div className="col overflow-hidden">
            <SideForm>
              <Switch>
                <PathwayRoute
                  path="/pathways/:pathwayId/settings/scoring"
                  exact
                  component={PathwaySettingsScoring}
                  pathway={props.pathway}
                />
                <PathwayRoute
                  path="/pathways/:pathwayId/settings/codes"
                  exact
                  component={PathwaySettingsCodes}
                  pathway={props.pathway}
                />
                <PathwayRoute
                  path="/pathways/:pathwayId/settings/bundle"
                  exact
                  component={PathwaySettingsBundle}
                  pathway={props.pathway}
                  company={props.company}
                />
                <PathwayRoute
                  path="/pathways/:pathwayId/settings/embed"
                  exact
                  component={PathwaySettingsEmbed}
                  pathway={props.pathway}
                />
                <PathwayRoute
                  path="/pathways/:pathwayId/settings"
                  exact
                  component={PathwaySettingsGeneral}
                  pathway={props.pathway}
                  company={props.company}
                />
              </Switch>
            </SideForm>
          </div>
        </div>
      </Panel>
    </View>
  )
}

PathwaySettings.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

export default PathwaySettings
