import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'
import media from '../css/_media'

export const PageAction = (props) => {
  return props.children
}

PageAction.propTypes = {
  divider: PropTypes.bool,
  children: PropTypes.node,
  button: PropTypes.bool,
  toolbar: PropTypes.bool,
  color: PropTypes.string,
  tag: PropTypes.any,
  tagProps: PropTypes.object,
}

PageAction.defaultProps = {
  tag: 'div',
}

class PageActions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: !!props.isOpen,
    }

    this.dropdownRef = React.createRef()
  }

  static propTypes = {
    isOpen: PropTypes.bool,
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  componentDidMount() {
    const originalClick = this.dropdownRef.current.handleDocumentClick
    this.dropdownRef.current.handleDocumentClick = (e) => {
      if (e.path?.find((e) => e.classList && e.classList.contains('modal'))) {
        return
      }
      originalClick.call(this.dropdownRef.current, e)
    }
  }

  render() {
    const children = React.Children.toArray(this.props.children)
    const toolbarChildren = children.filter((c) => c.props.toolbar === true)
    const dropdownChildren = children.filter((c) => c.props.toolbar !== true)
    let dropdown = null
    let toolbar = null

    if (toolbarChildren.length > 0) {
      toolbar = (
        <div className="col-auto toolbar d-none d-md-block">
          <div className="toolbar-actions">
            {toolbarChildren.map((c, index) => {
              const Item = c.props.tag
              const className = c.props.button
                ? c.props.color
                  ? `btn btn-${c.props.color}`
                  : 'btn btn-alt'
                : ''
              return (
                <div key={index} className="toolbar-item">
                  <Item {...c.props.tagProps} className={className}>
                    {c.props.children}
                  </Item>
                </div>
              )
            })}
          </div>
        </div>
      )
    }

    let dropdownClass = 'col-auto menu-col'
    if (dropdownChildren.length > 0) {
      dropdownClass += ' has-menu'
    } else {
      dropdownClass += ' has-no-menu'
    }
    if (toolbarChildren.length > 0) {
      dropdownClass += ' has-toolbar'
    }

    dropdown = (
      <div className={dropdownClass}>
        <Dropdown isOpen={this.state.isOpen} toggle={this.toggle} ref={this.dropdownRef}>
          <DropdownToggle color="alt" className="show-actions">
            <FontAwesomeIcon icon={faEllipsisV} />
          </DropdownToggle>
          <DropdownMenu className="actions fadeIn animated fastest" right persist>
            <div className="d-block d-md-none">
              {toolbarChildren.map((c, index) => {
                const Item = c.props.tag
                return (
                  <Item
                    divider={c.props.divider}
                    key={index}
                    to={c.props.to}
                    tag="div"
                    // toggle={false}
                    className={c.props.button ? 'button dropdown-item' : ''}
                    {...c.props.tagProps}
                  >
                    {c.props.children}
                  </Item>
                )
              })}
            </div>

            {dropdownChildren.map((c, index) => {
              const Item = c.props.tag
              return (
                <Item
                  divider={c.props.divider}
                  key={index}
                  tag="div"
                  to={c.props.to}
                  // toggle={false}
                  className={c.props.button ? 'button dropdown-item' : ''}
                  {...c.props.tagProps}
                >
                  {c.props.children}
                </Item>
              )
            })}
          </DropdownMenu>
        </Dropdown>
      </div>
    )

    return (
      <div className={this.props.className}>
        <div className="row no-gutters">
          {toolbar}
          {dropdown}
        </div>
      </div>
    )
  }
}

PageActions.propTypes = {
  children: PropTypes.node,
}

export default styled(PageActions)`
  font-size: 0.875rem;

  .show-actions {
    border-radius: 100%;
    text-align: center;
    overflow: hidden;
    display: block;
    padding: 0;
    font-size: 0.875rem;
    height: 2.5em;
    width: 2.5em;
    line-height: 2.5em;
    position: relative;

    .svg-inline--fa {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .toolbar {
    .btn {
      font-size: 1em;
      padding: 0.45em 0.95em;
    }

    .svg-inline--fa {
      margin-right: 0.15em;
    }
    .toolbar-actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    .toolbar-item {
      margin-left: 1.5em;
      flex: 1 1 auto;
      align-items: center;
      display: flex;
    }
  }

  .actions {
    font-size: 0.875rem;
    margin-top: 0.5em;
    min-width: 14em;
    max-width: 65vw;
    border-color: #d3d8dc;
    box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.1);
  }

  .dropdown-item {
    padding: 0.4em 1.5em 0.4em 1em;

    &.button {
      color: #07457f;

      cursor: default;
      &:hover {
        color: var(--primary);
      }
      &:active {
        color: #fff;
      }
    }

    .svg-inline--fa {
      text-align: center;
      width: 1.25em;
      color: #9eaebd;
      margin-right: 0.1em;
    }
  }

  .menu-col {
    display: none;
    margin-left: 1.5em;
    &.has-menu,
    &.has-toolbar {
      display: block;
    }

    @media (min-width: ${media.md}) {
      &.has-toolbar.has-no-menu {
        display: none;
      }
    }
  }
`
