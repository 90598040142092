import React from 'react'
import withForm from '../../../../_shared/components/withForm'
import { Button } from 'reactstrap'
import Dropdown from '../../../../_shared/components/Form/Dropdown'
import Field from '../../../../pagebuilder/blocks/Field'
import Input from '../../../../_shared/components/Form/Input'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Company } from '../../../../_shared/models/company/company.client'
import { AppState } from '../../types'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { ClientSubscription } from '../../../../pubsub/src/server/PubSub/client'
import Pathway from '../../../../_shared/models/pathway/pathway.backend.client'
import { FormContextProps } from '../../../../_shared/components/Form'
import ClientProgramGroup from '../../../../_shared/models/programGroup/programGroup.client'
import { shortCache } from '../../api/subscriptionCache'

const comparators = {
  options: ['is', 'is not'],
  string: ['equals', 'does not equal', 'contains', 'does not contain'],
}

const Value = ({ formData, pathway, index, programGroup }) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => (state.subscriptions.companyContext.data.companies[0] as Company) as Company
  )
  const field = formData.answers[index].field
  if (!field) {
    return null
  }
  const fieldId = field.split('.')[1]

  const block = pathway.getBlock(fieldId)
  if (!block) {
    return null
  }
  const hasOptions = block?.getOptions

  if (!hasOptions) {
    return <Input size="sm" name={`answers[${index}].value`} showLabel={false} />
  }

  return (
    <Dropdown
      name={`answers[${index}].value`}
      size="sm"
      showLabel={false}
      placeholder="(Select One)"
    >
      {block
        .getOptions({ company: selectedCompany, pathway, programGroup })
        .map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
    </Dropdown>
  )
}

const Comparator = ({ formData, pathway, index }) => {
  const field = formData.answers[index].field
  if (!field) {
    return null
  }

  const fieldId = field.split('.')[1]

  const hasOptions = pathway.getBlock(fieldId)?.getOptions

  let compOptions = []
  if (hasOptions) {
    compOptions = comparators.options
  } else {
    compOptions = comparators.string
  }

  return (
    <Dropdown
      name={`answers[${index}].comparator`}
      size="sm"
      showLabel={false}
      placeholder="(Select One)"
    >
      {compOptions.map((c) => (
        <option key={c}>{c}</option>
      ))}
    </Dropdown>
  )
}

interface AnswersFilterProps extends Partial<FormContextProps> {
  pathway: Pathway
  className?: string
}

const AnswersFilter: React.FC<AnswersFilterProps> = ({
  pathway,
  formData,
  setFieldValue,
  className,
}) => {
  if (!pathway && formData.pathways?.length !== 1) {
    return null
  }

  if (!formData.filterAnswers) {
    return (
      <div className={className}>
        <Button
          block
          size="sm"
          color="primary"
          type="button"
          onClick={() => {
            setFieldValue('answers', [{}])
            setFieldValue('filterAnswers', true)
          }}
        >
          Filter Answers
        </Button>
      </div>
    )
  }

  const pathwayId = pathway?._id || formData.pathways[0].value

  return (
    <div className={className + ' open'}>
      <SubscriptionLoader
        resource="pathway"
        cache={shortCache}
        query={{
          _id: pathwayId,
        }}
        showLoader={true}
      >
        {({ data, subscription }) => {
          const pathway = data?.pathways[0] as Pathway
          const programGroup =
            data?.program_groups && (data?.program_groups[0] as ClientProgramGroup)

          const fields = []

          pathway.getSteps().forEach((step) => {
            return step.getBlocks().map((block) => {
              if (block instanceof Field) {
                fields.push({
                  step: step._id,
                  id: block.id,
                  label: block.data.config.label,
                })
              }
            })
          })

          return formData.answers.map((aFilter, index) => (
            <div className="row answers-filter align-items-center no-gutters" key={index}>
              <div className="col">
                <Dropdown
                  showLabel={false}
                  size="sm"
                  name={`answers[${index}].field`}
                  placeholder="(Select One)"
                >
                  {fields.map((field) => (
                    <option value={`${field.step}.${field.id}`} key={field.id}>
                      {field.label}
                    </option>
                  ))}
                </Dropdown>
                <div className="row no-gutters">
                  <div className="col-auto comparator">
                    <Comparator formData={formData} index={index} pathway={pathway} />
                  </div>
                  <div className="col">
                    <Value
                      formData={formData}
                      index={index}
                      pathway={pathway}
                      programGroup={programGroup}
                    />
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="remove">
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => {
                      const answers = Array.from(formData.answers)
                      answers.splice(index, 1)
                      setFieldValue('answers', answers)
                    }}
                  />
                </div>
              </div>
            </div>
          ))
        }}
      </SubscriptionLoader>

      <Button
        block
        size="sm"
        color="primary"
        type="button"
        onClick={() => {
          const answers = Array.from(formData.answers)
          answers.push({})
          setFieldValue('answers', answers)
        }}
      >
        <FontAwesomeIcon icon={faPlus} /> Add Answer
      </Button>
      <Button
        block
        size="sm"
        color="alt"
        type="button"
        onClick={() => {
          setFieldValue('answers', undefined)
          setFieldValue('filterAnswers', false)
        }}
      >
        Clear Answers Filter
      </Button>
    </div>
  )
}

export default styled(withForm(AnswersFilter, true))`
  margin: 0 0 1rem 0;

  .is-invalid .invalid-feedback {
    font-size: 0.8rem;
  }

  .form-control.is-invalid {
    padding-right: 2rem;
    background-position: center right calc(1rem / 4);
  }

  &.open {
    background: #f0f2f6;
    padding: 0 1rem 1rem 1rem;
    margin: 0 -1rem 1rem -1rem;
  }

  .form-group {
    margin-bottom: 0.5rem;
  }

  .answers-filter {
    padding: 0.75rem 0.25rem 0.25rem 1rem;
    padding-top: 0;
    margin: 0 -1rem;
    &:first-child {
      padding-top: 0.75rem;
    }
    border-bottom: 1px solid #ced1d8;
    margin-bottom: 0.75rem;
  }

  .comparator {
    margin-right: 0.5rem;
  }

  .remove {
    margin-left: 0.5rem;
    padding: 0.5rem;
    color: #646d82;
    &:hover {
      color: #0b6dc7;
      cursor: pointer;
    }
  }
`
