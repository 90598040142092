import React from 'react'
import PropTypes from 'prop-types'
import PageHeader from '../../../components/PageHeader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Link from '../../../components/Link'
import styled from 'styled-components'
import { faPencil } from '@fortawesome/pro-solid-svg-icons/faPencil'
import RoundButton from '../../../components/RoundButton'
import View from '../../../components/View'
import { PanelList, PanelListItem } from '../../../components/PanelList'
import PathwayManageDropdown from '../../pathway/PathwayManageDropdown'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'

const PathwayC = (props) => (
  <PanelListItem className={props.className}>
    <div className="row align-items-center flex-nowrap">
      <div className="col">
        <Link className="panel-list-item-title" to={`/admin/pathways/${props.pathway._id}`}>
          {props.pathway.name}
        </Link>
        <div className="url">
          <a
            href={`https://${props.pathway.getPrimaryURL()}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            https://
            {props.pathway.getPrimaryURL()}
          </a>
        </div>
      </div>
      <div className="d-none d-md-flex col-auto buttons">
        <RoundButton tag={Link} size="sm" to={`/admin/pathways/${props.pathway._id}/settings`}>
          <FontAwesomeIcon icon={faPencil} />
        </RoundButton>
        <PathwayManageDropdown pathway={props.pathway} />
      </div>
    </div>
  </PanelListItem>
)
PathwayC.propTypes = {
  pathway: PropTypes.object.isRequired,
  className: PropTypes.string,
}

const Pathway = styled(PathwayC)`
  .url a {
    font-size: 0.875rem;
    &,
    &:hover {
      color: var(--text-muted-blue);
    }
  }
`

const PathwayTemplateIndex = (props) => {
  const title = 'Pathway Templates'
  return (
    <View title={title}>
      <PageHeader title={title} />

      <div className="content">
        <SubscriptionLoader
          resource="pathway"
          query={{ isTemplate: true }}
          sort={{ name: 1 }}
          showLoader={true}
        >
          {({ data }) => {
            return (
              <PanelList>
                {data && data.map((pathway) => <Pathway key={pathway._id} pathway={pathway} />)}
              </PanelList>
            )
          }}
        </SubscriptionLoader>
      </div>
    </View>
  )
}

export default PathwayTemplateIndex
