import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import SlidingPanel from '../../components/SlidingPanel'
import ResultsExportSchema from './ResultsFilterSchema'
import format from 'date-fns/format'
import qs from 'qs'
import Dropdown from '../../../../_shared/components/Form/Dropdown'
import Checkbox from '../../../../_shared/components/Form/Checkbox'
import Form from '../../../../_shared/components/Form'
import { connect } from 'react-redux'
import { CREDENTIALS } from '../../../../_shared/api/apiFetch'

const ResultsExportPanel = ({ isOpen, toggle, onExport, filter, startDate, endDate, src }) => (
  <SlidingPanel title="Export" isOpen={isOpen} toggle={toggle}>
    <Form formData={filter || {}} schema={ResultsExportSchema} onValidSubmit={onExport}>
      <div className="alert alert-warning">
        Exporting results between {format(startDate, 'MMMM do yyyy')} and{' '}
        {format(endDate, 'MMMM do yyyy')} with {Object.keys(filter || {}).length} filters.
      </div>

      <Checkbox
        label="Options"
        name="options"
        options={[{ label: 'Include invalid results', value: 'includeInvalid' }]}
      />

      <Dropdown label="Format" name="format">
        <option value="csv">CSV</option>
        <option value="json" disabled>
          JSON
        </option>
      </Dropdown>

      <Button color="primary" type="submit" block>
        Export Results
      </Button>
      <Button color="secondary" type="button" block onClick={toggle}>
        Cancel
      </Button>
    </Form>
  </SlidingPanel>
)
ResultsExportPanel.propTypes = {
  isOpen: PropTypes.bool,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  toggle: PropTypes.func,
}

class ResultsExport extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    filter: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.iframeRef = React.createRef()
  }

  state = {
    isOpen: false,
    src: null,
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  export = ({ formData }) => {
    const { dateRange, toggle, selectedCompany, filter } = this.props
    const params = {
      format: formData.format,
      start: dateRange[0].toISOString(),
      end: dateRange[1].toISOString(),
      query: {
        companyId: selectedCompany._id,
      },
    }

    if (formData.options && formData.options.includes('includeInvalid')) {
      params.includeInvalid = true
    }

    if (filter) {
      params.filter = filter
    }

    const query = qs.stringify(params)

    this.setState(
      {
        src: `${process.env.API_URL_LONG}/exportResults?${query}&_authorization=${CREDENTIALS.token}`,
      },
      () => {
        // wait for it to load then set src back
        if (this.iframeRef.current) {
          // this.waitForLoad(this.iframeRef.current)
        }
      }
    )
  }

  waitForLoad(iframe) {
    // const interval = setInterval(() => {
    //   const doc = iframe.contentDocument || iframe.contentWindow.document
    //   if (doc.readyState === 'complete') {
    //     clearInterval(interval)
    //     this.setState({
    //       src: null,
    //     })
    //   }
    // }, 50)
  }

  render() {
    return (
      <React.Fragment>
        <Button color="alt" onClick={this.toggle}>
          <FontAwesomeIcon icon={faDownload} size="sm" />
        </Button>
        <ResultsExportPanel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          filter={this.props.filter}
          onChange={this.props.onChange}
          startDate={this.props.dateRange[0]}
          endDate={this.props.dateRange[1]}
          onExport={this.export}
        />
        <iframe
          sandbox="allow-downloads allow-forms allow-modals allow-same-origin allow-popups allow-popups-to-escape-sandbox allow-scripts allow-top-navigation-by-user-activation"
          ref={this.iframeRef}
          id="exportResultsIFrame"
          style={{ display: 'none' }}
          src={this.state.src}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0],
})

export default connect(mapStateToProps)(ResultsExport)
