import React from 'react'
import Colorpicker from '../../../_shared/components/Form/Colorpicker'
import SettingsSection from '../SettingsSection'

const TextOptions = ({ path }) => (
  <SettingsSection title="Text">
    <Colorpicker name={`${path}.text.color`} />
  </SettingsSection>
)

export default TextOptions
