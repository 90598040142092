import qs from 'qs'

export function escapeRegExp(string: string): string {
  return String(string).replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export function qsPreParse(search: string): qs.ParsedQs {
  if (!search) {
    return {}
  }

  const keyCount = {}
  search
    .replace(/^\?/, '')
    .split('&')
    .forEach(function (keyValuePair) {
      const pair = keyValuePair.split('=')
      if (pair[0].indexOf('[]') === -1) {
        if (keyCount[pair[0]]) {
          keyCount[pair[0]]++
          // we know there is a duplicate ahead of me in the strigng
          search = search.replace(new RegExp(`${escapeRegExp(pair[0])}=[^&]*&?`), '')
        } else {
          keyCount[pair[0]] = 1
        }
      }
    })

  return qs.parse(search, {
    allowPrototypes: false,
    allowDots: true,
    arrayLimit: 50,
    depth: 3,
    parameterLimit: 100,
    ignoreQueryPrefix: true,
    parseArrays: true,
  })
}
