import React from 'react'
import PropTypes from 'prop-types'
import Panel, { PanelForm } from '../../components/Panel'
import { Redirect } from '../../components/Link'
import PageHeader from '../../components/PageHeader'
import { Button } from 'reactstrap'
import IntegrationAdapters from '../../../../_shared/models/IntegrationAdapters/IntegrationAdapters'
import IntegrationSchema from '../../../../_shared/models/integration/IntegrationSchema'
import View from '../../components/View'
import FormError from '../../../../_shared/components/Form/FormError'
import Form from '../../../../_shared/components/Form'
import Input from '../../../../_shared/components/Form/Input'
import Cardbox, { CardboxItem } from '../../../../_shared/components/Form/Cardbox'
import apiFetch from '../../../../_shared/api/apiFetch'

const { name, type } = IntegrationSchema.properties

const IntegrationFormSchema = {
  type: 'object',
  properties: { name, type },
  required: ['name', 'type'],
}
class IntegrationCreate extends React.Component {
  state = {
    isLoading: false,
    error: null,
    redirect: null,
  }

  static propTypes = {
    company: PropTypes.object,
    pathway: PropTypes.object,
    mock: PropTypes.object,
  }

  onSubmit = (data) => {
    const { company } = this.props
    if (this.state.isLoading) {
      return
    }
    data.formData.companyId = company._id

    this.setState({ isLoading: true }, async () => {
      try {
        const integration = await apiFetch('POST', '/integrations/create', { doc: data.formData })
        this.setState({
          isLoading: false,
          error: null,
          redirect: `/integrations/${integration.integrationId}`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect pathname={this.state.redirect} />
    }
    const title = 'New Integration'
    return (
      <View layout="sidebar" title={title}>
        <PageHeader
          breadcrumbs={[
            {
              label: 'Integrations',
              path: `/integrations`,
            },
          ]}
          title={title}
        />
        <div className="content">
          <Panel>
            <Form formData={{}} schema={IntegrationFormSchema} onValidSubmit={this.onSubmit}>
              <PanelForm center={false}>
                <Input type="text" name="name" />
              </PanelForm>

              <Cardbox name="type" thin onChange={this.changeType}>
                {IntegrationAdapters.getAdapters().map((i) => {
                  return (
                    <CardboxItem
                      key={i.key}
                      value={i.key}
                      title={i.name}
                      description={i.description}
                      image={`/images/${i.image}.png`}
                    />
                  )
                })}
              </Cardbox>

              <hr />
              {this.renderError()}
              <Button color="primary" type="submit" size="lg" disabled={this.state.isLoading}>
                Create Integration
              </Button>
            </Form>
          </Panel>
        </div>
      </View>
    )
  }
}

export default IntegrationCreate
