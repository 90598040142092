import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../../components/Panel'
import Loader from '../../../components/Loader'
import { Pie } from 'react-chartjs-2'
import CssPie from '../../../components/Pie'
import styled from 'styled-components'
import { Table } from 'reactstrap'
import palette from 'google-palette'
import apiFetch from '../../../../../_shared/api/apiFetch'
import Pathway from '../../../../../_shared/models/pathway/pathway.backend.client'
import qs from 'qs'

const data = {
  datasets: [
    {
      // hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    },
  ],
}

class PathwayAnswers extends React.PureComponent {
  state = {
    isLoading: true,
    answers: null,
  }

  static propTypes = {
    dateRange: PropTypes.array.isRequired,
    pathway: PropTypes.object.isRequired,
    filter: PropTypes.object,
  }

  loadAnswers = async () => {
    const { pathway, dateRange, filter } = this.props
    const start = dateRange[0]
    const end = dateRange[1]

    // load user activity
    try {
      const result = await apiFetch(
        'GET',
        '/statistics/pathwayAnswers',
        {},
        { pathwayId: pathway._id, start, end, filter },
        true
      )

      this.setState({
        isLoading: false,
        answers: result,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  componentDidMount() {
    this.loadAnswers()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter || prevProps.dateRange !== this.props.dateRange) {
      this.loadAnswers()
    }
  }

  getChartData(value) {
    if (typeof value === 'undefined') {
      return null
    }

    const labels = []
    const dataSet = []

    value.answers.forEach((a) => {
      labels.push(a.answer)
      dataSet.push(a.count)
    })

    const colors = palette('tol-rainbow', dataSet.length).map(function (hex) {
      return '#' + hex
    })

    return {
      labels,
      datasets: [
        Object.assign({}, data.datasets[0], {
          backgroundColor: colors,
          data: dataSet,
        }),
      ],
    }
  }

  render() {
    const { className, pathway } = this.props
    let { isLoading, answers } = this.state

    answers = answers?.reduce((map, obj) => {
      map[obj._id] = obj
      return map
    }, {})

    if (isLoading) {
      return <Loader />
    }

    return (
      <div className={className}>
        <Panel>
          {pathway.getSteps().map((step) => {
            let hasAnswers
            const output = (
              <div className="answers-step text-center" key={step._id}>
                <h3>{step.name}</h3>

                {step.getBlocks().map((block) => {
                  const blockAnswers = answers[block.id]?.answers
                  if (block.constructor.enableStatistics && blockAnswers) {
                    hasAnswers = true
                    const chartData = this.getChartData(answers[block.id])
                    const colors = chartData.datasets[0].backgroundColor
                    const total = chartData.datasets[0].data.reduce(
                      (value, item) => (value += item),
                      0
                    )
                    return (
                      <div className="answers-block" key={block.id}>
                        <h5>{block.getLabel(null, pathway)}</h5>
                        <div className="chart-container">
                          <Pie
                            data={chartData}
                            // height={170}
                            options={{
                              maintainAspectRatio: false,
                              // responsive: false,
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                            }}
                          />
                        </div>
                        <Table striped className="text-left">
                          <thead>
                            <tr>
                              <th>{block.getLabel(null, pathway)}</th>
                              <th width="100">Selections</th>
                              <th width="100">% of Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {blockAnswers
                              .sort((a, b) => b.count - a.count)
                              .map((answer, index) => {
                                const percentage = Math.round((answer.count / total) * 100)
                                return (
                                  <tr key={index}>
                                    <td>
                                      <div className="row no-gutters align-items-center">
                                        <div className="col-auto">
                                          <span
                                            className="legend"
                                            style={{
                                              backgroundColor: colors[index],
                                            }}
                                          />
                                        </div>
                                        <div className="col">{answer.answer}</div>
                                      </div>
                                    </td>
                                    <td>{answer.count}</td>
                                    <td>
                                      <CssPie value={percentage} />
                                      <span className="count">{percentage}%</span>
                                    </td>
                                  </tr>
                                )
                              })}
                            <tr>
                              <td className="text-right">
                                <strong>Total:</strong>
                              </td>
                              <td colSpan="2">
                                <strong>{total}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    )
                  } else {
                    return null
                  }
                })}
              </div>
            )

            if (hasAnswers) {
              return output
            } else {
              return null
            }
          })}
        </Panel>
      </div>
    )
  }
}

export default styled(PathwayAnswers)`
  .answers-step {
    margin: 0 0 6rem 0;
  }
  .answers-block {
    margin: 0 0 3rem 0;
  }
  table {
    margin: 2rem 0 3rem 0;
    font-size: 0.875rem;
    th {
      font-weight: 600;
    }
  }
  h5 {
    margin-bottom: 1rem;
  }
  .chart-container {
    height: 170px;
    position: relative;
  }
  .legend {
    display: block;
    width: 0.875rem;
    height: 0.875rem;
    margin: 0 0.5rem 0 0;
    border-radius: 100%;
  }
  .count {
    margin: 0 0 0 0.75rem;
  }
  .count,
  .csspie-wrapper {
    display: inline-block;
    vertical-align: middle;
  }
`
