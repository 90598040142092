import React, { SyntheticEvent } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap'
import styled from 'styled-components'
import { subDays, startOfDay, endOfDay, isEqual, addMonths, format } from 'date-fns'
import DayRangePicker from './DayRangePicker'
import { DateUtils } from 'react-day-picker'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import media from '../css/_media'

interface DatepickerOption {
  label: string
  key: string
  dates: () => DatepickerValue
}

export const DefaultOptions: Record<string, DatepickerOption> = {
  TODAY: {
    label: 'Today',
    key: '0d',
    dates(): DatepickerValue {
      return [startOfDay(new Date()), endOfDay(new Date())]
    },
  },
  YESTERDAY: {
    label: 'Yesterday',
    key: '-1d',
    dates(): DatepickerValue {
      const yesterday = subDays(new Date(), 1)
      return [startOfDay(yesterday), endOfDay(yesterday)]
    },
  },
  LAST_7_DAYS: {
    label: 'Last 7 Days',
    key: '-7d',
    dates(): DatepickerValue {
      const start = subDays(new Date(), 7)
      return [startOfDay(start), endOfDay(new Date())]
    },
  },
  LAST_30_DAYS: {
    label: 'Last 30 Days',
    key: '-30d',
    dates(): DatepickerValue {
      const start = subDays(new Date(), 30)
      return [startOfDay(start), endOfDay(new Date())]
    },
  },
  LAST_365_DAYS: {
    label: 'Last 365 Days',
    key: '-365d',
    dates(): DatepickerValue {
      const start = subDays(new Date(), 365)
      return [startOfDay(start), endOfDay(new Date())]
    },
  },
}

const StyledDropdownMenu = styled(DropdownMenu)`
  font-size: 0.875rem;
  padding: 0;
  z-index: 1000;
  > .row {
    flex-wrap: nowrap;
  }
  .datepicker {
    border-right: 1px solid var(--border-color);
  }
  .presets {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0.5em 0;
    .quick-links {
      flex: 1 1 auto;
    }
    .label {
      text-transform: uppercase;
      color: var(--text-muted);
      font-weight: 600;
      padding: 0.25em 1.5em 0.25em 1em;
      font-size: 0.75rem;
    }
    .dropdown-item {
      padding: 0.3em 1.5em 0.3em 1em;
      cursor: pointer;
    }

    .btn {
      font-size: 0.875rem;
      padding: 0.25rem 0.5rem;
    }
  }
  .DayPicker-Months {
    flex-wrap: nowrap;
  }
  .DayPicker {
    font-size: 0.875rem;
  }

  @media (max-width: ${media.lg}) {
    .DayPicker-Month:first-child {
      display: none;
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: var(--primary);
    &:hover {
      background-color: #0959a3;
    }
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    color: var(--primary);
  }
  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    border-radius: 4px !important;
  }
`

const StyledActions = styled.div`
  padding: 0 0.5rem;
`

interface DatepickerQuickLinksProps {
  className?: string
  value: DatepickerValue
  onSelect: (value: DatepickerOption) => void
  options: DatepickerOption[]
}

class DatepickerQuickLinks extends React.Component<DatepickerQuickLinksProps> {
  static defaultProps = {
    options: Object.values(DefaultOptions),
  }
  isActive(option: DatepickerOption) {
    const { value } = this.props
    if (!value) {
      return
    }
    // Determine if the current value is a quick link
    const dates = option.dates()
    return isEqual(dates[0], value[0]) && isEqual(dates[1], value[1])
  }
  render() {
    const { options, onSelect, className } = this.props
    return (
      <div className={className}>
        <div className="label">Quick Links</div>
        {options.map((option) => {
          return (
            <DropdownItem
              key={option.key}
              active={this.isActive(option)}
              onClick={() => {
                onSelect && onSelect(option)
              }}
            >
              {option.label}
            </DropdownItem>
          )
        })}
      </div>
    )
  }
}

export type DatepickerValue = [Date, Date]

interface DatepickerProps {
  isOpen?: boolean
  value?: DatepickerValue
  newValue?: DatepickerValue
  quickLinks?: DatepickerOption[]
  options?: DatepickerOption[]
  onChange?: (value: DatepickerValue, key: string) => void
}

interface DatepickerState {
  isOpen?: boolean
  key?: string
  value?: DatepickerValue
  newValue?: DatepickerValue
  newValueKey?: string
}

export default class Datepicker extends React.Component<DatepickerProps, DatepickerState> {
  constructor(props: DatepickerProps) {
    super(props)

    this.state = {
      isOpen: props.isOpen,
      value: props.value,
      newValue: props.value,
    }
  }

  static defaultProps = {
    isOpen: false,
    quickLinks: Object.values(DefaultOptions),
    value: DefaultOptions.LAST_7_DAYS.dates(),
  }

  toggle = (): void => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }))
  }

  handleDayClick = (day: Date): void => {
    const { newValue: value } = this.state
    let ranges
    if (!value) {
      ranges = { from: undefined, to: undefined }
    } else {
      ranges = { from: value[0], to: value[1] }
    }
    const range = DateUtils.addDayToRange(day, ranges)
    const newValue = [startOfDay(range.from), endOfDay(range.to)] as [Date, Date]
    this.setState({
      newValue,
    })
  }

  selectOption = (option: DatepickerOption): void => {
    this.setState({
      newValue: option.dates(),
      newValueKey: option.key,
    })
  }

  renderLabel(): React.ReactNode {
    const { value } = this.state

    // make a human readable version of the dates
    return format(value[0], 'MMM do yyyy') + ' - ' + format(value[1], 'MMM do yyyy')
  }

  apply = (): void => {
    const { onChange } = this.props
    const { newValue, newValueKey } = this.state
    this.setState(
      {
        value: newValue,
        key: newValueKey,
      },
      () => {
        this.toggle()
        onChange && onChange(newValue, newValueKey)
      }
    )
  }

  cancel = (e: SyntheticEvent): void => {
    const target = e.target as Element
    if (target.className?.includes('dropdown-item')) {
      return
    }
    const { value } = this.state
    this.setState(
      {
        newValue: value,
      },
      () => {
        this.toggle()
      }
    )
  }

  render(): React.ReactNode {
    const { newValue } = this.state
    let modifiers, from, to
    if (newValue) {
      from = newValue[0]
      to = newValue[1]
      modifiers = { start: from, end: to }
    }

    return (
      <Dropdown isOpen={this.state.isOpen} toggle={this.cancel} direction="down">
        <DropdownToggle color="alt" caret>
          <FontAwesomeIcon className="fa-btn-pre" icon={faCalendarAlt} /> {this.renderLabel()}
        </DropdownToggle>
        <StyledDropdownMenu right className="fadeIn animated fastest">
          <div className="row no-gutters">
            <div className="col-auto datepicker">
              <DayRangePicker
                className="Selectable"
                numberOfMonths={2}
                initialMonth={addMonths(new Date(), -1)}
                onDayClick={this.handleDayClick}
                selectedDays={[from, { from, to }]}
                modifiers={modifiers}
              />
            </div>
            <div className="col-auto presets">
              <DatepickerQuickLinks
                className="quick-links"
                onSelect={this.selectOption}
                value={newValue}
                options={this.props.options}
              />
              <DropdownItem divider />
              <StyledActions>
                <Button color="primary" size="sm" block onClick={this.apply}>
                  Apply
                </Button>
                <Button color="secondary" size="sm" block onClick={this.cancel}>
                  Cancel
                </Button>
              </StyledActions>
            </div>
          </div>
        </StyledDropdownMenu>
      </Dropdown>
    )
  }
}
