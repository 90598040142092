import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

class PanelListC extends React.Component {
  render() {
    const { className, children, provided, ...rest } = this.props
    return (
      <div className={className} ref={provided?.innerRef} {...rest}>
        {children}
      </div>
    )
  }
}
PanelListC.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export const PanelList = styled(PanelListC)`
  background: #fff;
  border-radius: 3px;
`

class PanelListItemC extends React.Component {
  render() {
    const { className, children, provided, ...rest } = this.props
    return (
      <div className={className} ref={provided?.innerRef} {...rest}>
        {children}
      </div>
    )
  }
}
PanelListItemC.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
}

export const PanelListItem = styled(PanelListItemC)`
  border-bottom: 1px solid var(--border-color);
  padding: 1rem;
  &:last-child {
    border-bottom: 0;
  }

  .panel-list-item-title {
    font-size: 1rem;
    /* font-weight: 600; */
  }

  .buttons {
    margin: -0.5rem 0;

    .btn {
      margin-right: 0.75rem;
      &:last-child {
        margin-right: 0;
      }
    }

    .dropdown .btn {
      margin-right: 0;
    }
  }
`
