import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from './views/NotFound'
import AdminDashboardIndex from './views/admin/dashboard/AdminDashboardIndex'
import CompanyIndex from './views/admin/company/CompanyIndex'
import CompanyCreate from './views/admin/company/CompanyCreate'
import CompanyUpdate from './views/admin/company/CompanyUpdate'
import SidebarLayout from './components/layouts/SidebarLayout'
import AdminSidebar from './components/AdminSidebar'
// import PathwayBundleIndex from './views/admin/pathwayBundle/PathwayBundleIndex'
// import PathwayBundleCreate from './views/admin/pathwayBundle/PathwayBundleCreate'
// import PathwayBundleUpdate from './views/admin/pathwayBundle/PathwayBundleUpdate'
import PathwayTemplateIndex from './views/admin/pathwayTemplate/PathwayTemplateIndex'
import UserCreate from './views/admin/user/AdminUserCreate'
import UserUpdate from './views/admin/user/AdminUserUpdate'
import UserIndex from './views/admin/user/AdminUserIndex'
import PathwayRoutes from './BackendPathwayRoutes'

const AdminRoutes = (props) => {
  return (
    <SidebarLayout sidebar={AdminSidebar}>
      <Switch>
        <Route path="/admin/" exact component={AdminDashboardIndex} />
        <Route path="/admin/clients" exact component={CompanyIndex} />
        <Route path="/admin/clients/inactive" exact component={CompanyIndex} />
        <Route path="/admin/clients/new" exact component={CompanyCreate} />
        <Route path="/admin/clients/:companyId" exact component={CompanyUpdate} />
        {/* 
          <Route
            path="/admin/pathwayBundles"
            exact
            component={PathwayBundleIndex}
          />
          <Route
            path="/admin/pathwayBundles/new"
            exact
            component={PathwayBundleCreate}
          />
          <Route
            path="/admin/pathwayBundles/:pathwayBundleId"
            exact
            component={PathwayBundleUpdate}
          /> */}

        <Route path="/admin/pathwayTemplates" exact component={PathwayTemplateIndex} />

        <Route
          path="/admin/pathways/:pathwayId/:tab?"
          exact
          render={(props) => <PathwayRoutes prefix="/admin" {...props} />}
        />

        <Route path="/admin/users/new" exact component={UserCreate} />
        <Route path="/admin/users/:userId" exact component={UserUpdate} />
        <Route path="/admin/users" exact component={UserIndex} />

        <Route component={NotFound} />
      </Switch>
    </SidebarLayout>
  )
}

export default AdminRoutes
