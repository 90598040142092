import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  ButtonGroup,
} from 'reactstrap'
import Link from '../components/Link'

class StepSelector extends React.Component {
  state = {
    dropdownOpen: false,
  }

  static propTypes = {
    className: PropTypes.string,
    pathway: PropTypes.object.isRequired,
    step: PropTypes.object.isRequired,
  }

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  stepLink = step => {
    if (!step) {
      return ''
    }
    return `/pathways/${this.props.pathway._id}/steps/${step._id}`
  }

  render() {
    const { className, pathway, step } = this.props

    const nextStep = pathway.getNextStep(step)
    const prevStep = pathway.getPrevStep(step)

    return (
      <div
        className={classnames(className, 'row align-items-stretch no-gutters')}
      >
        <div className="col-auto">
          <ButtonGroup>
            <Button
              color="alt"
              disabled={!prevStep}
              tag={Link}
              to={this.stepLink(prevStep)}
            >
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth />
            </Button>
            <Button
              color="alt"
              disabled={!nextStep}
              tag={Link}
              to={this.stepLink(nextStep)}
            >
              <FontAwesomeIcon icon={faChevronRight} fixedWidth />
            </Button>
          </ButtonGroup>
        </div>
        <div className="col">
          <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle color="alt" caret>
              Editing <span className="step-name">{step.name}</span>
            </DropdownToggle>
            <DropdownMenu>
              {pathway.getSteps().map(s => (
                <DropdownItem
                  key={s._id}
                  disabled={s._id === step._id}
                  tag={Link}
                  to={this.stepLink(s)}
                >
                  {s.name}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        </div>
      </div>
    )
  }
}

export default styled(StepSelector)`
  .dropdown-toggle {
    color: var(--text-muted-blue);
    max-width: 18rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .step-name {
    font-weight: 600;
    color: #395e8a;
  }
  .dropdown-menu {
    max-height: 40vh;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    font-size: 0.875rem;
    z-index: 2147483600;
    max-width: 100%;
    min-width: 200px;
    a {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
    }
  }
  .btn-group {
    margin-right: -1px;
  }
  .btn-group > .btn:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  a[disabled] {
    color: var(--text-muted-blue);
  }
`
