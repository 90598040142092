import React from 'react'
import styled from 'styled-components'
import { PersonaColors } from '../PersonaColors';


interface PersonaStackedBarChartBarProps {
  value: number
  label: string
  color: string
  className?: string
}

const PersonaStackedBarChartBar: React.FC<PersonaStackedBarChartBarProps> = ({
  value,
  label,
  color,
  className,
}) => {
  return (
    <li className={className} style={{ flexBasis: `${value}%` }}>
      <div className="stacked-bar-chart-entry">
        <div className="stacked-bar-chart-bar" style={{ backgroundColor: color }}></div>
        <div className="stacked-bar-chart-label">{label}</div>
        <div className="stacked-bar-chart-value">{value}%</div>
      </div>
    </li>
  )
}

const StyledPersonaStackedBarChartBar = styled(PersonaStackedBarChartBar)`
  .stacked-bar-chart-value {
    &:before {
      background: ${(props) => props.color};
    }
  }
`

interface PersonaStackedBarChartProps {
  className?: string
  expressive: number
  amiable: number
  analytical: number
  driver: number
}

const PersonaStackedBarChart: React.FC<PersonaStackedBarChartProps> = ({
  className,
  expressive,
  amiable,
  analytical,
  driver,
}) => {
  return (
    <div className={className}>
      <ul className="stacked-bar-chart">
        <StyledPersonaStackedBarChartBar label="Expressive" value={expressive} color={PersonaColors.expressive} />
        <StyledPersonaStackedBarChartBar label="Amiable" value={amiable} color={PersonaColors.amiable} />
        <StyledPersonaStackedBarChartBar label="Analytical" value={analytical} color={PersonaColors.analytical} />
        <StyledPersonaStackedBarChartBar label="Driver" value={driver} color={PersonaColors.driver} />
      </ul>
    </div>
  )
}

export default styled(PersonaStackedBarChart)`
  .stacked-bar-chart {
    list-style: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
    margin: 1.5rem 0 1rem 0;
    > li {
      flex: 1 1 auto;
      padding: 0;
      margin: 0 1rem 0 0;
      &:last-child {
        margin-right: 0;
      }
    }

    .stacked-bar-chart-entry {
    }

    .stacked-bar-chart-bar {
      height: 30px;
      border-radius: 3px;
      margin: 0 0 3px 0;
    }

    .stacked-bar-chart-label {
      color: #9e9e9e;
      font-size: 0.875rem;
      font-weight: 500;
      margin: 0 0 3px 0;
    }

    .stacked-bar-chart-value {
      color: #2d2d2d;
      font-size: 1rem;
      font-weight: bold;
      vertical-align: middle;
      display: flex;
      align-items: center;
      white-space: nowrap;

      &:before {
        border-radius: 3px;
        width: 8px;
        height: 8px;
        margin: 0 0.35em 0 0;
        content: '';
        display: inline-block;
      }
    }
  }
`
