import React from 'react'
import PropTypes from 'prop-types'
import { Sidebar, SidebarSection, SidebarLink } from '../components/Sidebar'
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome'
import { faLandmark } from '@fortawesome/pro-solid-svg-icons/faLandmark'
import { faUsersCog } from '@fortawesome/pro-solid-svg-icons/faUsersCog'

const AdminSidebar = (props) => (
  <Sidebar {...props}>
    <SidebarSection title="Admin">
      <SidebarLink icon={faHome} route="/admin" exact>
        Dashboard
      </SidebarLink>
    </SidebarSection>
    <SidebarSection title="Manage">
      {/* <SidebarLink icon={faLayerGroup} route="/admin/pathwayBundles">
        Bundles
      </SidebarLink> */}
      <SidebarLink icon={faLandmark} route="/admin/clients">
        Clients
      </SidebarLink>
      <SidebarLink icon={faUsersCog} route="/admin/users">
        Users
      </SidebarLink>
    </SidebarSection>
  </Sidebar>
)

AdminSidebar.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default AdminSidebar
