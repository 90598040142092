// eslint-disable-next-line @typescript-eslint/naming-convention
import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line @typescript-eslint/naming-convention
import PersonaStackedBarChart from '../../../views/statistics/personas/components/PersonaStackedBarChart'
const StyledTargetAudiencePreview = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  background-color: #f5f5f5;
  margin-bottom: 0.5rem;
  
  h5 {
    margin-bottom: 0.5rem;
  }
  hr {
    margin: 1rem -1rem;
    border: 4px solid #fff;
  }
`
function TargetAudiencePreview({ persona }: { persona: any }) {
  if (!persona.targetAudience) return null
  return (
    <StyledTargetAudiencePreview>
      <h5>Target Audience</h5>
      <hr />
      {persona.socialStyles ? (
        <>
          <h6>Social Styles</h6>
          <PersonaStackedBarChart
            analytical={Math.floor((persona.socialStyles.analytical / persona.sampleSize) * 100)}
            amiable={Math.floor((persona.socialStyles.amiable / persona.sampleSize) * 100)}
            driver={Math.floor((persona.socialStyles.driver / persona.sampleSize) * 100)}
            expressive={Math.floor((persona.socialStyles.expressive / persona.sampleSize) * 100)}
          />
          <hr />
        </>
      ) : null}
      {persona.targetAudience.demographics ? (
        <>
          <h6>Demographics</h6>
          <p>{persona.targetAudience.demographics}</p>
          <hr />
        </>
      ) : null}
      {persona.targetAudience.psychographics ? (
        <>
          <h6>Psychographics</h6>
          <p>{persona.targetAudience.psychographics}</p>
          <hr />
        </>
      ) : null}
      {persona.targetAudience.challenges ? (
        <>
          <h6>Challenges</h6>
          <p>{persona.targetAudience.challenges}</p>
          <hr />
        </>
      ) : null}
      {persona.targetAudience.goals ? (
        <>
          <h6>Goals</h6>
          <p>{persona.targetAudience.goals}</p>
        </>
      ) : null}
    </StyledTargetAudiencePreview>
  )
}

export default TargetAudiencePreview
