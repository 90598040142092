import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import GoalForm from './GoalForm'
import NotFound from '../NotFound'
import { withAlert } from 'react-alert'
import { redirect } from '../../components/Link'
import apiFetch from '../../../../_shared/api/apiFetch'

class GoalUpdate extends React.Component {
  state = {
    error: false,
    isLoading: false,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    goal: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  }

  onSubmit = (data) => {
    const { pathway, goal, history } = this.props
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/goals/update', {
          pathwayId: pathway._id,
          goalId: goal._id,
          goal: data.formData,
        })

        this.props.alert.show('Goal saved')
        const { location, history } = this.props

        redirect({
          location,
          history,
          pathname: `/pathways/${pathway._id}/goals`,
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  render() {
    const { pathway, goal } = this.props

    return (
      <>
        <PageHeader
          breadcrumbs={[{ label: pathway.name, path: `/pathways/${pathway._id}/goals` }]}
          title={goal.name}
        />
        <div className="content">
          <Panel>
            <GoalForm
              pathway={pathway}
              onSubmit={this.onSubmit}
              buttonLabel="Save Changes"
              error={this.state.error}
              goal={goal}
              isLoading={this.state.isLoading}
            />
          </Panel>
        </div>
      </>
    )
  }
}

GoalUpdate.propTypes = {
  pathway: PropTypes.object.isRequired,
  mock: PropTypes.object,
}

const GoalUpdateWithAlert = withAlert()(GoalUpdate)

const GoalLoader = (props) => {
  const { pathway, match } = props

  const goal = pathway.goals?.find((g) => g._id === match.params.goalId)
  if (!goal) {
    return <NotFound />
  }

  return <GoalUpdateWithAlert {...props} goal={goal} />
}

export default GoalLoader
