import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Line } from 'react-chartjs-2'
import isSameDay from 'date-fns/isSameDay'
import isBefore from 'date-fns/isBefore'
import addDays from 'date-fns/addDays'
import format from 'date-fns/format'
import differenceInDays from 'date-fns/differenceInDays'
import palette from 'google-palette'
import Stat from './Stat'

const data = {
  datasetTemplate: {
    lineTension: 0.25,
    backgroundColor: 'rgba(11,109,199,0)',
    borderColor: '#0b6dc7',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#0b6dc7',
    pointHoverRadius: 5,
    pointHoverBackgroundColor: '#0b6dc7',
    pointRadius: 0,
    pointHitRadius: 10,
    data: [65, 59, 80, 81, 56, 55, 40],
    fill: false,
  },
  datasets: [
    {
      label: 'Registrations',
      lineTension: 0.25,
      backgroundColor: 'rgba(11,109,199,0.05)',
      borderColor: '#0b6dc7',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBackgroundColor: '#0b6dc7',
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#0b6dc7',
      pointRadius: 0,
      pointHitRadius: 10,
      data: [65, 59, 80, 81, 56, 55, 40],
      fill: 'origin',
    },
    {
      label: 'Completions',
      lineTension: 0.25,
      backgroundColor: 'rgba(22,142,50,0.7)',
      borderColor: '#168e32',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      pointBorderColor: '#168e32',
      pointBackgroundColor: '#168e32',
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#168e32',
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: [30, 20, 40, 45, 36, 22, 14],
      fill: 'origin',
    },
  ],
}

function userActivityToChart(userActivityData, dateRange, pathway) {
  const [start, end] = dateRange
  const dataMap = userActivityData.reduce((map, obj) => {
    map[obj._id] = obj
    return map
  }, {})

  const diff = differenceInDays(end, start)
  let dateFormat = 'MMM d yyyy'
  if (diff < 365) {
    dateFormat = 'MMM d'
  }

  let day = start
  const dates = []
  const registrationsLine = []
  const completionsLine = []
  const goalLines = {}

  const goals =
    pathway.goals?.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    }) || []

  const colors = palette('tol-rainbow', goals.length || 0).map(function (hex) {
    return '#' + hex
  })

  while (isBefore(day, end) || isSameDay(day, end)) {
    dates.push(format(day, dateFormat))
    const dayFormatted = format(day, 'yyyy-MM-dd')
    const dayData = dataMap[dayFormatted]
    registrationsLine.push(dayData?.registrations || 0)
    completionsLine.push(dayData?.completions || 0)
    if (goals) {
      goals.forEach((goal) => {
        if (!goalLines[goal._id]) {
          goalLines[goal._id] = []
        }
        goalLines[goal._id].push(dayData ? dayData[goal._id] || 0 : 0)
      })
    }
    day = addDays(day, 1)
  }

  return {
    dates,
    datasets: [
      Object.assign(data.datasets[0], { data: registrationsLine }),
      Object.assign(data.datasets[1], { data: completionsLine }),
      ...goals.map((goal, index) =>
        Object.assign({}, data.datasetTemplate, {
          data: goalLines[goal._id],
          label: goal.name,
          borderColor: colors[index],
          pointBackgroundColor: colors[index],
          pointHoverBackgroundColor: colors[index],
        })
      ),
    ],
  }
}

const UserActivity = (props) => {
  const { className, userActivity, dateRange, pathway } = props

  let completionRate = Math.round((userActivity.completions / userActivity.registrations) * 100)
  if (isNaN(completionRate)) {
    completionRate = 0
  }

  const chartData = userActivityToChart(userActivity.data, dateRange, pathway)

  return (
    <div className={className}>
      <div className="row quick-stats">
        <div className="col">
          <Stat
            value={userActivity.visitors || 0}
            label="Unique Visitors"
            align="center"
            labelPosition="bottom"
          />
        </div>
        <div className="col">
          <Stat
            value={userActivity.registrations || 0}
            label="Registrations"
            align="center"
            labelPosition="bottom"
          />
        </div>
        <div className="col">
          <Stat
            value={userActivity.registrationRate || '0%'}
            label="Registration Rate"
            // valueSuffix="%"
            align="center"
            labelPosition="bottom"
          />
        </div>
      </div>

      <div className="row quick-stats">
        <div className="col">
          <Stat
            value={userActivity.averageLength || 0}
            label="Average Length (minutes)"
            align="center"
            labelPosition="bottom"
          />
        </div>
        <div className="col">
          <Stat
            value={userActivity.completions || 0}
            label="Completions"
            align="center"
            labelPosition="bottom"
          />
        </div>
        <div className="col">
          <Stat
            value={userActivity.completionRate || '0%'}
            label="Completion Rate"
            // valueSuffix="%"
            align="center"
            labelPosition="bottom"
          />
        </div>
      </div>
      <div className="chart-container">
        <Line
          data={{
            labels: chartData.dates,
            datasets: chartData.datasets,
          }}
          redraw={false}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            hover: {
              mode: 'index',
              intersect: false,
            },
            scales: {
              y: {
                ticks: {
                  beginAtZero: true,
                },
              },
            },
            plugins: {
              tooltip: {
                mode: 'index',
                intersect: false,
              },
              legend: {
                position: 'bottom',
                labels: {
                  padding: 20,
                  usePointStyle: true,
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}
UserActivity.propTypes = {
  className: PropTypes.string,
  userActivity: PropTypes.object,
}

export default styled(UserActivity)`
  margin: 0 0 3rem 0;
  font-size: 0.875rem;
  .chart-container {
    height: 300px;
    position: relative;
  }
`
