import React from 'react'
import PropTypes from 'prop-types'
import BasicModal from '../BasicModal'
import { faSync } from '@fortawesome/pro-solid-svg-icons/faSync'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'reactstrap'
import apiFetch from '../../../../_shared/api/apiFetch'
import styled from 'styled-components'

class RetryNotificationModal extends React.PureComponent {
  static propTypes = {
    notificationId: PropTypes.string.isRequired,
    responseId: PropTypes.string.isRequired
  }

  callMethod = async (toggle) => {
    const { notificationId, responseId } = this.props
    try {
      await apiFetch('POST', '/notifications/retry', {
        notificationId,
        responseId,
      })
      toggle()
    } catch (e) {
      window.alert(e)
    }
  }

  render() {
    return (
      <div style={{display: 'inline-block'}}>
        <BasicModal
          title="Retry Notification"
          message={
            <div>
              Are you sure you want to retry this notification?
            </div>
          }
          confirmLabel="Retry"
          confirmColor="outline-danger"
          cancelLabel="Cancel"
          confirm={this.callMethod}
          buttonTag={this.props.buttonTag}
          buttonProps={this.props.buttonProps}
        >
          <Button
            color="alt"
            size="sm"
          >
            <FontAwesomeIcon icon={faSync} size="xs" transform="up-2" /> Retry
          </Button>
        </BasicModal>
      </div>
    )
  }
}

export default RetryNotificationModal
