import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import Panel, { PanelForm } from '../../components/Panel'
import get from 'lodash/get'
import { withAlert } from 'react-alert'
import Link from '../../components/Link'
import CompanySchema from '../../../../_shared/models/company/CompanySchema'
import Dropdown from '../../../../_shared/components/Form/Dropdown'
import withForm from '../../../../_shared/components/withForm'
import FormError from '../../../../_shared/components/Form/FormError'
import Form from '../../../../_shared/components/Form'
import apiFetch from '../../../../_shared/api/apiFetch'
import GoogleButton from 'react-google-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'

const { name, type, bundles, website, phone, notes } = CompanySchema.properties

const CompanySettingsSchema = {
  type: 'object',
  properties: { name, type, bundles, website, phone, notes },
  required: ['name'],
}

class GoogleAnalyticsSettings extends React.Component {
  state = {
    accounts: [],
    properties: [],
    views: [],
    ga4: false,
    error: null,
  }

  componentDidMount() {
    this.getAccounts()

    const account = get(this.props.formData, 'googleAnalytics.config.account')
    if (account?.indexOf('accounts/') === 0) {
      this.setState({ ga4: true })
    }
    const property = get(this.props.formData, 'googleAnalytics.config.property')
    const view = get(this.props.formData, 'googleAnalytics.config.view')
    if (account && property) {
      this.getProperties()
    }
    if (account && property && view) {
      this.getViews()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { formData, setFieldValue } = this.props

    const prevAccount = get(prevProps.formData, 'googleAnalytics.config.account')
    const prevProperty = get(prevProps.formData, 'googleAnalytics.config.property')
    const account = get(formData, 'googleAnalytics.config.account')
    const property = get(formData, 'googleAnalytics.config.property')

    console.log({ account, prevAccount })

    if (account && account !== prevAccount) {
      setFieldValue('googleAnalytics.config.property', undefined)
      setFieldValue('googleAnalytics.config.view', undefined)
      this.getProperties()
    } else if (property && property !== prevProperty) {
      setFieldValue('googleAnalytics.config.view', undefined)
      this.getViews()
    }
  }

  getAccounts = async () => {
    const { company } = this.props
    try {
      const result = await apiFetch(
        'GET',
        '/google/analyticsAccounts',
        {},
        {
          companyId: company._id,
        },
        true
      )
      this.setState({
        accounts: result,
      })
    } catch (e) {
      this.setState({ error: String(e.message) })
      return
    }
  }

  getProperties = () => {
    const { company, formData } = this.props
    const account = get(formData, 'googleAnalytics.config.account')

    if (account.indexOf('accounts/') === 0) {
      this.setState({ ga4: true })
    } else {
      this.setState({ ga4: false })
    }

    this.setState(
      {
        properties: [],
        views: [],
      },
      async () => {
        const data = {
          companyId: company._id,
          accountId: account,
        }

        // Send ga4 signal
        if (account.indexOf('accounts/') === 0) {
          data['ga4'] = true
        }

        try {
          const result = await apiFetch('GET', '/google/analyticsProperties', {}, data, true)
          this.setState({
            properties: result,
          })
        } catch (e) {
          this.setState({ error: String(e.message) })
          return
        }
      }
    )
  }

  getViews = () => {
    const { company, formData } = this.props
    const account = get(formData, 'googleAnalytics.config.account')
    const property = get(formData, 'googleAnalytics.config.property')

    this.setState(
      {
        views: [],
      },
      async () => {
        if (property.indexOf('properties/') === 0) {
          return
        }
        try {
          const result = await apiFetch(
            'GET',
            '/google/analyticsViews',
            {},
            {
              companyId: company._id,
              accountId: account,
              propertyId: property,
            },
            true
          )
          this.setState({
            views: result,
          })
        } catch (e) {
          this.setState({ error: String(e.message) })
          return
        }
      }
    )
  }

  render() {
    const { formData } = this.props
    const { accounts, properties, views, ga4, error } = this.state

    // const accountSelected = !!get(formData, 'googleAnalytics.config.account', false)
    // const propertySelected = !!get(formData, 'googleAnalytics.config.property', false)

    return (
      <React.Fragment>
        {error && <div className="alert alert-danger">{error}</div>}
        <div>
          <Dropdown name="googleAnalytics.config.account" label="Account">
            {accounts?.map((account) => (
              <option key={account._id} value={account._id}>
                {account.name}
              </option>
            ))}
          </Dropdown>
          <Dropdown name="googleAnalytics.config.property" label="Property">
            {properties?.map((property) => (
              <option key={property._id} value={property._id}>
                {property.name}
              </option>
            ))}
          </Dropdown>
          {!ga4 && (
            <Dropdown name="googleAnalytics.config.view" label="View">
              {views?.map((view) => (
                <option key={view._id} value={view._id}>
                  {view.name}
                </option>
              ))}
            </Dropdown>
          )}
        </div>
      </React.Fragment>
    )
  }
}

const GoogleAnalyticsSettingsWithForm = withForm(GoogleAnalyticsSettings, true)

class CompanySettings extends React.Component {
  static defaultProps = {
    bundles: [],
  }
  state = {
    isLoading: false,
    error: null,
  }

  getFormData() {
    const { company, formData } = this.props
    if (formData) {
      return formData
    }

    if (company) {
      return {
        _id: company._id,
        name: company.name,
        googleAnalytics: company.googleAnalytics,
      }
    }

    return {}
  }

  unlinkGoogleAccount = async () => {
    const { company } = this.props
    if (window.confirm('Are you sure you want to unlink your Google Analytics account?')) {
      try {
        await apiFetch('POST', '/google/unlinkAccount', { companyId: company._id }, {}, true)
      } catch (e) {
        console.error(e)
      }
    }
  }

  linkGoogleAccount = async () => {
    const { company } = this.props
    try {
      const result = await apiFetch('GET', '/google/authUrl', {}, {}, true)
      let win,
        width = 500,
        height = 560,
        top = screen.height / 2 - height / 2,
        left = screen.width / 2 - width / 2,
        url = result

      if (!url) {
        return
      }

      url += '&state=' + company._id
      console.warn(url)

      win = window.open(
        url,
        'Analytics',
        'width=' +
          width +
          ',height=' +
          height +
          ',top=' +
          top +
          ',left=' +
          left +
          ',scrollbars=1,resizable=1,toolbar=0,menubar=0,location=0,status=0'
      )

      if (win) {
        win.focus()
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  onSubmit = (form) => {
    const { company, alert } = this.props

    this.setState(
      {
        isLoading: true,
      },
      async () => {
        try {
          await apiFetch('POST', '/companies/updateClient', {
            companyId: company._id,
            doc: { googleAnalytics: form.formData.googleAnalytics },
          })
          this.setState({
            isLoading: false,
          })
          alert.show('Saved changes')
        } catch (e) {
          this.setState({
            error: e,
          })
        }
      }
    )
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  linkCalendlyAccount = async () => {
    const { company } = this.props
    try {
      const result = await apiFetch('GET', '/calendly/authUrl', {}, {}, true)
      let win,
        width = 500,
        height = 600,
        top = screen.height / 2 - height / 2,
        left = screen.width / 2 - width / 2,
        url = result

      if (!url) {
        return
      }

      url += '?companyId=' + company._id
      console.warn(url)

      win = window.open(
        url,
        'Calendly',
        'width=' +
          width +
          ',height=' +
          height +
          ',top=' +
          top +
          ',left=' +
          left +
          ',scrollbars=1,resizable=1,toolbar=0,menubar=0,location=0,status=0'
      )

      if (win) {
        win.focus()
      }
    } catch (e) {
      throw new Error(e)
    }
  }

  unlinkCalendlyAccount = async () => {
    const { company } = this.props
    if (window.confirm('Are you sure you want to unlink your Calendly account?')) {
      try {
        await apiFetch('POST', '/calendly/unlinkAccount', { companyId: company._id }, {}, true)
      } catch (e) {
        console.error(e)
      }
    }
  }

  render() {
    const { buttonLabel, company } = this.props
    const { isLoading } = this.state

    return (
      <>
        <Panel>
          <Form
            formData={this.getFormData()}
            schema={CompanySettingsSchema}
            onValidSubmit={this.onSubmit}
          >
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <img
                  src="/images/logo-googleanalytics.svg"
                  width="200"
                  alt="Google Analytics"
                  style={{ marginBottom: '2rem' }}
                />

                {company.googleAnalytics && (
                  <div>
                    <p>
                      Your Google Analytics account ties into our{' '}
                      <Link to="/statistics">Statistics</Link> system.{' '}
                      <strong>
                        Please choose the account, property and view of your website.{' '}
                      </strong>
                      You can disable this functionality by{' '}
                      <a href="#" onClick={this.unlinkGoogleAccount}>
                        unlinking your account
                      </a>
                      .
                    </p>
                    <GoogleAnalyticsSettingsWithForm
                      company={company}
                      style={{ marginBottom: '2rem' }}
                    />

                    {this.renderError()}
                    <Button color="primary" type="submit" disabled={isLoading}>
                      {buttonLabel || 'Save Changes'}
                    </Button>
                  </div>
                )}

                {!company.googleAnalytics && (
                  <div>
                    <p>Link your Google Analytics account to enable Website statistics.</p>
                    <GoogleButton type="dark" onClick={this.linkGoogleAccount} />
                  </div>
                )}
              </div>
            </div>
          </Form>
        </Panel>
        <Panel>
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <img
                src="/images/logo-calendly.svg"
                width="200"
                alt="Calendly"
                style={{ marginBottom: '2rem' }}
              />

              {company.calendly && (
                <>
                  <p>
                    ✅ Your Calendly account is linked.{' '}
                    <a href="#" onClick={this.unlinkCalendlyAccount}>
                      Unlink your account
                    </a>
                    .
                  </p>
                </>
              )}

              {!company.calendly && (
                <>
                  <p>
                    Link your Calendly account to get details on booked appointments in Virtual
                    Adviser.
                  </p>
                  <Button color="primary" onClick={this.linkCalendlyAccount}>
                    <FontAwesomeIcon icon={faPlus} /> Link Account
                  </Button>
                </>
              )}
            </div>
          </div>
        </Panel>
      </>
    )
  }
}

CompanySettings.propTypes = {
  company: PropTypes.object,
  user: PropTypes.object,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
  bundles: PropTypes.array,
}

export default withAlert()(CompanySettings)
