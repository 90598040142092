import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { PanelForm } from '../../../components/Panel'
import { withAlert } from 'react-alert'
import AdditionalDomains from './AdditionalDomains'
import PathwaySchema from '../../../../../_shared/models/pathway/schema/PathwaySchema'
import Dropdown from '../../../../../_shared/components/Form/Dropdown'
import Number from '../../../../../_shared/components/Form/Number'
import withForm from '../../../../../_shared/components/withForm'
import FormError from '../../../../../_shared/components/Form/FormError'
import Form from '../../../../../_shared/components/Form'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import Cardbox, { CardboxItem } from '../../../../../_shared/components/Form/Cardbox'
import Input from '../../../../../_shared/components/Form/Input'
import { sort } from 'fast-sort'
import apiFetch from '../../../../../_shared/api/apiFetch'
import { shortCache } from '../../../api/subscriptionCache'
import { INACTIVITY_TIMER_DEFAULT } from '../../../../../frontend/client/types'

const {
  name,
  gaMeasurementId,
  googleAnalyticsUA,
  programGroupId,
  campaignId,
  type,
  sessionLengthMinutes,
  inactivityTimeoutSeconds,
} = PathwaySchema.properties

const PathwayFormSchema = {
  title: 'Pathway Form',
  type: 'object',
  properties: {
    name,
    programGroupId,
    campaignId,
    gaMeasurementId,
    googleAnalyticsUA,
    type,
    sessionLengthMinutes,
    inactivityTimeoutSeconds,
    urls: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          prefix: {
            type: 'string',
          },
          suffix: {
            type: 'string',
          },
          isDefault: {
            type: 'boolean',
          },
        },
        required: ['prefix', 'suffix'],
      },
    },
  },
  required: ['name', 'urls', 'campaignId'],
}

const PathwayTypeControl = ({ formData }) => {
  if (formData.campaignId === 'admissions') {
    return (
      <Dropdown name="type" label="Type" placeholder="--">
        <option value="appointment">Appointment Setting</option>
        <option value="interview">Interview</option>
      </Dropdown>
    )
  } else if (formData.campaignId === 'website') {
    return (
      <Dropdown name="type" label="Type">
        <option value="normal">Normal</option>
        <option value="chatbot">Chatbot</option>
      </Dropdown>
    )
  }

  return null
}

const PathwayTypeControlWithForm = withForm(PathwayTypeControl, true)

class PathwaySettingsGeneral extends React.Component {
  state = {
    isLoading: false,
    error: null,
    validURL: true,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    company: PropTypes.object.isRequired,
    alert: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    if (!data.formData.cookieConsentBanner) {
      data.formData.cookieConsentBanner = false
    }

    if (!data.formData.excludeBilling) {
      data.formData.excludeBilling = false
    }

    this.setState({ isLoading: true, error: false }, async () => {
      try {
        await apiFetch('POST', '/pathways/update', {
          pathwayId: this.props.pathway._id,
          doc: data.formData,
        })
        this.props.alert.show('Pathway saved')
        this.setState({ isLoading: false })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway, company } = this.props
    const {
      name,
      gaMeasurementId,
      googleAnalyticsUA,
      programGroupId,
      campaignId,
      type,
      sessionLengthMinutes,
      inactivityTimeoutSeconds,
      cookieConsentBanner,
      excludeBilling,
    } = pathway
    const formData = {
      name,
      gaMeasurementId,
      googleAnalyticsUA,
      programGroupId,
      campaignId,
      type,
      sessionLengthMinutes,
      inactivityTimeoutSeconds,
      cookieConsentBanner,
      excludeBilling,
      urls: pathway.urls.map((url) => {
        const suffixes = [process.env.DOMAIN_SUFFIX, ...(company.domains || [])]
        let prefix
        let suffix

        suffixes.forEach((s) => {
          if (url.value.includes(s)) {
            prefix = url.value.replace(s, '').replace(/\.$/, '')
            suffix = s
          }
        })

        return {
          prefix,
          suffix,
          isDefault: url.type === 'main',
        }
      }),
    }

    return (
      <Form formData={formData} schema={PathwayFormSchema} onValidSubmit={this.onSubmit}>
        <SubscriptionLoader
          resource="campaigns"
          query={{ companyId: company._id }}
          sort={{ name: 1 }}
          cache={shortCache}
          showLoader={true}
          transform={(store) => ({
            ...store,
            campaigns: sort(store.campaigns).asc((r) => r.name),
          })}
        >
          {({ data: campaignsSubData }) => (
            <SubscriptionLoader
              resource="programGroups"
              query={{ companyId: company._id }}
              sort={{ name: 1 }}
              cache={shortCache}
              showLoader={true}
              transform={(store) => ({
                ...store,
                programGroups: sort(store.program_groups).asc((r) => r.name),
              })}
            >
              {({ data: programGroupsSubData }) => (
                <React.Fragment>
                  <PanelForm center={false}>
                    <Input type="text" name="name" />
                    <Dropdown name="campaignId" label="Campaign">
                      <option value="website">Website</option>
                      <option value="admissions">Admissions</option>
                      <option value="landingpages">Landing Pages</option>
                      <option value="retention">Retention</option>
                      <option value="other">Other</option>
                      <option value="archive">Archive</option>
                      {campaignsSubData?.campaigns?.map((campaign) => (
                        <option key={campaign._id} value={campaign._id}>
                          {campaign.name}
                        </option>
                      ))}
                    </Dropdown>
                    <PathwayTypeControlWithForm />
                    <Dropdown name="programGroupId" label="Program Group">
                      <option value={false}>None</option>
                      {programGroupsSubData?.programGroups?.map((group) => (
                        <option key={group._id} value={group._id}>
                          {group.name}
                        </option>
                      ))}
                    </Dropdown>
                  </PanelForm>
                  <AdditionalDomains name="urls" company={company} />
                  <PanelForm center={false}>
                    <Input
                      type="text"
                      name="gaMeasurementId"
                      label="Google Analytics Measurement ID"
                      help="e.g. G-XXXXXXXXXX"
                    />
                    <Input
                      type="text"
                      name="googleAnalyticsUA"
                      label="Google Analytics Tracking Code"
                      help="e.g. UA-XXXXXX-XX"
                    />
                    <Cardbox name="excludeBilling" thin showLabel={false} vertical>
                      <CardboxItem
                        value={true}
                        title="Exclude from billing"
                        description="Pathways results will not be shown in billing report"
                      />
                    </Cardbox>
                    <Cardbox name="cookieConsentBanner" thin showLabel={false} vertical>
                      <CardboxItem
                        value={true}
                        title="Enable cookie consent banner"
                        description="Shows a cookie consent banner at the top of the first step"
                      />
                    </Cardbox>
                  </PanelForm>
                  <Number
                    label="Session Length (Minutes)"
                    min="10"
                    max="86400"
                    name="sessionLengthMinutes"
                    placeholder="1440"
                    help="Determines how long a user will stay logged in after starting the pathway. Default value is 1440 (2 days)."
                  />
                  <Number
                    label="Inactivity Timeout (Seconds)"
                    min={60}
                    max={60 * 60 * 24}
                    name="inactivityTimeoutSeconds"
                    placeholder={INACTIVITY_TIMER_DEFAULT}
                    help="When a user is idle it determines how long to wait before abandoning the pathway. Default value is 60 (1 minute)."
                  />
                  <hr />
                  {this.renderError()}
                  <Button color="primary" type="submit" disabled={this.state.isLoading}>
                    Save Changes
                  </Button>
                </React.Fragment>
              )}
            </SubscriptionLoader>
          )}
        </SubscriptionLoader>
      </Form>
    )
  }
}

export default withAlert()(PathwaySettingsGeneral)
