import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledRoundButton = styled.button`
  border-radius: 100%;
  height: 2.5em;
  line-height: 2.4em;
  width: 2.5em;
  padding: 0;

  font-size: ${(props) => {
    switch (props.size) {
      case 'sm':
        return '0.875rem'
      default:
        return '1rem'
    }
  }};
`

const RoundButton = (props) => {
  const className = 'btn btn-alt ' + props.className || ''

  const { tag, innerRef, ...restProps } = props

  // const Tag = props.tag ? StyledRoundButton.withComponent(props.tag) : StyledRoundButton

  return (
    <StyledRoundButton as={props.tag || null} {...restProps} ref={innerRef} className={className}>
      {props.children}
    </StyledRoundButton>
  )
}

RoundButton.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string,
  to: PropTypes.string,
  tag: PropTypes.any,
}

export default RoundButton
