import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-solid-svg-icons/faCopy'
import PathwaySelect from '../PathwaySelect'
import { redirect } from '../Link'
import { withRouter } from 'react-router-dom'
import Form from '../../../../_shared/components/Form'
import apiFetch from '../../../../_shared/api/apiFetch'

class ReplaceRegStepModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      redirect: null,
      fromPathway: null,
    }
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }

  toggle = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        if (this.state.modal === false) {
          document.body.click()
        }
      }
    )
  }

  chooseFromPathway = (fromPathway) => {
    this.setState({
      fromPathway: fromPathway ? fromPathway.value : null,
    })
  }

  replaceRegStep = async () => {
    const { pathway, location, history } = this.props
    if (!this.state.fromPathway) return

    try {
      await apiFetch('POST', '/pathways/replaceRegStep', {
        toPathwayId: pathway._id,
        fromPathwayId: this.state.fromPathway,
      })
      redirect({
        location,
        history,
        pathname: '/pathways/' + pathway._id,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const { pathway, tag, location, history, staticContext, match, buttonProps, ...rest } =
      this.props

    const HandleTag = tag || 'div'

    return (
      <div>
        <HandleTag {...buttonProps} onClick={this.toggle}>
          <FontAwesomeIcon fixedWidth icon={faCopy} /> Replace Registration Page
        </HandleTag>
        <Modal centered isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Replace Registration Page</ModalHeader>
          <ModalBody>
            The first step of the selected pathway will be replaced on {pathway.name}
            <Form>
              <PathwaySelect
                name="pathways"
                defaultOptions={true}
                filter={{ companyId: 'enrollmentresources' }}
                isMulti={false}
                onChange={this.chooseFromPathway}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="danger" onClick={this.replaceRegStep} disabled={!this.state.fromPathway}>
              Replace Registration Page
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default withRouter(ReplaceRegStepModal)
