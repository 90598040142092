import React from 'react'
import WidthOptions from './Width/WidthOptions'
import HeightOptions from './Height/HeightOptions'
import AlignmentOptions from './Alignment/AlignmentOptions'
import BackgroundOptions from './Background/BackgroundOptions'
import MarginOptions from './Margin/MarginOptions'
import PaddingOptions from './Padding/PaddingOptions'
import TextOptions from './Text/TextOptions'
import VisibilityOptions from './Visibility/VisibilityOptions'

const SectionAppearanceSettings = (props) => (
  <React.Fragment>
    <TextOptions path="appearance" />
    <BackgroundOptions path="appearance" />
    <WidthOptions path="appearance" />
    <HeightOptions path="appearance" />
    <AlignmentOptions path="appearance" />
    <MarginOptions path="appearance" />
    <PaddingOptions path="appearance" />
    <VisibilityOptions path="visibility" />
  </React.Fragment>
)

export default SectionAppearanceSettings
