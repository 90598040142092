import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-solid-svg-icons/faFilter'
import SlidingPanel from '../../components/SlidingPanel'
import PathwaySelect from '../../components/PathwaySelect'
import CampusSelect from '../../components/CampusSelect'
import ProgramSelect from '../../components/ProgramSelect'
import ResultsFilterSchema from './ResultsFilterSchema'
import AnswersFilter from './AnswersFilter'
import Form, { FormContext } from '../../../../_shared/components/Form'
import ButtonGroup, { ButtonGroupButton } from '../../../../_shared/components/Form/ButtonGroup'
import Slider from '../../../../_shared/components/Form/Slider'
import Input from '../../../../_shared/components/Form/Input'
import { useSelector } from 'react-redux'

const ResultsFilterPanel = (props) => {
  const selectedCompany = useSelector(
    (state) => state.subscriptions.companyContext.data.companies[0]
  )
  return (
    <SlidingPanel title="Filter" isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        formData={props.filter || {}}
        schema={ResultsFilterSchema}
        onValidSubmit={props.onChange}
        validateOnChange={true}
      >
        <PathwaySelect
          name="pathways"
          defaultOptions={true}
          filter={{ companyId: selectedCompany._id }}
          isMulti={true}
        />
        <AnswersFilter requirePathway selectedCompany={selectedCompany} />

        <CampusSelect name="campuses" defaultOptions={true} companyId={selectedCompany._id} />
        <ProgramSelect name="programs" defaultOptions={true} companyId={selectedCompany._id} />
        <ButtonGroup name="progress" defaultValue="any" size="sm">
          <ButtonGroupButton label="Any" value="any" />
          <ButtonGroupButton label="In Progress" value="inprogress" />
          <ButtonGroupButton label="Completed" value="completed" />
        </ButtonGroup>

        <Slider name="score" min={0} max={100} step={1} range={true} tooltip />

        <Input type="text" name="lead_src" size="sm" />
        <Input type="text" name="referrer" size="sm" />

        <ButtonGroup name="status" defaultValue="any" size="sm">
          <ButtonGroupButton label="Any" value="any" />
          <ButtonGroupButton label="Valid" value="valid" />
          <ButtonGroupButton label="Invalid" value="invalid" />
        </ButtonGroup>

        <ButtonGroup name="notifications" defaultValue="any" size="sm">
          <ButtonGroupButton label="Any" value="any" />
          <ButtonGroupButton label="Delivered" value="delivered" />
          <ButtonGroupButton label="Failed" value="failed" />
        </ButtonGroup>
        <hr />
        <FormContext.Consumer>
          {(formContext) => {
            return (
              <Button
                color="primary"
                type="submit"
                block
                onClick={props.toggle}
                disabled={Object.values(formContext.errors).length > 0}
              >
                Apply Filters
              </Button>
            )
          }}
        </FormContext.Consumer>
      </Form>
    </SlidingPanel>
  )
}

ResultsFilterPanel.propTypes = {
  isOpen: PropTypes.bool,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  toggle: PropTypes.func,
}

export default class ResultsFilter extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    filter: PropTypes.object,
  }

  state = {
    isOpen: false,
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    })
  }

  render() {
    return (
      <React.Fragment>
        <Button color="alt" onClick={this.toggle}>
          <FontAwesomeIcon icon={faFilter} size="sm" /> Filter
        </Button>
        <ResultsFilterPanel
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          filter={this.props.filter}
          onChange={this.props.onChange}
        />
      </React.Fragment>
    )
  }
}
