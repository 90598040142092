import React from 'react'
import DayPicker, { DayPickerProps } from 'react-day-picker'
import styled from 'styled-components'

const StyledDayPicker = styled(DayPicker)`
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }
  .DayPicker-Day {
    border-radius: 0 !important;
  }
  .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
`

interface Props extends DayPickerProps {
  className?: string
}

export default class DayRangePicker extends React.Component<Props> {
  static defaultProps = {
    numberOfMonths: 1,
  }
  render(): React.ReactNode {
    const { selectedDays } = this.props
    let modifiers
    if (selectedDays) {
      const { from, to } = selectedDays[1]
      modifiers = { start: from, end: to }
    }
    return (
      <StyledDayPicker
        className="Selectable"
        selectedDays={selectedDays}
        modifiers={modifiers}
        {...this.props}
      />
    )
  }
}
