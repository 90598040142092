import React from 'react'
import { withRouter, Link, RouteComponentProps, Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import View from '../components/View'
import SimpleLayout from '../components/layouts/SimpleLayout'
import trim from 'voca/trim'
import { User } from '../../../_shared/models/user/user.client'
import { Action, compose } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../types'
import FormError from '../../../_shared/components/Form/FormError'
import Form from '../../../_shared/components/Form'
import Input from '../../../_shared/components/Form/Input'
import apiFetch, { CREDENTIALS } from '../../../_shared/api/apiFetch'
import { ThunkDispatch } from 'redux-thunk'
import { initSocket } from '../api/socketio'
import Cookies from 'js-cookie'

declare global {
  interface Window {
    _previousLocation: string
  }
}

const LoginFormSchema = {
  type: 'object',
  properties: {
    email: {
      title: 'Email Address',
      type: 'string',
    },
    password: {
      type: 'string',
    },
  },
  required: ['email', 'password'],
}

interface LoginState {
  error: string
  isLoading: boolean
}

interface LoginProps extends RouteComponentProps {
  user?: User
  dispatch: ThunkDispatch<AppState, unknown, Action<string>>
}

class Login extends React.Component<LoginProps, LoginState> {
  state = {
    error: null,
    isLoading: false,
  }

  onSubmit = (data) => {
    let { email, password } = data.formData
    email = trim(email)
    password = trim(password)

    this.setState({ isLoading: true, error: null }, async () => {
      try {
        const result = await apiFetch('POST', '/login', {
          username: email,
          password,
        })

        this.setState(
          {
            isLoading: false,
          },
          async () => {
            if (result.token) {
              CREDENTIALS.token = result.token
              Cookies.set('authToken', result.token, {
                expires: 30,
                secure: process.env.NODE_ENV === 'production',
                sameSite: 'strict',
                domain: window.location.hostname,
              })
            }
            await initSocket(this.props.dispatch)
          }
        )
      } catch (e) {
        this.setState({
          isLoading: false,
          error: e.message,
        })
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { user } = this.props
    const { isLoading } = this.state

    if (user) {
      return <Redirect to={window._previousLocation || '/'} />
    }

    return (
      <View title="Login">
        <SimpleLayout>
          <Form schema={LoginFormSchema} onValidSubmit={this.onSubmit} warnOnUnsavedLeave={false}>
            <img src="/logo-white.svg" className="logo" width="60%" />
            <Input type="text" name="email" />
            <Input type="password" name="password" />

            <div className="links text-right">
              <Link to="/forgotPassword">Forgot password?</Link>
            </div>

            {this.renderError()}
            <Button color="primary" type="submit" block disabled={isLoading}>
              Login
            </Button>
          </Form>
        </SimpleLayout>
      </View>
    )
  }
}

const mapStateToProps = (state: AppState) => ({
  user: state.user,
})

export default compose(withRouter, connect(mapStateToProps))(Login)
