import React from 'react'
import styled from 'styled-components'
import Loader from '../../components/Loader'
import LeadsByTimeContainer from '../../components/Dashboard/LeadsByTime'
import SpendContainer from '../../components/Dashboard/SpendContainer'
import Stat from '../../components/Dashboard/Stat'
import Breakdown from '../../components/Dashboard/Breakdown'
import Campaigns from '../../components/Dashboard/Campaigns'
import DividerTitle from '../../components/Dashboard/DividerTitle'
import { Container, Row, Col } from 'reactstrap'
import { parse, format, startOfMonth } from 'date-fns'
import Panel from '../../components/Panel'
import KbLatest from '../../components/Dashboard/KbLatest'
import PromotedBundles from '../../components/PromotedBundles'
import { Company } from '../../../../_shared/models/company/company.client'
import apiFetch from '../../../../_shared/api/apiFetch'

const DashboardRow = styled(Row)`
  margin-bottom: 2.2rem;
`

const ReportsList = styled.ul`
  list-style: none;
  padding-left: 0;
  li {
    padding-bottom: 0.3rem;
  }
`

const ThinCol = styled(Col)`
  padding-left: 0;
  padding-right: 0;
`

interface Props {
  company: Company
}

interface State {
  isLoading: boolean
  error: string
  data: any
}

// function fetchy(path: string, params: RESTEndpoint['params']) {
//   console.log(path, params)
// }

// interface RESTEndpoint {
//   path: string
//   params?: Record<string, any>
// }
// interface TrackingCodesDelete {
//   pathwayId: string
//   codeId: string
// }

// const eAPI = {
//   resource: {
//     async action(params: TrackingCodesDelete) {
//       return fetchy('/resource/action', params)
//     },
//   },
// }

// eAPI.resource.action({
//   codeId: '1234',
//   pathwayId: '1234',
// })

class ReporterDashboard extends React.Component<Props, State> {
  state = {
    isLoading: true,
    error: null,
    data: null,
  }

  loadData(): void {
    this.setState(
      {
        isLoading: true,
        error: null,
        data: null,
      },
      async () => {
        const { company } = this.props

        try {
          const result = await apiFetch('GET', '/reporter', {}, { companyId: company._id })
          this.setState({
            isLoading: false,
            data: result,
          })
        } catch (e) {
          this.setState({
            isLoading: false,
            error: e,
          })
        }
      }
    )
  }

  componentDidMount(): void {
    this.loadData()
  }

  render(): React.ReactNode {
    const { isLoading, error, data } = this.state

    if (error) {
      throw new Error(error)
    }

    if (isLoading) {
      return <Loader />
    }

    return (
      <React.Fragment>
        <Row>
          <ThinCol xs="12" xl="10">
            <Container fluid={true}>
              <Panel>
                <h3>
                  {format(startOfMonth(new Date()), 'MMMM do')} to{' '}
                  {format(new Date(), 'MMMM do yyyy')}
                </h3>
                <Row>
                  <Col xs="12" sm="12" md="6">
                    <Stat label="Total Unique Leads" large={true} value={data.results.totalLeads} />
                  </Col>
                  <Col xs="12" sm="12" md="6">
                    <Stat
                      label="Total Lead Actions"
                      large={true}
                      value={data.results.leads.totals.uniqueLeads}
                    />
                  </Col>
                </Row>
                {!data.ppcOnly && (
                  <div>
                    <Row>
                      <DividerTitle>Web</DividerTitle>
                    </Row>
                    <DashboardRow>
                      <Col xs="12" sm="3">
                        <Stat
                          label="Total Unique Web & VAA Leads"
                          value={data.results.leads.totals.uniqueWeb.total}
                        />
                      </Col>
                      <Col xs="12" sm="3">
                        <Breakdown label="Web Breakdown" type="web" data={data.results} />
                      </Col>
                      <Col xs="12" sm="3">
                        <Stat label="Unique Visitors" value={data.results.nonPaidSearchVisitors} />
                      </Col>
                      <Col xs="12" sm="3">
                        <Stat
                          label="Web Conversion Rate"
                          value={data.results.webConversionRate}
                          postfix="%"
                        />
                      </Col>
                    </DashboardRow>
                  </div>
                )}
                {data.type !== 'webedu' && (
                  <div>
                    <Row>
                      <DividerTitle>PPC</DividerTitle>
                    </Row>
                    <DashboardRow>
                      <Col xs="12" sm="3">
                        <Stat
                          label="Total Unique PPC Leads"
                          value={data.results.leads.totals.uniquePPC.total}
                        />
                      </Col>
                      <Col xs="12" sm="3">
                        <Breakdown label="PPC Breakdown" type="ppc" data={data.results} />
                      </Col>
                      <Col xs="12" sm="3">
                        <Stat
                          label="PPC CPL"
                          prefix="$"
                          value={Math.round(data.results.paidSearchCPL * 100) / 100}
                        />
                      </Col>
                      <Col xs="12" sm="3">
                        <Stat
                          label="PPC Conversion Rate"
                          value={data.results.ppcConversionRate}
                          postfix="%"
                        />
                      </Col>
                    </DashboardRow>
                  </div>
                )}
                <DashboardRow>
                  <Col>
                    <LeadsByTimeContainer data={data} />
                  </Col>
                </DashboardRow>
                {data.type !== 'webedu' && (
                  <DashboardRow>
                    <Col>
                      <SpendContainer data={data} />
                    </Col>
                  </DashboardRow>
                )}
                <Row>
                  <DividerTitle>Campaigns</DividerTitle>
                </Row>
                <DashboardRow>
                  <Col>
                    <Campaigns data={data} />
                  </Col>
                </DashboardRow>
              </Panel>
            </Container>
          </ThinCol>
          <ThinCol xs="12" xl="2">
            <Panel thin={true}>
              <h3>Reports</h3>
              <ReportsList>
                {data.reportsList.map((report, i) => (
                  <li key={i}>
                    <a
                      href={`https://dashboard.enrollmentresources.com/clients/${data.clientId}/monthly/${report.date}`}
                    >
                      {format(parse(report.date, 'yyyy-MM', new Date()), 'MMMM yyyy')}
                    </a>
                  </li>
                ))}
              </ReportsList>
            </Panel>
          </ThinCol>
        </Row>
        <Row>
          <Col>
            <Panel>
              <h3>Latest Knowledgebase Updates</h3>
              <KbLatest />
            </Panel>
          </Col>
          <Col>
            <PromotedBundles company={this.props.company} />
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

export default ReporterDashboard
