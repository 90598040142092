import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import PageHeader from '../../components/PageHeader'
import View from '../../components/View'
import InfiniteScroll from 'react-infinite-scroller'
import Loader from '../../components/Loader'
import ResponseShortMeta from '../response/ResponseShortMeta'
import ProfilePic from '../../components/ProfilePic'
import Link from '../../components/Link'
import { PanelList, PanelListItem } from '../../components/PanelList'
import SubscriptionLoader from '../../containers/SubscriptionLoader'
import { sort } from 'fast-sort'
import { shortCache } from '../../api/subscriptionCache'

const Empty = (
  <div className="text-muted" style={{ fontStyle: 'italic' }}>
    No people found.
  </div>
)

const Person = (props) => {
  const { className, profile, lastVisit } = props
  return (
    <PanelListItem>
      <Link to={`/people/${profile._id}`} className={className}>
        <div className="row align-items-center">
          <div className="col-6">
            <div className="row flex-nowrap align-items-center">
              <div className="col-auto pic">
                <ProfilePic profile={profile} isOnline={profile.isOnline} />
              </div>
              <div className="col" style={{ overflow: 'hidden' }}>
                <div className="name">{profile.getFullName()}</div>
                <div className="email">{profile.email}</div>
              </div>
            </div>
          </div>
          <div className="col-6">
            {lastVisit && (
              <React.Fragment>
                <div className="pathway-name">{lastVisit?.pathway?.name}</div>
                <ResponseShortMeta
                  hideLeadStatus
                  response={lastVisit?.response}
                  isOnline={profile.isOnline}
                />
              </React.Fragment>
            )}
          </div>
        </div>
      </Link>
    </PanelListItem>
  )
}
Person.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired,
  lastVisit: PropTypes.object,
}

const StyledPerson = styled(Person)`
  display: block;
  margin: -1rem;
  padding: 1rem;
  font-size: 0.875rem;
  background: #fff;
  border-radius: 3px;
  color: var(--text-muted-blue) !important;
  transition: box-shadow 0.15s ease-out;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    .name {
      color: ${(props) => (props.profile.isOnline ? '#1aa73b' : '#118dff')};
    }
    box-shadow: 0 0 0 1px
      ${(props) => (props.profile.isOnline ? 'var(--success)' : 'var(--primary)')};
  }

  .name {
    font-weight: 600;
    color: ${(props) => (props.profile.isOnline ? 'var(--success)' : 'var(--primary)')};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .pic {
    padding-right: 0;
    position: relative;
    img {
      border-radius: 100%;
      width: 42px;
      margin-right: 0;
      height: 42px;
    }
  }

  .email {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .pathway-name {
    font-weight: 600;
    color: ${(props) => (props.profile.isOnline ? 'var(--success)' : '#526578')};
    font-size: 0.875rem;
  }

  ${ResponseShortMeta} {
    .lead-status {
      display: none;
    }
    margin: 0;
  }
`

class PeopleIndex extends React.PureComponent {
  static defaultProps = {
    limit: 20,
    perPage: 20,
  }

  state = {
    hasMore: false,
    limit: 20,
    firstLoad: true,
    currentPage: 0,
    skip: 0,
  }

  loadMore = () => {
    const newLimit = this.state.limit + this.props.perPage
    this.setState({
      limit: newLimit,
      currentPage: this.state.currentPage + 1,
      hasMore: false,
    })
  }

  setCount = (store) => {
    const hasMore = store?.people?.length >= this.state.limit
    this.setState({
      hasMore,
    })
  }

  render() {
    const title = 'People'
    return (
      <View layout="sidebar" title={title}>
        <PageHeader title={title} />
        <div className="content">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={this.state.hasMore}
            // initialLoad={true}
            useWindow={false}
          >
            <SubscriptionLoader
              resource="people"
              cache={shortCache}
              query={{ companyId: this.props.company._id }}
              sort={{ createdAt: -1 }}
              showLoader={true}
              showLoaderInline={true}
              limit={this.state.limit}
              setCount={this.setCount}
              transform={(store) => ({
                ...store,
                people: sort(store.people).desc((r) => r.createdAt),
              })}
            >
              {({ data, subscription }) => {
                const { people, pathways, responses } = data
                if (subscription.ready && (!people || people.length === 0)) {
                  return Empty
                }

                return (
                  <React.Fragment>
                    <div
                      className="row legend"
                      style={{
                        marginBottom: '0.5rem',
                        fontWeight: 600,
                        fontSize: '0.875rem',
                        color: '#4f657a',
                      }}
                    >
                      <div className="col">Person</div>
                      <div className="col">Last Visit</div>
                    </div>

                    <PanelList>
                      {people?.map((person) => {
                        const response = responses?.find((r) => r.personId === person._id)
                        return (
                          <StyledPerson
                            key={person._id}
                            profile={person}
                            lastVisit={
                              response
                                ? {
                                    response,
                                    pathway: pathways?.find((p) => p._id === response.pathwayId),
                                  }
                                : null
                            }
                          />
                        )
                      })}
                    </PanelList>
                  </React.Fragment>
                )
              }}
            </SubscriptionLoader>
          </InfiniteScroll>
        </div>
      </View>
    )
  }
}

export default PeopleIndex
