import React from 'react'
import styled from 'styled-components'
import Datepicker, { DatepickerValue, DefaultOptions } from '../../../components/Datepicker'
import PageHeader from '../../../components/PageHeader'
import View from '../../../components/View'
import PageActions, { PageAction } from '../../../components/PageActions'
import withDateRange from '../../../containers/DateRangeSelectorContainer'
import CampaignStatisticsOverview from './CampaignStatisticsOverview'
import { DateOptions } from '../StatisticsIndex'
import { Company } from '../../../../../_shared/models/company/company.client'
import { GlobalCampaigns } from '../../../../../_shared/models/campaign/campaign.client'
import SubscriptionLoader from '../../../containers/SubscriptionLoader'
import { ClientSubscription } from '../../../../../pubsub/src/server/PubSub/client'
import { RouteComponentProps } from 'react-router'
import { useSelector } from 'react-redux'
import { AppState } from '../../../types'
import { shortCache } from '../../../api/subscriptionCache'

interface Props {
  className?: string
  dateRange: DatepickerValue
  changeDateRange: () => void
  campaign: any
  selectedCompany: Company
}

const CampaignStatisticsIndex: React.FC<Props> = ({
  className,
  campaign,
  dateRange,
  changeDateRange,
  selectedCompany,
}) => (
  <View title={`${campaign.name} Statistics`}>
    <PageHeader
      title={`${campaign.name}`}
      breadcrumbs={[{ label: 'Statistics', path: '/statistics' }]}
      // navItems={[
      //   {
      //     label: 'Overview',
      //     route: `/statistics/campaign/${campaign._id}`,
      //     exact: true,
      //   },
      //   {
      //     label: 'Traffic Sources',
      //     route: `/statistics/campaign/${campaign._id}/trafficSources`,
      //     exact: true,
      //   },
      // ]}
    >
      <PageActions>
        <PageAction toolbar>
          <Datepicker onChange={changeDateRange} value={dateRange} options={DateOptions} />
        </PageAction>
      </PageActions>
    </PageHeader>
    <div className="content">
      <div className={className}>
        <CampaignStatisticsOverview
          companyId={selectedCompany._id}
          campaign={campaign}
          dateRange={dateRange}
        />

        {/*               
        <Switch>
          <Route
            path="/statistics/campaign/:campaignId"
            exact
            render={(p) => (
              <CampaignStatisticsOverview
                {...p}
                companyId={selectedCompany._id}
                campaign={campaign}
                dateRange={dateRange}
              />
            )}
          />
          <Route
            path="/statistics/campaign/:campaignId/trafficSources"
            exact
            render={(p) => (
              <CampaignTrafficSources
                {...p}
                companyId={selectedCompany._id}
                campaign={campaign}
                dateRange={dateRange}
              />
            )}
          />
        </Switch> */}
      </div>
    </div>
  </View>
)

const StyledCampaignStatisticsIndex = styled(CampaignStatisticsIndex)`
  .intro {
    h2 {
      margin-bottom: 0;
    }
    .meta {
      color: var(--text-muted);
      font-size: 0.875rem;
      font-style: italic;
    }
    margin-bottom: 1rem;
  }
  table {
    font-size: 0.875rem;

    th {
      border-top: 0;
      font-weight: 600;
    }
    td {
      vertical-align: middle;
    }
  }

  .sparkline-table {
    .chart-container {
      width: 220px;
    }
  }

  .csspie-wrapper {
    margin-left: -0.25em;
    margin-right: 0.5rem;
    position: relative;
    top: 1px;
  }
  .csspie-wrapper,
  .completion-rate {
    display: inline-block;
    vertical-align: middle;
  }

  .pathway-activity-legend {
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    width: 0.625rem;
    height: 0.625rem;
    margin-right: 0.5rem;
    position: relative;
    top: -1px;
    background: var(--gray);
  }
`

interface RouteParams {
  campaignId: string
}

interface DataLoaderProps extends RouteComponentProps<RouteParams> {
  dateRange: DatepickerValue
  changeDateRange: () => void
}

const DataLoader: React.FC<DataLoaderProps> = (props) => {
  if (!props.match) {
    return null
  }

  const selectedCompany = useSelector<AppState, Company>(
    (state) => (state.subscriptions.companyContext.data.companies[0] as Company) as Company
  )

  const campaignId = props.match.params.campaignId
  if (GlobalCampaigns[campaignId]) {
    return (
      <StyledCampaignStatisticsIndex
        {...props}
        selectedCompany={selectedCompany}
        campaign={GlobalCampaigns[campaignId]}
      />
    )
  } else {
    return (
      <SubscriptionLoader
        resource="campaign"
        cache={shortCache}
        query={{
          _id: props.match.params.campaignId,
        }}
        showLoader={true}
      >
        {(subscription: ClientSubscription) => (
          <StyledCampaignStatisticsIndex
            {...props}
            selectedCompany={selectedCompany}
            campaign={subscription.store.data}
          />
        )}
      </SubscriptionLoader>
    )
  }
}

export default withDateRange(DataLoader, DefaultOptions.LAST_30_DAYS.dates())
