import React from 'react'
import PropTypes from 'prop-types'
import Panel from '../../components/Panel'
import PageHeader from '../../components/PageHeader'
import styled from 'styled-components'
import media from '../../css/_media'
import Link from '../../components/Link'
import View from '../../components/View'
import BundledPathwaysLoader from '../../containers/BundledPathwaysLoader'

const Template = styled(Link)`
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  display: block;
  &,
  &:hover,
  &:active,
  &:focus {
    color: var(--text-muted);
    text-decoration: none;
  }
  &:hover {
    border-color: #0f6dc7;
    color: var(--text-muted-blue);
    h6 {
      color: var(--primary);
    }
  }
  margin: 0 0 1.5rem 0;
  font-size: 0.875rem;

  transition: all 0.15s ease-out;

  @media (min-width: ${media.md}) {
    margin-right: 1.5rem;
    &:nth-child(even) {
      margin-right: 0;
    }
    width: 48%;
    display: inline-block;
    vertical-align: top;
  }

  h6 {
    color: #304963;
    font-weight: 600;
    margin-bottom: 0.2rem;
    transition: all 0.15s ease-out;
  }

  p {
    margin: 0;
    transition: all 0.15s ease-out;
  }

  .available {
    color: var(--success);
    font-weight: 600;
  }

  &.disabled {
    cursor: not-allowed;
    h6 {
      color: var(--text-muted);
    }
    color: #aaa;
  }
`

const PathwayTemplate = (props) => {
  return (
    <Template
      onClick={props.onClick}
      className={(props.active ? 'active ' : '') + (props.available ? ' ' : 'disabled')}
      to={`/pathways/new/${props._id}`}
    >
      <h6>{props.name}</h6>
      <p>
        {props.shortDescription ||
          'Maecenas dictum, mauris at sollicitudin sodales, enim nulla iaculis sem, sed egestas nunc mauris in massa. Vestibulum et hendrerit purus, eget porttitor dui.'}
      </p>
      {props.available === false ? (
        <a href="javascript:void(0)" className="available">
          Upgrade your account to use these pathways
        </a>
      ) : null}
    </Template>
  )
}

PathwayTemplate.propTypes = {
  _id: PropTypes.string,
  name: PropTypes.string,
  shortDescription: PropTypes.string,
  available: PropTypes.bool,
  onClick: PropTypes.func,
  active: PropTypes.bool,
}

const CreatePathwayChooseTemplate = (props) => {
  const { className } = props

  const title = 'Create Pathway'
  return (
    <View layout="sidebar" title={title}>
      <PageHeader title={title} breadcrumbs={[{ label: 'Pathways', path: '/pathways' }]} />
      <div className={`content ${className}`}>
        <Panel>
          <div className="row align-items-center no-gutters" style={{ marginBottom: '1.5rem' }}>
            <div className="col-auto">
              <img src="/images/ribbon-cog.png" width="90" />
            </div>
            <div className="col" style={{ paddingLeft: '1rem' }}>
              <h3>Choose a template</h3>
              <div className="text-muted">Select from our best-practice pathways</div>
            </div>
          </div>
          <br />
          <PathwayTemplate
            _id="blank"
            name="Blank"
            available={true}
            shortDescription="Create a custom pathway from scratch."
          />
          {/* 
          <BundledPathwaysLoader
            query={{ companyId: props.company._id }}
            showLoader={true}
          >
            {({ data: bundles }) => (
              <div className="bundles">
                {bundles.map(bundle => (
                  <div key={bundle._id} className="bundle">
                    <h4 className="bundle-name">{bundle.name}</h4>
                    <div className="bundle-templates">
                      {bundle.pathwayTemplates.map(pt => (
                        <PathwayTemplate
                          key={pt._id}
                          _id={pt._id}
                          name={pt.name}
                          available={true}
                          // shortDescription="Create a custom pathway from scratch."
                        />
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </BundledPathwaysLoader> */}
        </Panel>
      </div>
    </View>
  )
}

CreatePathwayChooseTemplate.propTypes = {
  className: PropTypes.string,
  company: PropTypes.object,
}

export default styled(CreatePathwayChooseTemplate)`
  .bundle {
    margin: 2rem 0;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 1rem;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  h3 {
    margin-bottom: 0rem;
  }
  h4 {
    margin-bottom: 1rem;
  }
`
