import BaseIntegrationAdapter from '../BaseIntegrationAdapter'

export interface IntegrationHTTPConfig {
  url: string
  method: string
  successRegex?: string
  successStatusRange?: string
  mappingScript?: string
}

export const HTTPAdapterSchema = {
  type: 'object',
  properties: {
    url: {
      title: 'URL',
      type: 'string',
    },
    method: {
      title: 'HTTP Method',
      type: 'string',
      enum: ['POST', 'GET'],
    },
    successRegex: {
      title: 'Success RegEx',
      type: 'string',
    },
    successStatusRange: {
      title: 'Successful status code range',
      type: 'string',
    },
    abortRegex: {
      title: 'Abort RegEx',
      type: 'string',
    },
    mappingScript: {
      title: 'Parameter Mapping Script',
      type: 'string',
    },
  },
  required: ['url', 'method', 'successRegex'],
  additionalProperties: false,
}

class HTTPAdapter extends BaseIntegrationAdapter {
  name = 'HTTP'
  key = 'http'
  description =
    'Transmit data over HTTP. Great for webhooks and sending data to third party systems.'
  image = 'http'
  schema = HTTPAdapterSchema
}

export default HTTPAdapter
