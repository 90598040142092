import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle'

interface Props {
  className?: string
  title: string
  description: string | JSX.Element
}

const HelpTooltip = ({ className, title, description }: Props) => {
  return (
    <div className={className}>
      <div className="help-tooltip-icon">
        <FontAwesomeIcon fixedWidth icon={faQuestionCircle} />
      </div>
      <div className="help-tooltip">
        <div className="help-tooltip-title">{title}</div>
        <div className="help-tooltip-description">{description}</div>
      </div>
    </div>
  )
}

export default styled(HelpTooltip)`
  position: relative;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  vertical-align: top;

  .help-tooltip {
    display: none;
    z-index: 9000;
    position: absolute;
    top: 0;
    left: 0;
    background: #fffae8;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    padding: 1em;
    width: 100vw;
    min-width: 300px;
    max-width: 400px;
    font-size: 0.875rem;
  }
  &:hover .help-tooltip {
    display: block;
  }
  .help-tooltip-title {
    font-weight: 600;
    margin: 0 0 0.2em 0;
    color: #212529;
  }
  .help-tooltip-description {
    color: #686154;
  }

  .help-tooltip-icon {
    margin-left: 0.2em;
    font-size: 0.8em;

    .svg-inline--fa {
      color: #a7a7a7;
    }
  }
`
