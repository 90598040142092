import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'reactstrap'
import { PanelForm } from '../../../components/Panel'
import { withAlert } from 'react-alert'
import FormError from '../../../../../_shared/components/Form/FormError'
import Form from '../../../../../_shared/components/Form'
import Cardbox, { CardboxItem } from '../../../../../_shared/components/Form/Cardbox'
import apiFetch from '../../../../../_shared/api/apiFetch'

const PathwayFormSchema = {
  title: 'Pathway Scoring Form',
  type: 'object',
  properties: {
    scoringAlgorithm: {
      type: 'string',
    },
  },
  required: ['scoringAlgorithm'],
}

class PathwaySettingsScoring extends React.Component {
  state = {
    isLoading: false,
    error: null,
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    alert: PropTypes.object,
  }

  onSubmit = (data) => {
    if (this.state.isLoading) {
      return
    }

    this.setState({ isLoading: true, error: false }, async () => {
      try {
        await apiFetch('POST', '/pathways/update', {
          pathwayId: this.props.pathway._id,
          doc: data.formData,
        })
        this.props.alert.show('Pathway saved')
        this.setState({ isLoading: false })
      } catch (e) {
        this.setState({ isLoading: false, error: e })
        throw new Error(e)
      }
    })
  }

  renderError() {
    const { error } = this.state
    if (error) {
      return <FormError>{error}</FormError>
    }
  }

  render() {
    const { pathway } = this.props
    const { scoringAlgorithm } = pathway
    const formData = {
      scoringAlgorithm,
    }

    return (
      <Form formData={formData} schema={PathwayFormSchema} onValidSubmit={this.onSubmit}>
        <Cardbox
          label="Scoring Algorithm"
          name="scoringAlgorithm"
          defaultValue="linear"
          vertical
          thin
          help="Choose between several formulas to change the way scores are calculated for this pathway"
        >
          <CardboxItem
            value="linear"
            title="Linear"
            description={
              <span>
                Score&apos;s are based on the user&apos;s score out of the pathway&apos;s maximum
                possible score. A user with 10/20 points will receive a score of 50%.{' '}
                <strong>Useful for quizzes and assessments.</strong>
                <br />
                <var>Formula: User&apos;s Score / Maximum Possible Score</var>
              </span>
            }
          />
          <CardboxItem
            value="fractionalPower"
            title="Fractional Power"
            description={
              <span>
                Fractional power scoring adjusts the user&apos;s score on a curve, essentially
                making lower scores higher. A user with 10/20 points will receive a score of 84%.{' '}
                <strong>Useful for encouragement pathways.</strong>
                <br />
                <var>Formula: (User&apos;s Score/Maximum Possible Score) ^ 0.25</var>
              </span>
            }
          />
        </Cardbox>
        <PanelForm center={false} />

        <hr />
        {this.renderError()}
        <Button size="lg" color="primary" type="submit" disabled={this.state.isLoading}>
          Save Changes
        </Button>
      </Form>
    )
  }
}

export default withAlert()(PathwaySettingsScoring)
