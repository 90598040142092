import React, { useContext } from 'react'
import styled from 'styled-components'
import PageHeader from '../../../components/PageHeader'
import View from '../../../components/View'
import PersonaSocialStyle from './components/PersonaSocialStyle'
import { useSelector } from 'react-redux'
import { AppState } from '../../../types'
import { Company } from '../../../../../_shared/models/company/company.client'
import { PersonaCategories } from '../../../../../_shared/models/persona/persona.types'
import PersonaLoader, { PersonaContext } from './PersonaLoader'
import PersonaFunnel from './PersonaFunnel'
import PersonaInsights from './components/PersonaInsights'
import PersonaAge from './components/PersonaAge'
import PersonaEducation from './components/PersonaEducation'
import PersonaEmployment from './components/PersonaEmployment'
import PersonaChallenges from './components/PersonaChallenges'
import PersonaCareerInterest from './components/PersonaCareerInterest'
import PersonaCareerMotivations from './components/PersonaCareerMotivations'
import PersonaHoldingback from './components/PersonaHoldingback'
import NotFound from '../../NotFound'

// dumb presentation component
type Props = {
  className?: string
  id: string
}

const categories = {
  ...{ overall: PersonaCategories.overall },
  ...PersonaCategories.age,
  ...PersonaCategories.careerFields,
}

const chartOptions = {
  cutout: '75%',
  maintainAspectRatio: false,
  responsive: true,

  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
}

const PersonaStatistics: React.FC<Props> = ({ className }) => {
  const persona = useContext(PersonaContext)

  if (!persona) {
    return <NotFound />
  }

  const title = persona.name || categories[persona.category] || ''

  return (
    <div className={className}>
      <View title={title}>
        <PageHeader
          title={title}
          breadcrumbs={[
            { label: 'Statistics', path: '/statistics' },
            { label: 'Lenses', path: '/statistics/lens' },
          ]}
        />
        <div className="content">
          <PersonaFunnel persona={persona} />

          <div className="row">
            <div className="col col-6 order-md-1 order-lg-0">
              <PersonaSocialStyle persona={persona} />
              <PersonaAge persona={persona} />
              <PersonaEducation persona={persona} />
              <PersonaEmployment persona={persona} />
              <PersonaChallenges persona={persona} />
              <PersonaCareerInterest persona={persona} />
            </div>
            <div className="col col-6 order-md-0 order-lg-1">
              <PersonaInsights persona={persona} />
              <PersonaHoldingback persona={persona} />
            </div>
          </div>

          <PersonaCareerMotivations persona={persona} />
        </div>
      </View>
    </div>
  )
}

const StyledPersonaStatistics = styled(PersonaStatistics)`
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;

  h2 {
    padding: 20px;
  }

  .small-chart-container {
    max-width: 120px;
    max-height: 120px;
    margin: 0 auto;
  }

  .persona-metric-title {
    text-align: center;
    font-weight: 600;
    margin: 0 0 1em 0;
  }
  .persona-doughnut {
    position: relative;
  }
  .persona-doughnut-value {
    position: absolute;
    top: 50%;
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1;
    transform: translateY(-50%);
  }

  .progress-stat {
    margin: 0 0 1em 0;
  }

  .number-stat {
    margin: 0 0 1em 0;
  }

  .number-stat-value {
    color: var(--primary);
    font-size: 1.6rem;
    font-weight: bold;
  }
`

const PersonaView = ({ match, className }) => {
  const selectedCompany = useSelector<AppState, Company>(
    (state) => state.subscriptions.companyContext.data.companies[0] as Company as Company
  )

  return (
    <div className={className}>
      <PersonaLoader companyId={selectedCompany._id} id={match.params.personaKey}>
        <StyledPersonaStatistics id={match.params.personaKey} />
      </PersonaLoader>
    </div>
  )
}

export default styled(PersonaView)`
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: stretch;

  ${PersonaLoader} {
    flex: 1 1 auto;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
  }
`

// data loader component that uses the date range, company id, and persona key
// to get the generated persona data for the view
