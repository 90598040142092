import React from 'react'
import PropTypes from 'prop-types'
import View from '../../components/View'
import PageHeader from '../../components/PageHeader'
import CampaignForm from './CampaignForm'
import { withRouter } from 'react-router-dom'
import { redirect } from '../../components/Link'
import { connect } from 'react-redux'
import apiFetch from '../../../../_shared/api/apiFetch'

function breadcrumbs(props) {
  return [{ label: 'Pathways', path: '/pathways' }]
}

class CampaignCreate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      error: false,
    }
  }

  static propTypes = {
    mock: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
  }

  onSubmit = async (data) => {
    const { location, history } = this.props

    if (this.state.isLoading) {
      return
    }

    data.formData.companyId = this.props.selectedCompany._id

    this.setState({ isLoading: true }, async () => {
      try {
        await apiFetch('POST', '/campaigns/create', { doc: data.formData })
        redirect({
          location,
          history,
          pathname: '/pathways',
        })
      } catch (e) {
        this.setState({ isLoading: false, error: e.message })
        throw new Error(e.message)
      }
    })
  }

  render() {
    const { mock } = this.props
    const title = 'Create Campaign'
    return (
      <View title={title}>
        <PageHeader title={title} breadcrumbs={breadcrumbs(this.props)} />
        <div className="content">
          <CampaignForm
            bundles={mock && mock.bundles}
            onSubmit={this.onSubmit}
            isLoading={this.state.isLoading}
            buttonLabel="Create Campaign"
          />
        </div>
      </View>
    )
  }
}
CampaignCreate.propTypes = {
  mock: PropTypes.object,
  selectedCompany: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  selectedCompany: state.subscriptions.companyContext.data.companies[0],
})

export default connect(mapStateToProps)(withRouter(CampaignCreate))
