import React from 'react'
import styled from 'styled-components'
import { Persona } from '../../../../../../_shared/models/persona/persona.types'
import PersonaPanel from '../PersonaPanel'
import PersonaHorizontalBarChart from './PersonaHorizontalBarChart'
import { PersonaColors } from '../PersonaColors'

interface Props {
  className?: string
  persona: Persona
}

function extractHSL(hsl) {
  const match = hsl.match(/hsl\((\d+),\s*([\d.]+)%,\s*([\d.]+)%\)/i)

  if (!match) {
    throw new Error('Invalid HSL color')
  }

  return {
    hue: match[1], // Hue
    saturation: match[2], // Saturation
    lightness: match[3], // Lightness
  }
}

const primary = extractHSL(PersonaColors.primary)
const secondary = extractHSL(PersonaColors.secondary)

function calculateColorEducation(value, sampleSize) {
  const lightness = primary.lightness - (1 / 5) * Math.floor((value / sampleSize) * 100)
  const saturation = primary.saturation //+(1/7)*Math.floor((value / sampleSize) * 100);
  return `linear-gradient(to right, hsl(${primary.hue},${primary.saturation}%,${primary.lightness}%), hsl(${primary.hue}, ${saturation}%, ${lightness}%))`
}

function calculateColorPostSecondary(value, sampleSize) {
  const lightness = secondary.lightness - (1 / 5) * Math.floor((value / sampleSize) * 100)
  const saturation = secondary.saturation //+(1/7)*Math.floor((value / sampleSize) * 100);
  return `linear-gradient(to right, hsl(${secondary.hue},${secondary.saturation}%,${secondary.lightness}%), hsl(${secondary.hue}, ${saturation}%, ${lightness}%))`
}

const PersonaEducation: React.FC<Props> = ({ className, persona }) => {
  //create items array for highschool
  let itemsHighschool = [
    {
      label: 'Have High School or GED',
      image: calculateColorEducation(persona.metrics.haveHighSchool.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.haveHighSchool.value,
      valuePercentage: Math.floor(
        (persona.metrics.haveHighSchool.value / persona.sampleSize) * 100
      ),
    },
    {
      label: 'Still in High School',
      image: calculateColorEducation(persona.metrics.stillInHighSchool.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.stillInHighSchool.value,
      valuePercentage: Math.floor(
        (persona.metrics.stillInHighSchool.value / persona.sampleSize) * 100
      ),
    },
    {
      label: 'No High School or GED',
      image: calculateColorEducation(persona.metrics.noHighSchool.value, persona.sampleSize),
      color: PersonaColors.tertiary,
      value: persona.metrics.noHighSchool.value,
      valuePercentage: Math.floor((persona.metrics.noHighSchool.value / persona.sampleSize) * 100),
    },
  ]

  //push unknown bar to highschool if there is unknown data
  if (persona.metrics.noHighSchoolData.value > 0) {
    itemsHighschool.push({
      label: 'Unknown',
      image: '',
      color: PersonaColors.tertiary,
      value: persona.metrics.noHighSchoolData.value,
      valuePercentage: Math.floor(
        (persona.metrics.noHighSchoolData.value / persona.sampleSize) * 100
      ),
    })
  }

  //create items array for Post Secondary
  let itemsPostSecondary = [
    {
      label: 'Have Attended Post Secondary',
      image: calculateColorPostSecondary(
        persona.metrics.attendedPostSecondary.value,
        persona.sampleSize
      ),
      color: PersonaColors.tertiary,
      value: persona.metrics.attendedPostSecondary.value,
      valuePercentage: Math.floor(
        (persona.metrics.attendedPostSecondary.value / persona.sampleSize) * 100
      ),
    },
  ]

  //push unknown bar to highschool if there is unknown data
  if (persona.metrics.noPostSecondaryData.value) {
    itemsPostSecondary.push({
      label: 'Unknown',
      image: '',
      color: PersonaColors.tertiary,
      value: persona.metrics.noPostSecondaryData.value,
      valuePercentage: Math.floor(
        (persona.metrics.noPostSecondaryData.value / persona.sampleSize) * 100
      ),
    })

    /*
      // Add a "space" item
    itemsPostSecondary.push({
      label: '',
      image: '',
      color: 'transparent',  // make it transparent
      value: 0,  // no value
      valuePercentage: 0,  // no percentage
    });
  }

  itemsPostSecondary.push({
    label: 'Inquired at other schools',
    image: calculateColorPostSecondary(persona.metrics.inquiredOtherSchools.value, persona.sampleSize),
    color: PersonaColors.primary,
    value: persona.metrics.inquiredOtherSchools.value,
    valuePercentage: Math.floor(
      (persona.metrics.inquiredOtherSchools.value / persona.sampleSize) * 100
    ),
  });

  if(persona.metrics.noInquiryData.value){
    itemsPostSecondary.push({
      label: 'Unknown',
      image: '',
      color: PersonaColors.primary,
      value: persona.metrics.noInquiryData.value,
      valuePercentage: Math.floor(
        (persona.metrics.noInquiryData.value / persona.sampleSize) * 100
      ),
    });*/
  }

  return (
    <div className={className}>
      <PersonaPanel title="Education">
        <PersonaHorizontalBarChart label="High School" items={itemsHighschool} />
        <PersonaHorizontalBarChart label="Post Secondary" items={itemsPostSecondary} />
      </PersonaPanel>
    </div>
  )
}

export default styled(PersonaEducation)``
