import React from 'react'
import PropTypes from 'prop-types'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowUp'
import CompanySelect from '../CompanySelect'
import { Meteor } from 'meteor/meteor'
import { redirect } from '../Link'
import { withRouter } from 'react-router-dom'
import Form from '../../../../_shared/components/Form'
import apiFetch from '../../../../_shared/api/apiFetch'

class MovePathwayModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      redirect: null,
      company: null,
    }
  }

  static propTypes = {
    pathway: PropTypes.object.isRequired,
    tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  }

  toggle = () => {
    this.setState(
      {
        modal: !this.state.modal,
      },
      () => {
        if (this.state.modal === false) {
          document.body.click()
        }
      }
    )
  }

  chooseCompany = (company) => {
    this.setState({
      company: company ? company.value : null,
    })
  }

  moveCompany = async () => {
    const { pathway, location, history } = this.props
    if (!this.state.company) return

    try {
      await apiFetch('POST', '/pathways/move', {
        pathwayId: pathway._id,
        companyId: this.state.company,
      })
      redirect({
        location,
        history,
        pathname: `/pathways`,
      })
    } catch (e) {
      throw new Error(e)
    }
  }

  render() {
    const {
      pathway,
      tag,
      location,
      history,
      staticContext,
      match,
      buttonProps,
      ...rest
    } = this.props

    const HandleTag = tag || 'div'

    return (
      <div>
        <HandleTag {...buttonProps} onClick={this.toggle}>
          <FontAwesomeIcon fixedWidth icon={faArrowUp} /> Move
        </HandleTag>
        <Modal centered isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Move Pathway</ModalHeader>
          <ModalBody>
            <Form>
              <CompanySelect
                name="company"
                label="New Parent Company"
                help={`Choose the new company that <strong>${pathway.name}</strong> should belong to.`}
                multi={false}
                onChange={this.chooseCompany}
              />
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
            <Button color="primary" onClick={this.moveCompany} disabled={!this.state.company}>
              Move Pathway
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

export default withRouter(MovePathwayModal)
