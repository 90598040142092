import React from 'react'
import { withRouter, Link, RouteComponentProps } from 'react-router-dom'
import { Button } from 'reactstrap'
import View from '../components/View'
import SimpleLayout from '../components/layouts/SimpleLayout'
import Form from '../../../_shared/components/Form'
import Input from '../../../_shared/components/Form/Input'
import FormError from '../../../_shared/components/Form/FormError'

const FormSchema = {
  type: 'object',
  properties: {
    password: {
      type: 'string',
    },
  },
  required: ['password'],
}

interface State {
  error: string
  isLoading: boolean
  message: string
}

interface MatchProps {
  token: string
}

class ResetPassword extends React.Component<RouteComponentProps<MatchProps>, State> {
  state = {
    error: null,
    isLoading: false,
    message: null,
  }

  onSubmit = (data) => {
    const { password } = data.formData
    const { token } = this.props.match.params
    const { history } = this.props
    this.setState({ isLoading: true, error: null, message: null }, async () => {
      try {
        const result = await fetch(process.env.API_URL + '/resetPassword', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json', // eslint-disable-line
          },
          body: JSON.stringify({
            token,
            password,
          }),
        })
        const json = await result.json()

        if (json.error) {
          this.setState({
            isLoading: false,
            error: json.error,
          })
        } else {
          history.push('/')
        }
      } catch (e) {
        this.setState({
          isLoading: false,
          error: e.reason,
        })
      }
    })
  }

  render() {
    const { error, message, isLoading } = this.state
    return (
      <View title="Reset password">
        <SimpleLayout>
          <Form
            autocomplete="off"
            schema={FormSchema}
            onValidSubmit={this.onSubmit}
            warnOnUnsavedLeave={false}
          >
            <img src="/logo-white.svg" className="logo" width="60%" />
            <Input
              type="password"
              autocomplete="off"
              name="password"
              label="Choose a new password:"
            />

            {error && <FormError>{error}</FormError>}
            {message && <div className="alert alert-primary">{message}</div>}
            {!message && (
              <Button color="primary" type="submit" block disabled={isLoading}>
                Save New Password
              </Button>
            )}
            <br />
            <div className="links text-center">
              <Link to="/login">or return to login</Link>
            </div>
          </Form>
        </SimpleLayout>
      </View>
    )
  }
}

export default withRouter(ResetPassword)
